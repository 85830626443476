import React from 'react'
import { OnsiteInferenceResultProps } from './types'
import {
  Card,
  Typography,
  CardContent,
  Box,
  Checkbox,
  Divider,
} from '@mui/material'
import { ColorBox } from 'views/components/atoms'

export const OnsiteInferenceResult: React.FC<OnsiteInferenceResultProps> = (
  props: OnsiteInferenceResultProps
) => {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent
        sx={{
          padding: 0,
          ':last-child': { paddingBottom: 0 },
        }}
      >
        <Box display='flex' alignItems='center' pl={1} pt={2} pr={3}>
          <Checkbox
            checked={props.checked}
            size='small'
            color='secondary'
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              e.stopPropagation()
            }
            onChange={props.onClickCheckbox}
          />
          <Typography
            color='inherit'
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={props.onClickLink}
            data-testid={props.trainedModelId}
          >{`${props.trainedModelName} - ${props.trainedModelVersion}`}</Typography>
          {props.optional && (
            <Typography
              sx={{ marginLeft: (theme) => theme.spacing(1) }}
              variant='body2'
              color='textSecondary'
            >
              {props.optional}
            </Typography>
          )}
        </Box>
        <Box p={3} pl={4} pt={0}>
          {props.canvasInfos.map((canvasInfo, i) => (
            <Box key={i}>
              <Box p={1} display='flex' alignItems='center'>
                <ColorBox
                  color={canvasInfo.color}
                  sx={{ marginRight: (theme) => theme.spacing(1) }}
                />
                <Typography>{canvasInfo.id.substring(0, 8)}</Typography>
                <Typography
                  sx={{ marginLeft: (theme) => theme.spacing(1) }}
                  variant='body2'
                  color='textSecondary'
                >
                  {`${Number(((canvasInfo.score ?? 0) * 100).toFixed(3))}%`}
                </Typography>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}
