import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { FileRejection } from 'react-dropzone'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { State } from 'state/store'

import {
  FileSelectableDropzone,
  MetadataInput,
  Toast,
  showToast,
  CommonCompleteDialog,
  ErrorMessage,
  GlobalLoading,
  CommonStepper,
  BreadcrumbsComponent,
  SelectableTable,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  RADIO_ROW_HEIGHT,
  SearchInput,
} from 'views/components'
import Box from '@mui/material/Box'
import {
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  useTheme,
} from '@mui/material'
import { Timestamp } from 'firebase/firestore'
import { formatDateTimeSec } from 'views/components/utils/date'
import {
  Metadata,
  SceneCamera,
  SceneCameraEntryAction,
  sceneCameraEntryActions,
  sceneCameraEntryOperations,
  SceneCameraEntryStateKindArray,
  SceneCameraFile,
  SceneCameraListDisplayCondition,
} from 'state/ducks/sceneCameraEntry'

// How to simplify the description of react-redux.
const mapStateToProps = (state: State) => ({
  ...state.pages.sceneCameraEntryState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, SceneCameraEntryAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** カメラ配置一覧取得 */
  getSceneCameraList: () => {
    dispatch(sceneCameraEntryOperations.getSceneCameraList())
  },
  /** リストの表示条件の変更 */
  setListDisplayCondition: (listCondition: SceneCameraListDisplayCondition) =>
    dispatch(
      sceneCameraEntryActions.setSceneCameraListDisplayCondition(listCondition)
    ),
  /** カメラ配置一覧のクリア */
  clearSceneCameraList: () =>
    dispatch(sceneCameraEntryActions.clearSceneCameraList()),
  /** snapshotの購読解除 */
  unsubscribe: () => dispatch(sceneCameraEntryOperations.unsubscribe()),
  /** メタデータの更新 */
  setMetadata: (metadata: Metadata) =>
    dispatch(sceneCameraEntryActions.setMetadata(metadata)),
  /** 次のステップに戻る */
  nextStep: () => dispatch(sceneCameraEntryOperations.nextStep()),
  /** 前のステップに戻る */
  prevStep: () => dispatch(sceneCameraEntryOperations.prevStep()),
  /** revision更新 で選択したカメラ設定を設定 */
  setSelectedSceneCamera: (sceneCamera?: SceneCamera) =>
    dispatch(sceneCameraEntryActions.setSelectedSceneCamera(sceneCamera)),
  /** 新規作成/revision更新 のラジオボタンの値を設定 */
  setIsUpdateRevision: (isUpdateRevision: boolean) =>
    dispatch(sceneCameraEntryActions.setIsUpdateRevision(isUpdateRevision)),
  /** カメラ設定ファイルを設定 */
  setSceneCameraSettingFile: (file: File) =>
    dispatch(sceneCameraEntryActions.setCameraSettingFile(file)),
  /** カメラ設定ファイルのバリデーション */
  validateSceneCameraFile: (file: File) =>
    dispatch(sceneCameraEntryOperations.validateSceneCameraFile(file)),
  /** カメラ設定作成 */
  createSceneCamera: () =>
    dispatch(sceneCameraEntryOperations.createSceneCamera()),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () =>
    dispatch(sceneCameraEntryActions.setToastInfo(undefined)),
  /** Stateのクリア */
  clearSceneCameraEntryState: () =>
    dispatch(sceneCameraEntryActions.clearSceneCameraEntryState()),
})

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'aug-3d-scene-camera-id',
    title: 'カメラ配置ID',
    width: 180,
    sortable: false,
    position: 'center',
  },
  {
    id: 'name',
    title: 'カメラ配置名',
    width: 240,
    sortable: false,
    position: 'left',
  },
  {
    id: 'overview',
    title: '概要',
    width: 240,
    sortable: false,
    position: 'left',
  },
  {
    id: 'created-at',
    title: '作成日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'created-by',
    title: '作成ユーザーID',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

const HEADER_HEIGHT = 64
const FOOTER_HEIGHT = 100
const HEADER_MARGIN_BOTTOM = 24
const STEPPER_HEIGHT = 186
const BREADCRUMBS_HEIGHT = 40
/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${HEADER_MARGIN_BOTTOM}px - ${STEPPER_HEIGHT}px - ${FOOTER_HEIGHT}px - ${BREADCRUMBS_HEIGHT}px)`,
    overflowY: 'auto',
  },
  stepTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  errorField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  fileNameForm: {
    width: '100%',
    height: '70px',
    marginBottom: theme.spacing(2),
  },
  invisible: {
    display: 'none',
  },
  fileNameTextField: {
    width: '100%',
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
  contentDiv: {
    clear: 'both',
  },
  stepper: {
    margin: theme.spacing(3),
    marginTop: 0,
    marginBottom: '0px',
  },
  footerButtons: {
    height: FOOTER_HEIGHT,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  outputFormatSelection: {
    display: 'flex',
    justifyContent: 'felx-start',
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  head: {
    height: STEPPER_HEIGHT,
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textField: {
    width: '100%',
    color: '#000 !important',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
}))

const STEP_NAMES = ['登録先', 'カメラ配置設定', 'メタデータ', '確認']

const CameraInfoConfirmView: React.FC<Props> = (props) => {
  return (
    <Box component={Paper} mt={2} p={'24px 32px 32px'} width='100%'>
      <Typography>カメラ配置情報</Typography>
      <Box mt={1}>
        <Box mt={1} mb={2}>
          <MetadataInput
            nameProps={{
              label: '表示名',
              value: props.domainData.selectedSceneCamera
                ? props.domainData.selectedSceneCamera.name
                : props.domainData.metadata.name,
              readOnly: true,
              variant: 'standard',
            }}
            remarksProps={{
              label: '備考',
              value: props.domainData.selectedSceneCamera
                ? props.domainData.selectedSceneCamera.overview
                : props.domainData.metadata.overview,
              readOnly: true,
              variant: 'standard',
              rowNum: 0,
            }}
            textFieldSpace={0}
            helperText=''
            data-testid={'customModelInputPreview'}
          />
        </Box>
      </Box>
    </Box>
  )
}

const CameraSettingConfirmView: React.FC<Props> = (props) => {
  const { classes } = useStyles()

  const [parsedSceneCameraFile, setParsedSceneCameraFile] = useState<
    SceneCameraFile | undefined
  >(undefined)

  const revision = useMemo(
    () =>
      props.domainData.selectedSceneCamera !== undefined
        ? props.domainData.selectedSceneCamera.revision + 1
        : 0,
    [props.domainData.selectedSceneCamera]
  )

  useEffect(() => {
    const sceneCameraFile = props.domainData.cameraSettingFile
    if (sceneCameraFile === undefined) return undefined

    const parseFile = async () => {
      try {
        const text = await sceneCameraFile.text()
        const jsonObject = JSON.parse(text)
        setParsedSceneCameraFile(jsonObject)
      } catch (error) {
        console.error('Failed to parse JSON:', error)
      }
    }

    parseFile()
  }, [props.domainData.cameraSettingFile])

  return (
    <Box component={Paper} mt={2} p={'24px 32px 32px'} width='100%'>
      <Typography>カメラ配置設定</Typography>
      <Box mt={1}>
        <Box mt={1} mb={2}>
          <TextField
            className={classes.textField}
            variant='standard'
            label='Revision'
            value={revision}
            key={'key'}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Box mt={2}>
          <TextField
            className={classes.textField}
            variant='standard'
            label='位置（location）'
            value={
              parsedSceneCameraFile
                ? parsedSceneCameraFile.camera.location.join(', ')
                : ''
            }
            key={'key'}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Box mt={2}>
          <TextField
            className={classes.textField}
            variant='standard'
            label='画角（fov）'
            value={
              parsedSceneCameraFile
                ? parsedSceneCameraFile.camera.fov.join(', ')
                : ''
            }
            key={'key'}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Box mt={2}>
          <TextField
            className={classes.textField}
            variant='standard'
            label='視点（look-at）'
            value={
              parsedSceneCameraFile
                ? parsedSceneCameraFile.camera['look-at'].join(', ')
                : ''
            }
            key={'key'}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Box mt={2}>
          <TextField
            className={classes.textField}
            variant='standard'
            label='フォーマットバージョン'
            value={
              parsedSceneCameraFile ? parsedSceneCameraFile.schema_version : ''
            }
            key={'key'}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

const SceneCameraEntry: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()

  const {
    isEnableNextButton,
    isOpenCompleteDialog,
    handleClickNext,
    handleClickPrev,
    handleCloseCompleteDialog,
  } = useSceneCameraEntry(props)

  useEffect(() => {
    props.getSceneCameraList()
    return () => {
      props.clearSceneCameraEntryState()
      props.unsubscribe()
    }
  }, [])

  return (
    <div className={classes.container}>
      <Toast containerOptions={{ limit: 20 }}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            { name: 'カメラ配置一覧', path: '3d-scene-cameras' },
            { name: 'カメラ配置追加', path: 'entry' },
          ]}
        />
        <div className={classes.head}>
          <div className={classes.flexAndBetween}>
            <h2 data-testid='camera-entry-title'>カメラ配置</h2>
          </div>
          <div className={classes.stepper}>
            <CommonStepper
              stepLabels={STEP_NAMES}
              activeStepIndex={SceneCameraEntryStateKindArray.findIndex(
                (item) => item === props.appState.sceneCameraEntryState
              )}
            />
          </div>
        </div>
        <Box className={classes.content}>
          <StepContent {...props} />
        </Box>
        <div className={classes.footerButtons}>
          <div className={classes.footer}>
            {props.appState.sceneCameraEntryState !== 'DestinationState' ? (
              <Button
                variant='contained'
                color='primary'
                disabled={false}
                onClick={handleClickPrev}
                className={classes.leftButton}
              >
                戻る
              </Button>
            ) : (
              <div></div>
            )}

            <Button
              data-testid='next-step'
              variant='contained'
              color='primary'
              disabled={!isEnableNextButton}
              onClick={handleClickNext}
              className={classes.rightButton}
            >
              {props.appState.sceneCameraEntryState === 'ExecuteState'
                ? '作成'
                : '次へ'}
            </Button>
          </div>
        </div>
        <CommonCompleteDialog
          dialogText='正常にデータを登録しました。'
          label='カメラ配置ID'
          value={props.domainData.createdCameraId ?? ''}
          open={isOpenCompleteDialog}
          handleClose={() => handleCloseCompleteDialog()}
          data-testid='complete-dialog'
        />
      </Toast>
      <GlobalLoading open={props.appState.inProgress} />
    </div>
  )
}

/**
 * ステップ毎に切り替わるコンテンツ
 */
const StepContent = (props: Props) => {
  const { classes } = useStyles()
  const { handleChangeSearchValue, searchTableContent } =
    useSceneCameraList(props)

  const handleClickIsUpdateRevisionRadio = useCallback(
    (isUpdateRevision: boolean) => {
      if (!isUpdateRevision) props.setSelectedSceneCamera(undefined)
      props.setIsUpdateRevision(isUpdateRevision)
    },
    [props.setIsUpdateRevision]
  )

  const showErrorToast = useCallback(
    (fileName: string, messages: string[]) =>
      showToast(
        'error',
        <div>
          <div>{'メッセージ種別: error'}</div>
          <div>{`ファイル名: ${fileName}`}</div>
          <div>
            {messages.map((message) => (
              <li key={message}>{message}</li>
            ))}
          </div>
        </div>
      ),
    [showToast]
  )

  const notifySceneCameraFileErrors = useCallback(
    (fileRejections: FileRejection[]) =>
      fileRejections.forEach((errorFile) => {
        const errorFileName = errorFile.file.name
        const fileErrors = errorFile.errors
        const errorMessage: string[] = []
        fileErrors.forEach((errorFileMessage) => {
          switch (errorFileMessage.code) {
            case 'file-too-large': {
              errorMessage.push('ファイルサイズが不正です')
              break
            }
            case 'file-invalid-type': {
              errorMessage.push('選択されたファイルの形式が不正です')
              break
            }
            default:
              break
          }
        })
        showErrorToast(errorFileName, errorMessage)
      }),
    [showErrorToast]
  )

  /** トーストのコンポーネントを取得する */
  const getToastContent = (title: string, targets: string[]) => (
    <>
      <Typography>{title}</Typography>
      {targets.length > 0 && (
        <List>
          {targets.map((item) => (
            <ListItem key={item} dense>
              <ListItemText primary={item} className={classes.toastItemText} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  )

  /** トースト情報があった場合、表示する */
  useEffect(() => {
    if (props.appState.toastInfo) {
      showToast(
        props.appState.toastInfo.type,
        getToastContent(
          props.appState.toastInfo.title,
          props.appState.toastInfo.targets
        )
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  switch (props.appState.sceneCameraEntryState) {
    case 'DestinationState':
      return (
        <div>
          <div className={classes.outputFormatSelection}>
            <RadioGroup value={props.domainData.isUpdateRevision} row>
              <FormControlLabel
                data-testid='new'
                value={false}
                control={<Radio size='small' color='secondary' />}
                label='新規'
                onClick={() => handleClickIsUpdateRevisionRadio(false)}
              />
              <FormControlLabel
                data-testid='update-revision'
                value={true}
                control={<Radio size='small' color='secondary' />}
                label='revision 更新'
                onClick={() => handleClickIsUpdateRevisionRadio(true)}
              />
            </RadioGroup>
          </div>
          {props.domainData.isUpdateRevision && (
            <>
              <div className={classes.searchForm}>
                <div className={classes.searchField}>
                  <SearchInput
                    placeholder='キーワード (カメラ配置ID)'
                    value={
                      props.domainData.sceneCameraListDisplayCondition
                        .searchValue
                    }
                    onChangeValue={(event) =>
                      handleChangeSearchValue(event.target.value)
                    }
                    onClickSearch={() => searchTableContent()}
                    onPressEnter={() => searchTableContent()}
                  />
                </div>
              </div>
              <SceneCameraList {...props} />
            </>
          )}
        </div>
      )
    case 'UploadState':
      return (
        <div className={classes.contentDiv} data-testid='camera'>
          <div className={classes.stepTitle}>
            <Typography>カメラ配置ファイルを選択します。 </Typography>
          </div>
          <FileSelectableDropzone
            acceptFileOperation={(files: File[]) =>
              props.validateSceneCameraFile(files[0])
            }
            rejectedFileOperation={(fileRejections: FileRejection[]) =>
              notifySceneCameraFileErrors(fileRejections)
            }
            fileType='application/json'
            maxSize={1 * 1000 * 1000}
            data-testid={'inputSceneCameraFileDropzone'}
          />
          <Box
            mt={1}
            className={
              props.domainData.cameraSettingFile
                ? classes.fileNameForm
                : classes.invisible
            }
          >
            <TextField
              className={classes.fileNameTextField}
              variant='standard'
              id='filename'
              label='ファイル名'
              value={props.domainData.cameraSettingFile?.name ?? '   '}
              InputProps={{
                readOnly: true,
              }}
              required
            />
          </Box>
          <div>
            <div className={classes.stepTitle}></div>
          </div>
        </div>
      )
    case 'MetadataState':
      return (
        <div>
          <div className={classes.stepTitle}>
            <Typography>カメラ配置情報</Typography>
            <Box mt={1}>
              <MetadataInput
                nameProps={{
                  label: '表示名',
                  value: props.domainData.selectedSceneCamera
                    ? props.domainData.selectedSceneCamera.name
                    : props.domainData.metadata?.name,
                  variant: 'outlined',
                  readOnly: props.domainData.selectedSceneCamera ? true : false,
                  onChange: (event) => {
                    props.setMetadata({
                      ...props.domainData.metadata,
                      name: event.target.value,
                    })
                  },
                }}
                remarksProps={{
                  label: '概要',
                  value: props.domainData.selectedSceneCamera
                    ? props.domainData.selectedSceneCamera.overview
                    : props.domainData.metadata?.overview,
                  variant: 'outlined',
                  readOnly: props.domainData.selectedSceneCamera ? true : false,
                  onChange: (event) => {
                    props.setMetadata({
                      ...props.domainData.metadata,
                      overview: event.target.value,
                    })
                  },
                  rowNum: 0,
                }}
                data-testid={'metadata-input'}
                helperText=''
              />
            </Box>
          </div>
        </div>
      )
    case 'ExecuteState':
      return (
        <>
          <CreateSceneCameraErrorMessage {...props} />
          <CameraInfoConfirmView {...props} />
          <CameraSettingConfirmView {...props} />
        </>
      )
    default:
      return <></>
  }
}

/**
 * カメラ設定追加画面のロジック
 */
const useSceneCameraEntry = (props: Props) => {
  // 作成完了ダイアログの表示状態
  const [isOpenCompleteDialog, setIsOpenCompleteDialog] = useState(false)

  /** 次へ/作成ボタンの活性/非活性の制御 */
  const isEnableNextButton = useMemo(() => {
    switch (props.appState.sceneCameraEntryState) {
      case 'DestinationState':
        return props.domainData.isUpdateRevision &&
          props.domainData.selectedSceneCamera === undefined
          ? false
          : true
      case 'UploadState':
        return props.domainData.cameraSettingFile !== undefined
      case 'MetadataState':
        return (
          props.domainData.selectedSceneCamera !== undefined ||
          props.domainData.metadata.name !== ''
        )
      default:
        return true
    }
  }, [
    props.domainData.selectedSceneCamera,
    props.appState.sceneCameraEntryState,
    props.domainData.cameraSettingFile,
    props.domainData.metadata.name,
    props.domainData.isUpdateRevision,
  ])

  /** 次へ/作成ボタン押下時の処理 */
  const handleClickNext = useCallback(async () => {
    switch (props.appState.sceneCameraEntryState) {
      case 'ExecuteState':
        await props.createSceneCamera()
        break
      default:
        props.nextStep()
        break
    }
  }, [props.appState.sceneCameraEntryState])

  /** 戻るボタン押下時の処理 */
  const handleClickPrev = useCallback(() => {
    props.prevStep()
  }, [props.prevStep])

  /** 追加完了ダイアログを閉じる際の処理 */
  const handleCloseCompleteDialog = useCallback(() => {
    setIsOpenCompleteDialog(false)
    props.history.push('/3d-scene-cameras')
  }, [setIsOpenCompleteDialog])

  useEffect(() => {
    if (props.appState.sceneCameraEntrySubState.createSubState === 'Created') {
      setIsOpenCompleteDialog(true)
    }
  }, [props.appState.sceneCameraEntrySubState.createSubState])

  return {
    isOpenCompleteDialog,
    isEnableNextButton,
    handleClickNext,
    handleClickPrev,
    handleCloseCompleteDialog,
  }
}

/**
 * カメラ設定一覧に関するロジック
 */
const useSceneCameraList = (props: Props) => {
  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    props.setListDisplayCondition({
      ...props.domainData.sceneCameraListDisplayCondition,
      searchValue: value,
    })
  }
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    const pageNumber =
      props.domainData.currentSceneCameraList.length >
      props.domainData.sceneCameraListDisplayCondition.pageNumber *
        displayNumber
        ? props.domainData.sceneCameraListDisplayCondition.pageNumber
        : Math.ceil(
            props.domainData.currentSceneCameraList.length / displayNumber
          ) - 1

    props.setListDisplayCondition({
      ...props.domainData.sceneCameraListDisplayCondition,
      pageNumber: pageNumber,
      displayNumber: displayNumber,
    })

    props.getSceneCameraList()
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    props.clearSceneCameraList()
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.sceneCameraListDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.sceneCameraListDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })

    props.getSceneCameraList()
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.sceneCameraListDisplayCondition,
      pageNumber: pageNumber,
    })

    props.getSceneCameraList()
  }

  /** 検索の実行 */
  const searchTableContent = () => {
    props.clearSceneCameraList()
    props.setListDisplayCondition({
      ...props.domainData.sceneCameraListDisplayCondition,
      pageNumber: 0,
    })

    props.getSceneCameraList()
  }

  /** カメラ配置のラジオボタンが選択された時に切り替える処理 */
  const handleClickSceneCameraTableRadio = (row: number) => {
    const sceneCamera = props.domainData.currentSceneCameraList[row]
    props.setSelectedSceneCamera(sceneCamera)
  }

  /** カメラ配置一覧の選択値 */
  const sceneCameraListIndex = useMemo(() => {
    if (
      props.domainData.selectedSceneCamera === undefined ||
      props.domainData.currentSceneCameraList.length === 0
    ) {
      return -1
    }
    return props.domainData.currentSceneCameraList.findIndex(
      (sceneCamera) =>
        sceneCamera.id === props.domainData.selectedSceneCamera?.id
    )
  }, [
    props.domainData.selectedSceneCamera,
    props.domainData.currentSceneCameraList,
  ])

  /** カメラ配置一覧の初期値設定 */
  useEffect(() => {
    if (
      props.domainData.selectedSceneCamera === undefined &&
      props.domainData.currentSceneCameraList.length > 0 &&
      props.domainData.isUpdateRevision
    ) {
      props.setSelectedSceneCamera(props.domainData.currentSceneCameraList[0])
    }
  }, [
    props.domainData.selectedSceneCamera,
    props.domainData.currentSceneCameraList,
    props.domainData.isUpdateRevision,
  ])

  return {
    sceneCameraListIndex,
    handleChangeSearchValue,
    handleChangeDisplayNumber,
    changeTableSortOrder,
    pageChange,
    searchTableContent,
    handleClickSceneCameraTableRadio,
  }
}

/**
 * カメラ設定一覧コンポーネント
 */
const SceneCameraList = (props: Props) => {
  const globalTheme = useTheme()
  const {
    sceneCameraListIndex,
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleClickSceneCameraTableRadio,
  } = useSceneCameraList(props)

  /** テーブルに表示する配列 */
  const tableContent = useMemo(() => {
    // 表示条件
    const condition = props.domainData.sceneCameraListDisplayCondition

    const sceneCameraList = props.domainData.currentSceneCameraList.map(
      (sceneCamera) => ({
        id: sceneCamera.id,
        name: sceneCamera.name,
        overview: sceneCamera.overview,
        createdAt: sceneCamera.createdAt,
        createdBy: sceneCamera.createdBy,
      })
    )

    // 表示条件に合わせて配列を加工
    const displayList = sceneCameraList.slice(
      condition.displayNumber * condition.pageNumber,
      condition.displayNumber * condition.pageNumber + condition.displayNumber
    )

    // 表示対象が存在しない場合は、前のページの一覧を表示
    if (displayList.length === 0 && condition.pageNumber !== 0) {
      return sceneCameraList.slice(
        condition.displayNumber * (condition.pageNumber - 1),
        condition.displayNumber * (condition.pageNumber - 1) +
          condition.displayNumber
      )
    }

    return displayList
  }, [
    props.domainData.sceneCameraListDisplayCondition,
    props.domainData.currentSceneCameraList,
  ])

  /** テーブルに表示するモデルのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return tableContent.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'createdBy') {
          if (value) {
            return (
              <Typography key={key}>
                {(value as string).substring(0, 8)}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'id') {
          return (
            <Tooltip
              key={key}
              data-testid={`scene-camera-${value}`}
              title={value}
              placement='right-start'
            >
              <Typography key={key}>
                {(value as string).substring(0, 8)}
              </Typography>
            </Tooltip>
          )
        } else if (key === 'createdAt') {
          if (value) {
            return (
              <Typography key={key}>
                {value
                  ? formatDateTimeSec((value as Timestamp).toDate())
                  : TABLE_CELL_NOT_APPLICABLE}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else {
          if (value) {
            return <Typography key={key}>{value}</Typography>
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [tableContent])

  return (
    <SelectableTable
      displayNumber={
        props.domainData.sceneCameraListDisplayCondition.displayNumber
      }
      headers={TABLE_HEADERS}
      rows={tableRows}
      totalCount={props.domainData.sceneCameraListDisplayCondition.totalCount}
      loading={props.appState.inProgress}
      tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
      fixedColumnNumber={0}
      page={props.domainData.sceneCameraListDisplayCondition.pageNumber}
      sortOrder={{
        key: props.domainData.sceneCameraListDisplayCondition.sortKey,
        order: props.domainData.sceneCameraListDisplayCondition.sortOrder,
      }}
      selectedRowNumber={sceneCameraListIndex}
      onClickRadio={(index) => handleClickSceneCameraTableRadio(index)}
      onClickOrderChange={(key: string) => changeTableSortOrder(key)}
      onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
      onChangeDisplayNumber={(displayNumber: number) =>
        handleChangeDisplayNumber(displayNumber)
      }
    />
  )
}

/** カメラ設定作成失敗時のメッセージコンポーネント */
const CreateSceneCameraErrorMessage = (props: Props): JSX.Element => {
  const errorMessages: string[] = []
  if (
    props.appState.sceneCameraEntrySubState.createSubState === 'CreateError'
  ) {
    errorMessages.push('カメラ設定の追加に失敗しました。')
    return <ErrorMessage title='' targets={errorMessages} />
  } else return <></>
}

export const SceneCameraEntryPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SceneCameraEntry))
