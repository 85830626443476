import React, { useMemo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { FileRejection } from 'react-dropzone'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import HandymanIcon from '@mui/icons-material/Handyman'
import TimerIcon from '@mui/icons-material/Timer'

import { State } from 'state/store'
import {
  ModelGroupDetailAction,
  modelGroupDetailActions,
  ModelGroupDetailOperations,
  RelatedTrainedModel,
  Setting,
  SettingListDisplayCondition,
  TrainedModelListDisplayCondition,
} from 'state/ducks/modelGroupDetail'

import { isUndefined } from 'utils/typeguard'
import {
  GlobalLoading,
  EditableTextField,
  CopyableLabel,
  Toast,
  ModelGroupsIcon,
  SelectableTable,
  SelectableTableHeader,
  TooltipLink,
  TABLE_HEADER_HEIGHT,
  DISPLAY_NONE_RADIO_ROW_HEIGHT,
  FileUploadViewer,
  showToast,
  BreadcrumbsComponent,
  DataDetailItem,
  PostAddIconButton,
  STATUS_PROGRESS_BAR_ROW_HEIGHT,
} from 'views/components'
import { formatDateTimeSec } from 'views/components/utils/date'
import { TabItems } from 'views/components/organisms/tabLayout/types'
import { useTheme } from '@mui/material/styles'
import { handleResourceNotFound } from 'views/containers/utils'
import { hasSharedUserGroupQueryParameter } from 'views/containers/utils/queryParams'
import { Dialog, DialogContent } from '@mui/material'
import { CreateSettingPage } from '../createSetting'

const mapStateToProps = (state: State) => ({
  ...state.pages.modelGroupDetailState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, ModelGroupDetailAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** モデルグループ詳細取得 */
  getModelGroupDetail: (modelGroupId: string, isSharedUserGroup: boolean) =>
    dispatch(
      ModelGroupDetailOperations.getModelGroupDetail(
        modelGroupId,
        isSharedUserGroup
      )
    ),
  /** Stateのクリア */
  clearModelGroupDetailState: () =>
    dispatch(modelGroupDetailActions.clearModelGroupDetailState()),
  /** モデルグループの名前を更新する */
  updateModelGroupName: (docId: string, name: string) =>
    dispatch(
      ModelGroupDetailOperations.updateModelGroupDetailName(docId, name)
    ),
  /** モデルグループのモデルアイコンを更新する */
  updateModelGroupIcon: (docId: string, uploadGroupIcon: File) =>
    dispatch(
      ModelGroupDetailOperations.updateModelGroupIcon(docId, uploadGroupIcon)
    ),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (listCondition: TrainedModelListDisplayCondition) =>
    dispatch(modelGroupDetailActions.setListDisplayCondition(listCondition)),
  /** 次ページの活性非活性の切り替え */
  setModelListPagingState: (tableNextPageSubState: 'Enable' | 'Unable') =>
    dispatch(
      modelGroupDetailActions.setModelListPagingState(tableNextPageSubState)
    ),
  /** モデルグループのremarksを更新する */
  updateModelGroupRemarks: (docId: string, remarks: string) =>
    dispatch(
      ModelGroupDetailOperations.updateModelGroupDetailRemarks(docId, remarks)
    ),
  /** モデルグループのoverviewを更新する */
  updateModelGroupOverview: (docId: string, overview: string) =>
    dispatch(
      ModelGroupDetailOperations.updateModelGroupOverview(docId, overview)
    ),
  /** アイコンを削除 */
  deleteModelGroupIcon: (docId: string) =>
    dispatch(ModelGroupDetailOperations.deleteModelGroupIcon(docId)),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () =>
    dispatch(modelGroupDetailActions.setToastInfo(undefined)),
  /** リストの表示条件の変更 */
  setSettingListDisplayCondition: (
    displayCondition: SettingListDisplayCondition
  ) =>
    dispatch(
      modelGroupDetailActions.setSettingListDisplayCondition(displayCondition)
    ),
  /** セッティング一覧を取得する */
  getSettingList: (isSharedUserGroup: boolean) =>
    dispatch(ModelGroupDetailOperations.getSettingList(isSharedUserGroup)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'trainedModelId',
    title: 'モデル ID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'trainedModelVersion',
    title: 'バージョン',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'trainedModelName',
    title: 'モデル名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'inheritedVersion',
    title: '継承バージョン',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'status',
    title: 'ステータス',
    width: 150,
    sortable: false,
    position: 'center',
  },
]

/** テーブルのヘッダー */
const TABLE_HEADERS_FOR_SETTING_LIST: SelectableTableHeader[] = [
  {
    id: 'settingId',
    title: 'セッティングID',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'name',
    title: 'セッティング名',
    width: 250,
    sortable: false,
    position: 'left',
  },
  {
    id: 'settingGroupVersion',
    title: 'バージョン',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'createdAt',
    title: '登録日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'datasetTemplate',
    title: 'データセットテンプレート',
    width: 250,
    sortable: false,
    position: 'left',
  },
  {
    id: 'remarks',
    title: 'Remarks',
    width: 300,
    sortable: false,
    position: 'left',
  },
]

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  header: {
    height: '240px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tabContents: {
    '& > .MuiPaper-root': {
      backgroundColor: '#fafafa',
    },
  },
  noteTabButton: {
    backgroundColor: '#D9E5FF',
  },
  noteTabButtonDisabled: {
    backgroundColor: theme.palette.grey[100],
  },
  mt2Box: {
    marginTop: '16px',
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
  mediaSizeText: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionContent: {
    marginBottom: '16px',
  },
  iconDropZone: {
    width: '200px',
    position: 'relative',
  },
  innerContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& > .MuiPaper-root': {
      backgroundColor: 'inherit',
    },
  },
  nowTab: {
    backgroundColor: theme.palette.grey[200],
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  dataCreateDialog: {
    padding: theme.spacing(2),
    backgroundColor: '#fafafa',
  },
}))

const AlgorithmSpecificContent: React.FC<Props> = (props: Props) => {
  if (isUndefined(props.domainData.currentTrainedModelGroupDetail?.extended))
    return <></>

  if (
    !isUndefined(
      props.domainData.currentTrainedModelGroupDetail?.extended
        .objectClassification
    )
  ) {
    return (
      <Box component={Paper} my={2}>
        <Box p={'24px 32px 32px'}>
          <Typography>
            {
              props.domainData.currentTrainedModelGroupDetail.trainingAlgorithm
                .algorithmName
            }
          </Typography>
          <DataDetailItem
            formHelperText='クラスセット'
            endAdornment={
              <CopyableLabel
                value={
                  props.domainData.currentTrainedModelGroupDetail.extended
                    .objectClassification
                    ? props.domainData.currentTrainedModelGroupDetail.extended
                        .objectClassification.classSet.classSetId
                    : ''
                }
              />
            }
            startAdornment={
              props.domainData.currentTrainedModelGroupDetail.extended
                .objectClassification.classSet.classSetName ? (
                <Box sx={{ color: 'text.primary' }}>
                  <Typography>
                    {props.domainData.currentTrainedModelGroupDetail
                      ? props.domainData.currentTrainedModelGroupDetail.extended
                          .objectClassification.classSet.classSetName
                      : ''}
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ color: 'text.secondary' }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
          />
        </Box>
      </Box>
    )
  }

  return <></>
}

const ModelGroupDetail: React.FC<Props> = (props: Props) => {
  const globalTheme = useTheme()
  const { classes } = useStyles()
  const history = useHistory()
  const isSharedUserGroup = hasSharedUserGroupQueryParameter(
    props.location.search
  )

  const {
    changeTableSortOrder: changeTableSortOrderForSettingList,
    handleChangeDisplayNumber: handleChangeDisplayNumberForSettingList,
    pageChange: pageChangeForSettingList,
  } = useSettingListTable({ ...props, isSharedUserGroup })

  const showErrorToast = (fileName: string, messages?: string[]) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div data-testid='modelGroupIconErrorToastFileName'>{`ファイル名: ${fileName}`}</div>
        <div>
          {messages ? (
            messages.map((message) => <li key={message}>{message}</li>)
          ) : (
            <></>
          )}
        </div>
      </div>
    )

  const showInfoToast = (title: string) =>
    showToast(
      'info',
      <div>
        <div>{'メッセージ種別: info'}</div>
        <div data-testid='modelGroupIconUploadSuccess'>{title}</div>
      </div>
    )

  const fileUploadErrors = (fileRejections: FileRejection[]) =>
    fileRejections.forEach((errorFile) => {
      const errorFileName = errorFile.file.name
      const fileErrors = errorFile.errors
      const errorMessage: string[] = []
      fileErrors.forEach((errorFileMessage) => {
        switch (errorFileMessage.code) {
          case 'file-too-large': {
            errorMessage.push('ファイルサイズが不正です')
            break
          }
          case 'file-invalid-type': {
            errorMessage.push('選択されたファイルの形式が不正です')
            break
          }
          default:
            break
        }
      })
      showErrorToast(errorFileName, errorMessage)
    })

  // パスパラメータからモデルグループIDを取得
  const [selectedTrainedModelGroupId, setSelectedTrainedModelGroupId] =
    useState<string>('')

  /** 初期実行 */
  useEffect(() => {
    return () => {
      props.clearModelGroupDetailState()
    }
  }, [])

  useEffect(() => {
    if (props.location.hash === '') return

    const tabIndex = Number(props.location.hash.replace('#', ''))
    if (isNaN(tabIndex)) return

    setNowTab(tabIndex)
  }, [props.location.hash])

  useEffect(() => {
    // NOTE: modelGroupIdがprops.match.paramsに存在しないためwarningとなるがモデルグループ詳細に遷移する際は存在する
    setSelectedTrainedModelGroupId(
      (props.match.params as { [key: string]: string })['modelGroupId']
    )
    // 表示用詳細取得
    props.getModelGroupDetail(
      (props.match.params as { [key: string]: string })['modelGroupId'],
      isSharedUserGroup
    )
  }, [props.match.params])

  useEffect(() => {
    if (
      props.domainData.currentTrainedModelGroupDetail?.constraint?.setting
        ?.settingGroupId === undefined ||
      props.domainData.currentTrainedModelGroupDetail?.constraint?.setting
        ?.settingGroupId === ''
    ) {
      return
    }
    props.getSettingList(isSharedUserGroup)
  }, [
    props.domainData.currentTrainedModelGroupDetail?.constraint?.setting
      ?.settingGroupId,
  ])

  useEffect(() => {
    if (props.appState.toastInfo && props.appState.toastInfo.type === 'error') {
      showErrorToast(props.appState.toastInfo.title)
    } else if (
      props.appState.toastInfo &&
      props.appState.toastInfo.type === 'info'
    ) {
      showInfoToast(props.appState.toastInfo.title)
    }
    props.deleteToastInfo()
  }, [props.appState.toastInfo])

  /** モデルグループ詳細遷移時に変更 */
  useEffect(() => {
    if (!isUndefined(props.domainData.currentTrainedModelGroupDetail)) {
      setEditingTrainedModelGroupRemarks(
        props.domainData.currentTrainedModelGroupDetail.remarks
      )
      setEditingTrainedModelGroupName(
        props.domainData.currentTrainedModelGroupDetail.trainedModelGroupName
      )
      setEditingTrainedModelGroupOverview(
        props.domainData.currentTrainedModelGroupDetail.overview
      )
    }
  }, [props.domainData.currentTrainedModelGroupDetail])

  /** 対象のIDのデータがない場合、データが不正の場合はhomeに戻る */
  useEffect(() => {
    handleResourceNotFound(props.appState.trainedModelGroupDataState, history)
  }, [props.appState.trainedModelGroupDataState])

  /** テーブルに表示する配列 */
  const tableContent = useMemo(() => {
    // 表示条件
    const condition =
      props.domainData.currentTrainedModelGroupDetail
        .trainedModelListDisplayCondition
    // 表示条件に合わせて配列を加工
    const displayList =
      props.domainData.currentTrainedModelGroupDetail.relatedTrainedModelList.slice(
        condition.displayNumber * condition.pageNumber,
        condition.displayNumber * condition.pageNumber + condition.displayNumber
      )

    // 表示対象が存在しない場合は、前のページの一覧を表示
    if (displayList.length === 0 && condition.pageNumber !== 0) {
      return props.domainData.currentTrainedModelGroupDetail.relatedTrainedModelList.slice(
        condition.displayNumber * (condition.pageNumber - 1),
        condition.displayNumber * (condition.pageNumber - 1) +
          condition.displayNumber
      )
    }

    if (
      condition.displayNumber * (condition.pageNumber + 1) >=
      props.domainData.currentTrainedModelGroupDetail.relatedTrainedModelList
        .length
    ) {
      props.setModelListPagingState('Unable')
    } else {
      props.setModelListPagingState('Enable')
    }
    return displayList
  }, [
    props.domainData.currentTrainedModelGroupDetail
      .trainedModelListDisplayCondition,
    props.domainData.currentTrainedModelGroupDetail.relatedTrainedModelList,
  ])

  /** テーブルに表示するモデルのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    if (!tableContent) return
    const convertedList: RelatedTrainedModel[] = tableContent.map(
      (modelList: RelatedTrainedModel) => {
        return {
          trainedModelId: modelList.trainedModelId,
          trainedModelVersion: modelList.trainedModelVersion,
          trainedModelName: modelList.trainedModelName,
          inheritedVersion: modelList.inheritedVersion,
          transactionStatus: undefined, // TODO: v0.3.0ではN/Aとする
        }
      }
    )
    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'trainedModelId') {
          if (value) {
            return (
              <TooltipLink
                key={key}
                data-testid={`model-${value}`}
                title={value ?? 'abc'}
                placement='right-start'
                onClick={() =>
                  history.push(
                    `/model-groups/${selectedTrainedModelGroupId}/models/${value}${
                      isSharedUserGroup ? '?shared-user-group=true' : ''
                    }`
                  )
                }
              />
            )
          } else {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          }
        } else if (key === 'trainedModelVersion') {
          if (value) {
            return <Typography key={key}>{value.displayName}</Typography>
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'trainedModelName') {
          if (value) {
            return <Typography key={key}>{value}</Typography>
          } else {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          }
        } else if (key === 'inheritedVersion') {
          if (value && value.displayName !== '') {
            return <Typography key={key}>{value.displayName}</Typography>
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'transactionStatus') {
          if (value) {
            return <Typography key={key}>{value}</Typography>
          } else {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          }
        } else {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [tableContent])

  /** テーブル */
  const modelListTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.currentTrainedModelGroupDetail
            ? props.domainData.currentTrainedModelGroupDetail
                .trainedModelListDisplayCondition.displayNumber
            : 10
        }
        headers={TABLE_HEADERS}
        rows={tableRows ?? []}
        totalCount={
          props.domainData.currentTrainedModelGroupDetail
            .relatedTrainedModelList.length
        }
        tableHeight={
          2 + TABLE_HEADER_HEIGHT + 10 * DISPLAY_NONE_RADIO_ROW_HEIGHT
        }
        fixedColumnNumber={0}
        page={
          props.domainData.currentTrainedModelGroupDetail
            ? props.domainData.currentTrainedModelGroupDetail
                .trainedModelListDisplayCondition.pageNumber
            : 0
        }
        sortOrder={{
          key: 'modelVersion',
          order: 'desc',
        }}
        displayNoneRadio={true}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.appState.tableNextPageSubState,
    props.domainData.currentTrainedModelGroupDetail
      .trainedModelListDisplayCondition,
  ])

  const [editingTrainedModelGroupName, setEditingTrainedModelGroupName] =
    useState<string>('')
  const [editingTrainedModelGroupRemarks, setEditingTrainedModelGroupRemarks] =
    useState<string>('')
  const [
    editingTrainedModelGroupOverview,
    setEditingTrainedModelGroupOverview,
  ] = useState<string>('')
  const [nowTab, setNowTab] = useState(0)
  /** セッティング新規追加ダイアログ表示状態 */
  const [openSettingDialog, setOpenSettingDialog] = useState(false)

  /** urlから取得したmodelGroupId */
  const modelGroupId = props.match.params
    ? // NOTE: modelGroupIdがprops.match.paramsに存在しないためwarningとなるがモデルグループ詳細に遷移する際は存在する
      (props.match.params as { [key: string]: string })['modelGroupId']
    : ''
  if (modelGroupId === '') {
    console.error('Error Invalid Model Group ID')
    return <></>
  }

  /** セッティングの新規追加ボタン押下時処理 */
  const handleClickOpenSettingDialog = () => {
    setOpenSettingDialog(true)
  }
  /** セッティングの新規追加完了時処理（または、キャンセル） */
  const handleClickCloseSettingDialog = (id?: string) => {
    setOpenSettingDialog(false)
    if (isUndefined(id)) return
    props.getSettingList(isSharedUserGroup)
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.currentTrainedModelGroupDetail
        .trainedModelListDisplayCondition,
      pageNumber: pageNumber,
    })
  }

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    const pageNumber =
      props.domainData.currentTrainedModelGroupDetail.relatedTrainedModelList
        .length >
      props.domainData.currentTrainedModelGroupDetail
        .trainedModelListDisplayCondition.pageNumber *
        displayNumber
        ? props.domainData.currentTrainedModelGroupDetail
            .trainedModelListDisplayCondition.pageNumber
        : Math.ceil(
            props.domainData.currentTrainedModelGroupDetail
              .relatedTrainedModelList.length / displayNumber
          ) - 1

    props.setListDisplayCondition({
      ...props.domainData.currentTrainedModelGroupDetail
        .trainedModelListDisplayCondition,
      pageNumber: pageNumber,
      displayNumber: displayNumber,
    })
  }

  /** テーブルに表示するデータセットのJSXの２次元配列 */
  const settingListTableRows = useMemo(() => {
    let convertedList: Setting[] = props.domainData.settingList.map(
      (setting: Setting) => {
        return {
          settingId: setting.settingId,
          name: setting.name,
          settingGroupVersion: setting.settingGroupVersion,
          createdAt: setting.createdAt,
          datasetTemplate: setting.datasetTemplate,
          remarks: setting.remarks,
        }
      }
    )

    /** ソートキー、ソートオーダーによる並び替え */
    if (
      !isUndefined(props.domainData.settingListDisplayCondition) &&
      props.domainData.settingListDisplayCondition.sortKey === 'createdAt'
    ) {
      convertedList = convertedList.sort((item, item2) => {
        return (
          (item2.createdAt.toDate().getTime() -
            item.createdAt.toDate().getTime()) *
          (props.domainData.settingListDisplayCondition.sortOrder === 'asc'
            ? -1
            : 1)
        )
      })
    }

    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        switch (key) {
          case 'settingId':
            if (value) {
              return (
                <TooltipLink
                  key={key}
                  data-testid={`setting-${value}`}
                  title={value ?? 'undefined'}
                  placement='right-start'
                  onClick={() =>
                    props.history.push(
                      `/setting-groups/${
                        props.domainData.currentTrainedModelGroupDetail
                          .constraint?.setting.settingGroupId
                      }/settings/${value}${
                        isSharedUserGroup ? '?shared-user-group=true' : ''
                      }`
                    )
                  }
                />
              )
            } else {
              return (
                <Box
                  key={key}
                  sx={{ color: globalTheme.palette.text.secondary }}
                >
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
          case 'settingGroupVersion':
            return <Typography>{value.displayName}</Typography>
          case 'datasetTemplate':
            return <Typography>{value.metadata.name.ja}</Typography>
          case 'createdAt':
            // 終了日時がundefinedまたはUnixTime0の場合N/Aとする
            if (isUndefined(value)) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
            return (
              <Typography key={key}>
                {formatDateTimeSec(
                  isUndefined(value) ? undefined : value.toDate()
                )}
              </Typography>
            )
          default:
            return value ? (
              <Typography>{value}</Typography>
            ) : (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
        }
      })
    )
  }, [props.domainData.settingList])

  const tabItems: TabItems[] = [
    // 一般情報コンテンツ
    {
      label: '一般情報',
      displayInfo: (
        <>
          <Box component={Paper}>
            <Box p={'24px 32px 32px'}>
              <Box mb={2}>
                <Typography>アイコン</Typography>
              </Box>
              <Box ml={2}>
                <div className={classes.iconDropZone}>
                  <FileUploadViewer
                    fileType='.jpg,.png'
                    maxSize={256000}
                    file={
                      props.domainData.currentTrainedModelGroupDetail.iconUrl
                    }
                    fileDeleteAction={() =>
                      props.deleteModelGroupIcon(modelGroupId)
                    }
                    acceptFileOperation={(files: File[]) =>
                      props.updateModelGroupIcon(modelGroupId, files[0])
                    }
                    rejectedFileOperation={(fileRejections: FileRejection[]) =>
                      fileUploadErrors(fileRejections)
                    }
                    disabled={isSharedUserGroup}
                    data-testid={'model-group'}
                  />
                </div>
              </Box>
              <Box ml={2} mt={3}>
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>
                    ※ファイル形式はpng、jpg。画像サイズは256KBまでアップロード可能です
                  </Typography>
                </Box>
              </Box>
              <Box mt={2} mb={2}>
                <Typography>概要</Typography>
              </Box>
              <TextField
                style={{ width: '100%' }}
                value={editingTrainedModelGroupOverview}
                onChange={(event) =>
                  setEditingTrainedModelGroupOverview(event.target.value)
                }
                variant='outlined'
                multiline
                minRows={5}
                inputProps={{
                  'data-testid': 'input-remarks',
                }}
                disabled={isSharedUserGroup}
              />
              <Box
                display='flex'
                justifyContent='flex-end'
                width='100%'
                className={classes.mt2Box}
              >
                <Button
                  variant='outlined'
                  onClick={() =>
                    props.updateModelGroupOverview(
                      modelGroupId,
                      editingTrainedModelGroupOverview
                    )
                  }
                  disabled={isSharedUserGroup}
                  className={
                    isSharedUserGroup
                      ? classes.noteTabButtonDisabled
                      : classes.noteTabButton
                  }
                  data-testid='remarks-edit'
                >
                  <Typography color='primary'>保存</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
          <AlgorithmSpecificContent {...props} />
        </>
      ),
    },
    // モデル一覧
    {
      label: 'モデル一覧',
      displayInfo: (
        <>
          <Box component={Paper} p={'24px 32px 32px'}>
            <Box
              display={'flex'}
              justifyContent={'right'}
            >{`登録モデル数 ${props.domainData.currentTrainedModelGroupDetail.relatedTrainedModelList.length}`}</Box>
            <Box my={2}>{modelListTable}</Box>
          </Box>
        </>
      ),
    },
    // セッティングコンテンツ
    {
      label: 'セッティング',
      displayInfo: (
        <Box component={Paper}>
          <Box p={'24px 32px 32px'}>
            {!isSharedUserGroup && (
              <div className={classes.postAddButton}>
                <PostAddIconButton
                  label={'新規追加'}
                  onClick={handleClickOpenSettingDialog}
                />
              </div>
            )}
            <Box
              display={'flex'}
              justifyContent={'right'}
            >{`登録セッティング数 ${props.domainData.settingList.length}`}</Box>
            <SelectableTable
              displayNumber={
                props.domainData.settingListDisplayCondition.displayNumber
              }
              headers={TABLE_HEADERS_FOR_SETTING_LIST}
              rows={settingListTableRows}
              totalCount={
                props.domainData.settingListDisplayCondition.totalCount
              }
              loading={props.appState.isInProgressForGettingSettingList}
              tableHeight={
                TABLE_HEADER_HEIGHT + 10 * STATUS_PROGRESS_BAR_ROW_HEIGHT
              }
              fixedColumnNumber={0}
              page={props.domainData.settingListDisplayCondition.pageNumber}
              sortOrder={{
                key: props.domainData.settingListDisplayCondition.sortKey,
                order: props.domainData.settingListDisplayCondition.sortOrder,
              }}
              displayNoneRadio={true}
              onClickOrderChange={(key: string) =>
                changeTableSortOrderForSettingList(key)
              }
              onClickPageChange={(pageNumber: number) =>
                pageChangeForSettingList(pageNumber)
              }
              onChangeDisplayNumber={(displayNumber: number) =>
                handleChangeDisplayNumberForSettingList(displayNumber)
              }
            />
          </Box>
          <Dialog
            maxWidth='md'
            fullWidth={true}
            aria-labelledby='customized-dialog-title'
            open={openSettingDialog}
          >
            <DialogContent className={classes.dataCreateDialog}>
              <CreateSettingPage
                handleCloseDialog={(settingId?: string) =>
                  handleClickCloseSettingDialog(settingId)
                }
                settingGroupId={
                  props.domainData.currentTrainedModelGroupDetail?.constraint
                    ?.setting?.settingGroupId ?? ''
                }
                algorithmId={
                  props.domainData.currentTrainedModelGroupDetail
                    .trainingAlgorithm.algorithmId
                }
              />
            </DialogContent>
          </Dialog>
        </Box>
      ),
    },
    // 備考タブコンテンツ
    {
      label: '備考',
      displayInfo: (
        <>
          <Box component={Paper}>
            <Box p={'24px 32px 32px'}>
              <TextField
                style={{ width: '100%' }}
                value={editingTrainedModelGroupRemarks}
                onChange={(event) =>
                  setEditingTrainedModelGroupRemarks(event.target.value)
                }
                variant='outlined'
                multiline
                minRows={5}
                inputProps={{
                  'data-testid': 'input-remarks',
                }}
                disabled={isSharedUserGroup}
              />
              <Box
                display='flex'
                justifyContent='flex-end'
                width='100%'
                className={classes.mt2Box}
              >
                <Button
                  variant='outlined'
                  onClick={() =>
                    props.updateModelGroupRemarks(
                      modelGroupId,
                      editingTrainedModelGroupRemarks
                    )
                  }
                  disabled={isSharedUserGroup}
                  className={
                    isSharedUserGroup
                      ? classes.noteTabButtonDisabled
                      : classes.noteTabButton
                  }
                  data-testid='remarks-edit'
                >
                  <Typography color='primary'>保存</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      ),
    },
  ]

  return (
    <>
      <div className={classes.container}>
        <Toast containerOptions={{ limit: 20 }}>
          <Box
            style={{
              position: 'sticky',
              top: '64px',
              backgroundColor: '#fafafa',
              zIndex: 10,
            }}
          >
            <Box className={classes.innerContainer}>
              <Box pt={2}>
                <BreadcrumbsComponent
                  breadcrumbsPath={[
                    {
                      name: 'モデルグループ一覧',
                      path: 'model-groups',
                    },
                    {
                      name:
                        props.domainData.currentTrainedModelGroupDetail
                          .trainedModelGroupName !== ''
                          ? `${props.domainData.currentTrainedModelGroupDetail.trainedModelGroupName}`
                          : `${props.domainData.currentTrainedModelGroupDetail.trainedModelGroupId}`,
                      path: `${props.domainData.currentTrainedModelGroupDetail.trainedModelGroupId}`,
                    },
                  ]}
                />
              </Box>
              <div className={classes.flexAndBetween}>
                <Box
                  display='flex'
                  style={{
                    maxWidth: 'calc(100% - 130px)',
                    overflow: 'hidden',
                  }}
                >
                  <ModelGroupsIcon
                    className={classes.pageIcon}
                    data-testid='model-groups-icon'
                  />
                  <Box
                    height={76}
                    data-testid='model-detail-title'
                    style={{
                      overflow: 'hidden',
                    }}
                  >
                    <EditableTextField
                      value={editingTrainedModelGroupName}
                      disabled={isSharedUserGroup}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEditingTrainedModelGroupName(e.target.value)
                      }
                      onBlur={() => {
                        if (
                          props.domainData.currentTrainedModelGroupDetail
                            .trainedModelGroupName !==
                          editingTrainedModelGroupName
                        ) {
                          props.updateModelGroupName(
                            modelGroupId,
                            editingTrainedModelGroupName
                          )
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box display='flex'>
                  <CopyableLabel
                    value={modelGroupId}
                    isTooltip
                    placement='top'
                  />
                </Box>
              </div>
              <Box p={1}>
                {props.domainData.currentTrainedModelGroupDetail &&
                props.domainData.currentTrainedModelGroupDetail.createdAt ? (
                  <Box height={24} display='flex' justifyContent='end'>
                    <TimerIcon style={{ marginRight: '4px' }} />
                    <Typography>
                      {formatDateTimeSec(
                        props.domainData.currentTrainedModelGroupDetail.createdAt?.toDate()
                      )}
                    </Typography>
                  </Box>
                ) : (
                  <Box height={24} display='flex' justifyContent='end'></Box>
                )}
                <div className={classes.flexAndBetween}>
                  <Box height={64} display='flex' alignItems='center'>
                    <HandymanIcon
                      style={{ marginRight: '4px' }}
                      sx={{ color: globalTheme.palette.text.secondary }}
                    />
                    <Box sx={{ color: globalTheme.palette.text.secondary }}>
                      <Typography component='div'>
                        <h4>
                          {`${props.domainData.currentTrainedModelGroupDetail.trainingAlgorithm.algorithmName}`}
                        </h4>
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex'>
                    {typeof props.domainData.currentTrainedModelGroupDetail
                      ?.createdBy === 'string' ? (
                      <Typography>
                        {
                          props.domainData.currentTrainedModelGroupDetail
                            ?.createdBy
                        }
                      </Typography>
                    ) : (
                      <Typography>
                        {
                          props.domainData.currentTrainedModelGroupDetail
                            ?.createdBy.firstName
                        }{' '}
                        {
                          props.domainData.currentTrainedModelGroupDetail
                            ?.createdBy.familyName
                        }
                      </Typography>
                    )}
                  </Box>
                </div>
              </Box>
              <Box
                style={{
                  backgroundColor: '#fafafa',
                }}
              >
                <Tabs
                  indicatorColor='primary'
                  value={nowTab}
                  style={{
                    paddingBottom: '16px',
                    marginBottom: '1px',
                  }}
                  onChange={(_, value) => setNowTab(value)}
                >
                  {tabItems.map((item, index) => (
                    <Tab
                      style={{
                        width: `${100 / tabItems.length}%`,
                        maxWidth: '1200px',
                      }}
                      key={index}
                      className={clsx(nowTab === index && classes.nowTab)}
                      label={item.label}
                      data-testid={`change-tab-${index}`}
                    />
                  ))}
                </Tabs>
              </Box>
            </Box>
          </Box>
          <Box className={classes.tabContents}>
            <Box className={classes.innerContainer}>
              <Paper elevation={0}>
                <Box>{tabItems[nowTab].displayInfo}</Box>
              </Paper>
            </Box>
          </Box>
        </Toast>
      </div>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

type UseSettingListTable = Props & { isSharedUserGroup: boolean }
const useSettingListTable = (props: UseSettingListTable) => {
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    /** 表示数を変更 */
    props.setSettingListDisplayCondition({
      ...props.domainData.settingListDisplayCondition,
      pageNumber: 0,
      displayNumber,
    })

    props.getSettingList(props.isSharedUserGroup)
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    // ソート時に1ページ目に戻る
    props.setSettingListDisplayCondition({
      ...props.domainData.settingListDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.settingListDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })

    props.getSettingList(props.isSharedUserGroup)
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setSettingListDisplayCondition({
      ...props.domainData.settingListDisplayCondition,
      pageNumber: pageNumber,
    })

    props.getSettingList(props.isSharedUserGroup)
  }

  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
  }
}

export const ModelGroupDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModelGroupDetail))
