import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
  useRouteMatch,
  Link,
  useHistory,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import { State } from 'state/store'
import {
  ModelInfo,
  ModelListActions,
  modelListActions,
  modelListOperations,
} from 'state/ducks/modelList'
import { ModelListDisplayCondition } from 'state/ducks/modelList/types'

import {
  ModelIcon,
  SelectableTable,
  SearchInput,
  CustomTrainingPageParagraph,
  GlobalLoading,
  TooltipLink,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  DISPLAY_NONE_RADIO_ROW_HEIGHT,
  BreadcrumbsComponent,
} from 'views/components'
import { formatDateTimeSec } from 'views/components/utils/date'
import { convertModelKindWord } from 'views/containers/utils'
import { useTheme } from '@mui/material/styles'
import { CustomerAuthorizer } from 'views/components/organisms/customerAuthorizer'
import {
  hasSharedUserGroupQueryParameter,
  sharedUserGroupQueryParameter,
} from 'views/containers/utils/queryParams'

const mapStateToProps = (state: State) => ({
  ...state.pages.modelListState,
  ...state.app.domainData.authedUser,
  ...state.app.domainData.userProfile,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, ModelListActions>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** モデル一覧取得 */
  getModelList: (trainedModelGroupId: string, isSharedUserGroup: boolean) =>
    dispatch(
      modelListOperations.getModelList(trainedModelGroupId, isSharedUserGroup)
    ),
  /** モデル一覧をクリア */
  clearModelList: () => dispatch(modelListActions.clearModelList()),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (listCondition: ModelListDisplayCondition) =>
    dispatch(modelListActions.setListDisplayCondition(listCondition)),
  /** Stateのクリア */
  clearModelListState: () => dispatch(modelListActions.clearModelListState()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  head: {
    height: '240px',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  resultCountSelectBox: {
    width: theme.custom.table.resultCountSelect.width,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  stepContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  algorithmSelectBox: {
    width: '100%',
  },
  explanation: {
    fontSize: theme.typography.pxToRem(18),
  },
  modelGroupInfoItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flexStart',
    flexDirection: 'column',
  },
  toolTipItem: {
    width: '250px',
  },
}))

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'modelId',
    title: 'モデル ID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'modelName',
    title: 'モデル名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'modelKind',
    title: '種類',
    width: 150,
    sortable: false,
    position: 'left',
  },
  {
    id: 'modelGroupVersion',
    title: 'バージョン',
    width: 150,
    sortable: true,
    position: 'center',
  },
  {
    id: 'mlPipelineId',
    title: 'ML Pipeline ID',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'generated-at',
    title: '生成日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'generatedUser',
    title: '生成ユーザーID',
    width: 300,
    sortable: false,
    position: 'center',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const ModelList: React.FC<Props> = (props: Props) => {
  const { url } = useRouteMatch()
  const globalTheme = useTheme()
  const { classes } = useStyles()
  const history = useHistory()
  const isSharedUserGroup = hasSharedUserGroupQueryParameter(
    props.location.search
  )

  const [trainedModelGroupId, setTrainedModelGroupId] = useState<string>('')
  useEffect(() => {
    // NOTE: modelGroupIdがprops.match.paramsに存在しないためwarningとなるがモデルグループ詳細に遷移する際は存在する
    setTrainedModelGroupId(
      (props.match.params as { [key: string]: string })['modelGroupId']
    )
    props.getModelList(
      (props.match.params as { [key: string]: string })['modelGroupId'],
      isSharedUserGroup
    )
    return () => {
      props.clearModelListState()
    }
  }, [props.auth.customClaims.userGroupId])

  useEffect(() => {
    return () => {
      props.clearModelListState()
    }
  }, [])

  /** 元のモデルグループのデータがない時はhomeに戻る */
  useEffect(() => {
    if (props.appState.modelGroupSubState === 'GettingNotFound') {
      history.replace('/')
    }
  }, [props.appState.modelGroupSubState])

  const {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
  } = tableActions(props, trainedModelGroupId, isSharedUserGroup)

  /** 検索ワードがある場合日付ソートをさせない */
  const tableHeader = useMemo(() => {
    if (props.domainData.modelListDisplayCondition.searchValue) {
      return TABLE_HEADERS.map((header) => {
        return { ...header, sortable: false }
      })
    }

    return TABLE_HEADERS
  }, [props.domainData.modelListDisplayCondition.searchValue])

  /** テーブルに表示する配列 */
  const tableContent = useMemo(() => {
    // 表示条件
    const condition = props.domainData.modelListDisplayCondition
    // 表示条件に合わせて配列を加工
    const displayList = props.domainData.currentModelList.slice(
      condition.displayNumber * condition.pageNumber,
      condition.displayNumber * condition.pageNumber + condition.displayNumber
    )

    // 表示対象が存在しない場合は、前のページの一覧を表示
    if (displayList.length === 0 && condition.pageNumber !== 0) {
      return props.domainData.currentModelList.slice(
        condition.displayNumber * (condition.pageNumber - 1),
        condition.displayNumber * (condition.pageNumber - 1) +
          condition.displayNumber
      )
    }

    return displayList
  }, [
    props.domainData.modelListDisplayCondition,
    props.domainData.currentModelList,
  ])

  /** テーブルに表示するモデルのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    const convertedList: ModelInfo[] = tableContent.map(
      (modelList: ModelInfo) => {
        return {
          modelId: modelList.modelId,
          modelName: modelList.modelName,
          modelKind: modelList.modelKind,
          modelGroupVersion: {
            displayName: modelList.modelGroupVersion.displayName,
            major: modelList.modelGroupVersion.major,
            minor: modelList.modelGroupVersion.minor,
            patch: modelList.modelGroupVersion.patch,
          },
          mlPipeline: modelList.mlPipeline,
          generatedAt: modelList.generatedAt,
          uid: modelList.uid,
        }
      }
    )

    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'modelId') {
          return (
            <TooltipLink
              key={key}
              data-testid={`model-${value}`}
              title={value}
              placement='right-start'
              onClick={() => {
                history.replace(
                  `/model-groups/${trainedModelGroupId}/models/${value}${
                    isSharedUserGroup ? '?shared-user-group=true' : ''
                  }`
                )
              }}
            />
          )
        } else if (key === 'modelGroupVersion') {
          if (value.displayName) {
            return (
              <Typography key={key} align='center'>
                v{value.displayName}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'mlPipeline') {
          if (value.mlPipelineId) {
            return (
              <TooltipLink
                key={key}
                title={value.mlPipelineId}
                placement='right-start'
                onClick={() => {
                  value.mlPipelineKind === 'CustomTraining'
                    ? //カスタム学習詳細へ
                      history.push(`/custom-trainings/${value.mlPipelineId}`)
                    : // 推論詳細へ
                      history.push(`/inferences/${value.mlPipelineId}`)
                }}
                data-testid={`custom-training-${value.mlPipelineId}`}
              />
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'generatedAt') {
          if (value) {
            return (
              <Typography key={key}>
                {formatDateTimeSec(
                  value ? value.toDate() : TABLE_CELL_NOT_APPLICABLE
                )}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'modelKind') {
          return (
            <Typography key={key} align='center'>
              {value ? convertModelKindWord(value) : TABLE_CELL_NOT_APPLICABLE}
            </Typography>
          )
        } else if (key === 'modelName') {
          if (value) {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography>{value}</Typography>
              </Tooltip>
            )
          } else {
            return (
              <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          }
        } else if (key === 'uid') {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography>{value.substring(0, 8)}</Typography>
            </Tooltip>
          )
        } else {
          return <Typography key={key}>{TABLE_CELL_NOT_APPLICABLE}</Typography>
        }
      })
    )
  }, [tableContent])

  /** テーブル */
  const modelListTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={props.domainData.modelListDisplayCondition.displayNumber}
        headers={tableHeader}
        rows={tableRows}
        totalCount={props.domainData.modelListDisplayCondition.totalCount}
        loading={props.appState.inProgress}
        tableHeight={TABLE_HEADER_HEIGHT + 10 * DISPLAY_NONE_RADIO_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={props.domainData.modelListDisplayCondition.pageNumber}
        sortOrder={{
          key: props.domainData.modelListDisplayCondition.sortKey,
          order: props.domainData.modelListDisplayCondition.sortOrder,
        }}
        displayNoneRadio={true}
        onClickOrderChange={(key: string) => changeTableSortOrder(key)}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.domainData.modelListDisplayCondition,
    props.appState.inProgress,
  ])

  return (
    <>
      <div className={classes.stepContainer}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            {
              name: 'モデルグループ一覧',
              path: 'model-groups',
            },
            {
              name:
                props.domainData.trainedModelGroupName !== ''
                  ? `${props.domainData.trainedModelGroupName}`
                  : `${props.domainData.trainedModelGroupId}`,
              path: `${props.domainData.trainedModelGroupId}`,
              query: isSharedUserGroup
                ? sharedUserGroupQueryParameter
                : undefined,
            },
            {
              name: 'モデル一覧',
              path: 'models',
            },
          ]}
        />
        <div className={classes.postAddButton}>
          <Box display='flex' alignItems='center'>
            <ModelIcon
              className={classes.pageIcon}
              data-testid='modelListTitleIcon'
            />
            <Typography component='div'>
              <h2 data-testid='model-list-title'>モデル</h2>
            </Typography>
          </Box>
          <Box display='flex'>
            {isSharedUserGroup ? (
              <></>
            ) : (
              <CustomerAuthorizer type='UPLOAD_MODEL' auth={props.role ?? ''}>
                <>
                  <CloudUploadIcon />
                  <Link data-testid='model-entry' to={`${url}/entry`}>
                    <Typography>モデルアップロード</Typography>
                  </Link>
                </>
              </CustomerAuthorizer>
            )}
          </Box>
        </div>
        <CustomTrainingPageParagraph>
          <div className={classes.modelGroupInfoItem}>
            <div className={classes.toolTipItem}>
              <Typography data-testid='model-group-name'>
                モデルグループID:
                <TooltipLink
                  title={props.domainData.trainedModelGroupId}
                  placement='right-start'
                  onClick={() =>
                    history.push(
                      `/model-groups/${props.domainData.trainedModelGroupId}${
                        isSharedUserGroup ? '?shared-user-group=true' : ''
                      }`
                    )
                  }
                  data-testid={`model-group-id-${props.domainData.trainedModelGroupId}`}
                />
              </Typography>
            </div>
            <Typography data-testid='model-group-name'>
              {`モデルグループ名:${
                props.domainData.trainedModelGroupName ??
                TABLE_CELL_NOT_APPLICABLE
              }`}
            </Typography>
          </div>
        </CustomTrainingPageParagraph>
        <div className={classes.searchForm}>
          <div className={classes.searchField}>
            <SearchInput
              placeholder='キーワード (モデルID)'
              value={props.domainData.modelListDisplayCondition.searchValue}
              onChangeValue={(event) =>
                handleChangeSearchValue(event.target.value)
              }
              onClickSearch={() => searchTableContent()}
              onPressEnter={() => searchTableContent()}
            />
          </div>
        </div>
        {modelListTable}
      </div>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

const tableActions = (
  props: Props,
  trainedModelGroupId: string,
  isSharedUserGroup: boolean
) => {
  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    props.setListDisplayCondition({
      ...props.domainData.modelListDisplayCondition,
      searchValue: value,
    })
  }
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    props.clearModelList()
    const pageNumber =
      props.domainData.currentModelList.length >
      props.domainData.modelListDisplayCondition.pageNumber * displayNumber
        ? props.domainData.modelListDisplayCondition.pageNumber
        : Math.ceil(props.domainData.currentModelList.length / displayNumber) -
          1

    props.setListDisplayCondition({
      ...props.domainData.modelListDisplayCondition,
      pageNumber: pageNumber,
      displayNumber: displayNumber,
    })

    props.getModelList(trainedModelGroupId, isSharedUserGroup)
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    props.clearModelList()
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.modelListDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.modelListDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })

    props.getModelList(trainedModelGroupId, isSharedUserGroup)
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.modelListDisplayCondition,
      pageNumber: pageNumber,
    })

    props.getModelList(trainedModelGroupId, isSharedUserGroup)
  }

  /** 検索の実行 */
  const searchTableContent = () => {
    props.clearModelList()
    props.setListDisplayCondition({
      ...props.domainData.modelListDisplayCondition,
      pageNumber: 0,
    })

    props.getModelList(trainedModelGroupId, isSharedUserGroup)
  }

  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
  }
}

export const ModelListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModelList))
