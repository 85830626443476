import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Add from '@mui/icons-material/Add'

import { State } from 'state/store'
import {
  ScenePresetListActions,
  scenePresetListActions,
  scenePresetListOperations,
  ScenePresetListDisplayCondition,
  ScenePresetState,
  ScenePresetSubState,
  ThreeDimensionalData,
} from 'state/ducks/scenePresetList'

import {
  SelectableTable,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  DISPLAY_NONE_RADIO_ROW_HEIGHT,
  CustomTrainingPageParagraph,
  SearchInput,
  GlobalLoading,
  ScenePresetIcon,
  StepperLayout,
  Toast,
  MetadataInput,
  CommonCompleteDialog,
  BreadcrumbsComponent,
  ColorChip,
} from 'views/components'
import { formatDateTimeSec } from 'views/components/utils/date'
import {
  getChipColor,
  getUserGroupKind,
  getUserGroupKindList,
} from 'views/containers/utils'
import { useTheme } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'

const mapStateToProps = (state: State) => ({
  ...state.pages.scenePresetListState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, ScenePresetListActions>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** シーンプリセット一覧取得 */
  getScenePresetList: () => {
    dispatch(scenePresetListOperations.getScenePresetList())
  },
  /** シーンプリセット一覧をクリア */
  clearScenePresetList: () =>
    dispatch(scenePresetListActions.clearScenePresetList()),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (listCondition: ScenePresetListDisplayCondition) =>
    dispatch(scenePresetListActions.setListDisplayCondition(listCondition)),
  /** Stateのクリア */
  clearScenePresetListState: () =>
    dispatch(scenePresetListActions.clearScenePresetListState()),
  getScenePresetData: () =>
    dispatch(scenePresetListOperations.getScenePresetData()),
  executeScenePreset: () =>
    dispatch(scenePresetListOperations.executeScenePreset()),

  setBackgrounds: (backgrounds: ThreeDimensionalData[]) =>
    dispatch(scenePresetListActions.setBackgrounds(backgrounds)),
  setCameras: (cameras: ThreeDimensionalData[]) =>
    dispatch(scenePresetListActions.setCameras(cameras)),
  setLightGroups: (lightGroups: ThreeDimensionalData[]) =>
    dispatch(scenePresetListActions.setLightGroups(lightGroups)),
  setSelectedBackgrounds: (selectedBackgrounds: ThreeDimensionalData[]) =>
    dispatch(
      scenePresetListActions.setScenePresetBackgrounds(selectedBackgrounds)
    ),
  setSelectedCameras: (cameras: ThreeDimensionalData[]) =>
    dispatch(scenePresetListActions.setScenePresetCameras(cameras)),
  setSelectedLightGroups: (selectedLightGroups: ThreeDimensionalData[]) =>
    dispatch(
      scenePresetListActions.setScenePresetLightGroups(selectedLightGroups)
    ),
  setScenePresetName: (name: string) =>
    dispatch(scenePresetListActions.setScenePresetName(name)),
  setScenePresetOverview: (overview: string) =>
    dispatch(scenePresetListActions.setScenePresetOverview(overview)),
  clearScenePresetState: () =>
    dispatch(scenePresetListActions.clearScenePresetState()),
  setNextStepScenePresetState: (
    scenePresetState: ScenePresetState,
    scenePresetSubState: ScenePresetSubState
  ) =>
    dispatch(
      scenePresetListOperations.setNextStep(
        scenePresetState,
        scenePresetSubState
      )
    ),
  setPrevStepState: (scenePresetState: ScenePresetState) =>
    dispatch(scenePresetListOperations.setPrevStep(scenePresetState)),
  /** snapshotの購読解除 */
  unsubscribe: () => dispatch(scenePresetListOperations.unsubscribe()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  head: {
    height: '240px',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  resultCountSelectBox: {
    width: theme.custom.table.resultCountSelect.width,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  stepContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  userGroupKindSelectBox: {
    width: '100%',
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
  presetCreateDialog: {
    padding: theme.spacing(2),
    backgroundColor: '#fafafa',
  },
  presetCreateDialogContainer: {
    paddingTop: theme.spacing(3),
  },
  pageTitle: { width: '98%', margin: theme.spacing(1) },
  stepperDiv: {
    margin: theme.spacing(3),
  },
  cancelLink: {
    margin: theme.spacing(1),
    float: 'right',
    color: 'red',
  },
  stepTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chip: {
    backgroundColor: '#D9E5FF',
    marginRight: '8px',
  },
  addButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'metadata.name',
    title: 'シーンプリセット名',
    width: 240,
    sortable: false,
    position: 'left',
  },
  {
    id: 'metadata.overview',
    title: '概要',
    width: 240,
    sortable: false,
    position: 'left',
  },
  {
    id: 'aug-param-template-id',
    title: 'シーンプリセットID',
    width: 180,
    sortable: false,
    position: 'center',
  },
  {
    id: 'background-count',
    title: '背景数',
    width: 80,
    sortable: false,
    position: 'center',
  },
  {
    id: 'light-group-count',
    title: '光源数',
    width: 80,
    sortable: false,
    position: 'center',
  },
  {
    id: 'camera-count',
    title: 'カメラ数',
    width: 88,
    sortable: false,
    position: 'center',
  },
  {
    id: 'created-at',
    title: '作成日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const ScenePresetList: React.FC<Props> = (props: Props) => {
  const globalTheme = useTheme()
  const { classes } = useStyles()

  useEffect(() => {
    return () => {
      props.unsubscribe()
    }
  }, [])

  useEffect(() => {
    props.getScenePresetList()
    return () => {
      props.clearScenePresetListState()
    }
  }, [props.auth.customClaims.userGroupId])

  const {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectedUserGroupKind: selectUserGroupKind,
  } = tableActions(props)

  /** 検索ワードがある場合日付ソートをさせない */
  const tableHeader = useMemo(() => {
    if (props.domainData.scenePresetListDisplayCondition.searchValue) {
      return TABLE_HEADERS.map((header) => {
        return { ...header, sortable: false }
      })
    }

    return TABLE_HEADERS
  }, [props.domainData.scenePresetListDisplayCondition.searchValue])

  /** テーブルに表示する配列 */
  const tableContent = useMemo(() => {
    // 表示条件
    const condition = props.domainData.scenePresetListDisplayCondition
    // 表示条件に合わせて配列を加工
    const displayList = props.domainData.currentScenePresetList.slice(
      condition.displayNumber * condition.pageNumber,
      condition.displayNumber * condition.pageNumber + condition.displayNumber
    )

    // 表示対象が存在しない場合は、前のページの一覧を表示
    if (displayList.length === 0 && condition.pageNumber !== 0) {
      return props.domainData.currentScenePresetList.slice(
        condition.displayNumber * (condition.pageNumber - 1),
        condition.displayNumber * (condition.pageNumber - 1) +
          condition.displayNumber
      )
    }

    return displayList
  }, [
    props.domainData.scenePresetListDisplayCondition,
    props.domainData.currentScenePresetList,
  ])

  /** テーブルに表示するモデルのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    return tableContent.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'augParamTemplateId') {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography>{value.substring(0, 8)}</Typography>
            </Tooltip>
          )
        } else if (
          key === 'backgroundCont' ||
          key === 'cameraCount' ||
          key === 'lightGroupCount'
        ) {
          if (value) {
            return <Typography key={key}>{value}</Typography>
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'updatedAt' || key === 'createdAt') {
          if (value) {
            return (
              <Typography key={key}>
                {formatDateTimeSec(
                  value ? value.toDate() : TABLE_CELL_NOT_APPLICABLE
                )}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else {
          if (value) {
            return <Typography key={key}>{value}</Typography>
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [tableContent])

  /** テーブル */
  const scenePresetListTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.scenePresetListDisplayCondition.displayNumber
        }
        headers={tableHeader}
        rows={tableRows}
        totalCount={props.domainData.scenePresetListDisplayCondition.totalCount}
        loading={props.appState.inProgress}
        tableHeight={TABLE_HEADER_HEIGHT + 10 * DISPLAY_NONE_RADIO_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={props.domainData.scenePresetListDisplayCondition.pageNumber}
        sortOrder={{
          key: props.domainData.scenePresetListDisplayCondition.sortKey,
          order: props.domainData.scenePresetListDisplayCondition.sortOrder,
        }}
        displayNoneRadio={true}
        isPointer={true}
        onClickOrderChange={(key: string) => changeTableSortOrder(key)}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.domainData.scenePresetListDisplayCondition,
    props.appState.inProgress,
  ])

  const userGroupKindList = getUserGroupKindList(
    props.auth.customClaims.sharedList
  )
  /** シーンプリセット新規追加ダイアログ表示状態 */
  const [openPresetDialog, setOpenScenePresetDialog] = useState(false)

  /** シーンプリセットの新規追加ボタン押下時処理 */
  const handleClickOpenPresetDialog = () => {
    props.getScenePresetData()
    setOpenScenePresetDialog(true)
  }

  /** シーンプリセットの新規追加完了時処理（または、キャンセル） */
  const handleClickClosePresetDialog = () => {
    props.clearScenePresetState()
    setOpenScenePresetDialog(false)
  }
  const stepNames = ['シーンプリセット', 'メタデータ', '確認']

  const getStepperNum = (scenePresetState: ScenePresetState) => {
    switch (scenePresetState) {
      case 'SceneSettingState':
        return 0
      case 'MetadataState':
        return 1
      case 'ExecuteState':
        return 2
      default:
        return 0
    }
  }

  const getStepContent = (props: Props): JSX.Element => {
    const scenePresetMetadataInputHelper = (metadataSubState: string) =>
      metadataSubState === 'emptyRequired' ? '入力してください。' : ''
    switch (props.appState.scenePresetState) {
      case 'SceneSettingState':
        return (
          <Box sx={{ backgroundColor: '#fafafa' }}>
            <CustomTrainingPageParagraph>
              <Box mt={2} mb={2}>
                <Stack spacing={2}>
                  <Box>
                    <Typography>背景</Typography>
                    <Autocomplete
                      data-testid='background-select'
                      multiple
                      disableCloseOnSelect
                      value={
                        props.domainData.scenePresetCreationDialogData
                          .selectedBackgrounds
                      }
                      defaultValue={
                        props.domainData.scenePresetCreationDialogData
                          .selectedBackgrounds
                      }
                      filterSelectedOptions
                      options={
                        props.domainData.scenePresetCreationDialogData
                          .backgrounds
                      }
                      groupBy={(option) =>
                        getUserGroupKind(props.auth.customClaims).find(
                          (kind) => kind.id === option.userGroupId
                        )?.name ?? ''
                      }
                      getOptionLabel={(option) => (option ? option.name : '')}
                      onChange={(_event, value) => {
                        props.setSelectedBackgrounds(value)
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      renderTags={(tagValue) => {
                        return tagValue.map((option, index) => (
                          <ColorChip
                            key={index}
                            label={option.name}
                            size='medium'
                            variant='filled'
                            isStretch
                            customColor={getChipColor(
                              option.userGroupId,
                              props.auth.customClaims
                            )}
                            sx={{
                              marginRight: (theme) => theme.spacing(1),
                            }}
                            onDelete={() =>
                              props.setSelectedBackgrounds(
                                props.domainData.scenePresetCreationDialogData.selectedBackgrounds.filter(
                                  (selectedBackground) =>
                                    selectedBackground.id !== option.id
                                )
                              )
                            }
                          />
                        ))
                      }}
                      renderGroup={(params) => (
                        <li
                          key={params.key}
                          data-testId={`background-${
                            params.group === '共有データ'
                              ? 'shared'
                              : 'customer'
                          }`}
                        >
                          <Box
                            pl={1}
                            color={{
                              backgroundColor: getChipColor(
                                params.group,
                                props.auth.customClaims
                              ),
                            }}
                          >
                            {params.group}
                          </Box>
                          <Box>{params.children}</Box>
                        </li>
                      )}
                    />
                  </Box>
                  <Box>
                    <Typography>光源</Typography>
                    <Autocomplete
                      data-testid='light-group-select'
                      multiple
                      disableCloseOnSelect
                      filterSelectedOptions
                      value={
                        props.domainData.scenePresetCreationDialogData
                          .selectedLightGroups
                      }
                      defaultValue={
                        props.domainData.scenePresetCreationDialogData
                          .selectedLightGroups
                      }
                      options={
                        props.domainData.scenePresetCreationDialogData
                          .lightGroups
                      }
                      groupBy={(option) =>
                        getUserGroupKind(props.auth.customClaims).find(
                          (kind) => kind.id === option.userGroupId
                        )?.name ?? ''
                      }
                      getOptionLabel={(option) => (option ? option.name : '')}
                      onChange={(_event, value) => {
                        props.setSelectedLightGroups(value)
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      renderTags={(tagValue) => {
                        return tagValue.map((option, index) => (
                          <ColorChip
                            key={index}
                            label={option.name}
                            size='medium'
                            variant='filled'
                            isStretch
                            customColor={getChipColor(
                              option.userGroupId,
                              props.auth.customClaims
                            )}
                            sx={{
                              marginRight: (theme) => theme.spacing(1),
                            }}
                            onDelete={() =>
                              props.setSelectedLightGroups(
                                props.domainData.scenePresetCreationDialogData.selectedLightGroups.filter(
                                  (selectedLightGroup) =>
                                    selectedLightGroup.id !== option.id
                                )
                              )
                            }
                          />
                        ))
                      }}
                      renderGroup={(params) => (
                        <li
                          key={params.key}
                          data-testId={`light-group-${
                            params.group === '共有データ'
                              ? 'shared'
                              : 'customer'
                          }`}
                        >
                          <Box
                            pl={1}
                            color={{
                              backgroundColor: getChipColor(
                                params.group,
                                props.auth.customClaims
                              ),
                            }}
                          >
                            {params.group}
                          </Box>
                          <Box>{params.children}</Box>
                        </li>
                      )}
                    />
                  </Box>
                  <Box>
                    <Typography>カメラ配置</Typography>
                    <Autocomplete
                      data-testid='camera-select'
                      multiple
                      disableCloseOnSelect
                      filterSelectedOptions
                      value={
                        props.domainData.scenePresetCreationDialogData
                          .selectedCameras
                      }
                      defaultValue={
                        props.domainData.scenePresetCreationDialogData
                          .selectedCameras
                      }
                      options={
                        props.domainData.scenePresetCreationDialogData.cameras
                      }
                      groupBy={(option) =>
                        getUserGroupKind(props.auth.customClaims).find(
                          (kind) => kind.id === option.userGroupId
                        )?.name ?? ''
                      }
                      getOptionLabel={(option) => (option ? option.name : '')}
                      onChange={(_event, value) => {
                        props.setSelectedCameras(value)
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      renderTags={(tagValue) => {
                        return tagValue.map((option, index) => (
                          <ColorChip
                            key={index}
                            label={option.name}
                            size='medium'
                            variant='filled'
                            isStretch
                            customColor={getChipColor(
                              option.userGroupId,
                              props.auth.customClaims
                            )}
                            sx={{
                              marginRight: (theme) => theme.spacing(1),
                            }}
                            onDelete={() =>
                              props.setSelectedCameras(
                                props.domainData.scenePresetCreationDialogData.selectedCameras.filter(
                                  (selectedCamera) =>
                                    selectedCamera.id !== option.id
                                )
                              )
                            }
                          />
                        ))
                      }}
                      renderGroup={(params) => (
                        <li
                          key={params.key}
                          data-testId={`camera-${
                            params.group === '共有データ'
                              ? 'shared'
                              : 'customer'
                          }`}
                        >
                          <Box
                            pl={1}
                            color={{
                              backgroundColor: getChipColor(
                                params.group,
                                props.auth.customClaims
                              ),
                            }}
                          >
                            {params.group}
                          </Box>
                          <Box>{params.children}</Box>
                        </li>
                      )}
                    />
                  </Box>
                </Stack>
              </Box>{' '}
            </CustomTrainingPageParagraph>
          </Box>
        )
      case 'MetadataState':
        return (
          <Box>
            <Box className={classes.stepTitle}>
              <Typography>メタデータを入力します。</Typography>
            </Box>
            <MetadataInput
              nameProps={{
                label: '表示名',
                value: props.domainData.scenePresetCreationDialogData.name,
                variant: 'outlined',
                readOnly: false,
                onChange: (event) => {
                  props.setScenePresetName(event.target.value)
                },
              }}
              remarksProps={{
                label: '概要',
                value: props.domainData.scenePresetCreationDialogData.overview,
                variant: 'outlined',
                readOnly: false,
                onChange: (event) => {
                  props.setScenePresetOverview(event.target.value)
                },
                rowNum: 4,
              }}
              helperText={scenePresetMetadataInputHelper(
                props.appState.scenePresetSubState.metadataSubState
              )}
              data-testid='scene-preset-input'
            />
          </Box>
        )
      case 'ExecuteState':
        return (
          <>
            <CommonCompleteDialog
              open={
                props.appState.scenePresetSubState.executeSubState ===
                  'Executed' &&
                props.domainData.scenePresetCreationDialogData
                  .createdScenePresetId !== ''
              }
              value={
                props.domainData.scenePresetCreationDialogData
                  .createdScenePresetId
              }
              label='シーンプリセットID'
              dialogText={'正常にデータを登録しました。'}
              handleClose={() => handleClickClosePresetDialog()}
              data-testid='scene-preset-dialog'
            />
            <Box m={2}>
              <Stack spacing={2}>
                <Box>
                  <Typography>背景</Typography>
                  <Autocomplete
                    readOnly
                    disabled
                    multiple
                    value={
                      props.domainData.scenePresetCreationDialogData
                        .selectedBackgrounds
                    }
                    defaultValue={
                      props.domainData.scenePresetCreationDialogData
                        .selectedBackgrounds
                    }
                    options={
                      props.domainData.scenePresetCreationDialogData.backgrounds
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(_event, value) => {
                      props.setSelectedBackgrounds(value)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    renderTags={(tagValue) => {
                      return tagValue.map((option, index) => (
                        <ColorChip
                          key={index}
                          label={option.name}
                          size='medium'
                          isStretch
                          variant='filled'
                          customColor={getChipColor(
                            option.userGroupId,
                            props.auth.customClaims
                          )}
                          sx={{
                            marginRight: (theme) => theme.spacing(1),
                          }}
                        />
                      ))
                    }}
                  />
                </Box>
                <Box>
                  <Typography>光源</Typography>
                  <Autocomplete
                    readOnly
                    disabled
                    multiple
                    value={
                      props.domainData.scenePresetCreationDialogData
                        .selectedLightGroups
                    }
                    defaultValue={
                      props.domainData.scenePresetCreationDialogData
                        .selectedLightGroups
                    }
                    options={
                      props.domainData.scenePresetCreationDialogData.lightGroups
                    }
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} />}
                    renderTags={(tagValue) => {
                      return tagValue.map((option, index) => (
                        <ColorChip
                          key={index}
                          label={option.name}
                          size='medium'
                          isStretch
                          variant='filled'
                          customColor={getChipColor(
                            option.userGroupId,
                            props.auth.customClaims
                          )}
                          sx={{
                            marginRight: (theme) => theme.spacing(1),
                          }}
                        />
                      ))
                    }}
                  />
                </Box>
                <Box>
                  <Typography>カメラ配置</Typography>
                  <Autocomplete
                    readOnly
                    disabled
                    multiple
                    value={
                      props.domainData.scenePresetCreationDialogData
                        .selectedCameras
                    }
                    defaultValue={
                      props.domainData.scenePresetCreationDialogData
                        .selectedCameras
                    }
                    options={
                      props.domainData.scenePresetCreationDialogData.cameras
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(_event, value) => {
                      props.setSelectedCameras(value)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    renderTags={(tagValue) => {
                      return tagValue.map((option, index) => (
                        <ColorChip
                          key={index}
                          label={option.name}
                          size='medium'
                          isStretch
                          variant='filled'
                          customColor={getChipColor(
                            option.userGroupId,
                            props.auth.customClaims
                          )}
                          sx={{
                            marginRight: (theme) => theme.spacing(1),
                          }}
                        />
                      ))
                    }}
                  />
                </Box>
                <Box>
                  <MetadataInput
                    nameProps={{
                      label: '表示名',
                      value:
                        props.domainData.scenePresetCreationDialogData.name,
                      readOnly: true,
                      variant: 'standard',
                      onChange: (event) => {
                        props.setScenePresetName(event.target.value)
                      },
                    }}
                    remarksProps={{
                      label: '概要',
                      value:
                        props.domainData.scenePresetCreationDialogData.overview,
                      readOnly: true,
                      variant: 'standard',
                      onChange: (event) => {
                        props.setScenePresetOverview(event.target.value)
                      },
                      rowNum: 4,
                    }}
                    helperText={scenePresetMetadataInputHelper(
                      props.appState.scenePresetSubState.metadataSubState
                    )}
                    data-testid='scenePresetsInput'
                  />
                </Box>
              </Stack>
            </Box>
          </>
        )
      default:
        return (
          <Box>
            <Typography>Unknown</Typography>
          </Box>
        )
    }
  }

  const getNextButtonProps = (props: Props) => {
    const label =
      props.appState.scenePresetState !== 'ExecuteState'
        ? '次へ'
        : props.appState.scenePresetSubState.executeSubState === 'ExecuteError'
        ? '再作成'
        : '作成'
    let disabled = false
    switch (props.appState.scenePresetState) {
      case 'SceneSettingState':
        disabled =
          props.appState.scenePresetSubState.sceneSettingSubState !==
          'InputRequired'
        break
      case 'MetadataState':
        disabled =
          props.appState.scenePresetSubState.metadataSubState !==
          'InputRequired'
        break
      case 'ExecuteState':
        disabled =
          props.appState.scenePresetSubState.executeSubState ===
          'ExecuteInProgress'
        break
      default:
        disabled = false
    }

    const onClick =
      props.appState.scenePresetState !== 'ExecuteState'
        ? () =>
            props.setNextStepScenePresetState(
              props.appState.scenePresetState,
              props.appState.scenePresetSubState
            )
        : () => props.executeScenePreset()

    return {
      label,
      disabled,
      onClick,
    }
  }

  return (
    <>
      <div className={classes.stepContainer}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            {
              name: 'シーンプリセット一覧',
              path: 'scene-presets',
            },
          ]}
        />
        <div className={classes.postAddButton}>
          <Box display='flex' alignItems='center'>
            <ScenePresetIcon
              className={classes.pageIcon}
              data-testid='scenePresetListTitleIcon'
            />
            <Typography component='div'>
              <h2 data-testid='scene-preset-list-title'>シーンプリセット</h2>
            </Typography>
          </Box>
          <Box>
            <Dialog
              maxWidth='md'
              fullWidth={true}
              aria-labelledby='customized-dialog-title'
              open={openPresetDialog}
            >
              <DialogContent className={classes.presetCreateDialog}>
                <Box className={classes.presetCreateDialogContainer}>
                  <Toast containerOptions={{ limit: 20 }}>
                    <Button
                      onClick={() => handleClickClosePresetDialog()}
                      className={classes.cancelLink}
                      data-testid='ScenePresetButtonCancel'
                    >
                      <Typography>キャンセル</Typography>
                    </Button>
                    <Typography
                      variant='h5'
                      data-testid='scene-preset-title'
                      className={classes.pageTitle}
                    >
                      シーンプリセット
                    </Typography>
                    <Box className={classes.stepperDiv}>
                      <StepperLayout
                        stepProps={stepNames.map((stepName) => ({
                          label: stepName,
                          previousButtonProps: {
                            label: '戻る',
                            visibility: stepName === 'シーンプリセット',
                            disabled: stepName === 'シーンプリセット',
                            onClick: () =>
                              props.setPrevStepState(
                                props.appState.scenePresetState
                              ),
                          },
                          nextButtonProps: getNextButtonProps({ ...props }),
                          stepContent: getStepContent(props),
                        }))}
                        activeStepIndex={getStepperNum(
                          props.appState.scenePresetState
                        )}
                        data-testid='scene-presets'
                      />
                    </Box>
                  </Toast>
                </Box>
              </DialogContent>
            </Dialog>
            <Box className={classes.addButton}>
              <Add />
              <Button
                component='a'
                style={{
                  color: '#0000EE',
                  padding: 0,
                  margin: 0,
                  textDecoration: 'underline',
                }}
                data-testid='scene-presets-entry'
                onClick={handleClickOpenPresetDialog}
              >
                <Typography>プリセットを追加</Typography>
              </Button>
            </Box>
          </Box>
        </div>
        <CustomTrainingPageParagraph>
          <FormControl
            variant='outlined'
            className={classes.userGroupKindSelectBox}
          >
            <InputLabel id='scenePresetListUserGroupKind'>
              データ種別
            </InputLabel>
            <Select
              data-testid='select'
              labelId='scenePresetListUserGroupKind-label'
              id='scenePresetListUserGroupKind-outlined'
              value={
                props.domainData.scenePresetListDisplayCondition
                  .selectedUserGroupKind
              }
              onChange={(
                e: SelectChangeEvent<'UserGroup' | 'SharedUserGroup'>
              ) =>
                selectUserGroupKind(
                  e.target.value as 'UserGroup' | 'SharedUserGroup'
                )
              }
              label='Select User Group Kind'
            >
              {userGroupKindList.map((kind) => (
                <MenuItem
                  data-testid={kind.kind}
                  value={kind.kind}
                  key={kind.kind}
                >
                  {kind.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CustomTrainingPageParagraph>
        <div className={classes.searchForm}>
          <div className={classes.searchField}>
            <SearchInput
              placeholder='キーワード (シーンプリセットID)'
              value={
                props.domainData.scenePresetListDisplayCondition.searchValue
              }
              onChangeValue={(event) =>
                handleChangeSearchValue(event.target.value)
              }
              onClickSearch={() => searchTableContent()}
              onPressEnter={() => searchTableContent()}
            />
          </div>
        </div>
        {scenePresetListTable}
      </div>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tableActions = (props: Props) => {
  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    props.setListDisplayCondition({
      ...props.domainData.scenePresetListDisplayCondition,
      searchValue: value,
    })
  }
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    const pageNumber =
      props.domainData.currentScenePresetList.length >
      props.domainData.scenePresetListDisplayCondition.pageNumber *
        displayNumber
        ? props.domainData.scenePresetListDisplayCondition.pageNumber
        : Math.ceil(
            props.domainData.currentScenePresetList.length / displayNumber
          ) - 1

    props.setListDisplayCondition({
      ...props.domainData.scenePresetListDisplayCondition,
      pageNumber: pageNumber,
      displayNumber: displayNumber,
    })

    props.getScenePresetList()
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    props.clearScenePresetList()
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.scenePresetListDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.scenePresetListDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })

    props.getScenePresetList()
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.scenePresetListDisplayCondition,
      pageNumber: pageNumber,
    })

    props.getScenePresetList()
  }

  /** 検索の実行 */
  const searchTableContent = () => {
    props.clearScenePresetList()
    props.setListDisplayCondition({
      ...props.domainData.scenePresetListDisplayCondition,
      pageNumber: 0,
    })

    props.getScenePresetList()
  }
  /** ユーザーグループ種別の選択 */
  const selectedUserGroupKind = (
    selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
  ) => {
    props.clearScenePresetList()
    props.setListDisplayCondition({
      ...props.domainData.scenePresetListDisplayCondition,
      pageNumber: 0,
      displayNumber: 10,
      searchValue: '',
      sortKey: 'generated-at',
      sortOrder: 'desc',
      selectedUserGroupKind: selectedUserGroupKind,
    })
    props.getScenePresetList()
  }
  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectedUserGroupKind,
  }
}

export const ScenePresetListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ScenePresetList))
