import React, { useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { InferenceResultsViewerProps } from './types'
import { CanvasInfo } from 'views/utils/types'
import { ImageWithInfo } from 'views/components/molecules'
import { ThumbnailSlider } from 'views/components/organisms'
import { isUndefined } from 'utils/typeguard'
import NavigateBefore from '@mui/icons-material/NavigateBefore'
import NavigateNext from '@mui/icons-material/NavigateNext'
import IconButton from '@mui/material/IconButton'
import Fab from '@mui/material/Fab'
import Paper from '@mui/material/Paper'
import Slider from '@mui/material/Slider'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { grey } from '@mui/material/colors'
import FormControlLabel from '@mui/material/FormControlLabel'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import { Radio, RadioGroup } from '@mui/material'
import { TargetDisplay } from 'state/ducks/inferenceDetail'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageDataName: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  groupedImageListLength: { whiteSpace: 'nowrap' },
  enlargedImageDiv: {
    height: 720,
    width: 1280,
  },
  enlargedImageDivControl: {
    height: 400,
  },
  fab: {
    position: 'absolute',
    opacity: 0.5,
    zIndex: 1,
  },
  sliderDiv: {
    padding: theme.spacing(1),
    '& > *': {
      height: '100%',
      width: '100%',
    },
  },
  downloadButtonDiv: { display: 'flex', justifyContent: 'flex-end' },
  slider: {
    width: '30%',
    '& > .MuiSlider-track': {
      border: 'none',
    },
  },
  loading: {
    position: 'absolute',
    top: 'calc(50% - 32px / 2)',
  },
  label: {
    fontSize: '14px',
    maxWidth: '88px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  textField: {
    width: '5rem',
  },
}))

const CLASS_NAME_ITEM_HEIGHT = 48
const CLASS_NAME_ITEM_PADDING_TOP = 8
const ClassNameMenuProps = {
  PaperProps: {
    style: {
      maxHeight: CLASS_NAME_ITEM_HEIGHT * 4.5 + CLASS_NAME_ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
const TARGET_DISPLAY_LIST: {
  key: TargetDisplay
  label: string
}[] = [
  { key: 'results', label: '推論結果' },
  { key: 'annotations', label: '正解情報' },
  { key: 'differences', label: '差分' },
]

const getSelectedIdArray = (selectedIds: string[], selectId: string) => {
  const index = selectedIds.findIndex((id) => id === selectId)

  if (index === -1) {
    selectedIds.push(selectId)
  } else {
    selectedIds.splice(index, 1)
  }

  return selectedIds
}

const SLIDER_MARKS = Array.from({ length: 10 }).map((_, i) => {
  return {
    value: (i + 1) / 10,
  }
})

export const InferenceResultsViewer: React.FC<InferenceResultsViewerProps> = (
  props: InferenceResultsViewerProps
) => {
  const { classes } = useStyles()

  const ref = useRef<HTMLInputElement>(null)
  const [viewScore, setViewScore] = useState(0.5)
  const [hoverFlg, setHoverFlg] = useState(false)
  const [imageIndex, setImageIndex] = useState(
    props.imageData.findIndex((image) => image.id === props.selectedImageId)
  )
  const [displayCount, setDisplayCount] = useState('')

  useEffect(() => {
    if (props.canvasInfoDisplayCondition?.displayCount != null) {
      setDisplayCount(props.canvasInfoDisplayCondition.displayCount)
    }
  }, [props.canvasInfoDisplayCondition?.displayCount])

  const displayBoxesCount = useMemo(() => {
    return props.canvasData.results.filter(
      (result) => viewScore <= (result.score ?? 0)
    ).length
  }, [viewScore, props.canvasData.results])

  /** データ取得中のloading */
  const loading = useMemo(() => {
    if (props.loading) {
      return (
        <Box className={classes.loading}>
          <CircularProgress size={32} />
        </Box>
      )
    }
    return <></>
  }, [props.loading])

  const labelGroups = useMemo(() => {
    const labelGroups: CanvasInfo[] = []
    props.canvasData.results.map((result) => {
      const index = labelGroups.findIndex(
        (labelGroup) => labelGroup.id === result.id
      )
      if (index === -1) {
        labelGroups.push(result)
      }
    })
    return labelGroups
  }, [props.canvasData.results])

  const selectedCanvasInfos = useMemo(() => {
    const selectedCanvasInfos: CanvasInfo[] = []
    props.canvasData.results.map((result) => {
      const index =
        props.inferenceResultDisplayCondition.selectedLabelIds.findIndex(
          (selectedId) => selectedId === result.id
        )
      if (index !== -1) {
        selectedCanvasInfos.push(result)
      }
    })
    return selectedCanvasInfos
  }, [props.canvasData.results, props.inferenceResultDisplayCondition])

  const selectedDisplayBoxesCount = useMemo(() => {
    return selectedCanvasInfos.filter(
      (canvasInfo) => viewScore <= (canvasInfo.score ?? 0)
    ).length
  }, [viewScore, selectedCanvasInfos])

  /** 表示サムネイルとデータ */
  const imageWithInfo = useMemo(() => {
    const selectedImage = props.imageData.find(
      (data) => data.id === props.selectedImageId
    )
    if (isUndefined(selectedImage)) return
    const canvasData = {
      ...props.canvasData,
      results: selectedCanvasInfos.filter(
        (canvasInfo) => viewScore <= (canvasInfo.score ?? 0)
      ),
    }
    return (
      <ImageWithInfo
        imageHeight={props.isDialog ? (props.control ? 400 : undefined) : 300}
        isDialog={props.control ? false : true}
        url={selectedImage.processedUrl}
        canvasData={canvasData}
        canvasDisplayCondition={props.inferenceResultDisplayCondition}
        targetDisplay={props.inferenceResultDisplayCondition.targetDisplay}
        informations={[
          {
            key: 'ID',
            value: selectedImage.id.substring(0, 8),
          },
          {
            key: 'fileName',
            value: selectedImage.fileName,
          },
        ]}
        informationsDisplayDefault={true}
        informationsDisplayControlWithIcon={false}
      />
    )
  }, [
    displayBoxesCount,
    props.control,
    props.selectedImageId,
    props.canvasData,
    props.inferenceResultDisplayCondition,
    props.imageData,
    props.isDisableScore,
  ])

  const thumbnails = useMemo(
    () =>
      props.imageData.map((imageData) => {
        return { id: imageData.id, url: imageData.thumbnailUrl }
      }),
    [props.imageData.length]
  )

  const isSelected = (labelGroupId: string) => {
    const index =
      props.inferenceResultDisplayCondition.selectedLabelIds.findIndex(
        (id) => id === labelGroupId
      )
    if (index !== -1) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      {!props.isDialog ? (
        <div className={classes.root}>
          {props.isDisplayBoxesCount && (
            <Box
              display='flex'
              justifyContent='flex-end'
              alignItems='center'
              gap={1}
              mb={2}
            >
              <Box>表示数</Box>
              <Box>{selectedDisplayBoxesCount}</Box>
              <Box>/</Box>
              <Box>{selectedCanvasInfos.length}</Box>
            </Box>
          )}
          {!props.isDisableScore && (
            <Box display='flex' justifyContent='flex-end' mb={2}>
              <Slider
                className={classes.slider}
                marks={SLIDER_MARKS}
                value={viewScore}
                defaultValue={0.5}
                step={0.01}
                min={0}
                max={1}
                valueLabelDisplay='auto'
                track='inverted'
                onChange={(e, value) => setViewScore(value as number)}
              />
            </Box>
          )}
          {props.canvasInfoDisplayCondition != null && (
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              mb={2}
            >
              <Box>
                {props.classNames != null && (
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel>クラス</InputLabel>
                    <Select
                      labelId='class-name-multiple-checkbox-label'
                      id='class-name-multiple-checkbox'
                      multiple
                      value={props.selectedClassNames}
                      onChange={(event: SelectChangeEvent<string[]>) => {
                        if (!props.onCheckedClassName) return

                        const {
                          target: { value },
                        } = event
                        props.onCheckedClassName(
                          typeof value === 'string' ? value.split(',') : value
                        )
                      }}
                      input={<OutlinedInput label='class-name' />}
                      renderValue={(selected) =>
                        (selected as string[]).join(', ')
                      }
                      MenuProps={ClassNameMenuProps}
                    >
                      {props.classNames.map((className) => (
                        <MenuItem key={className} value={className}>
                          <Checkbox
                            checked={
                              props.selectedClassNames
                                ? props.selectedClassNames?.indexOf(className) >
                                  -1
                                : false
                            }
                          />
                          <ListItemText primary={className} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>
              <Box display='flex' alignItems='center' gap={1}>
                <Box>表示数</Box>
                <TextField
                  inputRef={ref}
                  className={classes.textField}
                  inputProps={{ style: { textAlign: 'right' } }}
                  size='small'
                  value={
                    props.canvasInfoDisplayCondition.isDisplayed
                      ? displayCount
                      : 0
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      ref.current?.blur()
                    }
                  }}
                  onChange={(e) => {
                    // 数字以外を入力させない
                    const reg = /^([1-9]\d*|0)$/
                    if (reg.test(e.target.value) || e.target.value === '') {
                      setDisplayCount(e.target.value)
                    }
                  }}
                  onBlur={() => {
                    if (
                      props.canvasInfoDisplayCondition == null ||
                      props.onChangeCanvasInfoDisplayCondition == null
                    )
                      return

                    let newValue = ''
                    if (
                      Number(displayCount) >
                      props.canvasInfoDisplayCondition?.totalCount
                    ) {
                      // 上限値以上の数値を入力された場合は上限値にする
                      newValue = String(
                        props.canvasInfoDisplayCondition?.totalCount
                      )
                    } else if (Number(displayCount) === 0) {
                      // 空文字、もしくは0が入力された場合は下限値の1を設定する
                      newValue = '1'
                    } else {
                      newValue = displayCount
                    }

                    setDisplayCount(newValue)
                    props.onChangeCanvasInfoDisplayCondition(newValue)
                  }}
                  disabled={!props.canvasInfoDisplayCondition.isDisplayed}
                />
                <Box>/</Box>
                <Box>
                  {props.canvasInfoDisplayCondition.isDisplayed
                    ? props.canvasInfoDisplayCondition.totalCount
                    : 0}
                </Box>
              </Box>
            </Box>
          )}
          <Box overflow='auto'>
            <Box display='flex' position='relative' width='966px'>
              <Box width='20%'>
                <Box component={Paper} p={1} overflow='auto'>
                  <Box>表示設定</Box>
                  <Box paddingLeft={2}>
                    {props.inferenceResultDisplayCondition.targetDisplay && (
                      <>
                        <Box display='flex' width='60%' mt={1}>
                          表示対象
                        </Box>
                        <Box display='flex' justifyContent='end' mr={2}>
                          <RadioGroup
                            value={
                              props.inferenceResultDisplayCondition
                                .targetDisplay
                            }
                            onChange={(event) =>
                              props.changeInferenceResultDisplayCondition({
                                ...props.inferenceResultDisplayCondition,
                                targetDisplay: event.target
                                  .value as TargetDisplay,
                              })
                            }
                          >
                            {TARGET_DISPLAY_LIST.map((item) => (
                              <FormControlLabel
                                key={item.key}
                                value={item.key}
                                label={item.label}
                                control={
                                  <Radio
                                    disabled={
                                      props.inferenceResultDisplayCondition
                                        .disabled || !props.canvasData[item.key]
                                    }
                                    color='secondary'
                                  />
                                }
                              />
                            ))}
                          </RadioGroup>
                        </Box>
                      </>
                    )}
                    <Box display='flex' alignItems='center'>
                      <Box display='flex' width='60%'>
                        画像
                      </Box>
                      <Box width='40%'>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={
                                props.inferenceResultDisplayCondition.disabled
                              }
                              checked={
                                !props.inferenceResultDisplayCondition.isHidden
                              }
                              onChange={(event) =>
                                props.changeInferenceResultDisplayCondition({
                                  ...props.inferenceResultDisplayCondition,
                                  isHidden: !event.target.checked,
                                })
                              }
                              color='secondary'
                            />
                          }
                          label=''
                        />
                      </Box>
                    </Box>
                    <Box display='flex' alignItems='center'>
                      <Box display='flex' width='60%'>
                        マスク
                      </Box>
                      <Box width='40%'>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={
                                props.inferenceResultDisplayCondition.disabled
                              }
                              checked={
                                props.inferenceResultDisplayCondition.mask
                              }
                              onChange={(event) =>
                                props.changeInferenceResultDisplayCondition({
                                  ...props.inferenceResultDisplayCondition,
                                  mask: event.target.checked,
                                })
                              }
                              color='secondary'
                            />
                          }
                          label=''
                        />
                      </Box>
                    </Box>
                    <Box display='flex' alignItems='center'>
                      <Box display='flex' width='60%'>
                        ボックス
                      </Box>
                      <Box width='40%'>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={
                                props.inferenceResultDisplayCondition.disabled
                              }
                              checked={
                                props.inferenceResultDisplayCondition.bbox
                              }
                              onChange={(event) =>
                                props.changeInferenceResultDisplayCondition({
                                  ...props.inferenceResultDisplayCondition,
                                  bbox: event.target.checked,
                                })
                              }
                              color='secondary'
                            />
                          }
                          label=''
                        />
                      </Box>
                    </Box>
                    <Box display='flex' alignItems='center'>
                      <Box display='flex' width='60%'>
                        ラベル
                      </Box>
                      <Box width='40%'>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={
                                props.inferenceResultDisplayCondition.disabled
                              }
                              checked={
                                props.inferenceResultDisplayCondition.label
                              }
                              onChange={(event) =>
                                props.changeInferenceResultDisplayCondition({
                                  ...props.inferenceResultDisplayCondition,
                                  label: event.target.checked,
                                })
                              }
                              color='secondary'
                            />
                          }
                          label=''
                        />
                      </Box>
                    </Box>
                    {!props.isDisableScore && (
                      <Box display='flex' alignItems='center'>
                        <Box display='flex' width='60%'>
                          スコア
                        </Box>
                        <Box width='40%'>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={
                                  props.inferenceResultDisplayCondition.score
                                }
                                onChange={(event) =>
                                  props.changeInferenceResultDisplayCondition({
                                    ...props.inferenceResultDisplayCondition,
                                    score: event.target.checked,
                                  })
                                }
                                color='secondary'
                              />
                            }
                            label=''
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box width='57%' display='flex' justifyContent='center'>
                <div style={{ height: '300px' }}>{imageWithInfo}</div>
                {loading}
              </Box>
              <Box ml={1} width='23%' overflow='auto'>
                {!props.isDisableSelectButton && (
                  <Box mb={1}>
                    <Button
                      variant='outlined'
                      style={{
                        backgroundColor: '#D9E5FF',
                        marginRight: '16px',
                      }}
                      onClick={() =>
                        props.changeInferenceResultDisplayCondition({
                          ...props.inferenceResultDisplayCondition,
                          selectedLabelIds: labelGroups.map((labelGroup) => {
                            return labelGroup.id
                          }),
                        })
                      }
                    >
                      <Typography variant='caption' color='primary'>
                        全選択
                      </Typography>
                    </Button>
                    <Button
                      variant='outlined'
                      style={{
                        backgroundColor: '#D9E5FF',
                      }}
                      onClick={() =>
                        props.changeInferenceResultDisplayCondition({
                          ...props.inferenceResultDisplayCondition,
                          selectedLabelIds: [],
                        })
                      }
                    >
                      <Typography variant='caption' color='primary'>
                        全解除
                      </Typography>
                    </Button>
                  </Box>
                )}
                <Box component={Paper} overflow='auto' maxHeight={240}>
                  <List>
                    {labelGroups.map((labelGroup) => {
                      return (
                        <ListItemButton
                          style={{ marginRight: '8px', marginLeft: '8px' }}
                          sx={
                            isSelected(labelGroup.id)
                              ? { backgroundColor: grey[200] }
                              : undefined
                          }
                          key={labelGroup.id}
                          onClick={() =>
                            props.changeInferenceResultDisplayCondition({
                              ...props.inferenceResultDisplayCondition,
                              selectedLabelIds: getSelectedIdArray(
                                props.inferenceResultDisplayCondition
                                  .selectedLabelIds,
                                labelGroup.id
                              ),
                            })
                          }
                        >
                          <Box display='flex' alignItems='center'>
                            <Box
                              mr={1}
                              width={10}
                              height={10}
                              style={
                                isSelected(labelGroup.id)
                                  ? { backgroundColor: labelGroup.color }
                                  : { backgroundColor: grey[300] }
                              }
                            />
                            <Tooltip
                              title={labelGroup.label}
                              placement='bottom'
                            >
                              <Typography className={classes.label}>
                                {labelGroup.label}
                              </Typography>
                            </Tooltip>
                          </Box>
                          <Box
                            display='flex'
                            justifyContent='flex-end'
                            width='100%'
                          >
                            <Tooltip title={labelGroup.id} placement='bottom'>
                              <Typography style={{ fontSize: '14px' }}>
                                {labelGroup.id.substring(0, 8)}
                              </Typography>
                            </Tooltip>
                          </Box>
                        </ListItemButton>
                      )
                    })}
                  </List>
                </Box>
              </Box>
            </Box>
          </Box>
          {!isUndefined(props.imageData) && !props.isDisableThumbnailSlider && (
            <div className={classes.sliderDiv}>
              <ThumbnailSlider
                thumbnails={thumbnails}
                rows={props.sliderRows}
                onClickImage={props.onClickImage}
                selectedId={props.selectedImageId}
              />
            </div>
          )}
        </div>
      ) : props.control ? (
        <div className={classes.root} style={{ width: '1280px' }}>
          {props.isDisplayBoxesCount && (
            <Box
              display='flex'
              justifyContent='flex-end'
              alignItems='center'
              gap={1}
              mb={2}
            >
              <Box>表示数</Box>
              <Box>{selectedDisplayBoxesCount}</Box>
              <Box>/</Box>
              <Box>{selectedCanvasInfos.length}</Box>
            </Box>
          )}
          {!props.isDisableScore && (
            <Box display='flex' justifyContent='flex-end' mb={2}>
              <Slider
                className={classes.slider}
                marks={SLIDER_MARKS}
                value={viewScore}
                defaultValue={0.5}
                step={0.01}
                min={0}
                max={1}
                valueLabelDisplay='auto'
                track='inverted'
                onChange={(e, value) => setViewScore(value as number)}
              />
            </Box>
          )}
          <Box display='flex' position='relative'>
            <Box width='20%'>
              <Box component={Paper} p={1} overflow='auto'>
                <Box>表示設定</Box>
                <Box paddingLeft={2}>
                  <Box display='flex' alignItems='center'>
                    <Box display='flex' width='60%'>
                      マスク
                    </Box>
                    <Box width='40%'>
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={
                              props.inferenceResultDisplayCondition.disabled
                            }
                            checked={props.inferenceResultDisplayCondition.mask}
                            onChange={(event) =>
                              props.changeInferenceResultDisplayCondition({
                                ...props.inferenceResultDisplayCondition,
                                mask: event.target.checked,
                              })
                            }
                            color='secondary'
                          />
                        }
                        label=''
                      />
                    </Box>
                  </Box>
                  <Box display='flex' alignItems='center'>
                    <Box display='flex' width='60%'>
                      ボックス
                    </Box>
                    <Box width='40%'>
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={
                              props.inferenceResultDisplayCondition.disabled
                            }
                            checked={props.inferenceResultDisplayCondition.bbox}
                            onChange={(event) =>
                              props.changeInferenceResultDisplayCondition({
                                ...props.inferenceResultDisplayCondition,
                                bbox: event.target.checked,
                              })
                            }
                            color='secondary'
                          />
                        }
                        label=''
                      />
                    </Box>
                  </Box>
                  <Box display='flex' alignItems='center'>
                    <Box display='flex' width='60%'>
                      ラベル
                    </Box>
                    <Box width='40%'>
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={
                              props.inferenceResultDisplayCondition.disabled
                            }
                            checked={
                              props.inferenceResultDisplayCondition.label
                            }
                            onChange={(event) =>
                              props.changeInferenceResultDisplayCondition({
                                ...props.inferenceResultDisplayCondition,
                                label: event.target.checked,
                              })
                            }
                            color='secondary'
                          />
                        }
                        label=''
                      />
                    </Box>
                  </Box>
                  {!props.isDisableScore && (
                    <Box display='flex' alignItems='center'>
                      <Box display='flex' width='60%'>
                        スコア
                      </Box>
                      <Box width='40%'>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={
                                props.inferenceResultDisplayCondition.score
                              }
                              onChange={(event) =>
                                props.changeInferenceResultDisplayCondition({
                                  ...props.inferenceResultDisplayCondition,
                                  score: event.target.checked,
                                })
                              }
                              color='secondary'
                            />
                          }
                          label=''
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box width='57%' display='flex' justifyContent='center'>
              <div
                className={classes.enlargedImageDivControl}
                onMouseEnter={() => setHoverFlg(true)}
                onMouseLeave={() => setHoverFlg(false)}
              >
                {hoverFlg && (
                  <Fab
                    className={classes.fab}
                    style={{ top: '50%', left: '22%' }}
                    disabled={imageIndex === 0}
                  >
                    <IconButton
                      onClick={() => {
                        setImageIndex(imageIndex - 1)
                        props.onClickImage(props.imageData[imageIndex - 1].id)
                      }}
                    >
                      <NavigateBefore />
                    </IconButton>
                  </Fab>
                )}
                {imageWithInfo}
                {hoverFlg && (
                  <Fab
                    className={classes.fab}
                    style={{ top: '50%', right: '25%' }}
                    disabled={imageIndex + 1 === props.imageData.length}
                  >
                    <IconButton
                      onClick={() => {
                        setImageIndex(imageIndex + 1)
                        props.onClickImage(props.imageData[imageIndex + 1].id)
                      }}
                    >
                      <NavigateNext />
                    </IconButton>
                  </Fab>
                )}
              </div>
              {loading}
            </Box>
            <Box ml={1} width='23%' overflow='auto'>
              <Box mb={1}>
                <Button
                  variant='outlined'
                  style={{
                    backgroundColor: '#D9E5FF',
                    marginRight: '16px',
                  }}
                  onClick={() =>
                    props.changeInferenceResultDisplayCondition({
                      ...props.inferenceResultDisplayCondition,
                      selectedLabelIds: labelGroups.map((labelGroup) => {
                        return labelGroup.id
                      }),
                    })
                  }
                >
                  <Typography variant='caption' color='primary'>
                    全選択
                  </Typography>
                </Button>
                <Button
                  variant='outlined'
                  style={{
                    backgroundColor: '#D9E5FF',
                  }}
                  onClick={() =>
                    props.changeInferenceResultDisplayCondition({
                      ...props.inferenceResultDisplayCondition,
                      selectedLabelIds: [],
                    })
                  }
                >
                  <Typography variant='caption' color='primary'>
                    全解除
                  </Typography>
                </Button>
              </Box>
              <Box component={Paper} overflow='auto' maxHeight={240}>
                <List>
                  {labelGroups.map((labelGroup) => {
                    return (
                      <ListItemButton
                        style={{ marginRight: '8px', marginLeft: '8px' }}
                        sx={
                          isSelected(labelGroup.id)
                            ? { backgroundColor: grey[200] }
                            : undefined
                        }
                        key={labelGroup.id}
                        onClick={() =>
                          props.changeInferenceResultDisplayCondition({
                            ...props.inferenceResultDisplayCondition,
                            selectedLabelIds: getSelectedIdArray(
                              props.inferenceResultDisplayCondition
                                .selectedLabelIds,
                              labelGroup.id
                            ),
                          })
                        }
                      >
                        <Box display='flex' alignItems='center'>
                          <Box
                            mr={1}
                            width={10}
                            height={10}
                            style={
                              isSelected(labelGroup.id)
                                ? { backgroundColor: labelGroup.color }
                                : { backgroundColor: grey[300] }
                            }
                          />
                          <Tooltip title={labelGroup.label} placement='bottom'>
                            <Typography className={classes.label}>
                              {labelGroup.label}
                            </Typography>
                          </Tooltip>
                        </Box>
                        <Box
                          display='flex'
                          justifyContent='flex-end'
                          width='100%'
                        >
                          <Tooltip title={labelGroup.id} placement='bottom'>
                            <Typography style={{ fontSize: '14px' }}>
                              {labelGroup.id.substring(0, 8)}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </ListItemButton>
                    )
                  })}
                </List>
              </Box>
            </Box>
          </Box>
        </div>
      ) : (
        <div className={classes.root}>
          <Box mr={2} ml={2}>
            <Box
              width='100%'
              display='flex'
              justifyContent='center'
              mb={1}
              position='relative'
            >
              <div
                className={classes.enlargedImageDiv}
                onMouseEnter={() => setHoverFlg(true)}
                onMouseLeave={() => setHoverFlg(false)}
              >
                {hoverFlg && (
                  <Fab
                    className={classes.fab}
                    style={{ top: '50%', left: '16px' }}
                    disabled={imageIndex === 0}
                  >
                    <IconButton
                      onClick={() => {
                        setImageIndex(imageIndex - 1)
                        props.onClickImage(props.imageData[imageIndex - 1].id)
                      }}
                    >
                      <NavigateBefore />
                    </IconButton>
                  </Fab>
                )}
                {imageWithInfo}
                {hoverFlg && (
                  <Fab
                    className={classes.fab}
                    style={{ top: '50%', right: '16px' }}
                    disabled={imageIndex + 1 === props.imageData.length}
                  >
                    <IconButton
                      onClick={() => {
                        setImageIndex(imageIndex + 1)
                        props.onClickImage(props.imageData[imageIndex + 1].id)
                      }}
                    >
                      <NavigateNext />
                    </IconButton>
                  </Fab>
                )}
              </div>
              {loading}
            </Box>
          </Box>
        </div>
      )}
    </>
  )
}
