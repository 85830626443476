// 補色に変換
export function convertCompColor(color: string): string {
  // バリデーション
  if (!/^#?[0-9A-Fa-f]{6}$/.test(color)) {
    return color
  }

  // `#` を削除.
  const cleanHex = color.startsWith('#') ? color.slice(1) : color

  // RGB に変換.
  const r = parseInt(cleanHex.slice(0, 2), 16)
  const g = parseInt(cleanHex.slice(2, 4), 16)
  const b = parseInt(cleanHex.slice(4, 6), 16)

  // 補色を算出
  const compR = 255 - r
  const compG = 255 - g
  const compB = 255 - b

  // RGB を 16進数 のカラーコードに変換.
  return `#${compR.toString(16).padStart(2, '0')}${compG
    .toString(16)
    .padStart(2, '0')}${compB.toString(16).padStart(2, '0')}`
}
