import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
  useRouteMatch,
  Link,
  useHistory,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'

import { State } from 'state/store'
import {
  FeatureDataGroupInfo,
  FeatureDataGroupListActions,
  featureDataGroupListActions,
  featureDataGroupListOperations,
  FeatureDataGroupListDisplayCondition,
} from 'state/ducks/featureDataGroupList'

import {
  FeatureDataGroupsIcon,
  SelectableTable,
  SearchInput,
  CustomTrainingPageParagraph,
  GlobalLoading,
  TooltipLink,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  DISPLAY_NONE_RADIO_ROW_HEIGHT,
  BreadcrumbsComponent,
} from 'views/components'

import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Add from '@mui/icons-material/Add'

import { formatDateTimeSec } from 'views/components/utils/date'
import { getUserGroupKindList } from 'views/containers/utils'
import { useTheme } from '@mui/material/styles'

const mapStateToProps = (state: State) => ({
  ...state.pages.featureDataGroupListState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, FeatureDataGroupListActions>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 特徴量データグループ一覧取得 */
  getFeatureDataGroupList: () => {
    dispatch(featureDataGroupListOperations.getFeatureDataGroupList())
  },
  /** 特徴量データグループ一覧をクリア */
  clearFeatureDataGroupList: () =>
    dispatch(featureDataGroupListActions.clearFeatureDataGroupList()),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (
    listCondition: FeatureDataGroupListDisplayCondition
  ) =>
    dispatch(
      featureDataGroupListActions.setListDisplayCondition(listCondition)
    ),
  /** Stateのクリア */
  clearFeatureDataGroupListState: () =>
    dispatch(featureDataGroupListActions.clearFeatureDataGroupListState()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  head: {
    height: '240px',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  resultCountSelectBox: {
    width: theme.custom.table.resultCountSelect.width,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  stepContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  algorithmSelectBox: {
    width: '100%',
  },
  explanation: {
    fontSize: theme.typography.pxToRem(18),
  },
}))

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'feature-data-group-id',
    title: '特徴量データグループ ID',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'name',
    title: '特徴量データグループ名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'algorithm-name',
    title: 'アルゴリズム名',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'remarks',
    title: '備考',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'overview',
    title: '概要',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'updated-at',
    title: '更新日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'created-at',
    title: '作成日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'created-user-id',
    title: '作成ユーザーID',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const FeatureDataGroupList: React.FC<Props> = (props: Props) => {
  const { url } = useRouteMatch()
  const { classes } = useStyles()
  const globalTheme = useTheme()
  const history = useHistory()

  useEffect(() => {
    props.getFeatureDataGroupList()
    return () => {
      props.clearFeatureDataGroupListState()
    }
  }, [props.auth.customClaims.userGroupId])

  const {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectTrainedModel,
    selectedUserGroupKind: selectUserGroupKind,
  } = tableActions(props, history)

  /** 検索ワードがある場合日付ソートをさせない */
  const tableHeader = useMemo(() => {
    if (props.domainData.featureDataGroupListDisplayCondition.searchValue) {
      return TABLE_HEADERS.map((header) => {
        return { ...header, sortable: false }
      })
    }

    return TABLE_HEADERS
  }, [props.domainData.featureDataGroupListDisplayCondition.searchValue])

  /** テーブルに表示する配列 */
  const tableContent = useMemo(() => {
    // 表示条件
    const condition = props.domainData.featureDataGroupListDisplayCondition
    // 表示条件に合わせて配列を加工
    const displayList = props.domainData.currentFeatureDataGroupList.slice(
      condition.displayNumber * condition.pageNumber,
      condition.displayNumber * condition.pageNumber + condition.displayNumber
    )

    // 表示対象が存在しない場合は、前のページの一覧を表示
    if (displayList.length === 0 && condition.pageNumber !== 0) {
      return props.domainData.currentFeatureDataGroupList.slice(
        condition.displayNumber * (condition.pageNumber - 1),
        condition.displayNumber * (condition.pageNumber - 1) +
          condition.displayNumber
      )
    }

    return displayList
  }, [
    props.domainData.featureDataGroupListDisplayCondition,
    props.domainData.currentFeatureDataGroupList,
  ])

  /** テーブルに表示するモデルのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    const convertedList: FeatureDataGroupInfo[] = tableContent.map(
      (featureDataGroupList: FeatureDataGroupInfo) => {
        return {
          featureDataGroupId: featureDataGroupList.featureDataGroupId,
          name: featureDataGroupList.name,
          algorithmName: featureDataGroupList.algorithmName,
          remarks: featureDataGroupList.remarks,
          overview: featureDataGroupList.overview,
          updatedAt: featureDataGroupList.updatedAt,
          createAt: featureDataGroupList.createAt,
          createdUserId: featureDataGroupList.createdUserId,
        }
      }
    )

    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'featureDataGroupId') {
          return (
            <TooltipLink
              key={key}
              data-testid={`feature-data-groups-${value}`}
              title={value}
              placement='right-start'
              onClick={(e) => {
                if (e) {
                  e.stopPropagation()
                }
                history.push(
                  `feature-data-groups/${value}${
                    props.domainData.featureDataGroupListDisplayCondition
                      .selectedUserGroupKind === 'UserGroup'
                      ? ''
                      : '?shared-user-group=true'
                  }`
                )
              }}
            />
          )
        } else if (key === 'updatedAt' || key === 'createAt') {
          if (value) {
            return (
              <Typography key={key}>
                {formatDateTimeSec(
                  value ? value.toDate() : TABLE_CELL_NOT_APPLICABLE
                )}
              </Typography>
            )
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'createdUserId') {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography>{value.substring(0, 8)}</Typography>
            </Tooltip>
          )
        } else {
          if (value) {
            return <Typography key={key}>{value}</Typography>
          }
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [tableContent])

  /** テーブル */
  const featureDataGroupListTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.featureDataGroupListDisplayCondition.displayNumber
        }
        headers={tableHeader}
        rows={tableRows}
        totalCount={
          props.domainData.featureDataGroupListDisplayCondition.totalCount
        }
        loading={props.appState.inProgress}
        tableHeight={TABLE_HEADER_HEIGHT + 10 * DISPLAY_NONE_RADIO_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={props.domainData.featureDataGroupListDisplayCondition.pageNumber}
        sortOrder={{
          key: props.domainData.featureDataGroupListDisplayCondition.sortKey,
          order:
            props.domainData.featureDataGroupListDisplayCondition.sortOrder,
        }}
        displayNoneRadio={true}
        isPointer={true}
        onClickRow={(index: number) => selectTrainedModel(index)}
        onClickOrderChange={(key: string) => changeTableSortOrder(key)}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.domainData.featureDataGroupListDisplayCondition,
    props.appState.inProgress,
  ])

  const userGroupKindList = getUserGroupKindList(
    props.auth.customClaims.sharedList
  )

  return (
    <>
      <div className={classes.stepContainer}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            {
              name: '特徴量データグループ一覧',
              path: 'feature-data-groups',
            },
          ]}
        />
        <div className={classes.postAddButton}>
          <Box display='flex' alignItems='center'>
            <FeatureDataGroupsIcon
              className={classes.pageIcon}
              data-testid='feature-dataListTitleIcon'
            />
            <Typography component='div'>
              <h2 data-testid='feature-data-group-list-title'>
                特徴量データグループ
              </h2>
            </Typography>
          </Box>
          <Box display='flex'>
            <Add />
            <Link data-testid='feature-data-groups-entry' to={`${url}/entry`}>
              <Typography>特徴量データグループ作成</Typography>
            </Link>
          </Box>
        </div>
        <CustomTrainingPageParagraph>
          <FormControl
            variant='outlined'
            className={classes.algorithmSelectBox}
          >
            <InputLabel id='feature-data-list-user-group-kind'>
              データ種別
            </InputLabel>
            <Select
              labelId='feature-data-list-user-group-kind-label'
              id='feature-data-list-user-group-kind-outlined'
              value={
                props.domainData.featureDataGroupListDisplayCondition
                  .selectedUserGroupKind
              }
              onChange={(e) =>
                selectUserGroupKind(
                  e.target.value as 'UserGroup' | 'SharedUserGroup'
                )
              }
              label='Select User Group Kind'
            >
              {userGroupKindList.map((kind) => (
                <MenuItem
                  data-testid={kind.kind}
                  value={kind.kind}
                  key={kind.kind}
                >
                  {kind.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CustomTrainingPageParagraph>
        <div className={classes.searchForm}>
          <div className={classes.searchField}>
            <SearchInput
              placeholder='キーワード (特徴量データグループID)'
              value={
                props.domainData.featureDataGroupListDisplayCondition
                  .searchValue
              }
              onChangeValue={(event) =>
                handleChangeSearchValue(event.target.value)
              }
              onClickSearch={() => searchTableContent()}
              onPressEnter={() => searchTableContent()}
            />
          </div>
        </div>
        {featureDataGroupListTable}
      </div>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tableActions = (props: Props, history: any) => {
  /** テーブルの行選択 */
  const selectTrainedModel = (index: number) => {
    const itemNumber =
      props.domainData.featureDataGroupListDisplayCondition.displayNumber *
        props.domainData.featureDataGroupListDisplayCondition.pageNumber +
      index
    history.push(
      `feature-data-groups/${
        props.domainData.currentFeatureDataGroupList[itemNumber]
          .featureDataGroupId
      }/feature-data${
        props.domainData.featureDataGroupListDisplayCondition
          .selectedUserGroupKind === 'UserGroup'
          ? ''
          : '?shared-user-group=true'
      }`
    )
  }
  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    props.setListDisplayCondition({
      ...props.domainData.featureDataGroupListDisplayCondition,
      searchValue: value,
    })
  }
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    const pageNumber =
      props.domainData.currentFeatureDataGroupList.length >
      props.domainData.featureDataGroupListDisplayCondition.pageNumber *
        displayNumber
        ? props.domainData.featureDataGroupListDisplayCondition.pageNumber
        : Math.ceil(
            props.domainData.currentFeatureDataGroupList.length / displayNumber
          ) - 1

    props.setListDisplayCondition({
      ...props.domainData.featureDataGroupListDisplayCondition,
      pageNumber: pageNumber,
      displayNumber: displayNumber,
    })

    props.getFeatureDataGroupList()
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    props.clearFeatureDataGroupList()
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.featureDataGroupListDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.featureDataGroupListDisplayCondition.sortOrder ===
        'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })

    props.getFeatureDataGroupList()
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.featureDataGroupListDisplayCondition,
      pageNumber: pageNumber,
    })

    props.getFeatureDataGroupList()
  }

  /** 検索の実行 */
  const searchTableContent = () => {
    props.clearFeatureDataGroupList()
    props.setListDisplayCondition({
      ...props.domainData.featureDataGroupListDisplayCondition,
      pageNumber: 0,
    })

    props.getFeatureDataGroupList()
  }
  /** ユーザーグループ種別の選択 */
  const selectedUserGroupKind = (
    selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
  ) => {
    props.clearFeatureDataGroupList()
    props.setListDisplayCondition({
      ...props.domainData.featureDataGroupListDisplayCondition,
      pageNumber: 0,
      displayNumber: 10,
      searchValue: '',
      sortKey: 'generated-at',
      sortOrder: 'desc',
      selectedUserGroupKind: selectedUserGroupKind,
    })
    props.getFeatureDataGroupList()
  }
  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
    selectTrainedModel,
    selectedUserGroupKind,
  }
}

export const FeatureDataGroupListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeatureDataGroupList))
