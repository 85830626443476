import { Dispatch } from 'redux'
import { State } from 'state/store'
import { inferenceEntryActions } from '.'

import { isUndefined } from 'utils/typeguard'
import { formatDateTimeSec } from 'views/components/utils/date'
import {
  getGroupedDataCollection,
  getGroupedDataMetadataCollection,
  getTrainedModelGroupQueriesCollection,
  getTrainedModelQueriesCollection,
  getDatasetsCollection,
  getAnnotationSetsCollection,
  getDatasetQueryCollection,
} from 'state/firebase'

import {
  InferenceStateKindArray,
  InferenceParamsType,
  GroupedImage,
  TrainedModel,
  Metadata,
  TrainedModelGroup,
  TrainedModelDataItem,
  ExecuteInfo,
  TrainingAlgorithmVersion,
  AvailableVersion,
  GroupedDataMetadataDocument,
  GroupedDataDocument,
} from './types'

import { InferenceApi } from './apis'
import { InferenceAlgorithmVersion } from 'state/app/domainData'
import {
  doc,
  DocumentData,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  QuerySnapshot,
  where,
} from 'firebase/firestore'
import { HttpsCallableResult } from 'firebase/functions'
import { fireStoreTypeGuard as fireStoreTypeGuardForAnnotationSetsDocument } from 'utils/fireStore/annotationSet'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelQueryDocument } from 'utils/fireStore/modelQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForModelGroupQueryDocument } from 'utils/fireStore/modelGroupQuery'
import { fireStoreTypeGuard as fireStoreTypeGuardForGroupedDataDocument } from 'utils/fireStore/groupedData'
import { fireStoreTypeGuard as fireStoreTypeGuardForGroupedDataMetadataDocument } from 'utils/fireStore/groupedDataMetadata'
import { fireStoreTypeGuard as fireStoreTypeGuardForDatasetsDocument } from 'utils/fireStore/dataset'
import {
  AnnotationSet,
  fireStoreTypeGuard as fireStoreTypeGuardForDatasetQueryDocument,
} from 'utils/fireStore/datasetQuery'
import { domainDataOperations } from 'state/app/domainData/operations'
import { ModelApi } from '../common/apis'
import { isGetModelsFilesResponse } from '../common/types'

/**
 * 有効なバージョンの中で最新のバージョンを返す
 */
const findInferenceAlgorithmLatestVersion = (
  algorithmVersions: InferenceAlgorithmVersion[],
  trainingAlgorithmVersion: TrainingAlgorithmVersion
): AvailableVersion | undefined => {
  let availableLatestVersion = undefined
  const trainingVersionNumberConvert: number =
    trainingAlgorithmVersion.major * 1000000000 +
    trainingAlgorithmVersion.minor * 1000000 +
    trainingAlgorithmVersion.patch * 1000 +
    (trainingAlgorithmVersion['pre-release'] ?? 99)
  algorithmVersions.map((item) => {
    const lowerLimitList =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      item.availableVersion!['training-algorithm'].lowerLimit!
    const lowerLimitNumberConvert: number =
      lowerLimitList.major * 1000000000 +
      lowerLimitList.minor * 1000000 +
      lowerLimitList.patch * 1000 +
      lowerLimitList.preRelease
    const upperLimitList =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      item.availableVersion!['training-algorithm'].upperLimit!
    const upperLimitNumberConvert: number =
      upperLimitList.major * 1000000000 +
      upperLimitList.minor * 1000000 +
      upperLimitList.patch * 1000 +
      upperLimitList.preRelease
    if (
      lowerLimitNumberConvert <= trainingVersionNumberConvert &&
      trainingVersionNumberConvert <= upperLimitNumberConvert
    ) {
      availableLatestVersion = item.algorithmVersion
    }
  })
  return availableLatestVersion
}

/**
 * 画像セットの一覧取得
 */
const getImageSetDocs = async (dispatch: Dispatch, getState: () => State) => {
  const userGroupId =
    getState().app.domainData.authedUser.auth.customClaims.userGroupId
  // 前回のSnapshotを破棄
  const preSnapshot =
    getState().pages.currentInferenceState.domainData.currentDatasetListSnapshot
  if (preSnapshot) {
    preSnapshot()
    dispatch(inferenceEntryActions.setCurrentDatasetListSnapshot(undefined))
  }

  // 表示条件取得
  const condition =
    getState().pages.currentInferenceState.domainData
      .groupedImageDisplayCondition

  const commonQuery = query(
    getDatasetQueryCollection(userGroupId),
    where(
      'algorithm-id',
      '==',
      getState().pages.currentInferenceState.domainData
        .selectedInferenceAlgorithm?.algorithmId
    )
  )

  const snapshot = onSnapshot(
    commonQuery,
    async (snapshot: QuerySnapshot<DocumentData>) => {
      // dataset.annotation-set-list[x]の グループデータのIDを取得
      const annotationSets: AnnotationSet[] = []

      snapshot.docs.forEach((datasetQueryItem) => {
        const data = datasetQueryItem.data()
        if (!fireStoreTypeGuardForDatasetQueryDocument(data)) {
          return undefined
        }

        if (!data['annotation-set-list']) {
          return undefined
        }

        // annotation-set-list field から annotation-set-kind が Inference or Test の annotation-set を保持
        data['annotation-set-list'].forEach((annotationSet: AnnotationSet) => {
          if (
            annotationSet['annotation-set-kind'] === 'Test' ||
            annotationSet['annotation-set-kind'] === 'Inference'
          ) {
            annotationSets.push(annotationSet)
          }
        })
      })

      const groupedDataIdList = Array.from(
        new Set(
          annotationSets.map(
            (annotationSet) => annotationSet['grouped-data-id']
          )
        )
      )
        // grouped-data-idの一覧のundefinedを除外
        .filter((groupedDataId) => groupedDataId !== undefined)

      if (groupedDataIdList.length === 0) {
        dispatch(inferenceEntryActions.setImageSetList([]))
        dispatch(
          inferenceEntryActions.setGroupedImageDisplayCondition({
            ...condition,
          })
        )
        return
      }
      // groupedData一覧取得
      const groupedDataList = await Promise.all(
        Array.from(groupedDataIdList).map((groupedDataId) =>
          getDoc(doc(getGroupedDataCollection(userGroupId), groupedDataId))
        )
      )
      const allGroupedDataListConvert = groupedDataList.map(
        (groupedDataDoc) => {
          const groupedData = groupedDataDoc.data()
          if (!groupedData) {
            return
          }
          if (!fireStoreTypeGuardForGroupedDataDocument(groupedData)) {
            return
          } else {
            return {
              id: groupedDataDoc.id,
              ...groupedData,
            } as GroupedDataDocument
          }
        }
      ) as (GroupedDataDocument | undefined)[]

      // groupedDataメタデータ一覧を取得
      const groupedDataMetadataList = await Promise.all(
        Array.from(groupedDataIdList).map((groupedDataId) =>
          getDoc(
            doc(getGroupedDataMetadataCollection(userGroupId), groupedDataId)
          )
        )
      )
      const groupedDataMetadataListConvert = groupedDataMetadataList.map(
        (groupedDataMetadataDoc) => {
          const groupedDataMetadata = groupedDataMetadataDoc.data()
          if (!groupedDataMetadata) {
            return
          }
          if (
            !fireStoreTypeGuardForGroupedDataMetadataDocument(
              groupedDataMetadata
            )
          ) {
            return
          } else {
            return {
              id: groupedDataMetadataDoc.id,
              ...groupedDataMetadata,
            } as GroupedDataMetadataDocument
          }
        }
      ) as (GroupedDataMetadataDocument | undefined)[]

      // 作成するgroupedData配列
      const groupedDataDisplayList: GroupedImage[] = []
      allGroupedDataListConvert.forEach((item) => {
        if (!item) {
          return
        }
        // 一致したIDでオブジェクトをマージ
        const metaData = groupedDataMetadataListConvert.find(
          (data) => data && data.id === item.id
        ) as GroupedDataMetadataDocument | undefined

        if (!fireStoreTypeGuardForGroupedDataMetadataDocument(metaData)) {
          return
        }

        // ベースモデルのオブジェクトを作成
        const groupedData: GroupedImage = {
          groupedDataId: item['grouped-data-id'] ? item['grouped-data-id'] : '',
          groupedDataName: metaData
            ? metaData['name']
              ? metaData['name']
              : ''
            : '',
          remarks: metaData
            ? metaData['remarks']
              ? metaData['remarks']
              : ''
            : '',
          generatedAt: item
            ? item['created-at']
              ? item['created-at']
              : undefined
            : undefined,
          createdBy: item ? (item['created-by'] ? item['created-by'] : '') : '',
        }
        groupedDataDisplayList.push(groupedData)
      })
      // 取得した画像セットの一覧を保持
      dispatch(
        inferenceEntryActions.setImageSetList(
          groupedDataDisplayList.filter((item) => item !== undefined)
        )
      )
    }
  )

  // 現在、データセット一覧（Dataset Queries Collectionの Snapshot を保持）
  dispatch(inferenceEntryActions.setCurrentDatasetListSnapshot(snapshot))
}

const hasOnnxFile = async (
  trainedModelId: string,
  userGroupId: string
): Promise<boolean> => {
  try {
    const result = await ModelApi.getModelsFiles(trainedModelId, userGroupId)

    if (isGetModelsFilesResponse(result)) {
      return result.data.items.some(
        (item) =>
          item.linkName === 'ONNX' &&
          item.mediaLinks.some((link) => link.mediaName.endsWith('.onnx'))
      )
    }

    return false
  } catch (error) {
    console.error(error)
    return false
  }
}

export const inferenceOperations = {
  // step1のモデルテーブルリスト
  getTrainedModelGroupList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(inferenceEntryActions.setInProgress(true))

        const hasSharedUserGroup = domainDataOperations.hasSharedUserGroup()(
          dispatch,
          getState
        )

        // 共有データの参照権がない場合は、カスタマーデータに変更する
        if (!hasSharedUserGroup) {
          dispatch(
            inferenceEntryActions.setTrainedModelGroupDisplayCondition({
              ...getState().pages.currentInferenceState.domainData
                .trainedModelGroupDisplayCondition,
              selectedUserGroupKind: 'UserGroup',
            })
          )
        }

        const userGroupId =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId

        const sharedUserGroupId = domainDataOperations.getSharedUserGroupId()(
          dispatch,
          getState
        )

        const selectedAlgorithmId =
          getState().pages.currentInferenceState.domainData
            .selectedInferenceAlgorithm?.algorithmId

        if (!selectedAlgorithmId) {
          dispatch(inferenceEntryActions.setInProgress(false))
          return
        }

        if (
          !getState().pages.currentInferenceState.domainData
            .selectedTrainedModelGroup ||
          getState().pages.currentInferenceState.domainData
            .selectedTrainedModelGroup?.trainedModels.length === 0
        ) {
          const userGroupTrainedModelGroup = await getDocs(
            query(
              getTrainedModelGroupQueriesCollection(userGroupId),
              where('algorithm-id', '==', selectedAlgorithmId)
            )
          )

          const sharedUserGroupTrainedModelGroup = hasSharedUserGroup
            ? await getDocs(
                query(
                  getTrainedModelGroupQueriesCollection(sharedUserGroupId),
                  where('access-control.is-shared', '==', true),
                  where(
                    'access-control.share-permissions.webapp',
                    '==',
                    'list'
                  ),
                  where('algorithm-id', '==', selectedAlgorithmId)
                )
              )
            : { docs: [] }

          if (
            !userGroupTrainedModelGroup ||
            !sharedUserGroupTrainedModelGroup
          ) {
            dispatch(inferenceEntryActions.setInProgress(false))
            return
          }

          const userGroupTrainedModelGroups: (TrainedModelGroup | undefined)[] =
            userGroupTrainedModelGroup.docs.map((item) => {
              const trainedModelGroupData = item.data()
              if (
                !fireStoreTypeGuardForModelGroupQueryDocument(
                  trainedModelGroupData
                )
              ) {
                return undefined
              }
              const trainedModel = trainedModelGroupData[
                'trained-model-list'
              ].find(
                (data: TrainedModelDataItem) =>
                  data['trained-model-group-version']['display-name'] ===
                  `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
              )
              const trainedModelCount =
                trainedModelGroupData['trained-model-count']
              return {
                trainedModelGroupId:
                  trainedModelGroupData['trained-model-group-id'],
                trainedModelGroupName:
                  trainedModelGroupData['trained-model-group-name'],
                trainedModelCount: trainedModelCount,
                latestTrainedModelVersion:
                  trainedModelCount > 0
                    ? `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
                    : '',
                latestTrainedModelName: trainedModel
                  ? trainedModel['trained-model-name']
                  : '',
                updatedAt: trainedModelGroupData['updated-at'],
                createdAt: trainedModelGroupData['created-at'],
                createdBy: trainedModelGroupData['created-by'],
                trainedModels: trainedModelGroupData['trained-model-list'].map(
                  (content: TrainedModelDataItem) => {
                    return {
                      trainedModelId: content['trained-model-id'],
                      trainedModelName: content['trained-model-name'],
                      trainedModelGroupVersion: {
                        displayName:
                          content['trained-model-group-version'][
                            'display-name'
                          ],
                        major: content['trained-model-group-version']['major'],
                        minor: content['trained-model-group-version']['minor'],
                        patch: content['trained-model-group-version']['patch'],
                      },
                      transactionStatus: content['transaction-status'],
                    }
                  }
                ),
                userGroupId: trainedModelGroupData['user-group-id'],
              } as TrainedModelGroup
            })

          const sharedUserGroupTrainedModelGroups: (
            | TrainedModelGroup
            | undefined
          )[] = sharedUserGroupTrainedModelGroup.docs.map((item) => {
            const trainedModelGroupData = item.data()
            if (
              !fireStoreTypeGuardForModelGroupQueryDocument(
                trainedModelGroupData
              )
            ) {
              return undefined
            }
            const trainedModel = trainedModelGroupData[
              'trained-model-list'
            ].find(
              (data: TrainedModelDataItem) =>
                data['trained-model-group-version']['display-name'] ===
                `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
            )
            const trainedModelCount =
              trainedModelGroupData['trained-model-count']
            return {
              trainedModelGroupId:
                trainedModelGroupData['trained-model-group-id'],
              trainedModelGroupName:
                trainedModelGroupData['trained-model-group-name'],
              trainedModelCount: trainedModelCount,
              latestTrainedModelVersion:
                trainedModelCount > 0
                  ? `${trainedModelGroupData['trained-model-group-version-latest']['major']}.${trainedModelGroupData['trained-model-group-version-latest']['minor']}.${trainedModelGroupData['trained-model-group-version-latest']['patch']}`
                  : '',
              latestTrainedModelName: trainedModel
                ? trainedModel['trained-model-name']
                : '',
              updatedAt: trainedModelGroupData['updated-at'],
              createdAt: trainedModelGroupData['created-at'],
              createdBy: trainedModelGroupData['created-by'],
              trainedModels: trainedModelGroupData['trained-model-list'].map(
                (content: TrainedModelDataItem) => {
                  return {
                    trainedModelId: content['trained-model-id'],
                    trainedModelName: content['trained-model-name'],
                    trainedModelGroupVersion: {
                      displayName:
                        content['trained-model-group-version']['display-name'],
                      major: content['trained-model-group-version']['major'],
                      minor: content['trained-model-group-version']['minor'],
                      patch: content['trained-model-group-version']['patch'],
                    },
                    transactionStatus: content['transaction-status'],
                  }
                }
              ),
              userGroupId: trainedModelGroupData['user-group-id'],
            } as TrainedModelGroup
          })
          dispatch(
            inferenceEntryActions.setTrainedModelGroupList({
              userGroup: userGroupTrainedModelGroups.filter(
                (trainedModelGroup) => trainedModelGroup !== undefined
              ) as TrainedModelGroup[],
              sharedUserGroup: sharedUserGroupTrainedModelGroups.filter(
                (trainedModelGroup) => trainedModelGroup !== undefined
              ) as TrainedModelGroup[],
            })
          )
        }
        dispatch(inferenceEntryActions.clearTrainedModelListDisplayCondition())
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(inferenceEntryActions.setInProgress(false))
      }
    },

  // step2の画像セットテーブルリスト
  getImageSetList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        dispatch(inferenceEntryActions.setInProgress(true))

        // データセット一覧に必要な関連Docsを取得
        await getImageSetDocs(dispatch, getState)
      } catch (error) {
        console.error(error)
      } finally {
        dispatch(inferenceEntryActions.setInProgress(false))
      }
    },
  /** snapshotの購読解除 */
  unsubscribeDatasetList:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      try {
        const snapshot =
          getState().pages.currentInferenceState.domainData
            .currentDatasetListSnapshot
        if (!isUndefined(snapshot)) {
          snapshot()
        }
      } catch (error) {
        console.error(error)
      }
    },

  /** 選択されたアルゴリスムをセット */
  setSelectedAlgorithmId:
    (algorithmId?: string) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(inferenceEntryActions.setSelectedAlgorithmId(algorithmId))
    },

  /** 選択された学習モデルをセット */
  setSelectedTrainedModel:
    (trainedModel?: TrainedModel) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(inferenceEntryActions.setSelectedTrainedModel(trainedModel))

      if (trainedModel) {
        dispatch(inferenceEntryActions.setTrainedModelSubState('Selected'))
      } else {
        dispatch(inferenceEntryActions.setTrainedModelSubState('Unselected'))
      }
    },

  /** 選択された画像セットをセット */
  setSelectedImageSet:
    (imageSet?: GroupedImage) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(inferenceEntryActions.setSelectedImageSet(imageSet))

      if (imageSet) {
        dispatch(inferenceEntryActions.setImageSetSubState('Selected'))
      } else {
        dispatch(inferenceEntryActions.setImageSetSubState('Unselected'))
      }
    },

  /** メタデータをセット */
  setInferenceMetadata:
    (metadata: Metadata) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(inferenceEntryActions.setInferenceMetadata(metadata))

      if (
        metadata.name &&
        getState().pages.currentInferenceState.domainData.inferenceMetaData
          ?.name
      ) {
        dispatch(inferenceEntryActions.setMetaDataSubState('InputRequired'))
      } else {
        dispatch(inferenceEntryActions.setMetaDataSubState('EmptyRequired'))
      }
    },

  /** 次へボタン */
  nextStep:
    (currentStep: number) =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      // STEP1 → STEP2 への遷移時は、onnxファイルの存在チェックを実施するため、ここでSTEP以降は行わない
      if (currentStep != 0)
        dispatch(
          inferenceEntryActions.setInferenceState(
            InferenceStateKindArray[currentStep + 1]
          )
        )

      switch (currentStep) {
        case 0:
          // 選択されたモデルがONNXファイルを持っているか確認
          dispatch(inferenceEntryActions.setInProgress(true))
          // eslint-disable-next-line no-case-declarations
          const trainedModelId =
            getState().pages.currentInferenceState.domainData
              .selectedTrainedModel?.trainedModelId
          // eslint-disable-next-line no-case-declarations
          const userGroupId =
            getState().pages.currentInferenceState.domainData
              .selectedTrainedModelGroup?.userGroupId
          // eslint-disable-next-line no-case-declarations
          const hasOnnx =
            trainedModelId && userGroupId
              ? await hasOnnxFile(trainedModelId, userGroupId)
              : false

          if (hasOnnx) {
            dispatch(
              inferenceEntryActions.setInferenceState(
                InferenceStateKindArray[currentStep + 1]
              )
            )
          } else {
            // 選択したモデルにONNXファイルが存在しない場合はSTEP2に遷移せず、エラーを表示
            dispatch(
              inferenceEntryActions.setToastInfo({
                type: 'error',
                title: 'ONNX ファイルが存在するモデルを選択してください',
                targets: [],
              })
            )
          }

          dispatch(inferenceEntryActions.setInProgress(false))
          break
        case 1:
          // メタデータの入力ステップ時にカスタム学習、カスタムモデルの表示名のデフォルト値を設定
          // eslint-disable-next-line no-case-declarations
          const inferenceState = getState().pages.currentInferenceState
          // eslint-disable-next-line no-case-declarations
          const displayNamePrefix =
            inferenceState.domainData.selectedTrainedModel?.trainedModelName +
            '_' +
            inferenceState.domainData.selectedGroupedImage?.groupedDataName
          // eslint-disable-next-line no-case-declarations
          const regex = /\/|\s+|:/g
          dispatch(
            inferenceEntryActions.setInferenceMetadata({
              name: `${displayNamePrefix}_${formatDateTimeSec(
                new Date()
              ).replace(regex, '')}`,
            })
          )
          dispatch(inferenceEntryActions.setMetaDataSubState('InputRequired'))
          break
        case 2:
          inferenceOperations.unsubscribeDatasetList()
          break
        default:
          break
      }
    },

  /** 戻るボタン */
  prevStep:
    (currentStep: number) =>
    async (dispatch: Dispatch): Promise<void> => {
      // カレントのステップの入力/選択情報をクリア
      switch (currentStep) {
        case 0:
          return
        case 1:
          inferenceOperations.unsubscribeDatasetList()
          dispatch(inferenceEntryActions.setSelectedImageSet(undefined))
          dispatch(inferenceEntryActions.setImageSetSubState('Unselected'))
          break
        case 2:
          dispatch(inferenceEntryActions.setInferenceMetadata(undefined))
          dispatch(inferenceEntryActions.setMetaDataSubState('EmptyRequired'))
          break
        default:
          break
      }

      dispatch(
        inferenceEntryActions.setInferenceState(
          InferenceStateKindArray[currentStep - 1]
        )
      )
    },

  /** 推論実行 */
  executeInference:
    () =>
    async (dispatch: Dispatch, getState: () => State): Promise<void> => {
      dispatch(inferenceEntryActions.setInProgress(true))
      try {
        const userGroupId: string =
          getState().app.domainData.authedUser.auth.customClaims.userGroupId
        const sharedUserGroupId: string =
          domainDataOperations.getSharedUserGroupId()(dispatch, getState)
        const {
          selectedInferenceAlgorithm,
          selectedTrainedModelGroup,
          selectedTrainedModel,
          selectedGroupedImage,
          inferenceMetaData,
        } = getState().pages.currentInferenceState.domainData

        if (
          !(
            selectedInferenceAlgorithm?.algorithmId &&
            selectedTrainedModel &&
            selectedGroupedImage &&
            selectedGroupedImage.groupedDataId &&
            inferenceMetaData &&
            selectedTrainedModelGroup
          )
        ) {
          console.error('Error invalid request')
          dispatch(inferenceEntryActions.executeInferenceFailure())
          return
        }

        const trainedModel = (
          await getDocs(
            getState().pages.currentInferenceState.domainData
              .selectedTrainedModelGroup?.userGroupId ===
              getState().app.domainData.authedUser.auth.customClaims
                .sharedList[0]
              ? query(
                  getTrainedModelQueriesCollection(sharedUserGroupId),
                  where(
                    'trained-model-id',
                    '==',
                    selectedTrainedModel.trainedModelId
                  ),
                  where('access-control.is-shared', '==', true),
                  where('access-control.share-permissions.webapp', '==', 'list')
                )
              : query(
                  getTrainedModelQueriesCollection(userGroupId),
                  where(
                    'trained-model-id',
                    '==',
                    selectedTrainedModel.trainedModelId
                  )
                )
          )
        ).docs[0]?.data()

        if (!trainedModel) {
          console.error('Error invalid request')
          dispatch(inferenceEntryActions.executeInferenceFailure())
          return
        }

        if (!fireStoreTypeGuardForModelQueryDocument(trainedModel)) {
          dispatch(inferenceEntryActions.executeInferenceFailure())
          return
        }

        // domainDataに保存しているAlgorithmの配列
        const domainDataAlgorithm = getState().app.domainData.algorithms
        const inferenceAlgorithm = domainDataAlgorithm.find(
          (item) => item.algorithmId === selectedInferenceAlgorithm?.algorithmId
        )

        const inferenceAlgorithmVersions =
          inferenceAlgorithm?.inferenceAlgorithm.inferenceAlgorithmVersions

        const trainingAlgorithmVersion =
          trainedModel['training-algorithm-version']

        if (!inferenceAlgorithmVersions) {
          return
        }

        const latestVersion = findInferenceAlgorithmLatestVersion(
          inferenceAlgorithmVersions,
          trainingAlgorithmVersion
        )

        if (!latestVersion) {
          console.error(
            'Error valid latest inference algorithm version not found'
          )
          dispatch(inferenceEntryActions.executeInferenceFailure())
          return
        }

        const annotationSet = await getDocs(
          query(
            getAnnotationSetsCollection(userGroupId),
            where('grouped-data-id', '==', selectedGroupedImage.groupedDataId)
          )
        )

        const annotationSetData = annotationSet.docs[0].data()

        if (!fireStoreTypeGuardForAnnotationSetsDocument(annotationSetData)) {
          dispatch(inferenceEntryActions.executeInferenceFailure())
          return
        }

        const annotationSetId = annotationSetData['annotation-set-id']

        const datasetDoc = await getDocs(
          query(
            getDatasetsCollection(userGroupId),
            where('annotation-set-list', 'array-contains', annotationSetId)
          )
        )

        const datasetData = datasetDoc.docs[0].data()

        if (!fireStoreTypeGuardForDatasetsDocument(datasetData)) {
          return
        }

        const inferenceState = getState().pages.currentInferenceState

        const inferenceParams: InferenceParamsType = {
          algorithmId: selectedInferenceAlgorithm.algorithmId,
          algorithmVersion: {
            displayName: latestVersion.displayName,
            major: latestVersion.major,
            minor: latestVersion.minor,
            patch: latestVersion.patch,
          },
          trainedModel: {
            trainedModelGroupId: selectedTrainedModelGroup.trainedModelGroupId,
            trainedModelId:
              inferenceState.domainData.selectedTrainedModel?.trainedModelId ??
              '',
            userGroupId: selectedTrainedModelGroup.userGroupId,
          },
          datasetId: datasetData['dataset-id'],
          inputFilesId:
            inferenceState.domainData.selectedGroupedImage?.groupedDataId ?? '',
          mlPipelineMetadata: inferenceMetaData,
          annotationSetId: [annotationSetId],
        }

        const result = (await InferenceApi.executeInference(
          inferenceParams
        )) as HttpsCallableResult<{
          result: boolean
          mlPipelineId: string
          stepId: string
        }>

        const executeInfo: ExecuteInfo = {
          mlPipelineId: result.data.mlPipelineId,
          inferenceStepId: result.data.stepId,
        }

        dispatch(
          inferenceEntryActions.executeInferenceSuccess(result.data.result)
        )
        dispatch(inferenceEntryActions.setExecutedMlPipelineId(executeInfo))
      } catch (error) {
        console.error(error)
        dispatch(inferenceEntryActions.executeInferenceFailure())
      } finally {
        dispatch(inferenceEntryActions.setInProgress(false))
      }
    },
}
