import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'

import { State } from 'state/store'

import {
  BreadcrumbsComponent,
  CopyableLabel,
  DataDetailItem,
  EditableTextField,
  GlobalLoading,
  SceneCameraIcon,
} from 'views/components'
import {
  SceneCameraDetailAction,
  sceneCameraDetailActions,
  SceneCameraDetailOperations,
} from 'state/ducks/sceneCameraDetail'
import {
  Box,
  Button,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import TimerIcon from '@mui/icons-material/Timer'
import { hasSharedUserGroupQueryParameter } from 'views/containers/utils/queryParams'
import { formatDateTimeSec } from 'views/components/utils/date'
import { TabItems } from 'views/components/organisms/tabLayout/types'
import clsx from 'clsx'
import { handleResourceNotFound } from 'views/containers/utils'

const mapStateToProps = (state: State) => ({
  ...state.pages.sceneCameraDetailState,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, SceneCameraDetailAction>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** カメラ配置詳細取得 */
  getSceneCameraDetail: (settingId: string, isSharedUserGroup: boolean) =>
    dispatch(
      SceneCameraDetailOperations.getSceneCameraDetail(
        settingId,
        isSharedUserGroup
      )
    ),
  /** Stateのクリア */
  clearSceneCameraDetailState: () =>
    dispatch(sceneCameraDetailActions.clearSceneCameraDetailState()),
  /** カメラ配置名を更新する */
  updateSceneCameraName: (id: string, name: string) =>
    dispatch(SceneCameraDetailOperations.updateSceneCameraName(id, name)),
  /** 概要を更新する */
  updateOverview: (id: string, overview: string) =>
    dispatch(SceneCameraDetailOperations.updateOverview(id, overview)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  innerContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& > .MuiPaper-root': {
      backgroundColor: '#fafafa',
    },
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  nowTab: {
    backgroundColor: theme.palette.grey[200],
  },
  overviewTabButton: {
    backgroundColor: '#D9E5FF',
  },
}))

const SceneCameraDetail: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const isSharedUserGroup = hasSharedUserGroupQueryParameter(
    props.location.search
  )
  // パスパラメータからカメラ配置IDを取得
  const [currentSceneCameraId, setCurrentSceneCameraId] = useState('')
  const [editingSceneCameraName, setEditingSceneCameraName] = useState('')
  const [editingSceneCameraOverview, setEditingSceneCameraOverview] =
    useState('')
  const [nowTab, setNowTab] = useState(0)

  /** 初期実行 */
  useEffect(() => {
    return () => {
      props.clearSceneCameraDetailState()
    }
  }, [])

  useEffect(() => {
    handleResourceNotFound(
      props.appState.sceneCameraDetailSubState.sceneCameraDataSubState,
      props.history
    )
  }, [
    props.appState.sceneCameraDetailSubState.sceneCameraDataSubState,
    props.history,
  ])

  useEffect(() => {
    setCurrentSceneCameraId(
      (props.match.params as { [key: string]: string })['id']
    )
    // 表示用詳細取得
    props.getSceneCameraDetail(
      (props.match.params as { [key: string]: string })['id'] ?? '',
      isSharedUserGroup
    )
  }, [props.match.params])

  /** カメラ詳細が変更時にセットする */
  useEffect(() => {
    setEditingSceneCameraName(props.domainData.currentSceneCameraDetail.name)
    setEditingSceneCameraOverview(
      props.domainData.currentSceneCameraDetail.overview
    )
  }, [props.domainData.currentSceneCameraDetail])

  /** 一般情報コンテンツ */
  const infoTab: TabItems = {
    label: '一般情報',
    displayInfo: (
      <>
        <Box component={Paper}>
          <Box p={'24px 32px 32px'}>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='Revision'
                startAdornment={
                  <Typography>
                    {props.domainData.currentSceneCameraDetail.revision}
                  </Typography>
                }
              />
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='位置 (location)'
                startAdornment={
                  <Typography>
                    {props.domainData.currentSceneCameraDetail.location.join(
                      ', '
                    )}
                  </Typography>
                }
              />
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='画角 (fov)'
                startAdornment={
                  <Typography>
                    {props.domainData.currentSceneCameraDetail.fov.join(', ')}
                  </Typography>
                }
              />
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='視点 (look-at)'
                startAdornment={
                  <Typography>
                    {props.domainData.currentSceneCameraDetail.lookAt.join(
                      ', '
                    )}
                  </Typography>
                }
              />
            </Box>
            <Box mt={1}>
              <DataDetailItem
                formHelperText='フォーマットバージョン'
                startAdornment={
                  <Typography>
                    {
                      props.domainData.currentSceneCameraDetail.formatVersion
                        ?.displayName
                    }
                  </Typography>
                }
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
  }

  /** 概要タブコンテンツ */
  const overviewTab: TabItems = {
    label: '概要',
    displayInfo: (
      <>
        <Box component={Paper}>
          <Box p={'24px 32px 32px'}>
            <TextField
              style={{ width: '100%' }}
              value={editingSceneCameraOverview}
              onChange={(event) =>
                setEditingSceneCameraOverview(event.target.value)
              }
              variant='outlined'
              multiline
              minRows={5}
              disabled={isSharedUserGroup}
              inputProps={{
                'data-testid': 'input-remarks',
              }}
            />
            {!isSharedUserGroup && (
              <Box display='flex' justifyContent='flex-end' width='100%' mt={2}>
                <Button
                  variant='outlined'
                  onClick={() =>
                    props.updateOverview(
                      currentSceneCameraId,
                      editingSceneCameraOverview
                    )
                  }
                  data-testid='remarks-edit'
                  className={classes.overviewTabButton}
                >
                  <Typography color={'primary'}>保存</Typography>
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </>
    ),
  }

  const tabItems: TabItems[] = [infoTab, overviewTab]

  return (
    <>
      <div className={classes.container}>
        <Box
          style={{
            position: 'sticky',
            top: '64px',
            backgroundColor: '#fafafa',
            zIndex: 10,
          }}
        >
          <Box className={classes.innerContainer}>
            <Box pt={2}>
              <BreadcrumbsComponent
                breadcrumbsPath={[
                  {
                    name: 'カメラ配置一覧',
                    path: '3d-scene-cameras',
                  },
                  {
                    name: props.domainData.currentSceneCameraDetail.name,
                    path: `${props.domainData.currentSceneCameraDetail.id}${
                      isSharedUserGroup ? '?shared-user-group=true' : ''
                    }`,
                  },
                ]}
              />
            </Box>
            <div className={classes.flexAndBetween}>
              <Box
                display='flex'
                style={{
                  maxWidth: 'calc(100% - 280px)',
                  overflow: 'hidden',
                }}
              >
                <SceneCameraIcon
                  className={classes.pageIcon}
                  data-testid='sceneCameraTitleIcon'
                />
                <Box
                  height={76}
                  data-testid='scene-camera-detail-title'
                  style={{
                    overflow: 'hidden',
                  }}
                >
                  <EditableTextField
                    value={editingSceneCameraName}
                    disabled={isSharedUserGroup}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setEditingSceneCameraName(e.target.value)
                    }
                    onBlur={() => {
                      if (
                        props.domainData.currentSceneCameraDetail.name !==
                        editingSceneCameraName
                      ) {
                        props.updateSceneCameraName(
                          currentSceneCameraId,
                          editingSceneCameraName
                        )
                      }
                    }}
                  />
                </Box>
              </Box>
              <Box>
                <CopyableLabel
                  value={currentSceneCameraId}
                  isTooltip
                  placement='top'
                />
              </Box>
            </div>
            <Box p={1}>
              {props.domainData.currentSceneCameraDetail &&
              props.domainData.currentSceneCameraDetail.createdAt ? (
                <Box display='flex' justifyContent='end'>
                  <TimerIcon style={{ marginRight: '4px' }} />
                  <Typography>
                    {formatDateTimeSec(
                      props.domainData.currentSceneCameraDetail.createdAt?.toDate()
                    )}
                  </Typography>
                </Box>
              ) : (
                <Box height={24} display='flex' justifyContent='end'></Box>
              )}
              <Box
                height={64}
                display='flex'
                justifyContent='end'
                alignItems='center'
              >
                <Typography>
                  {props.domainData.currentSceneCameraDetail?.createdBy}
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                backgroundColor: '#fafafa',
              }}
            >
              <Tabs
                indicatorColor='primary'
                value={nowTab}
                style={{
                  paddingBottom: '16px',
                  marginBottom: '1px',
                }}
                onChange={(_, value) => setNowTab(value)}
              >
                {tabItems.map((item, index) => (
                  <Tab
                    style={{
                      width: `${100 / tabItems.length}%`,
                      maxWidth: '1200px',
                    }}
                    key={index}
                    className={clsx(nowTab === index && classes.nowTab)}
                    label={item.label}
                    data-testid={`change-tab-${index}`}
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
        </Box>
        <Box className={classes.innerContainer}>
          <Paper elevation={0}>
            <Box>{tabItems[nowTab].displayInfo}</Box>
          </Paper>
        </Box>
      </div>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

export const SceneCameraDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SceneCameraDetail))
