import React, { useEffect, ChangeEvent } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { State } from 'state/store'
import { robotDataAnalysisEntryOperations } from 'state/ducks/robotDataAnalysisEntry/operations'
import {
  RobotDataAnalysisEntryAction,
  robotDataAnalysisEntryActions,
} from 'state/ducks/robotDataAnalysisEntry/actions'
import {
  BreadcrumbsComponent,
  CustomTrainingPageParagraph,
  DateRangeInput,
  GlobalLoading,
  Toast,
  showToast,
} from 'views/components'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import {
  UserInput,
  QueryConditions,
  THRESHOLD,
} from 'state/ducks/robotDataAnalysisEntry'
import { IconCheckbox } from 'views/components/atoms/iconCheckbox'
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material'
import { RobotDataAnalysisIcon } from 'views/components/atoms/icon/robotDataAnalysisIcon'
import { isUUIDv4 } from 'utils/typeguard'
import { robotDataAnalysisExtractedImagesActions } from 'state/ducks/robotDataAnalysisExtractedImages'
import { useDispatch } from 'react-redux'

// 配列は , 区切りの文字列に変換
const createArrayParam = (prefix: string, arr?: string[]) => {
  const str = arr?.join(',')
  return `${prefix}=${str ?? ''}&`
}

// 抽出リストページに必要なクエリパラメータを生成
const createParams = (queryConditions: QueryConditions): string => {
  let params = '?'
  Object.entries(queryConditions).forEach(([key, value]) => {
    switch (key) {
      case 'confirmed':
        params = params + `confirmed=${value}&`
        break
      case 'executionStatus':
        if (Object.keys(value).some((key) => value[key])) {
          params =
            params +
            `execution-status=${Object.keys(value)
              .filter((key) => value[key])
              .join(',')}&`
        } else {
          params = params + 'execution-status=&'
        }
        break
      case 'executionIdList':
        params = params + createArrayParam('execution-id-list', value)
        break
      case 'robotIdList':
        params = params + createArrayParam('robot-id-list', value)
        break
      case 'executionDate':
        params = params + `execution-date-from=${value?.from ?? ''}&`
        params = params + `execution-date-to=${value?.to ?? ''}&`
        break
      case 'modelGroupIdList':
        params = params + createArrayParam('model-group-id-list', value)
        break
      case 'objectRecognitionResults':
        params = params + `object-recognition-results=${value}&`
        break
      default:
        break
    }
  })
  // 不要な末尾の & を削除
  const searchParams = params.slice(0, -1)
  return searchParams
}

/** How to simplify the description of react-redux. */
const mapStateToProps = (state: State) => ({
  ...state.pages.robotDataAnalysisEntryState,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, RobotDataAnalysisEntryAction>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** ユーザー一覧取得 */
  getModelGroupList: () =>
    dispatch(robotDataAnalysisEntryOperations.getModelGroupList()),
  /** 件数取得 */
  getCount: () => dispatch(robotDataAnalysisEntryOperations.getCount()),
  /** 入力した検索条件の保存 */
  setQueryConditions: (queryConditions: QueryConditions) =>
    dispatch(robotDataAnalysisEntryActions.setQueryConditions(queryConditions)),
  setUserInputList: (list: UserInput[]) =>
    dispatch(robotDataAnalysisEntryActions.setUserInputList(list)),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () =>
    dispatch(robotDataAnalysisEntryActions.setToastInfo(undefined)),
  /** Stateのクリア */
  clearRobotDataAnalysisEntryState: () =>
    dispatch(robotDataAnalysisEntryActions.clearRobotDataAnalysisEntryState()),
  /** training dat id 取得 */
  getTrainingDataId: async () => {
    return await dispatch(robotDataAnalysisEntryOperations.getTrainingDataId())
  },
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  contentScroll: {
    width: '100%',
    height: 'calc(100% - 180px)',
    overflow: 'hidden',
    overflowY: 'auto',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(7)}`,
    paddingTop: theme.spacing(3),
  },
  stepContainer: {
    overflow: 'hidden',
    padding: `${theme.spacing(2)} ${theme.spacing(7)} ${theme.spacing(1)}`,
  },
}))

const RobotDataAnalysisEntry: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const history = useHistory()
  const queryConditions = props.domainData.queryConditions
  const userInputList = props.appState.userInputList
  const userInputExecutionId = userInputList.find(
    (input) => input.name === 'executionId'
  )
  const userInputRobotId = userInputList.find(
    (input) => input.name === 'robotId'
  )
  const dispatch = useDispatch()

  const handleChangeExecutionId = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    props.setQueryConditions({
      ...queryConditions,
      executionIdList: [e.target.value],
    })
    props.setUserInputList(
      userInputList.map((val) =>
        val.name === 'executionId'
          ? {
              ...val,
              isValid:
                e.target.value === '' ||
                e.target.value === undefined ||
                (e.target.value !== undefined && isUUIDv4(e.target.value)),
            }
          : val
      )
    )
  }

  const handleChangeRobotId = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    props.setQueryConditions({
      ...queryConditions,
      robotIdList: [e.target.value],
    })
    props.setUserInputList(
      userInputList.map((val) =>
        val.name === 'robotId'
          ? {
              ...val,
              isValid:
                e.target.value === '' ||
                e.target.value === undefined ||
                (e.target.value !== undefined && isUUIDv4(e.target.value)),
            }
          : val
      )
    )
  }

  const handleSelect = (e: SelectChangeEvent<string>) => {
    if (e.target.value === '') {
      props.setQueryConditions({
        ...queryConditions,
        modelGroupIdList: undefined,
      })
      return
    }
    props.setQueryConditions({
      ...queryConditions,
      modelGroupIdList: [e.target.value],
    })
  }

  useEffect(() => {
    props.getModelGroupList()
  }, [])

  useEffect(() => {
    if (props.appState.toastInfo) {
      showErrorToast(props.appState.toastInfo.title)
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  useEffect(() => {
    props.getCount()
  }, [props.domainData.queryConditions])

  const showErrorToast = (title: string) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div>{title}</div>
      </div>
    )

  return (
    <Box className={classes.stepContainer}>
      <Toast containerOptions={{ limit: 20 }}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            {
              name: 'On-site Data',
              path: 'robot-data-analysis/entry',
            },
          ]}
        />
        <Box display='flex' justifyContent='space-between' width='100%'>
          <Box display='flex' alignItems='center'>
            <RobotDataAnalysisIcon
              className={classes.pageIcon}
              data-testid='robotDataAnalysisEntryTitleIcon'
            />
            <Typography component='div'>
              <h2 data-testid='robot-data-analysis-entry-title'>Query</h2>
            </Typography>
          </Box>
          <Box pb={2} display='flex' alignItems='end'>
            <Typography variant='body1' data-testid='query-count'>
              {props.domainData.count.isOver
                ? `over ${THRESHOLD}`
                : `Count: ${props.domainData.count.countNumber}`}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box className={classes.contentScroll}>
          <Box sx={{ width: '100%', overflow: 'hidden' }}>
            <CustomTrainingPageParagraph>
              <Typography variant='body1' pt={2}>
                Execution Status
              </Typography>
              <Box display='flex' alignItems='center'>
                <IconCheckbox
                  checked={queryConditions.executionStatus.success}
                  icon={<CheckCircleOutline fontSize='small' color='success' />}
                  label='success'
                  variant='body1'
                  sx={{ marginRight: (theme) => theme.spacing(4) }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.setQueryConditions({
                      ...queryConditions,
                      executionStatus: {
                        ...queryConditions.executionStatus,
                        success: e.target.checked,
                      },
                    })
                  }
                  data-testid='success-checkbox'
                />
                <IconCheckbox
                  checked={queryConditions.executionStatus.fail}
                  icon={<ErrorOutline fontSize='small' color='warning' />}
                  label='fail'
                  variant='body1'
                  sx={{ marginRight: (theme) => theme.spacing(4) }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.setQueryConditions({
                      ...queryConditions,
                      executionStatus: {
                        ...queryConditions.executionStatus,
                        fail: e.target.checked,
                      },
                    })
                  }
                />
                <IconCheckbox
                  checked={queryConditions.executionStatus.error}
                  icon={<ErrorOutline fontSize='small' color='error' />}
                  label='error'
                  variant='body1'
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.setQueryConditions({
                      ...queryConditions,
                      executionStatus: {
                        ...queryConditions.executionStatus,
                        error: e.target.checked,
                      },
                    })
                  }
                />
              </Box>
            </CustomTrainingPageParagraph>
            <Divider />
            <CustomTrainingPageParagraph>
              <Typography variant='body1' py={2}>
                Execution Date
              </Typography>
              <DateRangeInput
                fromDate={{
                  date: queryConditions.executionDate?.from,
                  setDate: (date) =>
                    props.setQueryConditions({
                      ...queryConditions,
                      executionDate: {
                        ...queryConditions.executionDate,
                        from: date,
                      },
                    }),
                }}
                toDate={{
                  date: queryConditions.executionDate?.to,
                  setDate: (date) =>
                    props.setQueryConditions({
                      ...queryConditions,
                      executionDate: {
                        ...queryConditions.executionDate,
                        to: date,
                      },
                    }),
                }}
                setEnabledRange={(value) =>
                  props.setUserInputList(
                    userInputList.map((val) =>
                      val.name === 'executionDate'
                        ? {
                            ...val,
                            isValid: value,
                          }
                        : val
                    )
                  )
                }
              />
            </CustomTrainingPageParagraph>
            <Divider />
            <CustomTrainingPageParagraph>
              <Typography variant='body1' pt={2} pb={2}>
                Execution ID
              </Typography>
              <TextField
                defaultValue={queryConditions.executionIdList}
                variant='outlined'
                rows={1}
                inputProps={{ 'data-testid': 'execution-id-input' }}
                sx={{ width: '100%' }}
                onChange={handleChangeExecutionId}
                error={!userInputExecutionId?.isValid}
                helperText={
                  !userInputExecutionId?.isValid && 'uuid v4で入力してください'
                }
              />
            </CustomTrainingPageParagraph>
            <Divider />
            <CustomTrainingPageParagraph>
              <Typography variant='body1' pt={2} pb={2}>
                Robot ID
              </Typography>
              <TextField
                defaultValue={queryConditions.robotIdList}
                variant='outlined'
                rows={1}
                inputProps={{ 'data-testid': 'robot-id-input' }}
                sx={{ width: '100%' }}
                onChange={handleChangeRobotId}
                error={!userInputRobotId?.isValid}
                helperText={
                  !userInputRobotId?.isValid && 'uuid v4で入力してください'
                }
              />
            </CustomTrainingPageParagraph>
            <Divider />
            <CustomTrainingPageParagraph>
              <FormControl variant='outlined' sx={{ width: '100%' }}>
                <Typography variant='body1' pt={2} pb={2}>
                  Model group(s)
                </Typography>
                <Select
                  data-testid='select-model-group'
                  labelId='model-group-label'
                  id='model-group-label-outlined'
                  value={queryConditions.modelGroupIdList?.[0] ?? ''}
                  onChange={handleSelect}
                >
                  <MenuItem
                    value={''}
                    sx={{ height: (theme) => theme.spacing(4.5) }}
                  ></MenuItem>
                  {props.domainData.modelGroupList.map((item) => (
                    <MenuItem
                      data-testid={`select-${item.modelGroupId}`}
                      value={item.modelGroupId}
                      key={item.modelGroupId}
                    >
                      {`${item.modelGroupName} (${item.modelGroupId})`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CustomTrainingPageParagraph>
            <Divider />
            <Box mb={1}>
              <Typography variant='body1' pt={2}>
                Confirmed
              </Typography>
              <Box display='flex' alignItems='center'>
                <Checkbox
                  color='secondary'
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.setQueryConditions({
                      ...queryConditions,
                      confirmed: e.target.checked,
                    })
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={queryConditions.confirmed}
                  data-testid='confirmed-checkbox'
                />
                <Typography variant='body2'>unconfirmed item</Typography>
              </Box>
            </Box>
            <Divider />
            <Box mb={1}>
              <Typography variant='body1' pt={2}>
                Object recognition results
              </Typography>
              <Box display='flex' alignItems='center'>
                <Checkbox
                  color='secondary'
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    props.setQueryConditions({
                      ...queryConditions,
                      objectRecognitionResults: !e.target.checked,
                    })
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={!queryConditions.objectRecognitionResults}
                  data-testid={'object-recognition-results-checkbox'}
                />
                <Typography variant='body2'>no-result</Typography>
              </Box>
            </Box>
            <Divider />
          </Box>
        </Box>
        <Box className={classes.footer}>
          <Button
            variant='contained'
            color='primary'
            disabled={
              !userInputList.every((input) => input.isValid) ||
              props.domainData.count.countNumber === 0 ||
              props.domainData.count.isOver
            }
            data-testid='run-query'
            sx={{ width: '100%' }}
            onClick={async () => {
              dispatch(
                robotDataAnalysisExtractedImagesActions.clearRobotDataAnalysisExtractedImagesState()
              )

              props.domainData.count.countNumber === 1
                ? history.push({
                    pathname: `/robot-data-analysis/extracted-images/${await props.getTrainingDataId()}`,
                  })
                : history.push({
                    pathname: '/robot-data-analysis/extracted-images',
                    search: createParams(props.domainData.queryConditions),
                  })
            }}
          >
            {'RUN QUERY'}
          </Button>
        </Box>
        <GlobalLoading open={props.appState.inProgress} />
      </Toast>
    </Box>
  )
}

export const RobotDataAnalysisEntryPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RobotDataAnalysisEntry))
