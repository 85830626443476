import {
  CreateAction,
  ActionsUnion,
  Action,
  ActionWithPayload,
} from 'state/utils/actions-utils'
import {
  GroupedImage,
  InferenceDisplayCondition,
  InferenceActionType,
  InferenceStateKind,
  Metadata,
  TrainedModel,
  TrainedModelGroup,
  ExecuteInfo,
  GroupedImageDisplayCondition,
  TrainedModelGroupLists,
} from './types'
import { ToastInfo } from 'state/utils'

export type InferenceEntryAction = ActionsUnion<typeof inferenceEntryActions>

export const inferenceEntryActions = {
  setSelectedAlgorithmId: (
    algorithmId?: string
  ): ActionWithPayload<
    'SET_SELECTED_ALGORITHM_ID_FOR_INFERENCE',
    { algorithmId?: string }
  > =>
    CreateAction(InferenceActionType.SET_SELECTED_ALGORITHM_ID_FOR_INFERENCE, {
      algorithmId,
    }),
  setTrainedModelGroupList: (
    trainedModelGroups: TrainedModelGroupLists
  ): ActionWithPayload<
    'SET_TRAINED_MODEL_GROUP_LIST_FOR_INFERENCE',
    { trainedModelGroups: TrainedModelGroupLists }
  > =>
    CreateAction(
      InferenceActionType.SET_TRAINED_MODEL_GROUP_LIST_FOR_INFERENCE,
      {
        trainedModelGroups,
      }
    ),
  setSelectedTrainedModelGroup: (
    selectedTrainedModelGroup?: TrainedModelGroup
  ): ActionWithPayload<
    'SET_SELECTED_TRAINED_MODEL_GROUP_FOR_INFERENCE',
    { selectedTrainedModelGroup?: TrainedModelGroup }
  > =>
    CreateAction(
      InferenceActionType.SET_SELECTED_TRAINED_MODEL_GROUP_FOR_INFERENCE,
      {
        selectedTrainedModelGroup,
      }
    ),
  setSelectedTrainedModel: (
    selectedTrainedModel?: TrainedModel
  ): ActionWithPayload<
    'SET_SELECTED_TRAINED_MODEL_FOR_INFERENCE',
    { selectedTrainedModel?: TrainedModel }
  > =>
    CreateAction(InferenceActionType.SET_SELECTED_TRAINED_MODEL_FOR_INFERENCE, {
      selectedTrainedModel,
    }),
  setTrainedModelSubState: (
    trainedModelSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_INFERENCE_ALGORITHM_SUB_STATE',
    { trainedModelSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(InferenceActionType.SET_INFERENCE_ALGORITHM_SUB_STATE, {
      trainedModelSubState,
    }),
  setSelectedImageSet: (
    selectedGroupedImage?: GroupedImage
  ): ActionWithPayload<
    'SET_SELECTED_IMAGE_SET',
    { selectedGroupedImage?: GroupedImage }
  > =>
    CreateAction(InferenceActionType.SET_SELECTED_IMAGE_SET, {
      selectedGroupedImage,
    }),
  setImageSetSubState: (
    groupedDataSubState: 'Unselected' | 'Selected'
  ): ActionWithPayload<
    'SET_IMAGE_SET_SUB_STATE',
    { groupedDataSubState: 'Unselected' | 'Selected' }
  > =>
    CreateAction(InferenceActionType.SET_IMAGE_SET_SUB_STATE, {
      groupedDataSubState,
    }),
  setMetaDataSubState: (
    metaDataSubState: 'EmptyRequired' | 'InputRequired'
  ): ActionWithPayload<
    'SET_META_DATA_SUB_STATE',
    { metaDataSubState: 'EmptyRequired' | 'InputRequired' }
  > =>
    CreateAction(InferenceActionType.SET_META_DATA_SUB_STATE, {
      metaDataSubState,
    }),
  setImageSetList: (
    groupedImageList: GroupedImage[]
  ): ActionWithPayload<
    'SET_IMAGE_SET_LIST',
    { groupedImageList: GroupedImage[] }
  > =>
    CreateAction(InferenceActionType.SET_IMAGE_SET_LIST, {
      groupedImageList,
    }),
  setTrainedModelGroupDisplayCondition: (
    modelGroupDisplayCondition: InferenceDisplayCondition & {
      selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
    }
  ): ActionWithPayload<
    'SET_MODEL_GROUP_DISPLAY_CONDITION_FOR_INFERENCE',
    {
      modelGroupDisplayCondition: InferenceDisplayCondition & {
        selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
      }
    }
  > =>
    CreateAction(
      InferenceActionType.SET_MODEL_GROUP_DISPLAY_CONDITION_FOR_INFERENCE,
      {
        modelGroupDisplayCondition,
      }
    ),
  setTrainedModelDisplayCondition: (
    trainedModelDisplayCondition: InferenceDisplayCondition
  ): ActionWithPayload<
    'SET_TRAINED_MODEL_DISPLAY_CONDITION_FOR_INFERENCE',
    {
      trainedModelDisplayCondition: InferenceDisplayCondition
    }
  > =>
    CreateAction(
      InferenceActionType.SET_TRAINED_MODEL_DISPLAY_CONDITION_FOR_INFERENCE,
      {
        trainedModelDisplayCondition,
      }
    ),
  setGroupedImageDisplayCondition: (
    groupedImageDisplayCondition: GroupedImageDisplayCondition
  ): ActionWithPayload<
    'SET_IMAGE_SET_DISPLAY_CONDITION',
    { groupedImageDisplayCondition: InferenceDisplayCondition }
  > =>
    CreateAction(InferenceActionType.SET_IMAGE_SET_DISPLAY_CONDITION, {
      groupedImageDisplayCondition,
    }),
  setInferenceMetadata: (
    inferenceMetadata?: Metadata
  ): ActionWithPayload<
    'SET_INFERENCE_METADATA',
    { inferenceMetadata?: Metadata }
  > =>
    CreateAction(InferenceActionType.SET_INFERENCE_METADATA, {
      inferenceMetadata,
    }),
  setInferenceState: (
    inferenceState: InferenceStateKind
  ): ActionWithPayload<
    'SET_INFERENCE_STATE',
    { inferenceState: InferenceStateKind }
  > =>
    CreateAction(InferenceActionType.SET_INFERENCE_STATE, {
      inferenceState,
    }),
  executeInferenceSuccess: (
    isExecuted: boolean
  ): ActionWithPayload<'EXEC_INFERENCE_SUCCESS', { isExecuted: boolean }> =>
    CreateAction(InferenceActionType.EXEC_INFERENCE_SUCCESS, {
      isExecuted,
    }),
  executeInferenceFailure: (): Action<'EXEC_INFERENCE_FAILURE'> =>
    CreateAction(InferenceActionType.EXEC_INFERENCE_FAILURE),
  setExecutedMlPipelineId: (
    executeInfo: ExecuteInfo
  ): ActionWithPayload<
    'SET_EXECUTED_ML_PIPELINE_ID_FOR_INFERENCE',
    { executeInfo: ExecuteInfo }
  > =>
    CreateAction(
      InferenceActionType.SET_EXECUTED_ML_PIPELINE_ID_FOR_INFERENCE,
      {
        executeInfo,
      }
    ),
  clearCurrentInferenceState: (): Action<'CLEAR_CURRENT_INFERENCE_STATE'> =>
    CreateAction(InferenceActionType.CLEAR_CURRENT_INFERENCE_STATE),
  setInProgress: (
    inProgress: boolean
  ): ActionWithPayload<
    'SET_IN_PROGRESS_FOR_INFERENCE_START',
    { inProgress: boolean }
  > =>
    CreateAction(InferenceActionType.SET_IN_PROGRESS_FOR_INFERENCE_START, {
      inProgress,
    }),
  clearTrainedModelListDisplayCondition:
    (): Action<'CLEAR_TRAINED_MODEL_LIST_DISPLAY_CONDITION_FOR_INFERENCE'> =>
      CreateAction(
        InferenceActionType.CLEAR_TRAINED_MODEL_LIST_DISPLAY_CONDITION_FOR_INFERENCE
      ),
  clearTrainedModelGroupListDisplayCondition:
    (): Action<'CLEAR_TRAINED_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_INFERENCE'> =>
      CreateAction(
        InferenceActionType.CLEAR_TRAINED_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_INFERENCE
      ),
  setCurrentDatasetListSnapshot: (
    currentDatasetListSnapshot?: () => void
  ): ActionWithPayload<
    'SET_CURRENT_DATASET_LIST_SNAPSHOT',
    { currentDatasetListSnapshot?: () => void }
  > =>
    CreateAction(InferenceActionType.SET_CURRENT_DATASET_LIST_SNAPSHOT, {
      currentDatasetListSnapshot,
    }),
  setToastInfo: (
    toastInfo: ToastInfo | undefined
  ): ActionWithPayload<
    'SET_TOAST_INFO_FOR_INFERENCE',
    { toastInfo: ToastInfo | undefined }
  > =>
    CreateAction(InferenceActionType.SET_TOAST_INFO_FOR_INFERENCE, {
      toastInfo,
    }),
}
