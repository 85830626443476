import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  withRouter,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { Timestamp } from 'firebase/firestore'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import MoreVert from '@mui/icons-material/MoreVert'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'

import { State } from 'state/store'
import {
  AccountDetailAction,
  accountDetailActions,
  AccountDetailOperations,
  AccountInfo,
  Customer,
  CustomerListDisplayCondition,
  CustomerListPagingState,
  DisplayCustomer,
} from 'state/ducks/accountDetail'

import {
  GlobalLoading,
  AccountListIcon,
  CheckableTableHeader,
  CheckableTable,
  CHECKABLE_TABLE_HEADER_HEIGHT,
  ActionDialog,
  CommonCompleteDialog,
  showToast,
  Toast,
  CHECKBOX_ROW_HEIGHT,
} from 'views/components'
import { formatDateTimeSec } from 'views/components/utils/date'
import { isDetailPathParams } from 'views/containers/utils/typeguard'
import { isUndefined } from 'utils/typeguard'
import { TabItems } from 'views/components/organisms/tabLayout/types'
import { handleResourceNotFound } from 'views/containers/utils'
import { AccountAuthorizer } from 'views/components/organisms/accountAuthorizer'
import { AccountGroupRole } from 'views/utils/types'

const mapStateToProps = (state: State) => ({
  ...state.pages.accountDetailState,
  ...state.app.domainData.authedUser,
  ...state.app.domainData.userProfile,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, AccountDetailAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** ユーザー情報取得 */
  getAccountInfo: (accountId: string, queryAccountGroupId?: string) =>
    dispatch(
      AccountDetailOperations.getAccountInfo(accountId, queryAccountGroupId)
    ),
  setAccountInfo: (accountInfo: AccountInfo) =>
    dispatch(accountDetailActions.setAccountInfo(accountInfo)),
  getCustomerList: () => dispatch(AccountDetailOperations.getCustomerList()),
  /** プロフィール更新処理 */
  updateProfile: (
    uid: string,
    firstName: string,
    familyName: string,
    accountId: string
  ) =>
    dispatch(
      AccountDetailOperations.updateProfile(
        uid,
        firstName,
        familyName,
        accountId
      )
    ),
  /** パスワード強制更新処理 */
  forcedAccountPasswordUpdate: (uid: string) =>
    dispatch(AccountDetailOperations.forcedAccountPasswordUpdate(uid)),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (listCondition: CustomerListDisplayCondition) =>
    dispatch(
      accountDetailActions.setCustomerListDisplayCondition(listCondition)
    ),
  /** アカウント削除 */
  accountDelete: (uid: string) =>
    dispatch(AccountDetailOperations.accountDelete(uid)),
  /** 次ページの活性非活性の切り替え */
  setAccountListPagingState: (nextPageSubState: CustomerListPagingState) =>
    dispatch(accountDetailActions.setCustomerListPagingState(nextPageSubState)),
  /** アカウントカストマー関係の更新処理 */
  updateAccountCustomerRelations: (
    uid: string,
    accountCustomers: Customer[]
  ) => {
    dispatch(
      AccountDetailOperations.updateAccountCustomerRelations(uid, [
        ...accountCustomers,
      ])
    )
  },
  /** カスタムクレームの更新 */
  updateCustomClaimSuperUser: (
    uid: string,
    customClaimKey: string,
    customClaimValue: boolean
  ) =>
    dispatch(
      AccountDetailOperations.updateCustomClaimSuperUser(
        uid,
        customClaimKey,
        customClaimValue
      )
    ),
  updateAccountGroupRole: (
    uid: string,
    accountGroupRole: AccountGroupRole,
    accountId: string
  ) =>
    dispatch(
      AccountDetailOperations.updateAccountGroupRole(
        uid,
        accountGroupRole,
        accountId
      )
    ),
  /** Stateのクリア */
  clearAccountDetailPageState: () =>
    dispatch(accountDetailActions.clearAccountDetailState()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    textTransform: 'none',
    width: '100%',
    cursor: 'pointer',
  },
  tabContents: {
    '& > .MuiPaper-root': {
      backgroundColor: '#fafafa',
    },
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
  innerContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  nowTab: {
    backgroundColor: theme.palette.grey[200],
  },
}))

// FIXME 管理方法の検討
const LANGUAGE_LIST = [
  { key: 'ja', value: '日本' },
  // { key: 'en', value: '英語' },
]
const LOCATE_LIST = [{ key: 'JP', value: '日本' }]

const ACCOUNT_ROLE_LIST = [
  {
    id: 'admin',
    name: '管理者',
  },
  {
    id: 'user',
    name: '一般ユーザー',
  },
]

const ROLE_LIST = [
  { key: 'admin', value: '管理者' },
  { key: 'developer', value: 'AIエンジニア' },
  { key: 'user', value: '一般ユーザー' },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

/** カスタマーテーブルのヘッダー */
const CUSTOMER_TABLE_HEADERS: CheckableTableHeader[] = [
  {
    id: 'customerName',
    title: 'カスタマー名',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'role',
    title: 'ロール',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'lastAccessed',
    title: '最終ログイン',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'linkedDate',
    title: 'アクセス設定日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
]

const AccountDetail: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const history = useHistory()
  const search = useLocation().search
  const query = new URLSearchParams(search)

  const [userFirstName, setUserFirstName] = useState<string>('')
  const [userFamilyName, setUserFamilyName] = useState<string>('')
  const [accountLocate, setAccountLocate] = useState<string>('')
  const [accountLanguage, setAccountLanguage] = useState<string>('')
  const [accountRole, setAccountRole] = useState<AccountGroupRole>('user')
  const [mfaChecked, setMfaChecked] = React.useState(false)
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined)
  const open = Boolean(anchorEl)
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)
  const [nowTab, setNowTab] = useState(0)
  const [checkedCustomerIds, setCheckedCustomerIds] = useState<string[]>([])

  /** 初期実行 */
  useEffect(() => {
    props.getAccountInfo(
      isDetailPathParams(props.match.params) ? props.match.params.id : '',
      query.get('account-group-id') ?? undefined
    )
    props.getCustomerList()
    return () => {
      props.clearAccountDetailPageState()
    }
  }, [])

  useEffect(() => {
    // 初期値セット
    setUserFirstName(props.domainData.accountInfo.firstName)
    setUserFamilyName(props.domainData.accountInfo.familyName)
    setAccountLocate(props.domainData.accountInfo.locate)
    setAccountLanguage(props.domainData.accountInfo.language)
    setMfaChecked(
      props.domainData.accountInfo.mfaGroupSetting === 'required'
        ? true
        : props.domainData.accountInfo.isMfa
    )
  }, [props.domainData.accountInfo])

  useEffect(() => {
    if (props.domainData.accountInfo.accountGroupRole == null) {
      // account-group-roleが設定されていない場合は一般ユーザーを初期値とする
      setAccountRole('user')
    } else {
      setAccountRole(props.domainData.accountInfo.accountGroupRole)
    }
  }, [props.domainData.accountInfo.accountGroupRole])

  /** アカウント削除エラー時Toast */
  useEffect(() => {
    switch (props.appState.accountDeleteSubState) {
      case 'DeleteError':
        showErrorToast('アカウントの削除に失敗しました。')
        break
      case 'AccountDeleteAborted':
        showErrorToast('削除出来ませんでした(自ユーザーは削除できません)')
        break
      default:
        break
    }
  }, [props.appState.accountDeleteSubState])

  /** ロール更新エラー時Toast */
  useEffect(() => {
    switch (props.appState.accountUpdateRoleSubState) {
      case 'UpdateRoleError':
        showErrorToast('ロールの更新に失敗しました。')
        break
      case 'UpdateRoleAborted':
        showErrorToast(
          'ロールの更新に失敗しました。(自ユーザをユーザーに変更できません)'
        )
        break
      default:
        break
    }
  }, [props.appState.accountUpdateRoleSubState])

  /** カスタマーの紐付け更新エラー時Toast */
  useEffect(() => {
    switch (props.appState.accountUpdateCustomerListSubState) {
      case 'UpdateCustomerListError':
        showErrorToast('カスタマーの紐付けの更新に失敗しました。')
        break
      default:
        break
    }
  }, [props.appState.accountUpdateCustomerListSubState])

  /** 対象のIDのデータがない場合、データが不正の場合はhomeに戻る */
  useEffect(() => {
    handleResourceNotFound(props.appState.accountDataSubState, history)
  }, [props.appState.accountDataSubState])

  const {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    accountGroupCustomers,
    accountGroupCustomersDisplaySlice,
    tableRows,
  } = useTableDisplayActions({ ...props, checkedCustomerIds })

  const [checkedRows, setCheckedRows] = useState(
    props.domainData.customerList
      ? Array(props.domainData.customerList.length).fill(false)
      : []
  )

  useEffect(() => {
    setCheckedCustomerIds(
      props.domainData.accountInfo.customerList.map(
        (customer: Customer) => customer.customerId
      )
    )
  }, [props.domainData.accountInfo.customerList])

  useEffect(() => {
    setCheckedRows(
      accountGroupCustomersDisplaySlice.map(
        (displayedCustomer: Customer) =>
          checkedCustomerIds.find(
            (customerId) => displayedCustomer.customerId === customerId
          ) !== undefined
      )
    )
  }, [
    accountGroupCustomersDisplaySlice,
    checkedCustomerIds,
    props.domainData.customerListDisplayCondition,
  ])

  const customerTableHeader = CUSTOMER_TABLE_HEADERS

  /** テーブル */
  const customerListTable = useMemo(() => {
    return (
      <CheckableTable
        displayNumber={
          props.domainData.customerListDisplayCondition.displayNumber
        }
        headers={customerTableHeader}
        rows={tableRows}
        selectedRows={checkedRows}
        totalCount={
          props.domainData.customerList
            ? props.domainData.customerList.length
            : 0
        }
        loading={props.appState.inProgress}
        tableHeight={CHECKABLE_TABLE_HEADER_HEIGHT + 10 * CHECKBOX_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={props.domainData.customerListDisplayCondition.pageNumber}
        sortOrder={{
          key: props.domainData.customerListDisplayCondition.sortKey,
          order: props.domainData.customerListDisplayCondition.sortOrder,
        }}
        displayNoneCheckbox={false}
        onClickCheckbox={(rowIndex: number) =>
          handleAccountCustomerRelationsUpdate(rowIndex)
        }
        onClickOrderChange={(key: string) => changeTableSortOrder(key)}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.appState.customerListState.nextPageSubState,
    props.domainData.customerListDisplayCondition,
    props.appState.inProgress,
    checkedRows,
  ])

  /** urlから取得したaccountId */
  const accountId = isDetailPathParams(props.match.params)
    ? props.match.params.id
    : ''
  if (accountId === '') {
    console.error('Error Invalid accountId')
    return <></>
  }

  const handleAccountCustomerRelationsUpdate = (rowIndex: number) => {
    const customerIndex =
      props.domainData.customerListDisplayCondition.pageNumber *
        props.domainData.customerListDisplayCondition.displayNumber +
      rowIndex

    const customerId = accountGroupCustomers[customerIndex].customerId
    if (!checkedCustomerIds.includes(customerId)) {
      setCheckedCustomerIds([...checkedCustomerIds, customerId])

      if (
        props.domainData.accountInfo.customerList.find(
          (customer) => customer.customerId === customerId
        ) == null
      ) {
        const customerName =
          props.domainData.customerList.find(
            (customer) => customer.customerId === customerId
          )?.customerName ?? ''
        props.setAccountInfo({
          ...props.domainData.accountInfo,
          customerList: [
            ...props.domainData.accountInfo.customerList,
            {
              customerId,
              customerName,
              role: props.domainData.accountInfo.role ? 'admin' : 'user',
              lastAccessed: Timestamp.fromMillis(0),
              linkedDate: Timestamp.fromMillis(0),
            },
          ],
        })
      }
    } else {
      setCheckedCustomerIds(
        checkedCustomerIds.filter((id) => id !== customerId)
      )
    }
    const newCheckedRows = [...checkedRows]
    newCheckedRows[rowIndex] = !newCheckedRows[rowIndex]
    setCheckedRows(newCheckedRows)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(undefined)

  const showErrorToast = (message: string) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div>{message}</div>
      </div>
    )

  const tabItems: TabItems[] = [
    // プロフィールコンテンツ
    {
      label: 'プロフィール',
      displayInfo: (
        <>
          <Box component={Paper} p={'24px 32px 32px'}>
            <Box display='flex' mb={2}>
              <TextField
                label='メールアドレス'
                variant='outlined'
                value={props.domainData.accountInfo.mail}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                disabled
              />
            </Box>
            <Box display='flex' mb={2}>
              <Box display='flex' mr={2} width='50%'>
                <TextField
                  label='名'
                  variant='outlined'
                  value={userFirstName}
                  fullWidth
                  onChange={(event) => setUserFirstName(event.target.value)}
                />
              </Box>
              <Box display='flex' width='50%'>
                <TextField
                  label='姓'
                  variant='outlined'
                  value={userFamilyName}
                  fullWidth
                  onChange={(event) => setUserFamilyName(event.target.value)}
                />
              </Box>
            </Box>
            <Box display='flex' mb={2}>
              <TextField
                label='所属'
                variant='outlined'
                value={props.domainData.accountInfo.accountGroupName ?? 'N/A'}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                disabled
              />
            </Box>
          </Box>
          <Box display='flex' mt={2}>
            <Button
              variant='outlined'
              style={{
                backgroundColor: '#D9E5FF',
                width: '100%',
              }}
              onClick={() =>
                props.updateProfile(
                  props.domainData.accountInfo.uid,
                  userFirstName,
                  userFamilyName,
                  accountId
                )
              }
            >
              <Typography color='primary'>更新</Typography>
            </Button>
          </Box>
        </>
      ),
    },
    // 環境設定コンテンツ
    {
      label: '環境設定',
      displayInfo: (
        <Box component={Paper} p={'24px 32px 32px'}>
          <Box display='flex' mb={2}>
            <FormControl variant='outlined' fullWidth>
              <InputLabel>言語</InputLabel>
              <Select
                disabled
                value={accountLanguage}
                onChange={(e: SelectChangeEvent<string>) => {
                  setAccountLanguage(e.target.value as string)
                }}
                label='言語'
              >
                {LANGUAGE_LIST.map((language) => (
                  <MenuItem value={language.key} key={language.key}>
                    {language.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box display='flex' mb={2}>
            <FormControl variant='outlined' fullWidth>
              <InputLabel>地域</InputLabel>
              <Select
                disabled
                value={accountLocate}
                onChange={(e: SelectChangeEvent<string>) =>
                  setAccountLocate(e.target.value as string)
                }
                label='地域'
              >
                {LOCATE_LIST.map((locate) => (
                  <MenuItem value={locate.key} key={locate.key}>
                    {locate.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      ),
    },
    // アカウントコンテンツ
    {
      label: 'アカウント',
      displayInfo: (
        <>
          <Box component={Paper} p={'24px 32px 32px'}>
            <Box display='flex' mb={2}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel>アカウントグループロール</InputLabel>
                <Select
                  value={accountRole}
                  onChange={(e: SelectChangeEvent<string>) => {
                    setAccountRole(e.target.value as AccountGroupRole)
                  }}
                  label='アカウントグループロール'
                  disabled={!props.auth.customClaims.superUser}
                >
                  {ACCOUNT_ROLE_LIST.map((item) => (
                    <MenuItem value={item.id} key={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box display='flex' mb={2}>
              <FormControlLabel
                control={
                  <Switch
                    color='default'
                    disabled={
                      props.domainData.accountInfo.mfaGroupSetting ===
                        'required' ||
                      props.domainData.accountInfo.mfaGroupSetting === 'none'
                    }
                    checked={mfaChecked}
                    onChange={(event) => setMfaChecked(event.target.checked)}
                  />
                }
                label='MFA設定'
                labelPlacement='start'
              />
            </Box>
            <Box display='flex' mb={2}>
              <TextField
                label='電話番号'
                variant='outlined'
                value={props.domainData.accountInfo.phoneNumber}
                fullWidth
                // TODO 現在は参照のみ
                InputProps={{
                  readOnly: true,
                }}
                disabled
              />
            </Box>
          </Box>
          <AccountAuthorizer
            type='CHANGE_ACCOUNT_ACCOUNT_GROUP_PERMISSION'
            superUser={props.auth.customClaims.superUser}
            accountGroupRole={props.accountGroupRole ?? ''}
          >
            <Box display='flex' mt={2}>
              <Button
                variant='outlined'
                style={{
                  backgroundColor: '#D9E5FF',
                  width: '100%',
                }}
                onClick={() =>
                  props.updateAccountGroupRole(
                    props.domainData.accountInfo.uid,
                    accountRole,
                    accountId
                  )
                }
                data-testid='account-profile-update'
              >
                <Typography color='primary'>更新</Typography>
              </Button>
            </Box>
          </AccountAuthorizer>
        </>
      ),
    },
    // パスワードコンテンツ
    {
      label: 'パスワード',
      displayInfo: (
        <>
          <Box component={Paper} p={'24px 32px 32px'}>
            <TextField
              label='パスワード更新日時'
              value={
                props.domainData.accountInfo.passwordUpdateDate
                  ? props.domainData.accountInfo.passwordUpdateDate.seconds !==
                    0
                    ? formatDateTimeSec(
                        props.domainData.accountInfo.passwordUpdateDate.toDate()
                      )
                    : 'N/A'
                  : 'N/A'
              }
              InputProps={{
                readOnly: true,
              }}
              disabled={
                props.domainData.accountInfo.passwordUpdateDate &&
                props.domainData.accountInfo.passwordUpdateDate.seconds === 0
              }
              fullWidth
              variant='standard'
            />
          </Box>
          <Box mt={6} mb={2}>
            <Typography variant='body2'>{`${props.domainData.accountInfo.mail} の次回ログイン時にパスワード更新を強制させる`}</Typography>
          </Box>
          <Box display='flex'>
            <Button
              variant='outlined'
              style={{
                backgroundColor: '#D9E5FF',
                width: '100%',
              }}
              onClick={() =>
                props.forcedAccountPasswordUpdate(
                  props.domainData.accountInfo.uid
                )
              }
            >
              <Typography color='primary'>パスワード強制更新</Typography>
            </Button>
          </Box>
        </>
      ),
    },
    // カスタマーコンテンツ
    {
      label: 'カスタマー',
      displayInfo: (
        <>
          <Box component={Paper} p={'24px 32px 32px'}>
            <Box maxHeight='500px'>{customerListTable}</Box>
          </Box>
          <AccountAuthorizer
            type='ASSOCIATE_CUSTOMER_TO_ACCOUNT'
            superUser={props.auth.customClaims.superUser}
            accountGroupRole={props.accountGroupRole ?? ''}
          >
            <Box display='flex' mt={2}>
              <Button
                variant='outlined'
                style={{
                  backgroundColor: '#D9E5FF',
                  width: '100%',
                }}
                onClick={() => {
                  const customerList = checkedCustomerIds.map(
                    (checkedCustomerId) => {
                      const customer =
                        props.domainData.accountInfo.customerList.find(
                          (customer) =>
                            customer.customerId === checkedCustomerId
                        )
                      if (customer) {
                        return customer
                      }
                      const availableCustomer =
                        props.domainData.customerList.find(
                          (customer) =>
                            customer.customerId === checkedCustomerId
                        )
                      if (!availableCustomer) {
                        console.error(
                          `checkedCustomerIds contains id "${checkedCustomerId}" which is not in the list of all account group customers ?! better check that code ASAP, this should be an unreachable state.`
                        )
                        throw 'Invalid Value Error: can not update the account customer relations because one of the customerId does not exist in the account group customer relations.'
                      }
                      return {
                        customerId: availableCustomer.customerId,
                        customerName: availableCustomer.customerName,
                        role: 'user',
                        lastAccessed: Timestamp.fromMillis(0),
                        linkedDate: Timestamp.fromMillis(0),
                      }
                    }
                  )
                  props.updateAccountCustomerRelations(
                    props.domainData.accountInfo.uid,
                    customerList
                  )
                }}
              >
                <Typography color='primary'>更新</Typography>
              </Button>
            </Box>
          </AccountAuthorizer>
        </>
      ),
    },
    // アクティビティーコンテンツ
    {
      label: 'アクティビティー',
      displayInfo: (
        <Box component={Paper} p={'24px 32px 32px'}>
          <Box display='flex' mb={2}>
            <TextField
              label='最終ログイン日時'
              value={
                props.domainData.accountInfo.lastLoginTime
                  ? props.domainData.accountInfo.lastLoginTime.seconds !== 0
                    ? formatDateTimeSec(
                        props.domainData.accountInfo.lastLoginTime.toDate()
                      )
                    : 'N/A'
                  : 'N/A'
              }
              InputProps={{
                readOnly: true,
              }}
              disabled={
                props.domainData.accountInfo.lastLoginTime &&
                props.domainData.accountInfo.lastLoginTime.seconds === 0
              }
              fullWidth
              variant='standard'
            />
          </Box>
          <Box display='flex' mb={2}>
            <TextField
              label='登録日時'
              value={
                props.domainData.accountInfo.createdAt
                  ? props.domainData.accountInfo.createdAt.seconds !== 0
                    ? formatDateTimeSec(
                        props.domainData.accountInfo.createdAt.toDate()
                      )
                    : 'N/A'
                  : 'N/A'
              }
              InputProps={{
                readOnly: true,
              }}
              disabled={
                props.domainData.accountInfo.createdAt &&
                props.domainData.accountInfo.createdAt.seconds === 0
              }
              fullWidth
              variant='standard'
            />
          </Box>
          <Box display='flex' mb={2}>
            <TextField
              label='初回ログイン日時'
              value={
                props.domainData.accountInfo.firstLoginTime
                  ? props.domainData.accountInfo.firstLoginTime.seconds !== 0
                    ? formatDateTimeSec(
                        props.domainData.accountInfo.firstLoginTime.toDate()
                      )
                    : 'N/A'
                  : 'N/A'
              }
              InputProps={{
                readOnly: true,
              }}
              disabled={
                props.domainData.accountInfo.firstLoginTime &&
                props.domainData.accountInfo.firstLoginTime.seconds === 0
              }
              fullWidth
              variant='standard'
            />
          </Box>
        </Box>
      ),
    },
  ]

  return (
    <>
      <Toast containerOptions={{ limit: 20 }}>
        <div className={classes.container}>
          <Box
            style={{
              position: 'sticky',
              top: '64px',
              backgroundColor: '#fafafa',
              zIndex: 10,
            }}
          >
            <Box className={classes.innerContainer}>
              <div
                className={classes.flexAndBetween}
                style={{ paddingTop: '16px' }}
              >
                <Box display='flex'>
                  <AccountListIcon
                    className={classes.pageIcon}
                    data-testid='account-detail-icon'
                  />
                  <Typography component={'div'}>
                    <h2 data-testid='account-detail-title'>ユーザー詳細</h2>
                  </Typography>
                </Box>
                <Box display='flex'>
                  <IconButton
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      handleClick(e)
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                  <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem
                      onClick={() => {
                        handleClose()
                        setOpenDeleteDialog(true)
                      }}
                    >
                      <Box display='flex'>
                        <Box mr={1}>
                          <PersonRemoveIcon />
                        </Box>
                        <Typography>ユーザー削除</Typography>
                      </Box>
                    </MenuItem>
                  </Menu>
                </Box>
              </div>
              <Box p={1}>
                <div
                  className={classes.flexAndBetween}
                  style={{ marginBottom: '8px' }}
                >
                  <Box display='flex'>
                    <Box ml='47px'>
                      <Typography>
                        {props.domainData.accountInfo.mail}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex'>
                    <Typography>{`${props.domainData.accountInfo.firstName} ${props.domainData.accountInfo.familyName}`}</Typography>
                  </Box>
                </div>
                <Box display='flex' justifyContent='flex-end' mb={1}>
                  <Typography>
                    {props.domainData.accountInfo.accountGroupName}
                  </Typography>
                </Box>
              </Box>
              <Box
                style={{
                  backgroundColor: '#fafafa',
                }}
              >
                <Tabs
                  indicatorColor='primary'
                  value={nowTab}
                  style={{
                    paddingBottom: '16px',
                    marginBottom: '1px',
                  }}
                  onChange={(_, value) => setNowTab(value)}
                >
                  {tabItems.map((item, index) => (
                    <Tab
                      style={{
                        width: `${100 / tabItems.length}%`,
                        maxWidth: '1200px',
                      }}
                      key={index}
                      className={clsx(nowTab === index && classes.nowTab)}
                      label={item.label}
                      data-testid={`change-tab-${index}`}
                    />
                  ))}
                </Tabs>
              </Box>
            </Box>
          </Box>
          <Box className={classes.tabContents}>
            <Box className={classes.innerContainer}>
              <Box>{tabItems[nowTab].displayInfo}</Box>
            </Box>
          </Box>
        </div>
      </Toast>
      <ActionDialog
        open={openDeleteDialog}
        message={'ユーザーを削除してもよろしいでしょうか？'}
        handleClose={() => setOpenDeleteDialog(false)}
        dialogAction={() =>
          props.accountDelete(props.domainData.accountInfo.uid)
        }
        actionButtonText='ユーザー削除'
        closeButtonText='キャンセル'
      />
      <CommonCompleteDialog
        open={props.appState.accountDeleteSubState === 'DeleteSuccess'}
        handleClose={() => history.push('/admin/accounts')}
        dialogText={''}
        data-testid={'delete-account'}
        value={'削除が完了しました'}
        label={''}
      />
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

interface UseTableDisplayActions extends Props {
  checkedCustomerIds: string[]
}
const useTableDisplayActions = (props: UseTableDisplayActions) => {
  const globalTheme = useTheme()
  const accountGroupCustomers = useMemo(() => {
    let customersArray = props.domainData.customerList
      ? props.domainData.customerList.map((availableCustomer) => {
          const selectedCustomer =
            props.domainData.accountInfo.customerList.find(
              (customer) => customer.customerId === availableCustomer.customerId
            )
          return {
            customerId: availableCustomer.customerId,
            customerName: availableCustomer.customerName,
            role: selectedCustomer ? selectedCustomer.role : '',
            lastAccessed: selectedCustomer
              ? selectedCustomer.lastAccessed
              : Timestamp.fromMillis(0),
            linkedDate: selectedCustomer?.linkedDate ?? Timestamp.fromMillis(0),
          }
        })
      : []
    /** ソートキー、ソートオーダーによる並び替え */
    if (
      !isUndefined(props.domainData.customerListDisplayCondition) &&
      props.domainData.customerListDisplayCondition.sortKey === 'lastAccessed'
    ) {
      customersArray = customersArray.sort((item, item2) => {
        return (
          (item2.lastAccessed.toDate().getTime() -
            item.lastAccessed.toDate().getTime()) *
          (props.domainData.customerListDisplayCondition.sortOrder === 'asc'
            ? -1
            : 1)
        )
      })
    }
    return customersArray
  }, [
    props.domainData.accountInfo.customerList,
    props.domainData.customerListDisplayCondition,
    props.domainData.customerList,
  ])

  /** 表示対象のベースモデルデータ */
  const accountGroupCustomersDisplaySlice = useMemo(() => {
    if (!isUndefined(props.domainData.customerListDisplayCondition)) {
      /** 表示するベースモデルの整形 */
      return accountGroupCustomers.slice(
        props.domainData.customerListDisplayCondition.displayNumber *
          props.domainData.customerListDisplayCondition.pageNumber,
        (props.domainData.customerListDisplayCondition.pageNumber + 1) *
          props.domainData.customerListDisplayCondition.displayNumber
      )
    }
    return accountGroupCustomers
  }, [accountGroupCustomers])

  /** テーブルに表示するモデルのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    const convertedList: DisplayCustomer[] =
      accountGroupCustomersDisplaySlice.map((customer: Customer) => {
        return {
          customerName: customer.customerName,
          role: customer.role,
          lastAccessed: customer.lastAccessed,
          linkedDate: customer.linkedDate,
        }
      })
    return convertedList.map((data, index) =>
      Object.entries(data).map(([key, value]) => {
        switch (key) {
          case 'lastAccessed':
          case 'linkedDate':
            // 終了日時がundefinedまたはUnixTime0の場合N/Aとする
            if (isUndefined(value) || value.seconds === 0) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
            return (
              <Typography>
                {formatDateTimeSec(
                  isUndefined(value) ? undefined : value.toDate()
                )}
              </Typography>
            )
          case 'customerName':
            return (
              <Tooltip title={value} placement='bottom'>
                <Typography>{value}</Typography>
              </Tooltip>
            )
          case 'role':
            return (
              <Select
                displayEmpty
                value={value}
                renderValue={() =>
                  value !== '' ? (
                    ROLE_LIST.find((role) => role.key === value)?.value
                  ) : (
                    <em>{TABLE_CELL_NOT_APPLICABLE}</em>
                  )
                }
                sx={{ backgroundColor: 'white', width: '10rem' }}
                disabled={
                  !props.checkedCustomerIds.includes(
                    accountGroupCustomersDisplaySlice[index].customerId
                  ) || props.domainData.accountInfo.role
                }
                onChange={(e) =>
                  handleChangeCustomerRole(
                    accountGroupCustomersDisplaySlice[index].customerId,
                    e.target.value
                  )
                }
              >
                {ROLE_LIST.map((role) => (
                  <MenuItem value={role.key} key={role.key}>
                    {role.value}
                  </MenuItem>
                ))}
              </Select>
            )
          default:
            return value ? (
              <Typography>{value}</Typography>
            ) : (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
        }
      })
    )
  }, [
    accountGroupCustomersDisplaySlice,
    props.checkedCustomerIds,
    props.domainData.accountInfo.role,
  ])

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    /** 表示数を変更 */
    props.setListDisplayCondition({
      ...props.domainData.customerListDisplayCondition,
      pageNumber:
        props.domainData.customerList.length >
        props.domainData.customerListDisplayCondition.pageNumber * displayNumber
          ? props.domainData.customerListDisplayCondition.pageNumber
          : Math.ceil(props.domainData.customerList.length / displayNumber) - 1,
      displayNumber: displayNumber ?? 10,
    })
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.customerListDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.customerListDisplayCondition.sortOrder === 'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.customerListDisplayCondition,
      pageNumber: pageNumber,
    })
  }

  const handleChangeCustomerRole = (customerId: string, role: string) => {
    const customerList =
      props.domainData.accountInfo.customerList.map<Customer>((customer) =>
        customer.customerId === customerId
          ? {
              ...customer,
              role,
            }
          : customer
      )
    const updateAccountInfo: AccountInfo = {
      ...props.domainData.accountInfo,
      customerList,
    }
    props.setAccountInfo(updateAccountInfo)
  }

  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    accountGroupCustomers,
    accountGroupCustomersDisplaySlice,
    tableRows,
  }
}

export const AccountDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountDetail))
