import React, { useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { OnsiteImageViewerProps } from './types'
import { InferenceResultView } from 'views/components/molecules'
import {
  Settings as SettingsIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomInMap as ZoomInMapIcon,
} from '@mui/icons-material'
import {
  IconButton,
  Box,
  Badge,
  Typography,
  CircularProgress,
} from '@mui/material'
import { CanvasInfo } from 'views/utils/types'
import { ColorChip } from 'views/components/atoms'

const RATE = 0.2

const useStyles = makeStyles()(() => ({
  settings: {
    top: 0,
    left: 0,
    zIndex: 1,
  },
  tools: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
  },
  img: {
    zIndex: 0,
    objectFit: 'contain',
    height: '100%',
    width: '534px',
    margin: 'auto',
    display: 'block',
  },
}))

type Position = {
  x: number
  y: number
}

export const OnsiteImageViewer: React.FC<OnsiteImageViewerProps> = (
  props: OnsiteImageViewerProps
) => {
  const { classes } = useStyles()
  const canvasContainerRef = useRef<HTMLCanvasElement>(null)
  // 拡縮ボタンの押下数
  const [count, setCount] = useState(0)
  // ドラッグ中フラグ
  const [mouseDown, setMouseDown] = useState<boolean>(false)
  // 位置情報
  const [translate, setTranslate] = useState<Position>({ x: 0, y: 0 })
  const [recentTranslate, setRecentTranslate] = useState<Position>({
    x: 0,
    y: 0,
  })
  // マウスを押し込んだときのカーソルの座標
  const startPoint = useRef<Position>({ x: 0, y: 0 })

  const selectedTags: CanvasInfo[] = useMemo(() => {
    const tags: CanvasInfo[] = []
    props.canvasInfos.forEach((info) => {
      if (!tags.some((tag) => tag.id === info.id)) {
        tags.push({ ...info, score: info.score ?? 0 })
      }
    })

    return tags
  }, [props.canvasInfos, props.canvasDisplayCondition])

  /** データ取得中のloading */
  const loading = useMemo(() => {
    if (props.loading) {
      return (
        <Box
          sx={{
            position: 'absolute',
            top: 'calc(50% - 32px / 2)',
            left: 'calc(50% - 32px / 2)',
          }}
        >
          <CircularProgress size={32} />
        </Box>
      )
    }
    return <></>
  }, [props.loading])

  // 対象を位置情報の場所に移動
  useEffect(() => {
    if (canvasContainerRef.current === null) return
    canvasContainerRef.current.style.translate = `${translate.x}px ${translate.y}px`
  }, [translate])

  const getImageRow = () => {
    if (props.url === '') {
      return <></>
    } else if (props.canvasInfos && props.canvasDisplayCondition) {
      return (
        <InferenceResultView
          imageHeight={props.imageHeight}
          isDialog={props.isDialog}
          url={props.url}
          canvasInfos={props.canvasInfos}
          canvasDisplayCondition={props.canvasDisplayCondition}
        />
      )
    } else {
      return <img src={props.url} className={classes.img} />
    }
  }

  // 拡大
  const zoomIn = () => {
    if (props.maxZoomIn && count >= props.maxZoomIn) return
    setCount(count + 1)
  }

  // 縮小
  const zoomOut = () => {
    if (count <= 0) return
    setCount(count - 1)
  }

  // 初期表示状態に戻す処理
  const zoomInMap = () => {
    setCount(0)
    setTranslate({ x: 0, y: 0 })
  }

  // ドラッグ開始処理
  const handleDown = (e: React.MouseEvent<EventTarget & HTMLElement>): void => {
    e.stopPropagation()
    if (canvasContainerRef.current === null) return
    // ドラッグ開始位置を保存
    startPoint.current = { x: e.pageX, y: e.pageY }
    setRecentTranslate(translate)

    setMouseDown(true)
  }

  // ドラッグ中の処理
  const handleMove = (e: React.MouseEvent<EventTarget & HTMLElement>): void => {
    e.stopPropagation()
    if (!canvasContainerRef.current || !mouseDown) return

    // ドラッグが移動した位置からドラッグ開始位置を引き、移動した距離を translate の現在位置に足す
    setTranslate({
      x: recentTranslate.x + e.pageX - startPoint.current.x,
      y: recentTranslate.y + e.pageY - startPoint.current.y,
    })
  }

  // ドラッグ終了処理
  const handleUp = (e: React.MouseEvent<EventTarget & HTMLElement>): void => {
    e.stopPropagation()
    if (!canvasContainerRef.current) return

    setMouseDown(false)
  }

  return (
    <Box display='flex' justifyContent='center'>
      <Box
        sx={{ width: props.imageHeight ? props.imageHeight * 1.78 : '534px' }}
      >
        <Box position='relative' sx={{ overflow: 'hidden' }}>
          <Box
            ref={canvasContainerRef}
            style={{ scale: `${1 + count * RATE} ${1 + count * RATE}` }}
            onMouseDown={handleDown}
            onMouseUp={handleUp}
            onMouseMove={handleMove}
            onMouseOut={handleUp}
          >
            {getImageRow()}
            {loading}
          </Box>
          <IconButton
            size='small'
            onClick={(e) => {
              e.stopPropagation()
              props.onClickSettings()
            }}
            sx={{
              position: 'absolute !important',
              margin: (theme) => theme.spacing(1),
            }}
            className={classes.settings}
          >
            <Badge
              color='error'
              variant='dot'
              invisible={!props.settingCustomized}
            >
              <SettingsIcon fontSize='small' />
            </Badge>
          </IconButton>
          <Box className={classes.tools}>
            <IconButton
              size='small'
              onClick={(e) => {
                e.stopPropagation()
                zoomIn()
              }}
              sx={{ margin: (theme) => theme.spacing(1) }}
            >
              <ZoomInIcon fontSize='small' />
            </IconButton>
            <IconButton
              size='small'
              onClick={(e) => {
                e.stopPropagation()
                zoomOut()
              }}
              sx={{ margin: (theme) => theme.spacing(1) }}
            >
              <ZoomOutIcon fontSize='small' />
            </IconButton>
            <IconButton
              size='small'
              onClick={(e) => {
                e.stopPropagation()
                zoomInMap()
              }}
              sx={{ margin: (theme) => theme.spacing(1) }}
            >
              <ZoomInMapIcon fontSize='small' />
            </IconButton>
          </Box>
        </Box>
        <Box p={1} display='flex'>
          <Box flexGrow={1}>
            {selectedTags.map((tag) => (
              <ColorChip
                key={tag.id}
                label={tag.id.substring(0, 8)}
                size='small'
                variant='filled'
                customColor={tag.color}
                sx={{
                  marginRight: (theme) => theme.spacing(1),
                  marginBottom: (theme) => theme.spacing(0.5),
                }}
              />
            ))}
          </Box>
          <Typography
            sx={{ width: '150px', textAlign: 'right', minWidth: '150px' }}
          >{`${props.canvasInfos.length} out of ${props.displayCount} items`}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
