import { isArray, isNumber, isObject, isString } from 'utils/typeguard'

export interface MediaLink {
  mediaName: string
  mediaUrl: string
  mediaSize: number
}

export interface TrainedModelDlLink {
  linkName: string
  mediaLinks: MediaLink[]
  totalMediaSize: number
}

export interface GetModelsFilesResponse {
  data: {
    items: TrainedModelDlLink[]
  }
}

/** MediaLink[]かどうか */
const isMediaLinks = (object: unknown): object is MediaLink[] => {
  return (
    isArray(object) &&
    object.every((element) => {
      return (
        isString(element.mediaName) &&
        isString(element.mediaUrl) &&
        isNumber(element.mediaSize)
      )
    })
  )
}

/** GetModelsFilesResponseかどうか */
export const isGetModelsFilesResponse = (
  object: unknown
): object is GetModelsFilesResponse => {
  return (
    isObject(object) &&
    isObject(object.data) &&
    isArray(object.data.items) &&
    object.data.items.every((element) => {
      return isString(element.linkName) && isMediaLinks(element.mediaLinks)
    })
  )
}
