import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
  useRouteMatch,
  useHistory,
  Link,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import Box from '@mui/material/Box'
import Add from '@mui/icons-material/Add'

import { State } from 'state/store'
import {
  FeatureDataGeneratingListAction,
  featureDataGeneratingListActions,
  featureDataGeneratingListOperations,
  FeatureDataGeneratingDisplayCondition,
  FeatureDataGenerating,
} from 'state/ducks/featureDataGeneratingList'

import { getFeatureDataGroupId } from 'utils/ducks/featureDataGroup'
import { isUndefined } from 'utils/typeguard'
import {
  TrainingIcon,
  SelectableTable,
  SearchInput,
  StatusProgressBar,
  RunningTimeLabel,
  GlobalLoading,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  STATUS_PROGRESS_BAR_ROW_HEIGHT,
  TooltipLink,
  BreadcrumbsComponent,
} from 'views/components'
import {
  convertProgressWord,
  convertProgressColor,
} from 'views/containers/utils'
import {
  formatDateTimeSec,
  formatTimeSecByMillSecond,
} from 'views/components/utils/date'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import { Link as MuiLink } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const mapStateToProps = (state: State) => ({
  ...state.pages.featureDataGeneratingListState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, FeatureDataGeneratingListAction>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 特徴量データ生成一覧取得 */
  getFeatureDataGeneratingList: () =>
    dispatch(
      featureDataGeneratingListOperations.getFeatureDataGeneratingList()
    ),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (
    listCondition: FeatureDataGeneratingDisplayCondition
  ) =>
    dispatch(
      featureDataGeneratingListActions.setListDisplayCondition(listCondition)
    ),
  /** Stateのクリア */
  clearFeatureDataGeneratingListState: () =>
    dispatch(
      featureDataGeneratingListActions.clearFeatureDataGeneratingListState()
    ),
  /** snapshotの購読解除 */
  unsubscribe: () =>
    dispatch(featureDataGeneratingListOperations.unsubscribe()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  head: {
    height: '240px',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  stepContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  link: {
    cursor: 'pointer',
    textTransform: 'none',
    width: '100%',
  },
}))

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'mlPipelineId',
    title: 'ML Pipeline ID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'name',
    title: '生成名',
    width: 250,
    sortable: false,
    position: 'left',
  },
  {
    id: 'type',
    title: '種別',
    width: 250,
    sortable: false,
    position: 'left',
  },
  {
    id: 'progress',
    title: '進捗',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'time',
    title: '実行時間',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'ml-pipeline.started-at',
    title: '開始日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'ended-at',
    title: '終了日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'dataset',
    title: 'データセット',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'generateFeatureData',
    title: '特徴量データ',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'executionUser',
    title: '実行ユーザーID',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

const FeatureDataGeneratingList: React.FC<Props> = (props: Props) => {
  const { url } = useRouteMatch()
  const globalTheme = useTheme()
  const { classes } = useStyles()
  const history = useHistory()

  /** 特徴量データグループIDを取得 */
  const getFeatureDataGroupIdPath = async (
    userGroupId: string,
    featureDataId: string
  ) => {
    const featureDataGroupId = await getFeatureDataGroupId(
      userGroupId,
      featureDataId
    )
    return featureDataGroupId
  }

  useEffect(() => {
    return () => {
      props.unsubscribe()
    }
  }, [])

  useEffect(() => {
    props.getFeatureDataGeneratingList()
    return () => {
      props.clearFeatureDataGeneratingListState()
    }
  }, [props.auth.customClaims.userGroupId])

  const {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
  } = useTable(props)

  /** 検索ワードがある場合日付ソートをさせない */
  const tableHeader = useMemo(() => {
    if (props.domainData.featureDataGeneratingDisplayCondition.searchValue) {
      return TABLE_HEADERS.map((header) => {
        return { ...header, sortable: false }
      })
    }

    return TABLE_HEADERS
  }, [props.domainData.featureDataGeneratingDisplayCondition.searchValue])

  /** テーブルに表示するデータセットのJSXの２次元配列 */
  const tableRows = useMemo(() => {
    const convertedList: FeatureDataGenerating[] =
      props.domainData.currentFeatureDataGeneratingList.map(
        (featureDataGenerating: FeatureDataGenerating) => {
          return {
            mlPipelineId: featureDataGenerating.mlPipelineId,
            mlPipelineName: featureDataGenerating.mlPipelineName,
            algorithmName: featureDataGenerating.algorithmName,
            progress: featureDataGenerating.progress,
            time: '',
            startedAt: featureDataGenerating.startedAt,
            endedAt: featureDataGenerating.endedAt,
            dataset: {
              datasetId: featureDataGenerating.dataset.datasetId,
              datasetName: featureDataGenerating.dataset.datasetName,
            },
            featureData: {
              featureDataId: featureDataGenerating.featureData.featureDataId,
              featureDataName:
                featureDataGenerating.featureData.featureDataName,
            },
            accountId: featureDataGenerating.accountId,
          }
        }
      )

    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        switch (key) {
          case 'progress':
            return (
              <StatusProgressBar
                status={convertProgressWord(
                  data.progress.transactionStatus,
                  true
                )}
                progressRate={data.progress.progressRate}
                progressColor={convertProgressColor(
                  data.progress.transactionStatus
                )}
              />
            )
          case 'mlPipelineId':
            return isUndefined(value) ? (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            ) : (
              <TooltipLink
                data-testid={`feature-data-generating-${value}`}
                title={value}
                placement='right-start'
                onClick={() => {
                  history.push(`feature-data-generatings/${value}`)
                }}
              />
            )
          case 'featureData':
            return value.featureDataName ? (
              <MuiLink
                className={classes.link}
                underline='none'
                onClick={async () => {
                  const featureDataGroupId = await getFeatureDataGroupIdPath(
                    props.auth.customClaims.userGroupId,
                    value.featureDataId
                  )
                  return history.push(
                    `/feature-data-groups/${featureDataGroupId}/feature-data/${value.featureDataId}`
                  )
                }}
                data-testid={`feature-data-detail-${value.featureDataId}`}
              >
                <Typography key={key}>{value.featureDataName}</Typography>
              </MuiLink>
            ) : (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          case 'dataset':
            return value.datasetName ? (
              <MuiLink
                className={classes.link}
                underline='none'
                onClick={() => {
                  history.push(`datasets/${value.datasetId}`)
                }}
                data-testid={`dataset-detail-${value.datasetId}`}
              >
                <Typography key={key}>{value.datasetName}</Typography>
              </MuiLink>
            ) : (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          case 'algorithmName':
            return isUndefined(value) ? (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography align='center'>
                  {TABLE_CELL_NOT_APPLICABLE}
                </Typography>
              </Box>
            ) : (
              <Tooltip title={value} placement='bottom'>
                <Typography align='center'>{value}</Typography>
              </Tooltip>
            )
          case 'startedAt':
          case 'endedAt':
            // 終了日時がundefinedまたはUnixTime0の場合N/Aとする
            if (isUndefined(value)) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            } else if (key === 'endedAt' && value.seconds === 0) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
            return (
              <Typography key={key}>
                {formatDateTimeSec(
                  isUndefined(value) ? undefined : value.toDate()
                )}
              </Typography>
            )
          case 'time':
            // 成功、失敗かつ終了時刻が存在しないまたは、UnixTime0の場合は、N/Aとする
            if (
              (['Completed', 'Failed'].includes(
                data.progress.transactionStatus
              ) &&
                !data.endedAt) ||
              data.endedAt?.seconds === 0
            ) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
            return !isUndefined(data.startedAt) &&
              !isUndefined(data.endedAt) ? (
              <Typography key={key}>
                {formatTimeSecByMillSecond(
                  data.endedAt.toDate().getTime() -
                    data.startedAt.toDate().getTime()
                )}
              </Typography>
            ) : (
              <RunningTimeLabel startedAt={data.startedAt.toDate()} />
            )
          case 'mlPipelineName':
            if (value) {
              return (
                <Tooltip title={value} placement='bottom'>
                  <Typography>{value}</Typography>
                </Tooltip>
              )
            } else {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
          case 'accountId':
            return (
              <Tooltip title={value} placement='bottom'>
                <Typography>{value.substring(0, 8)}</Typography>
              </Tooltip>
            )
          default:
            return (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
        }
      })
    )
  }, [props.domainData.currentFeatureDataGeneratingList])

  /** テーブル */
  const featureDataGeneratingTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.featureDataGeneratingDisplayCondition.displayNumber
        }
        headers={tableHeader}
        rows={tableRows}
        totalCount={
          props.domainData.featureDataGeneratingDisplayCondition.totalCount
        }
        loading={props.appState.inProgress}
        tableHeight={TABLE_HEADER_HEIGHT + 10 * STATUS_PROGRESS_BAR_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={props.domainData.featureDataGeneratingDisplayCondition.pageNumber}
        sortOrder={{
          key: props.domainData.featureDataGeneratingDisplayCondition.sortKey,
          order:
            props.domainData.featureDataGeneratingDisplayCondition.sortOrder,
        }}
        includesStatusProgressBar={true}
        displayNoneRadio={true}
        onClickOrderChange={(key: string) => changeTableSortOrder(key)}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.domainData.featureDataGeneratingDisplayCondition,
    props.appState.inProgress,
  ])

  return (
    <>
      <Box className={classes.stepContainer}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            {
              name: '特徴量データ生成一覧',
              path: 'feature-data-generatings',
            },
          ]}
        />
        <div className={classes.postAddButton}>
          <Box display='flex'>
            <TrainingIcon
              className={classes.pageIcon}
              data-testid='featureDataGeneratingListTitleIcon'
            />
            <Typography component='div'>
              <h2 data-testid='feature-data-generating-list-title'>
                特徴量データ生成
              </h2>
            </Typography>
          </Box>
          <Box display='flex'>
            <Add />
            <Link
              data-testid='feature-data-generating-entry'
              to={`${url}/entry`}
            >
              <Typography>新規生成指示</Typography>
            </Link>
          </Box>
        </div>
        <div className={classes.searchForm}>
          <div className={classes.searchField}>
            <SearchInput
              placeholder='キーワード (ML Pipeline ID)'
              value={
                props.domainData.featureDataGeneratingDisplayCondition
                  .searchValue
              }
              onChangeValue={(event) =>
                handleChangeSearchValue(event.target.value)
              }
              onClickSearch={() => searchTableContent()}
              onPressEnter={() => searchTableContent()}
            />
          </div>
        </div>
        {featureDataGeneratingTable}
      </Box>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

export const FeatureDataGeneratingListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeatureDataGeneratingList))

const useTable = (props: Props) => {
  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    /** 検索ワードを変更 */
    props.setListDisplayCondition({
      ...props.domainData.featureDataGeneratingDisplayCondition,
      searchValue: value,
    })
  }
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    /** 表示数を変更 */
    props.setListDisplayCondition({
      ...props.domainData.featureDataGeneratingDisplayCondition,
      pageNumber: 0,
      displayNumber: displayNumber,
    })

    props.getFeatureDataGeneratingList()
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.featureDataGeneratingDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.featureDataGeneratingDisplayCondition.sortOrder ===
        'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })

    props.getFeatureDataGeneratingList()
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.featureDataGeneratingDisplayCondition,
      pageNumber: pageNumber,
    })

    props.getFeatureDataGeneratingList()
  }

  /** 検索の実行 */
  const searchTableContent = () => {
    props.setListDisplayCondition({
      ...props.domainData.featureDataGeneratingDisplayCondition,
      pageNumber: 0,
    })

    props.getFeatureDataGeneratingList()
  }

  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
  }
}
