import React, { useRef, useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { FileRejection } from 'react-dropzone'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { State } from 'state/store'
import {
  ModelGroupEntryAction,
  modelGroupEntryActions,
  modelGroupEntryOperations,
  ModelGroupEntryStateKindArray,
  ModelGroupEntryStateKind,
} from 'state/ducks/modelGroupEntry'
import {
  ClassSet,
  ClassSetDisplayCondition,
  ToastInfo,
} from 'state/ducks/modelGroupEntry/types'

import { isUndefined } from 'utils/typeguard'
import {
  CommonCompleteDialog,
  CommonStepper,
  CustomTrainingPageParagraph,
  GlobalLoading,
  showToast,
  Toast,
  FileUploadViewer,
  BreadcrumbsComponent,
  SelectableTable,
  SearchInput,
  SelectableTableHeader,
  AccordionLabel,
} from 'views/components'
import { Timestamp } from '@firebase/firestore'
import { formatDateTimeSec } from 'views/components/utils/date'
import { useTheme } from '@mui/material/styles'
import { Tooltip } from '@mui/material'
import { getUserGroupKindList } from 'views/containers/utils'

/** How to simplify the description of react-redux. */
const mapStateToProps = (state: State) => ({
  ...state.pages.modelGroupEntryState,
  ...state.app.domainData.authedUser,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, ModelGroupEntryAction>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** モデルグループ作成の状態をクリア */
  clearModelGroupEntryState: () =>
    dispatch(modelGroupEntryActions.clearModelGroupEntryState()),
  /** モデルグループ作成のアルゴリズムをセット */
  setSelectedAlgorithm: (selectedAlgorithm: string) =>
    dispatch(modelGroupEntryActions.setSelectedAlgorithm(selectedAlgorithm)),
  /** モデルグループ名をセット */
  setModelGroupMetadataName: (modelGroupName: string) =>
    dispatch(modelGroupEntryActions.setModelGroupMetadataName(modelGroupName)),
  /** モデルグループの概要をセット */
  setModelGroupOverView: (overView: string) =>
    dispatch(modelGroupEntryActions.setModelGroupOverView(overView)),
  /** モデルグループのアイコンをセット */
  setModelGroupIcon: (modelGroupIcon: File | undefined) =>
    dispatch(modelGroupEntryActions.setModelGroupIcon(modelGroupIcon)),
  /** モデルグループのremarksをセット */
  setModelGroupRemarks: (remarks: string) =>
    dispatch(modelGroupEntryActions.setModelGroupRemarks(remarks)),
  /** モデルグループ作成の進む戻る */
  setModelGroupState: (step: ModelGroupEntryStateKind) =>
    dispatch(modelGroupEntryActions.setModelGroupState(step)),
  /** アルゴリズム選択状態 */
  setAlgorithmSubState: (algorithmSubState: 'Unselected' | 'Selected') =>
    dispatch(modelGroupEntryActions.setAlgorithmSubState(algorithmSubState)),
  /** メタデータ入力状態 */
  setMetadataSubState: (metadataSubState: 'EmptyRequired' | 'InputRequired') =>
    dispatch(modelGroupEntryActions.setMetadataSubState(metadataSubState)),
  /** モデルグループ作成 */
  createModelGroup: () =>
    dispatch(modelGroupEntryOperations.createModelGroup()),
  /** トーストの情報をセット */
  setToastInfo: (toastInfo?: ToastInfo) =>
    dispatch(modelGroupEntryActions.setToastInfo(toastInfo)),
  /** 選択されたモデル種別をセット */
  setSelectedTrainedModelKind: (modelKind?: 'Custom' | 'Specified') =>
    dispatch(modelGroupEntryActions.setSelectedTrainedModelKind(modelKind)),
  /** クラスセット一覧を取得 */
  getClassSets: () => dispatch(modelGroupEntryOperations.getClassSets()),
  /** クラスセット一覧をクリア */
  clearClassSets: () => dispatch(modelGroupEntryActions.clearClassSets()),
  /** クラスセットの表示条件の変更 */
  setClassSetDisplayCondition: (classSetCondition: ClassSetDisplayCondition) =>
    dispatch(
      modelGroupEntryActions.setClassSetDisplayCondition(classSetCondition)
    ),
  /** クラスセットの表示条件をクリア */
  clearClassSetDisplayCondition: () =>
    dispatch(modelGroupEntryActions.clearClassSetDisplayCondition()),
  /** 選んだリストをセットする(ClassSet) */
  setSelectedClassSet: (data?: ClassSet) =>
    dispatch(modelGroupEntryOperations.setSelectedClassSet(data)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const HEADER_HEIGHT = 64
const FOOTER_HEIGHT = 100
const HEADER_MARGIN_BOTTOM = 24
const STEPPER_HEIGHT = 186
const BREADCRUMBS_HEIGHT = 40

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

/** テーブルのヘッダー */
const CLASS_SETS_TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'classSetId',
    title: 'クラスセットID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'classSetName',
    title: 'クラスセット名',
    width: 250,
    sortable: false,
    position: 'center',
  },
  {
    id: 'classList',
    title: 'クラスセット',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'createdAt',
    title: '登録日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'classSetRemarks',
    title: 'Remarks',
    width: 300,
    sortable: false,
    position: 'left',
  },
]

/** モデル種別一覧 */
const MODEL_KIND_LIST = [
  {
    kind: 'Custom',
    name: 'カスタム',
  },
  {
    kind: 'Specified',
    name: 'スペシファイド',
  },
]

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
  },
  head: {
    height: STEPPER_HEIGHT,
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${HEADER_MARGIN_BOTTOM}px - ${STEPPER_HEIGHT}px - ${FOOTER_HEIGHT}px - ${BREADCRUMBS_HEIGHT}px)`,
    overflowY: 'auto',
  },
  footerButtons: {
    height: FOOTER_HEIGHT,
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  stepper: {
    margin: theme.spacing(3),
    marginBottom: '0px',
  },
  stepContainer: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    marginTop: theme.spacing(1),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
  textField: {
    width: '100%',
    color: '#000 !important',
  },
  algorithmSelectBox: {
    width: '100%',
  },
  stepTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  toastItemText: {
    whiteSpace: 'nowrap',
  },
  modelGroupParamsTitle: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  modelGroupParamsContent: {
    width: '100%',
  },
  iconDropZone: {
    width: '200px',
    position: 'relative',
  },
  floatButtons: {
    zIndex: 1,
    position: 'absolute',
    color: theme.palette.common.black,
  },
  menuIcon: {
    top: theme.spacing(-1),
    right: theme.spacing(-8),
  },
  deleteItem: { color: theme.palette.secondary.main },
  iconPreview: {
    objectFit: 'contain',
    display: 'block',
    width: '200px',
    height: '200px',
  },
  iconNoImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #333',
    width: '200px',
    height: '200px',
  },
  notApplicable: {
    color: theme.palette.text.secondary,
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
}))

/** ステップの名称 */
const STEP_NAMES = ['アルゴリズム', 'メタデータ', '確認']
/** テーブルのセルのデータ未存在時の表示 */
const MODEL_GROUP_PARAM_NOT_APPLICABLE = 'N/A'

type AlgorithmSpecificContentProps = Props & {
  classSetToDetail: (classSet?: ClassSet) =>
    | {
        ['クラスセットID']: string
        ['クラスセット名']: string
        ['クラス名リスト']: string
        ['登録日時']: string
        ['備考']: string
      }
    | undefined
}
const AlgorithmSpecificContent: React.FC<AlgorithmSpecificContentProps> = (
  props: AlgorithmSpecificContentProps
) => {
  if (props.domainData.selectedClassSet != null) {
    return (
      <Box component={Paper} my={2} p={'24px 32px 32px'} width='100%'>
        <Typography>クラスセット</Typography>
        <Box mt={1}>
          <InputLabel shrink>クラスセット名</InputLabel>
          <Box mt={1}>
            <AccordionLabel
              label={props.domainData.selectedClassSet?.classSetName ?? ''}
              details={props.classSetToDetail(
                props.domainData.selectedClassSet
              )}
              prefix={'-'}
              delimiter={':'}
            />
          </Box>
        </Box>
      </Box>
    )
  }
  return <></>
}

const ModelGroupEntry: React.FC<Props> = (props: Props) => {
  const history = useHistory()
  const { classes } = useStyles()

  /** コンテンツエリア（ステッパー、戻る/次へ表示エリア以外） */
  const contentRef = useRef<HTMLDivElement>(null)

  /** step */
  const [currentStep, setCurrentStep] = useState<number>(0)

  /** 学習アルゴリズムのサブステップ */
  const [trainingAlgorithmSubStep, setTrainingAlgorithmSubStep] =
    useState(false)

  const [canShowNameError, setCanShowNameError] = useState(false)
  const getErrorStatus = (canShowErrorState: boolean, prop?: string) =>
    canShowErrorState && (!prop || prop === '')

  /** fileのbase64文字列を保持 */
  const [fileToBase64, setFileToBase64] = useState<string>('')

  useEffect(() => {
    // アルゴリズムの一覧を取得
    props.setSelectedAlgorithm(props.algorithms[0].algorithmId)
    return () => {
      props.clearModelGroupEntryState()
    }
  }, [])

  /** currentStepが変更されらstepを変更 */
  useEffect(() => {
    props.setModelGroupState(ModelGroupEntryStateKindArray[currentStep])
  }, [currentStep])

  /** アルゴリズム選択時に状態を変更 */
  useEffect(() => {
    if (props.domainData.selectedAlgorithm) {
      props.setAlgorithmSubState('Selected')
    } else {
      props.setAlgorithmSubState('Unselected')
    }
  }, [props.domainData.selectedAlgorithm])

  /** メタデータの名前変更時、モデル種別変更時に状態を変更 */
  useEffect(() => {
    if (
      props.domainData.modelGroupMetaData.modelGroupName &&
      props.domainData.selectedTrainedModelKind
    ) {
      props.setMetadataSubState('InputRequired')
    } else {
      props.setMetadataSubState('EmptyRequired')
    }
  }, [
    props.domainData.modelGroupMetaData.modelGroupName,
    props.domainData.selectedTrainedModelKind,
  ])

  /** メタデータのアイコン変更時に状態を変更 */
  useEffect(() => {
    if (!props.domainData.modelGroupMetaData.modelGroupIcon) {
      return setFileToBase64('')
    }
    const reader = new FileReader()
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e && e.target && e.target.result) {
        setFileToBase64(e.target.result as string)
      }
    }
    reader.readAsDataURL(props.domainData.modelGroupMetaData.modelGroupIcon)
  }, [props.domainData.modelGroupMetaData.modelGroupIcon])

  /** エラートースト */
  useEffect(() => {
    if (props.appState.toastInfo) {
      showErrorToast(props.appState.toastInfo.title, [])
      props.setToastInfo(undefined)
    }
  }, [props.appState.toastInfo])

  /** 次へボタンの活性非活性 */
  const enableNextButton = useMemo(() => {
    switch (props.appState.modelGroupEntryStateKind) {
      case 'AlgorithmState':
        return (
          props.appState.modelGroupEntrySubStateKind.algorithmSubState ===
          'Selected'
        )
      case 'MetaDataState':
        return (
          props.appState.modelGroupEntrySubStateKind.metaDataSubState ===
          'InputRequired'
        )
      case 'ExecuteState':
        return true
    }
  }, [
    props.appState.modelGroupEntryStateKind,
    props.appState.modelGroupEntrySubStateKind,
  ])

  const showErrorToast = (fileName: string, messages: string[]) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div data-testid='modelUploadErrorToastFileName'>{`ファイル名: ${fileName}`}</div>
        <div>
          {messages.map((message) => (
            <li key={message}>{message}</li>
          ))}
        </div>
      </div>
    )

  /** ファイル削除 */
  const modelGroupIconDelete = () => {
    props.setModelGroupIcon(undefined)
  }

  const fileUploadErrors = (fileRejections: FileRejection[]) =>
    fileRejections.forEach((errorFile) => {
      const errorFileName = errorFile.file.name
      const fileErrors = errorFile.errors
      const errorMessage: string[] = []
      fileErrors.forEach((errorFileMessage) => {
        switch (errorFileMessage.code) {
          case 'file-too-large': {
            errorMessage.push('ファイルサイズが不正です')
            break
          }
          case 'file-invalid-type': {
            errorMessage.push('選択されたファイルの形式が不正です')
            break
          }
          default:
            break
        }
      })
      showErrorToast(errorFileName, errorMessage)
    })

  const onClickPrevButton = () => {
    switch (currentStep) {
      case 0:
        if (trainingAlgorithmSubStep) {
          setTrainingAlgorithmSubStep(false)
          props.setSelectedClassSet(undefined)
          props.clearClassSets()
          props.clearClassSetDisplayCondition()
          setTableSearchValue('')
        }
        break
      default:
        setCurrentStep(currentStep - 1)
        break
    }
  }

  const onClickNextButton = () => {
    const selectedAlgorithmId = props.domainData.selectedAlgorithm ?? ''
    const objectClassificationAlgorithmIds = props.algorithms
      .filter(
        (algorithm) => algorithm.algorithmPurpose === 'ObjectClassification'
      )
      .map((algorithm) => algorithm.algorithmId)

    switch (currentStep) {
      case 0:
        if (
          objectClassificationAlgorithmIds.includes(selectedAlgorithmId) &&
          !trainingAlgorithmSubStep
        ) {
          setTrainingAlgorithmSubStep(true)
        } else {
          setCurrentStep(currentStep + 1)
        }
        break
      case 2:
        props.createModelGroup()
        break
      default:
        setCurrentStep(currentStep + 1)
        break
    }
  }

  /** クラスセット一覧取得 */
  useEffect(() => {
    if (
      props.appState.modelGroupEntryStateKind === 'AlgorithmState' &&
      trainingAlgorithmSubStep
    ) {
      props.getClassSets()
    }
  }, [props.appState.modelGroupEntryStateKind, trainingAlgorithmSubStep])

  const {
    classSetRows,
    searchedClassSetsLength,
    tableSearchValue,
    selectedClassSetIndex,
    handleChangeDisplayNumber,
    selectUserGroupKind,
    searchTableContent,
    setTableSearchValue,
    selectClassSetTableRadio,
    changeTableSortOrder,
    pageChange,
    userGroupKindList,
    classSetToDetail,
  } = useClassSetsTable(props)

  /** Stepによってコンテンツを切り替える */
  const getStepContent = (props: Props): JSX.Element => {
    switch (props.appState.modelGroupEntryStateKind) {
      case 'AlgorithmState':
        if (isUndefined(props.algorithms)) {
          return <></>
        }

        if (trainingAlgorithmSubStep) {
          return (
            <div className={classes.stepContainer}>
              <CustomTrainingPageParagraph>
                <Box mt={2}>
                  <FormControl
                    variant='outlined'
                    className={classes.algorithmSelectBox}
                  >
                    <InputLabel id='class-set-user-group-kind'>
                      データ種別
                    </InputLabel>
                    <Select
                      labelId='class-set-user-group-kind-label'
                      id='class-set-user-group-kind-outlined'
                      value={
                        props.domainData.classSetDisplayCondition
                          .selectedUserGroupKind
                      }
                      onChange={(e) =>
                        selectUserGroupKind(
                          e.target.value as 'UserGroup' | 'SharedUserGroup'
                        )
                      }
                      label='Select User Group Kind'
                    >
                      {userGroupKindList.map((kind) => (
                        <MenuItem
                          data-testid={kind.kind}
                          value={kind.kind}
                          key={kind.kind}
                        >
                          {kind.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box mt={2}>
                  <div className={classes.searchForm}>
                    <div className={classes.searchField}>
                      <SearchInput
                        placeholder='検索 (クラスセットIDなど)'
                        value={tableSearchValue}
                        onChangeValue={(event) =>
                          setTableSearchValue(event.target.value)
                        }
                        onClickSearch={() => searchTableContent()}
                        onPressEnter={() => searchTableContent()}
                      />
                    </div>
                  </div>
                </Box>
                <SelectableTable
                  headers={CLASS_SETS_TABLE_HEADERS}
                  rows={classSetRows}
                  displayNumber={
                    props.domainData.classSetDisplayCondition.displayNumber
                  }
                  totalCount={
                    searchedClassSetsLength ?? props.domainData.classSets.length
                  }
                  tableHeight={600}
                  selectedRowNumber={selectedClassSetIndex}
                  fixedColumnNumber={0}
                  page={props.domainData.classSetDisplayCondition.pageNumber}
                  sortOrder={{
                    key: props.domainData.classSetDisplayCondition.sortKey,
                    order: props.domainData.classSetDisplayCondition.sortOrder,
                  }}
                  displayNoneRadio={false}
                  onChangeDisplayNumber={(displayNumber: number) =>
                    handleChangeDisplayNumber(displayNumber)
                  }
                  onClickRadio={(row: number) => selectClassSetTableRadio(row)}
                  onClickOrderChange={(key: string) =>
                    changeTableSortOrder(key)
                  }
                  onClickPageChange={(pageNumber: number) =>
                    pageChange(pageNumber)
                  }
                />
              </CustomTrainingPageParagraph>
            </div>
          )
        }
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph>
              <FormControl
                variant='outlined'
                className={classes.algorithmSelectBox}
              >
                <InputLabel id='modelGroupEntry'>アルゴリズム</InputLabel>
                <Select
                  data-testid='select'
                  labelId='modelGroupEntry-label'
                  id='modelGroupEntry-outlined'
                  defaultValue={props.algorithms[0].algorithmId}
                  value={props.domainData.selectedAlgorithm}
                  onChange={(e: SelectChangeEvent<string>) =>
                    props.setSelectedAlgorithm(e.target.value as string)
                  }
                  label='Select Algorithm'
                >
                  {props.algorithms.map((algorithm) => (
                    <MenuItem
                      data-testid={algorithm.algorithmId}
                      value={algorithm.algorithmId}
                      key={algorithm.algorithmId}
                    >
                      {algorithm.metadata.name.ja}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CustomTrainingPageParagraph>
          </div>
        )
      case 'MetaDataState':
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph>
              <div className={classes.modelGroupParamsTitle}>
                <Typography>モデルグループ名</Typography>
              </div>
              <TextField
                className={classes.modelGroupParamsContent}
                value={props.domainData.modelGroupMetaData.modelGroupName}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => props.setModelGroupMetadataName(e.target.value)}
                onBlur={() => setCanShowNameError(true)}
                error={getErrorStatus(
                  canShowNameError,
                  props.domainData.modelGroupMetaData.modelGroupName
                )}
                helperText={
                  getErrorStatus(
                    canShowNameError,
                    props.domainData.modelGroupMetaData.modelGroupName
                  ) && ''
                }
                variant='outlined'
                rows={1}
                type={'text'}
                required
                data-testid={'model-group-name-input'}
              />
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph title={'モデル種別'}>
              <FormControl
                variant='outlined'
                className={classes.algorithmSelectBox}
              >
                <InputLabel id='modelGroupEntryInput'>モデル種別</InputLabel>
                <Select
                  data-testid='select'
                  labelId='modelGroupEntryInput-label'
                  id='modelGroupEntryInput-outlined'
                  value={props.domainData.selectedTrainedModelKind}
                  onChange={(e: SelectChangeEvent<'Custom' | 'Specified'>) =>
                    props.setSelectedTrainedModelKind(
                      e.target.value as 'Custom' | 'Specified'
                    )
                  }
                  label='Select TrainedModelKind'
                >
                  {MODEL_KIND_LIST.map((kind) => (
                    <MenuItem
                      data-testid={`select${kind.kind}`}
                      value={kind.kind}
                      key={kind.name}
                    >
                      {kind.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph>
              <div className={classes.modelGroupParamsTitle}>
                <Typography>概要</Typography>
              </div>
              <TextField
                className={classes.modelGroupParamsTitle}
                value={props.domainData.modelGroupMetaData.overView}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => props.setModelGroupOverView(e.target.value)}
                variant='outlined'
                minRows={1}
                multiline
                type={'text'}
                data-testid={'over-view-input'}
              />
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph>
              <div className={classes.modelGroupParamsTitle}>
                <Typography>アイコン</Typography>
              </div>
              <div className={classes.iconDropZone}>
                <FileUploadViewer
                  fileType='.jpg,.png'
                  maxSize={256000}
                  file={props.domainData.modelGroupMetaData.modelGroupIcon}
                  fileDeleteAction={modelGroupIconDelete}
                  acceptFileOperation={(files: File[]) =>
                    props.setModelGroupIcon(files[0])
                  }
                  rejectedFileOperation={(fileRejections: FileRejection[]) =>
                    fileUploadErrors(fileRejections)
                  }
                  data-testid={'model-group'}
                />
              </div>
            </CustomTrainingPageParagraph>
            <CustomTrainingPageParagraph>
              <div className={classes.modelGroupParamsTitle}>
                <Typography>備考</Typography>
              </div>
              <TextField
                className={classes.modelGroupParamsContent}
                value={props.domainData.modelGroupMetaData.remarks}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => props.setModelGroupRemarks(e.target.value)}
                variant='outlined'
                minRows={1}
                multiline
                type={'text'}
                data-testid={'remarks-input'}
              />
            </CustomTrainingPageParagraph>
          </div>
        )
      case 'ExecuteState':
        return (
          <div className={classes.stepContainer}>
            <Box component={Paper} mt={1} p={'24px 32px 32px'} mb={1}>
              <Typography>モデルグループ情報</Typography>
              <Box mt={1}>
                <TextField
                  id='algorithm'
                  className={classes.textField}
                  label='アルゴリズム'
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    props.domainData.selectedAlgorithm
                      ? props.algorithms.find(
                          (algorithm) =>
                            algorithm.algorithmId ===
                            props.domainData.selectedAlgorithm
                        )?.metadata.name.ja
                      : ''
                  }
                  key={props.domainData.selectedAlgorithm}
                  variant='standard'
                />
              </Box>
              <Box mt={2}>
                <TextField
                  id='model-group-name'
                  className={classes.textField}
                  label={'モデルグループ名'}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={props.domainData.modelGroupMetaData.modelGroupName}
                  key={props.domainData.modelGroupMetaData.modelGroupName}
                  variant='standard'
                />
              </Box>
              <Box mt={2}>
                <TextField
                  id='over-view'
                  className={clsx(classes.textField, {
                    [classes.notApplicable]:
                      !props.domainData.modelGroupMetaData.overView,
                  })}
                  label={'概要'}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    props.domainData.modelGroupMetaData.overView ??
                    MODEL_GROUP_PARAM_NOT_APPLICABLE
                  }
                  key={props.domainData.modelGroupMetaData.overView}
                  variant='standard'
                />
              </Box>
              <Box mt={2}>
                <InputLabel shrink>アイコン</InputLabel>
                {fileToBase64 ? (
                  <Box mt={1}>
                    <img
                      src={fileToBase64}
                      className={`${classes.iconPreview} ${classes.notApplicable}`}
                    />
                  </Box>
                ) : (
                  <Box mt={1} className={classes.iconNoImage}>
                    <Typography>No image</Typography>
                  </Box>
                )}
              </Box>
              <Box mt={2}>
                <TextField
                  id='model-group-remarks'
                  className={clsx(classes.textField, {
                    [classes.notApplicable]:
                      !props.domainData.modelGroupMetaData.overView,
                  })}
                  label={'備考'}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    props.domainData.modelGroupMetaData.remarks ??
                    MODEL_GROUP_PARAM_NOT_APPLICABLE
                  }
                  key={props.domainData.modelGroupMetaData.remarks}
                  variant='standard'
                />
              </Box>
            </Box>
            <AlgorithmSpecificContent
              {...props}
              classSetToDetail={classSetToDetail}
            />
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <div className={classes.container}>
        <Box pl={7}>
          <BreadcrumbsComponent
            breadcrumbsPath={[
              {
                name: 'モデルグループ一覧',
                path: 'model-groups',
              },
              {
                name: 'モデルグループ作成',
                path: 'entry',
              },
            ]}
          />
        </Box>
        <Toast containerOptions={{ limit: 20 }}>
          <div className={classes.head}>
            <h2
              className={classes.pageTitle}
              data-testid='model-group-entry-title'
            >
              モデルグループ
            </h2>
            <div className={classes.stepper}>
              <CommonStepper
                stepLabels={STEP_NAMES}
                activeStepIndex={currentStep}
              />
            </div>
          </div>
          <div className={classes.content} ref={contentRef}>
            {getStepContent(props)}
          </div>
          <div className={classes.footerButtons}>
            <div className={classes.footer}>
              {!(currentStep === 0 && !trainingAlgorithmSubStep) ? (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={onClickPrevButton}
                  className={classes.leftButton}
                >
                  {'戻る'}
                </Button>
              ) : (
                <div></div>
              )}
              <Button
                data-testid='next-step'
                variant='contained'
                color='primary'
                disabled={!enableNextButton}
                onClick={onClickNextButton}
                className={classes.rightButton}
              >
                {props.appState.modelGroupEntryStateKind === 'ExecuteState'
                  ? '作成'
                  : '次へ'}
              </Button>
            </div>
          </div>
          <CommonCompleteDialog
            open={
              !isUndefined(props.domainData.executedModeGroupId) &&
              props.appState.modelGroupEntrySubStateKind.executeSubState ===
                'Executed'
            }
            value={props.domainData.executedModeGroupId ?? ''}
            handleClose={() => history.push('/model-groups')}
            label={'モデルグループID'}
            dialogText={'正常にモデルグループを作成しました。'}
            data-testid={'exec-model-group-id'}
          />
          <GlobalLoading open={props.appState.inProgress} />
        </Toast>
      </div>
    </>
  )
}

export const ModelGroupEntryPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ModelGroupEntry))

const useClassSetsTable = (props: Props) => {
  const globalTheme = useTheme()
  const [searchedClassSetsLength, setSearchedClassSetsLength] = useState<
    number | undefined
  >(0)
  /** 検索ワードを一時保持 */
  const [tableSearchValue, setTableSearchValue] = useState<string>('')

  /** クラスセットの表示対象のセッティングデータ */
  const adjustClassSetTableRow = useMemo(() => {
    let newClassSetArray = props.domainData.classSets
    /** 検索ワードから検索 */
    if (props.domainData.classSetDisplayCondition.searchValue) {
      newClassSetArray = newClassSetArray.filter(
        (item) =>
          item.classSetId ===
            props.domainData.classSetDisplayCondition.searchValue ||
          item.classSetName ===
            props.domainData.classSetDisplayCondition.searchValue ||
          item.classSetId.startsWith(
            props.domainData.classSetDisplayCondition.searchValue
          ) ||
          item.classSetName.startsWith(
            props.domainData.classSetDisplayCondition.searchValue
          )
      )
      /** 検索後の配列の長さをセット */
      setSearchedClassSetsLength(newClassSetArray.length)
    } else {
      /** 検索後の配列の長さをセット */
      setSearchedClassSetsLength(undefined)
    }
    /** ソートキー、ソートオーダーによる並び替え */
    if (props.domainData.classSetDisplayCondition.sortKey === 'createdAt') {
      newClassSetArray = newClassSetArray.sort((item, item2) => {
        return (
          ((item2.createdAt ? item2.createdAt : Timestamp.fromMillis(0))
            .toDate()
            .getTime() -
            (item.createdAt ? item.createdAt : Timestamp.fromMillis(0))
              .toDate()
              .getTime()) *
          (props.domainData.classSetDisplayCondition.sortOrder === 'asc'
            ? -1
            : 1)
        )
      })
    }

    /** 表示するセッティングの整形 */
    return newClassSetArray.slice(
      props.domainData.classSetDisplayCondition.displayNumber *
        props.domainData.classSetDisplayCondition.pageNumber,
      (props.domainData.classSetDisplayCondition.pageNumber + 1) *
        props.domainData.classSetDisplayCondition.displayNumber
    )
  }, [props.domainData.classSetDisplayCondition, props.domainData.classSets])

  /** テーブルに表示するクラスセットのJSXの２次元配列 */
  const classSetRows = useMemo(() => {
    const dataRows = adjustClassSetTableRow.map((classSet) => ({
      classSetId: classSet.classSetId,
      classSetName: classSet.classSetName,
      classList: classSet.classList.join(', '),
      createdAt: classSet.createdAt
        ? formatDateTimeSec(classSet.createdAt.toDate())
        : '',
      classSetRemarks: classSet.classSetRemarks,
    }))

    return dataRows.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '' || isUndefined(value)) {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'classSetId') {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography data-testid={`class-set-${value}`}>
                {value.substring(0, 8)}
              </Typography>
            </Tooltip>
          )
        } else {
          return <Typography key={key}>{value}</Typography>
        }
      })
    )
  }, [adjustClassSetTableRow])

  useEffect(() => {
    if (isUndefined(props.domainData.selectedClassSet)) {
      props.setSelectedClassSet(adjustClassSetTableRow[0])
    }
  }, [adjustClassSetTableRow])

  /** 検索処理 */
  const searchTableContent = () => {
    props.setClassSetDisplayCondition({
      ...props.domainData.classSetDisplayCondition,
      searchValue: tableSearchValue,
      pageNumber: 0,
    })
  }

  /** クラスセット選択のユーザーグループ種別の選択 */
  const selectUserGroupKind = (
    selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
  ) => {
    props.clearClassSets()
    props.setClassSetDisplayCondition({
      ...props.domainData.classSetDisplayCondition,
      pageNumber: 0,
      displayNumber: 10,
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      selectedUserGroupKind: selectedUserGroupKind,
    })
    props.getClassSets()
  }

  const selectedClassSetIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedClassSet)) return -1
    return adjustClassSetTableRow.findIndex(
      (classSet) =>
        classSet.classSetId === props.domainData.selectedClassSet?.classSetId
    )
  }, [adjustClassSetTableRow, props.domainData.selectedClassSet])

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    const maxPageNumber =
      Math.ceil(props.domainData.classSets.length / displayNumber) - 1
    if (props.domainData.classSetDisplayCondition.pageNumber <= maxPageNumber) {
      /** 表示数を変更 */
      props.setClassSetDisplayCondition({
        ...props.domainData.classSetDisplayCondition,
        displayNumber: displayNumber,
      })
    } else {
      /** 表示数を変更 */
      props.setClassSetDisplayCondition({
        ...props.domainData.classSetDisplayCondition,
        pageNumber: maxPageNumber,
        displayNumber: displayNumber,
      })
    }
  }

  /** クラスセットのラジオボタン押下時処理 */
  const selectClassSetTableRadio = (row: number) => {
    const classSet = adjustClassSetTableRow[row]
    props.setSelectedClassSet(classSet)
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    props.setClassSetDisplayCondition({
      ...props.domainData.classSetDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.classSetDisplayCondition.sortKey === key
          ? props.domainData.classSetDisplayCondition.sortOrder === 'asc'
            ? 'desc'
            : 'asc'
          : props.domainData.classSetDisplayCondition.sortOrder,
    })
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setClassSetDisplayCondition({
      ...props.domainData.classSetDisplayCondition,
      pageNumber: pageNumber,
    })
  }

  const userGroupKindList = getUserGroupKindList(
    props.auth.customClaims.sharedList
  )

  /** 学習アルゴリズム詳細変換 */
  const trainingAlgorithmToDetail = (algorithm: {
    selectedAlgorithmId: string
    selectedAlgorithmVersion: string
  }) => {
    const { selectedAlgorithmId, selectedAlgorithmVersion } = algorithm
    const selectedAlgorithm = props.algorithms.find(
      (algorithm) => algorithm.algorithmId === selectedAlgorithmId
    )
    if (selectedAlgorithm == null) {
      return
    }

    const selectedAlgorithmVersionData =
      selectedAlgorithm.trainingAlgorithm.trainingAlgorithmVersions.find(
        (algorithmVersion) =>
          algorithmVersion.algorithmVersion.displayName ===
          selectedAlgorithmVersion
      )
    if (selectedAlgorithmVersionData == null) {
      return
    }

    return {
      ['バージョン']: selectedAlgorithmVersionData.trainingAlgorithmVersion,
      ['登録日時']: selectedAlgorithmVersionData.releasedAt
        ? formatDateTimeSec(selectedAlgorithmVersionData.releasedAt.toDate())
        : '',
      ['remarks']: selectedAlgorithmVersionData.metadata.remarks.ja,
    }
  }

  /** クラスセット変換 */
  const classSetToDetail = (classSet?: ClassSet) => {
    if (isUndefined(classSet)) return undefined
    return {
      ['クラスセットID']: classSet.classSetId,
      ['クラスセット名']: classSet.classSetName,
      ['クラス名リスト']: classSet.classList.join(','),
      ['登録日時']: formatDateTimeSec(classSet.createdAt.toDate()),
      ['備考']: classSet.classSetRemarks,
    }
  }

  return {
    searchedClassSetsLength,
    classSetRows,
    tableSearchValue,
    selectedClassSetIndex,
    userGroupKindList,
    selectClassSetTableRadio,
    handleChangeDisplayNumber,
    searchTableContent,
    selectUserGroupKind,
    setTableSearchValue,
    changeTableSortOrder,
    pageChange,
    trainingAlgorithmToDetail,
    classSetToDetail,
  }
}
