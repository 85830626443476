import { Add } from '@mui/icons-material'
import { Box, Tooltip, Typography, useTheme } from '@mui/material'
import React, { FC, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  Link,
  RouteComponentProps,
  useHistory,
  useRouteMatch,
  withRouter,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import {
  FeatureDataTransferring,
  FeatureDataTransferringDisplayCondition,
  FeatureDataTransferringListActions,
  featureDataTransferringListActions,
  featureDataTransferringListOperations,
} from 'state/ducks/featureDataTransferringList'
import { State } from 'state/store'
import { makeStyles } from 'tss-react/mui'
import { getFeatureDataGroupId } from 'utils/ducks/featureDataGroup'
import { isUndefined } from 'utils/typeguard'
import {
  BreadcrumbsComponent,
  GlobalLoading,
  RunningTimeLabel,
  STATUS_PROGRESS_BAR_ROW_HEIGHT,
  SearchInput,
  SelectableTable,
  SelectableTableHeader,
  StatusProgressBar,
  TABLE_HEADER_HEIGHT,
  TrainingIcon,
} from 'views/components'
import {
  formatDateTimeSec,
  formatTimeSecByMillSecond,
} from 'views/components/utils/date'
import {
  convertProgressColor,
  convertProgressWord,
} from 'views/containers/utils'
import { Link as MuiLink } from '@mui/material'

const mapStateToProps = (state: State) => ({
  ...state.pages.featureDataTransferringListState,
  ...state.app.domainData.authedUser,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, FeatureDataTransferringListActions>
const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 特徴量データ生成一覧取得 */
  getFeatureDataTransferringList: () =>
    dispatch(
      featureDataTransferringListOperations.getFeatureDataTransferringList()
    ),
  /** リストの表示条件の変更 */
  setListDisplayCondition: (
    listCondition: FeatureDataTransferringDisplayCondition
  ) =>
    dispatch(
      featureDataTransferringListActions.setListDisplayCondition(listCondition)
    ),
  /** Stateのクリア */
  clearFeatureDataTransferringListState: () =>
    dispatch(featureDataTransferringListActions.clearListDisplayCondition()),
  /** snapshotの購読解除 */
  unsubscribe: () =>
    dispatch(featureDataTransferringListOperations.unsubscribe()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  head: {
    height: '240px',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  stepContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  link: {
    cursor: 'pointer',
    textTransform: 'none',
    width: '100%',
  },
}))

/** テーブルのヘッダー */
const TABLE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'mlPipelineId',
    title: 'ML Pipeline ID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'name',
    title: '特徴量データ転送指示名',
    width: 250,
    sortable: false,
    position: 'left',
  },
  {
    id: 'type',
    title: '種別',
    width: 250,
    sortable: false,
    position: 'center',
  },
  {
    id: 'featureData',
    title: '特徴量データ名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'progress',
    title: '進捗',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'time',
    title: '実行時間',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'ml-pipeline.started-at',
    title: '開始日時',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'ended-at',
    title: '終了日時',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'executionUser',
    title: '実行ユーザーID',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

export const FeatureDataTransferringList: FC<Props> = (props) => {
  const { url } = useRouteMatch()
  const globalTheme = useTheme()
  const { classes } = useStyles()
  const history = useHistory()

  /** 特徴量データグループIDを取得 */
  const getFeatureDataGroupIdPath = async (
    userGroupId: string,
    featureDataId: string
  ) => {
    const featureDataGroupId = await getFeatureDataGroupId(
      userGroupId,
      featureDataId
    )
    return featureDataGroupId
  }

  useEffect(() => {
    return () => {
      props.unsubscribe()
    }
  }, [])

  useEffect(() => {
    props.getFeatureDataTransferringList()
    return () => {
      props.clearFeatureDataTransferringListState()
    }
  }, [props.auth.customClaims.userGroupId])

  const {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
  } = useTable(props)

  /** 検索ワードがある場合日付ソートをさせない */
  const tableHeader = useMemo(() => {
    if (props.domainData.featureDataTransferringDisplayCondition.searchValue) {
      return TABLE_HEADERS.map((header) => {
        return { ...header, sortable: false }
      })
    }

    return TABLE_HEADERS
  }, [props.domainData.featureDataTransferringDisplayCondition.searchValue])

  /** テーブルに表示する特徴量データ転送のJSXの２次元配列 */
  const tableRows = useMemo(() => {
    const convertedList: FeatureDataTransferring[] =
      props.domainData.currentFeatureDataTransferringList.map(
        (featureDataTransferring: FeatureDataTransferring) => {
          return {
            mlPipelineId: featureDataTransferring.mlPipelineId,
            mlPipelineName: featureDataTransferring.mlPipelineName,
            algorithmName: featureDataTransferring.algorithmName,
            featureData: {
              featureDataId: featureDataTransferring.featureData.featureDataId,
              featureDataName:
                featureDataTransferring.featureData.featureDataName,
            },
            progress: featureDataTransferring.progress,
            time: '',
            startedAt: featureDataTransferring.startedAt,
            endedAt: featureDataTransferring.endedAt,
            accountId: featureDataTransferring.accountId,
          }
        }
      )

    return convertedList.map((data) =>
      Object.entries(data).map(([key, value]) => {
        switch (key) {
          case 'progress':
            return (
              <StatusProgressBar
                status={convertProgressWord(
                  data.progress.transactionStatus,
                  true
                )}
                progressRate={data.progress.progressRate}
                progressColor={convertProgressColor(
                  data.progress.transactionStatus
                )}
              />
            )
          case 'mlPipelineId':
            return isUndefined(value) ? (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            ) : (
              <Tooltip title={value} placement='bottom'>
                <Typography>{value.substring(0, 8)}</Typography>
              </Tooltip>
            )
          case 'featureData':
            return value.featureDataName ? (
              <MuiLink
                className={classes.link}
                underline='none'
                onClick={async () => {
                  const featureDataGroupId = await getFeatureDataGroupIdPath(
                    props.auth.customClaims.userGroupId,
                    value.featureDataId
                  )
                  return history.push(
                    `/feature-data-groups/${featureDataGroupId}/feature-data/${value.featureDataId}`
                  )
                }}
                data-testid={`feature-data-detail-${value.featureDataId}`}
              >
                <Typography key={key}>{value.featureDataName}</Typography>
              </MuiLink>
            ) : (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
          case 'algorithmName':
            return isUndefined(value) ? (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography align='center'>
                  {TABLE_CELL_NOT_APPLICABLE}
                </Typography>
              </Box>
            ) : (
              <Tooltip title={value} placement='bottom'>
                <Typography align='center'>{value}</Typography>
              </Tooltip>
            )
          case 'startedAt':
          case 'endedAt':
            // 終了日時がundefinedまたはUnixTime0の場合N/Aとする
            if (isUndefined(value)) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            } else if (key === 'endedAt' && value.seconds === 0) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
            return (
              <Typography key={key}>
                {formatDateTimeSec(
                  isUndefined(value) ? undefined : value.toDate()
                )}
              </Typography>
            )
          case 'time':
            // 成功、失敗かつ終了時刻が存在しないまたは、UnixTime0の場合は、N/Aとする
            if (
              (['Completed', 'Failed'].includes(
                data.progress.transactionStatus
              ) &&
                !data.endedAt) ||
              data.endedAt?.seconds === 0
            ) {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
            return !isUndefined(data.startedAt) &&
              !isUndefined(data.endedAt) ? (
              <Typography key={key}>
                {formatTimeSecByMillSecond(
                  data.endedAt.toDate().getTime() -
                    data.startedAt.toDate().getTime()
                )}
              </Typography>
            ) : (
              <RunningTimeLabel startedAt={data.startedAt.toDate()} />
            )
          case 'mlPipelineName':
            if (value) {
              return (
                <Tooltip title={value} placement='bottom'>
                  <Typography>{value}</Typography>
                </Tooltip>
              )
            } else {
              return (
                <Box sx={{ color: globalTheme.palette.text.secondary }}>
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              )
            }
          case 'accountId':
            return (
              <Tooltip title={value} placement='bottom'>
                <Typography>{value.substring(0, 8)}</Typography>
              </Tooltip>
            )
          default:
            return (
              <Box sx={{ color: globalTheme.palette.text.secondary }}>
                <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              </Box>
            )
        }
      })
    )
  }, [props.domainData.currentFeatureDataTransferringList])

  /** テーブル */
  const featureDataTransferringTable = useMemo(() => {
    return (
      <SelectableTable
        displayNumber={
          props.domainData.featureDataTransferringDisplayCondition.displayNumber
        }
        headers={tableHeader}
        rows={tableRows}
        totalCount={
          props.domainData.featureDataTransferringDisplayCondition.totalCount
        }
        loading={props.appState.inProgress}
        tableHeight={TABLE_HEADER_HEIGHT + 10 * STATUS_PROGRESS_BAR_ROW_HEIGHT}
        fixedColumnNumber={0}
        page={
          props.domainData.featureDataTransferringDisplayCondition.pageNumber
        }
        sortOrder={{
          key: props.domainData.featureDataTransferringDisplayCondition.sortKey,
          order:
            props.domainData.featureDataTransferringDisplayCondition.sortOrder,
        }}
        includesStatusProgressBar={true}
        displayNoneRadio={true}
        onClickOrderChange={(key: string) => changeTableSortOrder(key)}
        onClickPageChange={(pageNumber: number) => pageChange(pageNumber)}
        onChangeDisplayNumber={(displayNumber: number) =>
          handleChangeDisplayNumber(displayNumber)
        }
      />
    )
  }, [
    tableRows,
    props.domainData.featureDataTransferringDisplayCondition,
    props.appState.inProgress,
  ])

  return (
    <>
      <Box className={classes.stepContainer}>
        <BreadcrumbsComponent
          breadcrumbsPath={[
            {
              name: '特徴量データ転送指示一覧',
              path: 'feature-data-transferring',
            },
          ]}
        />
        <div className={classes.postAddButton}>
          <Box display='flex'>
            <TrainingIcon
              className={classes.pageIcon}
              data-testid='featureDataTransferringListTitleIcon'
            />
            <Typography component='div'>
              <h2 data-testid='feature-data-transferring-list-title'>
                特徴量データ転送
              </h2>
            </Typography>
          </Box>
          <Box display='flex'>
            <Add />
            <Link
              data-testid='feature-data-transferring-entry'
              to={`${url}/entry`}
            >
              <Typography>新規転送指示</Typography>
            </Link>
          </Box>
        </div>
        <div className={classes.searchForm}>
          <div className={classes.searchField}>
            <SearchInput
              placeholder='キーワード (ML Pipeline ID)'
              value={
                props.domainData.featureDataTransferringDisplayCondition
                  .searchValue
              }
              onChangeValue={(event) =>
                handleChangeSearchValue(event.target.value)
              }
              onClickSearch={() => searchTableContent()}
              onPressEnter={() => searchTableContent()}
            />
          </div>
        </div>
        {featureDataTransferringTable}
      </Box>
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

export const FeatureDataTransferringListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeatureDataTransferringList))

const useTable = (props: Props) => {
  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    /** 検索ワードを変更 */
    props.setListDisplayCondition({
      ...props.domainData.featureDataTransferringDisplayCondition,
      searchValue: value,
    })
  }
  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    /** 表示数を変更 */
    props.setListDisplayCondition({
      ...props.domainData.featureDataTransferringDisplayCondition,
      pageNumber: 0,
      displayNumber: displayNumber,
    })

    props.getFeatureDataTransferringList()
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    // ソート時に1ページ目に戻る
    props.setListDisplayCondition({
      ...props.domainData.featureDataTransferringDisplayCondition,
      sortKey: key,
      sortOrder:
        props.domainData.featureDataTransferringDisplayCondition.sortOrder ===
        'desc'
          ? 'asc'
          : 'desc',
      pageNumber: 0,
    })

    props.getFeatureDataTransferringList()
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    props.setListDisplayCondition({
      ...props.domainData.featureDataTransferringDisplayCondition,
      pageNumber: pageNumber,
    })

    props.getFeatureDataTransferringList()
  }

  /** 検索の実行 */
  const searchTableContent = () => {
    props.setListDisplayCondition({
      ...props.domainData.featureDataTransferringDisplayCondition,
      pageNumber: 0,
    })

    props.getFeatureDataTransferringList()
  }

  return {
    changeTableSortOrder,
    pageChange,
    handleChangeDisplayNumber,
    handleChangeSearchValue,
    searchTableContent,
  }
}
