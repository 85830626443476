import { InferenceEntryAction } from './actions'
import { InferenceActionType, CurrentInferenceState } from './types'

const initialState: CurrentInferenceState = {
  domainData: {
    selectedInferenceAlgorithm: {
      algorithmId: '2aabc470-70c6-49c7-9d63-21598c4a859a',
    },
    trainedModelGroups: {
      sharedUserGroup: [],
      userGroup: [],
    },
    selectedTrainedModel: undefined,
    selectedTrainedModelGroup: undefined,
    groupedImageList: [],
    selectedGroupedImage: undefined,
    trainedModelDisplayCondition: {
      searchValue: '',
      sortKey: 'generatedAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    trainedModelGroupDisplayCondition: {
      selectedUserGroupKind: 'UserGroup',
      searchValue: '',
      sortKey: 'createdAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    groupedImageDisplayCondition: {
      searchValue: '',
      sortKey: 'generatedAt',
      sortOrder: 'desc',
      displayNumber: 10,
      pageNumber: 0,
    },
    inferenceMetaData: {
      name: '',
      remarks: undefined,
    },
    executeInfo: {
      mlPipelineId: undefined,
      inferenceStepId: undefined,
    },
    currentDatasetListSnapshot: undefined,
  },
  appState: {
    inferenceState: 'TrainedModelListState',
    inferenceAlgorithmState: {
      trainedModelSubState: 'Unselected',
      groupedDataSubState: 'Unselected',
      metaDataSubState: 'EmptyRequired',
      executeSubState: 'BeforeExecute',
    },
    inProgress: false,
  },
}

export const CurrentInferenceReducer = (
  state: CurrentInferenceState = initialState,
  action: InferenceEntryAction
): CurrentInferenceState => {
  switch (action.type) {
    case InferenceActionType.SET_SELECTED_ALGORITHM_ID_FOR_INFERENCE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedInferenceAlgorithm: {
            algorithmId: action.payload.algorithmId,
          },
        },
      }
    case InferenceActionType.SET_TRAINED_MODEL_GROUP_LIST_FOR_INFERENCE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          trainedModelGroups: action.payload.trainedModelGroups,
        },
      }
    case InferenceActionType.SET_SELECTED_TRAINED_MODEL_GROUP_FOR_INFERENCE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedTrainedModelGroup: action.payload.selectedTrainedModelGroup,
        },
      }
    case InferenceActionType.SET_SELECTED_TRAINED_MODEL_FOR_INFERENCE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedTrainedModel: action.payload.selectedTrainedModel,
        },
      }
    case InferenceActionType.SET_IMAGE_SET_LIST:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          groupedImageList: action.payload.groupedImageList,
        },
      }
    case InferenceActionType.SET_SELECTED_IMAGE_SET:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          selectedGroupedImage: action.payload.selectedGroupedImage,
        },
      }
    case InferenceActionType.SET_INFERENCE_METADATA:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          inferenceMetaData: action.payload.inferenceMetadata,
        },
      }
    case InferenceActionType.SET_MODEL_GROUP_DISPLAY_CONDITION_FOR_INFERENCE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          trainedModelGroupDisplayCondition:
            action.payload.modelGroupDisplayCondition,
        },
      }
    case InferenceActionType.SET_TRAINED_MODEL_DISPLAY_CONDITION_FOR_INFERENCE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          trainedModelDisplayCondition:
            action.payload.trainedModelDisplayCondition,
        },
      }
    case InferenceActionType.SET_IMAGE_SET_DISPLAY_CONDITION:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          groupedImageDisplayCondition:
            action.payload.groupedImageDisplayCondition,
        },
      }
    case InferenceActionType.SET_INFERENCE_ALGORITHM_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          inferenceAlgorithmState: {
            ...state.appState.inferenceAlgorithmState,
            trainedModelSubState: action.payload.trainedModelSubState,
          },
        },
      }
    case InferenceActionType.SET_IMAGE_SET_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          inferenceAlgorithmState: {
            ...state.appState.inferenceAlgorithmState,
            groupedDataSubState: action.payload.groupedDataSubState,
          },
        },
      }
    case InferenceActionType.SET_META_DATA_SUB_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          inferenceAlgorithmState: {
            ...state.appState.inferenceAlgorithmState,
            metaDataSubState: action.payload.metaDataSubState,
          },
        },
      }
    case InferenceActionType.SET_INFERENCE_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          inferenceState: action.payload.inferenceState,
        },
      }
    case InferenceActionType.EXEC_INFERENCE_SUCCESS:
      return {
        ...state,
        appState: {
          ...state.appState,
          inferenceAlgorithmState: {
            ...state.appState.inferenceAlgorithmState,
            executeSubState: 'Executed',
          },
        },
      }
    case InferenceActionType.EXEC_INFERENCE_FAILURE:
      return {
        ...state,
        appState: {
          ...state.appState,
          inferenceAlgorithmState: {
            ...state.appState.inferenceAlgorithmState,
            executeSubState: 'ExecuteError',
          },
        },
      }
    case InferenceActionType.SET_EXECUTED_ML_PIPELINE_ID_FOR_INFERENCE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          executeInfo: {
            ...action.payload.executeInfo,
          },
        },
      }
    case InferenceActionType.SET_IN_PROGRESS_FOR_INFERENCE_START:
      return {
        ...state,
        appState: {
          ...state.appState,
          inProgress: action.payload.inProgress,
        },
      }
    case InferenceActionType.CLEAR_TRAINED_MODEL_LIST_DISPLAY_CONDITION_FOR_INFERENCE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          trainedModelDisplayCondition: {
            ...initialState.domainData.trainedModelDisplayCondition,
          },
        },
      }
    case InferenceActionType.CLEAR_TRAINED_MODEL_GROUP_LIST_DISPLAY_CONDITION_FOR_INFERENCE:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          trainedModelGroupDisplayCondition: {
            ...initialState.domainData.trainedModelGroupDisplayCondition,
          },
        },
      }
    case InferenceActionType.CLEAR_CURRENT_INFERENCE_STATE:
      return {
        ...initialState,
      }
    case InferenceActionType.SET_CURRENT_DATASET_LIST_SNAPSHOT:
      return {
        ...state,
        domainData: {
          ...state.domainData,
          currentDatasetListSnapshot: action.payload.currentDatasetListSnapshot,
        },
      }
    case InferenceActionType.SET_TOAST_INFO_FOR_INFERENCE:
      return {
        ...state,
        appState: {
          ...state.appState,
          toastInfo: action.payload.toastInfo,
        },
      }
    default:
      return state
  }
}
