import React, { useRef, useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import {
  RouteComponentProps,
  withRouter,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { Timestamp } from 'firebase/firestore'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'

import { State } from 'state/store'
import {
  InferenceEntryAction,
  inferenceOperations,
  InferenceStateKindArray,
  TrainedModel,
  GroupedImage,
  Metadata,
  inferenceEntryActions,
  InferenceDisplayCondition,
  TrainedModelGroup,
  InferenceStateKind,
} from 'state/ducks/inference'

import { isUndefined, isString } from 'utils/typeguard'
import { compareVersions } from 'utils/versions'
import {
  SelectableTable,
  AccordionLabel,
  SearchInput,
  MLPipelineCompleteDialog,
  CommonStepper,
  MetadataInput,
  PostAddIconButton,
  CustomTrainingPageParagraph,
  ErrorMessage,
  GlobalLoading,
  TableDialog,
  TooltipLink,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  RADIO_ROW_HEIGHT,
  ConfirmViewerDialog,
  BreadcrumbsComponent,
} from 'views/components'
import { CreateImageSetPage } from 'views/containers/pages'
import {
  convertAlgorithmKind,
  convertModelKindWord,
  getUserGroupKindList,
} from 'views/containers/utils'
import { formatDateTimeSec } from 'views/components/utils/date'
import { useTheme } from '@mui/material/styles'
import {
  InferenceQueryParameters,
  getInferenceQueryParameters,
} from 'views/containers/utils/queryParams'

/** How to simplify the description of react-redux. */
const mapStateToProps = (state: State) => ({
  ...state.pages.currentInferenceState,
  ...state.app.domainData.authedUser,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, InferenceEntryAction>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 画面の必要情報の取得 */
  getParams: (inferenceStateKind: InferenceStateKind) => {
    switch (inferenceStateKind) {
      case 'TrainedModelListState':
        dispatch(inferenceOperations.getTrainedModelGroupList())
        break
      case 'GroupedDataState':
        dispatch(inferenceOperations.getImageSetList())
        break
      default:
        break
    }
  },
  /** 推論開始画面のパラメータをリセット */
  clearInferenceState: () =>
    dispatch(inferenceEntryActions.clearCurrentInferenceState()),
  /** アルゴリズムの選択 */
  setSelectedAlgorithmId: (algorithmId: string) => {
    dispatch(inferenceOperations.setSelectedAlgorithmId(algorithmId))
  },
  /** 選択されたアルゴリズムに紐づいた配列を取得 */
  getTrainedModelGroupList: () =>
    dispatch(inferenceOperations.getTrainedModelGroupList()),
  /** 次へボタン押下時処理 */
  nextStep: (currentStep: number) => {
    if (currentStep >= InferenceStateKindArray.length - 1) {
      /** 推論開始 */
      dispatch(inferenceOperations.executeInference())
    } else {
      dispatch(inferenceOperations.nextStep(currentStep))
    }
  },
  /** 戻るボタン押下時処理 */
  prevStep: (currentStep: number) => {
    dispatch(inferenceOperations.prevStep(currentStep))
  },
  /** モデルのモデルグループの選択 */
  setSelectedModelGroup: (modelGroup?: TrainedModelGroup) =>
    dispatch(inferenceEntryActions.setSelectedTrainedModelGroup(modelGroup)),
  /** 選んだリストをセットする(BaseModel) */
  setSelectedBaseModel: (data?: TrainedModel) =>
    dispatch(inferenceOperations.setSelectedTrainedModel(data)),
  /** 画像セットの選択 */
  setSelectedImageSet: (imageSet?: GroupedImage) => {
    dispatch(inferenceOperations.setSelectedImageSet(imageSet))
  },
  /** メタデータの入力 */
  setInferenceMetadata: (metadata: Metadata) => {
    dispatch(inferenceOperations.setInferenceMetadata(metadata))
  },
  /** 学習済みモデルの表示条件の変更 */
  setTrainedModelDisplayCondition: (condition: InferenceDisplayCondition) =>
    dispatch(inferenceEntryActions.setTrainedModelDisplayCondition(condition)),
  /** モデルのモデルグループ表示条件 */
  setTrainedModelGroupDisplayCondition: (
    condition: InferenceDisplayCondition & {
      selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
    }
  ) =>
    dispatch(
      inferenceEntryActions.setTrainedModelGroupDisplayCondition(condition)
    ),
  /** 画像セットの表示条件の変更 */
  setGroupedImageDisplayCondition: (
    imageSetCondition: InferenceDisplayCondition
  ) =>
    dispatch(
      inferenceEntryActions.setGroupedImageDisplayCondition(imageSetCondition)
    ),
  /** snapshotの購読解除 */
  unsubscribeDatasetList: () =>
    dispatch(inferenceOperations.unsubscribeDatasetList()),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const HEADER_HEIGHT = 64
const FOOTER_HEIGHT = 100
const HEADER_MARGIN_BOTTOM = 24
const STEPPER_HEIGHT = 186
const BREADCRUMBS_HEIGHT = 40

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
  },
  head: {
    height: STEPPER_HEIGHT,
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${HEADER_MARGIN_BOTTOM}px - ${STEPPER_HEIGHT}px - ${FOOTER_HEIGHT}px - ${BREADCRUMBS_HEIGHT}px)`,
    overflowY: 'auto',
  },
  footerButtons: {
    height: FOOTER_HEIGHT,
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  stepper: {
    margin: theme.spacing(3),
    marginBottom: '0px',
  },
  stepContainer: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    marginTop: theme.spacing(1),
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  resultCountSelectBox: {
    width: theme.custom.table.resultCountSelect.width,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
  textField: {
    width: '100%',
    color: '#000 !important',
  },
  sectionTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
  outputFormatSelection: {
    display: 'flex',
    justifyContent: 'felx-start',
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  postAddButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  algorithmSelectBox: {
    width: '100%',
  },
  dataCreateDialog: {
    padding: theme.spacing(2),
    backgroundColor: '#fafafa',
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  confirmButton: {
    marginRight: theme.spacing(7),
  },
}))

/** ステップの名称 */
const STEP_NAMES = ['モデル', '画像セット', 'メタデータ', '確認']

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'
/** テーブルのデフォルト表示数 */
const DEFAULT_DISPLAY_NUMBER = 5

/** モデルのテーブルの選択状態 */
const BASE_MODEL_HEADERS: SelectableTableHeader[] = [
  {
    id: 'trainedModelId',
    title: 'モデルID',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'trainedModelGroupVersion',
    title: 'バージョン',
    width: 200,
    sortable: true,
    position: 'center',
  },
  {
    id: 'modelName',
    title: 'モデル名',
    width: 200,
    sortable: false,
    position: 'left',
  },
  {
    id: 'transactionStatus',
    title: 'ステータス',
    width: 150,
    sortable: false,
    position: 'left',
  },
]

/** モデルグループのヘッダー */
const MODEL_GROUP_HEADERS: SelectableTableHeader[] = [
  {
    id: 'trainedModelGroupId',
    title: 'モデルグループID',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'trainedModelGroupName',
    title: 'モデルグループ名',
    width: 200,
    sortable: true,
    position: 'left',
  },
  {
    id: 'trainedModelCount',
    title: '登録モデル数',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'latestTrainedModelVersion',
    title: '最新バージョン',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'latestTrainedModelName',
    title: '最新モデル名',
    width: 150,
    sortable: false,
    position: 'left',
  },
  {
    id: 'updatedAt',
    title: '更新日時',
    width: 200,
    sortable: true,
    position: 'left',
  },
  {
    id: 'createdAt',
    title: '作成日時',
    width: 200,
    sortable: true,
    position: 'left',
  },
  {
    id: 'createdBy',
    title: '作成ユーザID',
    width: 150,
    sortable: false,
    position: 'center',
  },
]
/** 画像セットのテーブルの選択状態 */
const IMAGE_SET_HEADERS: SelectableTableHeader[] = [
  {
    id: 'imageSetId',
    title: '画像セットID',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'imageASetName',
    title: '画像セット名',
    width: 300,
    sortable: false,
    position: 'center',
  },
  {
    id: 'remarks',
    title: '備考',
    width: 400,
    sortable: false,
    position: 'center',
  },
  {
    id: 'generatedAt',
    title: '生成日時',
    width: 300,
    sortable: true,
    position: 'center',
  },
  {
    id: 'createdBy',
    title: '生成ユーザID',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

const InferenceInfoConfirmView: React.FC<Props> = (props: Props) => {
  return (
    <Box component={Paper} mt={1} p={'24px 32px 32px'} width='100%'>
      <Typography>推論情報</Typography>
      <Box mt={1}>
        <MetadataInput
          nameProps={{
            label: '表示名',
            value: props.domainData.inferenceMetaData
              ? props.domainData.inferenceMetaData.name
              : '',
            readOnly: true,
            variant: 'standard',
          }}
          remarksProps={{
            label: '備考',
            value: props.domainData.inferenceMetaData
              ? props.domainData.inferenceMetaData.remarks
              : '',
            readOnly: true,
            rowNum: 0,
            variant: 'standard',
          }}
          textFieldSpace={0}
          helperText=''
          data-testid={'inferenceInputPreview'}
        />
      </Box>
    </Box>
  )
}

const AlgorithmsConfirmView: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  return (
    <Box component={Paper} mt={2} p={'24px 32px 32px'} width='100%'>
      <Typography>アルゴリズム</Typography>
      <Box mt={1}>
        <TextField
          id='algorithm'
          className={classes.textField}
          label='アルゴリズム種別'
          InputProps={{
            readOnly: true,
          }}
          value={convertAlgorithmKind(
            props.domainData.selectedInferenceAlgorithm?.algorithmId ?? ''
          )}
          key={props.domainData.selectedInferenceAlgorithm?.algorithmId}
          variant='standard'
        />
      </Box>
    </Box>
  )
}

type CustomModelInfoConfirmViewProps = Props & {
  modelGroupToDetail: (
    modelGroup?: TrainedModelGroup,
    userGroupKind?: 'UserGroup' | 'SharedUserGroup'
  ) =>
    | {
        ['データ種別']: string
        ['モデルグループID']: string
        ['登録モデル数']: string
        ['最新バージョン']: string
        ['最新モデル名']: string
        ['更新日時']: string
        ['作成日時']: string
        ['作成ユーザーID']: string
      }
    | undefined
  baseModelToDetail: (
    baseModel?: TrainedModel,
    userGroupKind?: 'UserGroup' | 'SharedUserGroup'
  ) =>
    | {
        ['データ種別']: string
        ['モデルID']: string
        ['バージョン']: string
        ['モデル名']: string
        ['ステータス']: string
      }
    | undefined
  imageSetToDetail: (imageSet?: GroupedImage) =>
    | {
        ['画像セットID']: string
        ['画像セット名']: string
        ['備考']: string
        ['生成日時']: string
        ['生成ユーザーID']: string
      }
    | undefined
}

const CustomModelInfoConfirmView: React.FC<CustomModelInfoConfirmViewProps> = (
  props: CustomModelInfoConfirmViewProps
) => {
  return (
    <Box component={Paper} mt={2} p={'24px 32px 32px'} mb={1} width='100%'>
      <Typography>モデル情報</Typography>
      <Box mt={1}>
        <InputLabel shrink>モデルグループ</InputLabel>
        <Box mt={1}>
          <AccordionLabel
            label={
              props.domainData.selectedTrainedModelGroup?.trainedModelGroupName
            }
            details={props.modelGroupToDetail(
              props.domainData.selectedTrainedModelGroup,
              props.domainData.trainedModelGroupDisplayCondition
                .selectedUserGroupKind
            )}
            prefix={'-'}
            delimiter={':'}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <InputLabel shrink>モデル</InputLabel>
        <Box mt={1}>
          <AccordionLabel
            label={props.domainData.selectedTrainedModel?.trainedModelName}
            details={props.baseModelToDetail(
              props.domainData.selectedTrainedModel,
              props.domainData.trainedModelGroupDisplayCondition
                .selectedUserGroupKind
            )}
            prefix={'-'}
            delimiter={':'}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <InputLabel shrink>画像セット</InputLabel>
        <Box mt={1}>
          <AccordionLabel
            label={props.domainData.selectedGroupedImage?.groupedDataName}
            details={props.imageSetToDetail(
              props.domainData.selectedGroupedImage
            )}
            prefix={'-'}
            delimiter={':'}
          />
        </Box>
      </Box>
    </Box>
  )
}

const Inference: React.FC<Props> = (props: Props) => {
  const history = useHistory()
  const { classes } = useStyles()
  const search = useLocation().search
  const query = getInferenceQueryParameters(search)

  /** カレントのステップ */
  const currentStep = useMemo(
    () => InferenceStateKindArray.indexOf(props.appState.inferenceState),
    [props.appState.inferenceState]
  )

  /** 各種一覧取得（推論アルゴリズム/画像セット） */
  useEffect(() => {
    props.getParams(props.appState.inferenceState)
  }, [props.appState.inferenceState])

  /** 次ページの表示 */
  const nextPageAction = () => {
    switch (props.appState.inferenceState) {
      case 'TrainedModelListState':
        if (baseModelSubStep && props.domainData.selectedTrainedModelGroup) {
          props.nextStep(currentStep)
          break
        } else if (props.domainData.selectedTrainedModelGroup) {
          setBaseModelSubStep(true)
          break
        }
        break
      default:
        props.nextStep(currentStep)
        break
    }
  }

  useEffect(() => {
    return () => {
      props.clearInferenceState()
      props.unsubscribeDatasetList()
    }
  }, [])

  useEffect(() => {
    if (query['shared-user-group'] === 'true') {
      props.setTrainedModelGroupDisplayCondition({
        ...props.domainData.trainedModelGroupDisplayCondition,
        selectedUserGroupKind: 'SharedUserGroup',
      })
    }
  }, [props.match.params])

  /** コンテンツエリア（ステッパー、戻る/次へ表示エリア以外） */
  const contentRef = useRef<HTMLDivElement>(null)

  /** ステップ更新時のスクロール操作 */
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
      handleChangeSearchValue('')
    }
  }, [currentStep])

  /** カスタム学習の開始失敗時メッセージ */
  const InferenceErrorMessage = (props: Props): JSX.Element => {
    const errorMessages: string[] = []
    if (
      props.appState.inferenceAlgorithmState.executeSubState === 'ExecuteError'
    ) {
      errorMessages.push('推論処理の開始に失敗しました。')
      return <ErrorMessage title='' targets={errorMessages} />
    } else return <></>
  }

  /** 画像セットの新規追加ボタン押下時処理 */
  const handleClickOpenImageSetDialog = () => {
    setOpenImageSetDialog(true)
  }

  /** 画像セットのキャンセルボタン押下時処理 */
  const handleClickCloseImageSetDialog = (imageSetId?: string) => {
    setOpenImageSetDialog(false)
    props.getParams(props.appState.inferenceState)
    if (isUndefined(imageSetId)) return
    props.setSelectedImageSet(undefined)
  }

  /** 推論実行の表示名更新処理 */
  const updateInferenceMetaDataName = (name: string) => {
    props.setInferenceMetadata({
      ...props.domainData.inferenceMetaData,
      name,
    })
  }

  /** 推論実行の備考更新処理 */
  const updateInferenceMetaDataRemarks = (remarks: string) => {
    props.setInferenceMetadata(
      props.domainData.inferenceMetaData
        ? {
            ...props.domainData.inferenceMetaData,
            remarks,
          }
        : { name: '', remarks: '' }
    )
  }
  /** 次へのボタン制御 */
  const enableNextButton = useMemo(() => {
    switch (props.appState.inferenceState) {
      case 'TrainedModelListState':
        return (
          props.appState.inferenceAlgorithmState.trainedModelSubState ===
          'Selected'
        )
      case 'GroupedDataState':
        return (
          props.appState.inferenceAlgorithmState.groupedDataSubState ===
          'Selected'
        )
      case 'MetaDataState':
        return (
          props.appState.inferenceAlgorithmState.metaDataSubState ===
          'InputRequired'
        )
      case 'ExecuteState':
        return true
      default:
        return false
    }
  }, [props.appState.inferenceState, props.appState.inferenceAlgorithmState])

  /** 画像セット新規追加ダイアログ表示状態 */
  const [openImageSetDialog, setOpenImageSetDialog] = useState(false)

  /** モデルのモデルグループのサブステップ */
  const [baseModelSubStep, setBaseModelSubStep] = useState<boolean>(false)

  /** 検索ワードを一時保持 */
  const [tableSearchValue, setTableSearchValue] = useState<string>('')

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    setTableSearchValue(value)
  }

  /** 検索処理 */
  const searchTableContent = () => {
    switch (props.appState.inferenceState) {
      case 'GroupedDataState':
        props.setGroupedImageDisplayCondition({
          ...props.domainData.groupedImageDisplayCondition,
          searchValue: tableSearchValue,
        })
        props.getParams(props.appState.inferenceState)
        break
      default:
        break
    }
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    switch (props.appState.inferenceState) {
      case 'TrainedModelListState':
        if (baseModelSubStep) {
          props.setTrainedModelDisplayCondition({
            ...props.domainData.trainedModelDisplayCondition,
            pageNumber: pageNumber,
          })
        } else {
          props.setTrainedModelGroupDisplayCondition({
            ...props.domainData.trainedModelGroupDisplayCondition,
            pageNumber: pageNumber,
          })
        }
        break
      case 'GroupedDataState':
        props.setGroupedImageDisplayCondition({
          ...props.domainData.groupedImageDisplayCondition,
          pageNumber: pageNumber,
        })
        break
      default:
        break
    }
  }

  /** テーブルのソートオーダー変更 */
  const changeTableSortOrder = (key: string) => {
    switch (props.appState.inferenceState) {
      case 'TrainedModelListState':
        if (baseModelSubStep) {
          /** ソートキー、ソートオーダーをセット */
          props.setTrainedModelDisplayCondition({
            ...props.domainData.trainedModelDisplayCondition,
            pageNumber: 0,
            sortKey: key,
            sortOrder:
              props.domainData.trainedModelDisplayCondition.sortKey === key
                ? props.domainData.trainedModelDisplayCondition.sortOrder ===
                  'asc'
                  ? 'desc'
                  : 'asc'
                : props.domainData.trainedModelDisplayCondition.sortOrder,
          })
        } else {
          /** ソートキー、ソートオーダーをセット */
          props.setTrainedModelGroupDisplayCondition({
            ...props.domainData.trainedModelGroupDisplayCondition,
            pageNumber: 0,
            sortKey: key,
            sortOrder:
              props.domainData.trainedModelGroupDisplayCondition.sortKey === key
                ? props.domainData.trainedModelGroupDisplayCondition
                    .sortOrder === 'asc'
                  ? 'desc'
                  : 'asc'
                : props.domainData.trainedModelGroupDisplayCondition.sortOrder,
          })
        }
        break
      case 'GroupedDataState':
        /** 画像セットテーブルのソートキー、ソートオーダーをセット */
        props.setGroupedImageDisplayCondition({
          ...props.domainData.groupedImageDisplayCondition,
          pageNumber: 0,
          sortKey: key,
          sortOrder:
            props.domainData.groupedImageDisplayCondition.sortOrder === 'desc'
              ? 'asc'
              : 'desc',
        })
        break
      default:
        break
    }
  }

  /** 前ページ表示 */
  const prevPageAction = () => {
    if (
      props.appState.inferenceState === 'TrainedModelListState' &&
      baseModelSubStep
    ) {
      setBaseModelSubStep(false)
      props.prevStep(currentStep)
    } else {
      props.prevStep(currentStep)
    }
  }

  /** アルゴリズム選択 */
  const setSelectedAlgorithmId = (id: string) => {
    props.setSelectedModelGroup(undefined)
    props.setSelectedAlgorithmId(id)
    props.getTrainedModelGroupList()
  }

  useEffect(() => {
    // クエリパラメータにアルゴリズムIDが含まれる場合は、初期値として設定
    const algorithmId = query['algorithm-id']
    if (algorithmId) {
      setSelectedAlgorithmId(algorithmId)
    } else {
      const inferenceAlgorithmList = props.algorithms.filter(
        (algorithm) => algorithm.algorithmPurpose !== 'TemplateMatching'
      )
      if (
        inferenceAlgorithmList.length > 0 &&
        inferenceAlgorithmList[0].algorithmId
      ) {
        setSelectedAlgorithmId(inferenceAlgorithmList[0].algorithmId)
      }
    }
  }, [props.algorithms])

  const {
    searchedImageSetListLength,
    imageSetRows,
    selectedImageSetIndex,
    selectImageSetTableRadio,
    handleChangeDisplayNumber,
    tableDialog,
    setSelectRowIndex,
    modelGroupRows,
    selectedModelGroupIndex,
    selectedModelGroupTableRadio,
    baseModelRows,
    selectedBaseModelIndex,
    selectBaseModelTableRadio,
    modelGroupToDetail,
    baseModelToDetail,
    imageSetToDetail,
  } = useTableDisplayActions(
    props,
    query,
    setTableSearchValue,
    baseModelSubStep
  )

  /** Stepによってコンテンツを切り替える */
  const getStepContent = (props: Props): JSX.Element => {
    switch (props.appState.inferenceState) {
      case 'TrainedModelListState':
        return (
          <Box className={classes.stepContainer}>
            {!baseModelSubStep ? (
              <CustomTrainingPageParagraph level={'chapter'}>
                <CustomTrainingPageParagraph>
                  <FormControl
                    variant='outlined'
                    className={classes.algorithmSelectBox}
                  >
                    <InputLabel id='modelListUserGroupKind'>
                      データ種別
                    </InputLabel>
                    <Select
                      labelId='modelListUserGroupKind-label'
                      id='modelListUserGroupKind-outlined'
                      value={
                        props.domainData.trainedModelGroupDisplayCondition
                          .selectedUserGroupKind
                      }
                      onChange={(
                        e: SelectChangeEvent<'UserGroup' | 'SharedUserGroup'>
                      ) => {
                        props.setSelectedModelGroup(undefined)
                        props.setTrainedModelGroupDisplayCondition({
                          ...props.domainData.trainedModelGroupDisplayCondition,
                          selectedUserGroupKind: e.target.value as
                            | 'UserGroup'
                            | 'SharedUserGroup',
                        })
                      }}
                      label='Select User Group Kind'
                    >
                      {userGroupKindList.map((kind) => (
                        <MenuItem
                          data-testid={kind.kind}
                          value={kind.kind}
                          key={kind.kind}
                        >
                          {kind.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CustomTrainingPageParagraph>
              </CustomTrainingPageParagraph>
            ) : (
              <></>
            )}
            {!baseModelSubStep &&
            props.algorithms.filter(
              (algorithm) => algorithm.algorithmPurpose !== 'TemplateMatching'
            ).length > 0 ? (
              <CustomTrainingPageParagraph>
                <Box mt={2}>
                  <FormControl
                    variant='outlined'
                    className={classes.algorithmSelectBox}
                  >
                    <InputLabel id='customTrainingModel'>
                      アルゴリズム
                    </InputLabel>
                    <Select
                      labelId='customTrainingModel-label'
                      id='customTrainingModel-outlined'
                      defaultValue={
                        query['algorithm-id']
                          ? query['algorithm-id'] ?? undefined
                          : props.algorithms.filter(
                              (algorithm) =>
                                algorithm.algorithmPurpose !==
                                'TemplateMatching'
                            )[0].algorithmId
                      }
                      value={
                        props.domainData.selectedInferenceAlgorithm?.algorithmId
                      }
                      onChange={(e: SelectChangeEvent<string | null>) =>
                        setSelectedAlgorithmId(e.target.value as string)
                      }
                      label='Select Algorithm'
                      data-testid='select'
                    >
                      {props.algorithms
                        .filter(
                          (algorithm) =>
                            algorithm.algorithmPurpose !== 'TemplateMatching'
                        )
                        .map((algorithm) => {
                          return (
                            <MenuItem
                              data-testid={algorithm.algorithmId}
                              value={algorithm.algorithmId}
                              key={algorithm.algorithmId}
                            >
                              {algorithm.metadata.name.ja}
                            </MenuItem>
                          )
                        })}
                    </Select>
                  </FormControl>
                </Box>
              </CustomTrainingPageParagraph>
            ) : (
              <></>
            )}
            <CustomTrainingPageParagraph>
              {!isUndefined(
                props.domainData.selectedInferenceAlgorithm?.algorithmId
              ) && (
                <>
                  {!baseModelSubStep ? (
                    <>
                      <CustomTrainingPageParagraph
                        level={'part'}
                        title={'モデルグループ'}
                      >
                        <SelectableTable
                          displayNumber={
                            props.domainData.trainedModelGroupDisplayCondition
                              .displayNumber
                          }
                          headers={MODEL_GROUP_HEADERS}
                          rows={modelGroupRows}
                          totalCount={
                            props.domainData.trainedModelGroupDisplayCondition
                              .selectedUserGroupKind === 'SharedUserGroup'
                              ? props.domainData.trainedModelGroups
                                  .sharedUserGroup.length
                              : props.domainData.trainedModelGroups.userGroup
                                  .length
                          }
                          tableHeight={
                            TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT
                          }
                          onChangeDisplayNumber={(displayNumber: number) =>
                            handleChangeDisplayNumber(displayNumber)
                          }
                          selectedRowNumber={selectedModelGroupIndex}
                          fixedColumnNumber={0}
                          page={
                            props.domainData.trainedModelGroupDisplayCondition
                              .pageNumber
                          }
                          sortOrder={{
                            key: props.domainData
                              .trainedModelGroupDisplayCondition.sortKey,
                            order:
                              props.domainData.trainedModelGroupDisplayCondition
                                .sortOrder,
                          }}
                          onClickRadio={(row: number) =>
                            selectedModelGroupTableRadio(row)
                          }
                          onClickOrderChange={(key: string) =>
                            changeTableSortOrder(key)
                          }
                          onClickPageChange={(pageNumber: number) =>
                            pageChange(pageNumber)
                          }
                        />
                      </CustomTrainingPageParagraph>
                    </>
                  ) : (
                    <>
                      <SelectableTable
                        displayNumber={
                          props.domainData.trainedModelDisplayCondition
                            .displayNumber
                        }
                        headers={BASE_MODEL_HEADERS}
                        rows={baseModelRows}
                        totalCount={
                          props.domainData.selectedTrainedModelGroup
                            ?.trainedModels.length
                        }
                        tableHeight={
                          TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT
                        }
                        onChangeDisplayNumber={(displayNumber: number) =>
                          handleChangeDisplayNumber(displayNumber)
                        }
                        selectedRowNumber={selectedBaseModelIndex}
                        fixedColumnNumber={0}
                        page={
                          props.domainData.trainedModelDisplayCondition
                            .pageNumber
                        }
                        sortOrder={{
                          key: props.domainData.trainedModelDisplayCondition
                            .sortKey,
                          order:
                            props.domainData.trainedModelDisplayCondition
                              .sortOrder,
                        }}
                        onClickRadio={(row: number) =>
                          selectBaseModelTableRadio(row)
                        }
                        onClickRow={(row: number) => {
                          setSelectRowIndex(row)
                        }}
                        onClickOrderChange={(key: string) =>
                          changeTableSortOrder(key)
                        }
                        onClickPageChange={(pageNumber: number) =>
                          pageChange(pageNumber)
                        }
                      />
                    </>
                  )}
                </>
              )}
            </CustomTrainingPageParagraph>
          </Box>
        )
      case 'GroupedDataState':
        if (isUndefined(props.domainData.groupedImageList)) {
          return <></>
        } else {
          return (
            <Box className={classes.stepContainer}>
              <Dialog
                maxWidth='md'
                fullWidth={true}
                aria-labelledby='customized-dialog-title'
                open={openImageSetDialog}
              >
                <DialogContent className={classes.dataCreateDialog}>
                  <CreateImageSetPage
                    handleCloseDialog={(imageSetId?: string) =>
                      handleClickCloseImageSetDialog(imageSetId)
                    }
                  />
                </DialogContent>
              </Dialog>
              <Box className={classes.postAddButton}>
                <Typography className={classes.sectionTitle}>
                  {'画像セット'}
                </Typography>
                <Box>
                  <PostAddIconButton
                    label={'新規追加'}
                    onClick={handleClickOpenImageSetDialog}
                  />
                </Box>
              </Box>
              <Box className={classes.searchForm}>
                <Box className={classes.searchField}>
                  <SearchInput
                    placeholder='検索（画像セットID）'
                    value={tableSearchValue}
                    onChangeValue={(event) =>
                      handleChangeSearchValue(event.target.value)
                    }
                    onClickSearch={() => searchTableContent()}
                    onPressEnter={() => searchTableContent()}
                  />
                </Box>
              </Box>
              <SelectableTable
                displayNumber={
                  props.domainData.groupedImageDisplayCondition.displayNumber
                }
                headers={IMAGE_SET_HEADERS}
                rows={imageSetRows}
                totalCount={
                  !isUndefined(searchedImageSetListLength)
                    ? searchedImageSetListLength
                    : props.domainData.groupedImageList.length
                }
                tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
                selectedRowNumber={selectedImageSetIndex}
                fixedColumnNumber={0}
                page={props.domainData.groupedImageDisplayCondition.pageNumber}
                sortOrder={{
                  key: props.domainData.groupedImageDisplayCondition.sortKey,
                  order:
                    props.domainData.groupedImageDisplayCondition.sortOrder,
                }}
                onClickRadio={(row: number) => selectImageSetTableRadio(row)}
                onClickRow={(row: number) => {
                  setSelectRowIndex(row)
                }}
                onClickOrderChange={(key: string) => changeTableSortOrder(key)}
                onClickPageChange={(pageNumber: number) =>
                  pageChange(pageNumber)
                }
                onChangeDisplayNumber={(displayNumber: number) =>
                  handleChangeDisplayNumber(displayNumber)
                }
              />
            </Box>
          )
        }
      case 'MetaDataState':
        return (
          <Box className={classes.stepContainer}>
            <CustomTrainingPageParagraph level={'part'} title={''}>
              <MetadataInput
                nameProps={{
                  label: '表示名',
                  value: props.domainData.inferenceMetaData
                    ? props.domainData.inferenceMetaData.name
                    : '',
                  variant: 'outlined',
                  readOnly: false,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    updateInferenceMetaDataName(e.target.value),
                }}
                remarksProps={{
                  label: '備考',
                  value: props.domainData.inferenceMetaData
                    ? props.domainData.inferenceMetaData.remarks
                    : '',
                  variant: 'outlined',
                  readOnly: false,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    updateInferenceMetaDataRemarks(e.target.value),
                  rowNum: 10,
                }}
                helperText=''
                data-testid={'customTrainingInput'}
              />
            </CustomTrainingPageParagraph>
          </Box>
        )
      case 'ExecuteState':
        return (
          <Box className={classes.stepContainer}>
            <InferenceErrorMessage {...props} />
            <InferenceInfoConfirmView {...props} />
            <AlgorithmsConfirmView {...props} />
            <CustomModelInfoConfirmView
              {...props}
              modelGroupToDetail={modelGroupToDetail}
              baseModelToDetail={baseModelToDetail}
              imageSetToDetail={imageSetToDetail}
            />
          </Box>
        )
      default:
        return <></>
    }
  }
  const userGroupKindList = getUserGroupKindList(
    props.auth.customClaims.sharedList
  )

  return (
    <>
      <Box className={classes.container}>
        <Box pl={7}>
          <BreadcrumbsComponent
            breadcrumbsPath={[
              {
                name: '推論一覧',
                path: 'inferences',
              },
              {
                name: '推論指示',
                path: 'entry',
              },
            ]}
          />
        </Box>
        <div className={classes.head}>
          <Box className={classes.flexAndBetween}>
            <h2 className={classes.pageTitle} data-testid='inference-title'>
              推論
            </h2>
            <Box className={classes.confirmButton}>
              <Tooltip title='設定情報' placement='bottom'>
                <IconButton
                  onClick={() => {
                    setOpenConfirmDialog(true)
                  }}
                >
                  <ReceiptLongIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box className={classes.stepper}>
            <CommonStepper
              stepLabels={STEP_NAMES}
              activeStepIndex={currentStep}
            />
          </Box>
        </div>
        <Box className={classes.content} ref={contentRef}>
          {getStepContent(props)}
        </Box>
        <Box className={classes.footerButtons}>
          <Box className={classes.footer}>
            {props.appState.inferenceState === 'TrainedModelListState' &&
            !baseModelSubStep ? (
              <Box></Box>
            ) : (
              <Button
                variant='contained'
                color='primary'
                disabled={false}
                onClick={() => prevPageAction()}
                className={classes.leftButton}
              >
                {'戻る'}
              </Button>
            )}
            <Button
              data-testid='next-step'
              variant='contained'
              color='primary'
              disabled={!enableNextButton}
              onClick={() => nextPageAction()}
              className={classes.rightButton}
            >
              {props.appState.inferenceState === 'ExecuteState'
                ? '開始'
                : '次へ'}
            </Button>
          </Box>
        </Box>
        <MLPipelineCompleteDialog
          open={
            !isUndefined(props.domainData.executeInfo.mlPipelineId) &&
            !isUndefined(props.domainData.executeInfo.inferenceStepId)
          }
          value={props.domainData.executeInfo.mlPipelineId ?? ''}
          secondValueItem={{
            label: '推論ステップ ID',
            value: props.domainData.executeInfo.inferenceStepId ?? '',
          }}
          handleClose={() => history.push('/inferences')}
          label={'MLパイプラインID'}
          dialogText={'正常に推論処理を開始しました。'}
          data-testid={'ml-pipeline-id'}
        />
        {tableDialog}
        <ConfirmViewerDialog
          maxWidth='md'
          open={openConfirmDialog}
          message={
            <Box width='630px'>
              <Box display='flex'>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.domainData.inferenceMetaData !== undefined &&
                      props.domainData.inferenceMetaData?.name !== ''
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                </Box>
                <InferenceInfoConfirmView {...props} />
              </Box>
              <Box display='flex'>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.domainData.selectedInferenceAlgorithm !== undefined
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                </Box>
                <AlgorithmsConfirmView {...props} />
              </Box>
              <Box display='flex'>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.appState.inferenceAlgorithmState
                        .trainedModelSubState === 'Selected' &&
                      props.appState.inferenceAlgorithmState
                        .groupedDataSubState === 'Selected'
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                </Box>
                <CustomModelInfoConfirmView
                  {...props}
                  modelGroupToDetail={modelGroupToDetail}
                  baseModelToDetail={baseModelToDetail}
                  imageSetToDetail={imageSetToDetail}
                />
              </Box>
            </Box>
          }
          handleClose={() => setOpenConfirmDialog(false)}
        />
        <GlobalLoading open={props.appState.inProgress} />
      </Box>
    </>
  )
}

export const InferencePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Inference))

const useTableDisplayActions = (
  props: Props,
  query: InferenceQueryParameters,
  setTableSearchValue?: React.Dispatch<React.SetStateAction<string>>,
  baseModelSubStep?: boolean
) => {
  const globalTheme = useTheme()
  /** 表示対象のモデルのモデルグループデータ */
  const adjustModelGroupTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.trainedModelGroupDisplayCondition)) {
      let newSourceModelGroupArray =
        props.domainData.trainedModelGroupDisplayCondition
          .selectedUserGroupKind === 'SharedUserGroup'
          ? props.domainData.trainedModelGroups.sharedUserGroup
          : props.domainData.trainedModelGroups.userGroup
      /** ソートキー、ソートオーダーによる並び替え */
      if (
        props.domainData.trainedModelGroupDisplayCondition.sortKey ===
        'createdAt'
      ) {
        newSourceModelGroupArray = newSourceModelGroupArray.sort(
          (prevSourceModelGroup, nextSourceModelGroup) => {
            return (
              ((nextSourceModelGroup.createdAt
                ? nextSourceModelGroup.createdAt
                : Timestamp.fromMillis(0)
              )
                .toDate()
                .getTime() -
                (prevSourceModelGroup.createdAt
                  ? prevSourceModelGroup.createdAt
                  : Timestamp.fromMillis(0)
                )
                  .toDate()
                  .getTime()) *
              (props.domainData.trainedModelGroupDisplayCondition.sortOrder ===
              'asc'
                ? -1
                : 1)
            )
          }
        )
      } else if (
        props.domainData.trainedModelGroupDisplayCondition.sortKey ===
        'updatedAt'
      ) {
        newSourceModelGroupArray = newSourceModelGroupArray.sort(
          (prevSourceModelGroup, nextSourceModelGroup) => {
            return (
              ((nextSourceModelGroup.updatedAt
                ? nextSourceModelGroup.updatedAt
                : Timestamp.fromMillis(0)
              )
                .toDate()
                .getTime() -
                (prevSourceModelGroup.updatedAt
                  ? prevSourceModelGroup.updatedAt
                  : Timestamp.fromMillis(0)
                )
                  .toDate()
                  .getTime()) *
              (props.domainData.trainedModelGroupDisplayCondition.sortOrder ===
              'asc'
                ? -1
                : 1)
            )
          }
        )
      } else {
        newSourceModelGroupArray = newSourceModelGroupArray.sort(
          (item, item2) => {
            if (item.trainedModelGroupName < item2.trainedModelGroupName) {
              return props.domainData.trainedModelGroupDisplayCondition
                .sortOrder === 'asc'
                ? -1
                : 1
            }
            if (item.trainedModelGroupName > item2.trainedModelGroupName) {
              return props.domainData.trainedModelGroupDisplayCondition
                .sortOrder === 'asc'
                ? 1
                : -1
            }
            // names must be equal
            return 0
          }
        )
      }
      /** 表示するモデルグループの整形 */
      return newSourceModelGroupArray.slice(
        props.domainData.trainedModelGroupDisplayCondition.displayNumber *
          props.domainData.trainedModelGroupDisplayCondition.pageNumber,
        (props.domainData.trainedModelGroupDisplayCondition.pageNumber + 1) *
          props.domainData.trainedModelGroupDisplayCondition.displayNumber
      )
    }
    return []
  }, [
    props.domainData.trainedModelGroupDisplayCondition,
    props.domainData.trainedModelGroups,
  ])

  /** 表示対象のモデルデータ */
  const adjustBaseModelTableRow = useMemo(() => {
    if (
      !isUndefined(props.domainData.trainedModelDisplayCondition) &&
      !isUndefined(props.domainData.selectedTrainedModelGroup)
    ) {
      let newBaseModelArray =
        props.domainData.selectedTrainedModelGroup?.trainedModels ?? []
      /** ソートキー、ソートオーダーによる並び替え */
      newBaseModelArray = newBaseModelArray.sort((item, item2) => {
        return compareVersions(
          item.trainedModelGroupVersion,
          item2.trainedModelGroupVersion,
          props.domainData.trainedModelDisplayCondition.sortOrder
        )
      })
      /** 表示するモデルの整形 */
      return newBaseModelArray.slice(
        props.domainData.trainedModelDisplayCondition.displayNumber *
          props.domainData.trainedModelDisplayCondition.pageNumber,
        (props.domainData.trainedModelDisplayCondition.pageNumber + 1) *
          props.domainData.trainedModelDisplayCondition.displayNumber
      )
    }
    return props.domainData.selectedTrainedModelGroup?.trainedModels ?? []
  }, [
    props.domainData.trainedModelDisplayCondition,
    props.domainData.selectedTrainedModelGroup?.trainedModels,
  ])

  /** モデルのモデルグループの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(props.domainData.trainedModelGroups) &&
      isUndefined(props.domainData.selectedTrainedModelGroup)
    ) {
      // クエリパラメータにモデルグループIDが含まれる場合は、初期値として設定
      const modelGroupId = query['trained-model-group-id']
      if (modelGroupId) {
        const modelGroupList =
          props.domainData.trainedModelGroupDisplayCondition
            .selectedUserGroupKind === 'SharedUserGroup'
            ? props.domainData.trainedModelGroups.sharedUserGroup
            : props.domainData.trainedModelGroups.userGroup
        const modelGroup = modelGroupList.find(
          (group) => group.trainedModelGroupId === modelGroupId
        )
        if (modelGroup) {
          props.setSelectedModelGroup(modelGroup)
        } else {
          props.setSelectedModelGroup(modelGroupList[0])
        }
      } else {
        if (
          props.domainData.trainedModelGroupDisplayCondition
            .selectedUserGroupKind === 'SharedUserGroup'
        ) {
          props.setSelectedModelGroup(
            props.domainData.trainedModelGroups.sharedUserGroup[0]
          )
        } else {
          props.setSelectedModelGroup(
            props.domainData.trainedModelGroups.userGroup[0]
          )
        }
      }
    }
  }, [
    props.domainData.trainedModelGroups,
    props.domainData.selectedTrainedModelGroup,
  ])

  /** モデルの初期値選択 */
  useEffect(() => {
    if (!isUndefined(props.domainData.selectedTrainedModelGroup)) {
      // クエリパラメータにモデルIDが含まれる場合は、初期値として設定
      const baseModelId = query['trained-model-id']
      if (baseModelId) {
        const baseModel =
          props.domainData.selectedTrainedModelGroup.trainedModels.find(
            (baseModel) => baseModel.trainedModelId === baseModelId
          )
        if (baseModel) {
          props.setTrainedModelDisplayCondition({
            ...props.domainData.trainedModelDisplayCondition,
          })
          props.setSelectedBaseModel(baseModel)
        } else {
          props.setSelectedBaseModel(
            props.domainData.selectedTrainedModelGroup.trainedModels[0]
          )
        }
      } else {
        props.setSelectedBaseModel(
          props.domainData.selectedTrainedModelGroup.trainedModels[0]
        )
      }
    }
  }, [props.domainData.selectedTrainedModelGroup])

  /** 検索結果結果の配列の長さを保持(画像セットテーブル) */
  const [searchedImageSetListLength, setSearchedImageSetListLength] = useState<
    number | undefined
  >(undefined)

  /** 画像セットのテーブルをソートして返す */
  const imageSetTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.groupedImageList)) {
      let newImageSetArray = props.domainData.groupedImageList
      /** 検索ワードから検索 */
      if (props.domainData.groupedImageDisplayCondition.searchValue) {
        newImageSetArray = newImageSetArray.filter(
          (item) =>
            item.groupedDataId.startsWith(
              props.domainData.groupedImageDisplayCondition.searchValue
            ) ||
            item.groupedDataId ===
              props.domainData.groupedImageDisplayCondition.searchValue
        )
        /** 検索後の配列の長さをセット */
        setSearchedImageSetListLength(newImageSetArray.length)
      } else {
        /** 検索後の配列の長さをセット */
        setSearchedImageSetListLength(undefined)
      }
      newImageSetArray.sort((prevImageSet, nextImageSet) => {
        return (
          ((nextImageSet.generatedAt
            ? nextImageSet.generatedAt
            : Timestamp.fromMillis(0)
          )
            .toDate()
            .getTime() -
            (prevImageSet.generatedAt
              ? prevImageSet.generatedAt
              : Timestamp.fromMillis(0)
            )
              .toDate()
              .getTime()) *
          (props.domainData.groupedImageDisplayCondition.sortOrder === 'desc'
            ? 1
            : -1)
        )
      })
      /** 表示するモデルの整形 */
      return newImageSetArray.slice(
        props.domainData.groupedImageDisplayCondition.displayNumber *
          props.domainData.groupedImageDisplayCondition.pageNumber,
        (props.domainData.groupedImageDisplayCondition.pageNumber + 1) *
          props.domainData.groupedImageDisplayCondition.displayNumber
      )
    }
  }, [
    props.domainData.groupedImageList,
    props.domainData.groupedImageDisplayCondition,
  ])

  // 画像セットテーブルの配列を作成
  const imageSetRows = useMemo(() => {
    if (!isUndefined(imageSetTableRow)) {
      const rowData: GroupedImage[] = imageSetTableRow.map((data) => {
        return {
          groupedDataId: data.groupedDataId,
          groupedDataName: data.groupedDataName,
          remarks: data.remarks,
          createdAt: data.generatedAt,
          createdBy: data.createdBy,
        }
      })
      const reactNode = rowData.map((data) =>
        Object.entries(data).map(([key, value]) => {
          switch (key) {
            case 'groupedDataId':
              return isUndefined(value) || value === '' ? (
                <Box
                  key={key}
                  sx={{ color: globalTheme.palette.text.secondary }}
                >
                  <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                </Box>
              ) : (
                <TooltipLink
                  key={key}
                  data-testid={`grouped-data-id-${value}`}
                  title={value}
                  placement='right-start'
                  onClick={() => {
                    console.log('推論詳細画面実装時に遷移処理実装')
                  }}
                />
              )
            case 'createdAt':
              return isUndefined(value) ? (
                <Typography key={key}>{TABLE_CELL_NOT_APPLICABLE}</Typography>
              ) : (
                <Typography key={key}>
                  {formatDateTimeSec(
                    isUndefined(value) ? undefined : value.toDate()
                  )}
                </Typography>
              )
            case 'groupedDataName':
              if (value === '') {
                return (
                  <Box
                    key={key}
                    sx={{ color: globalTheme.palette.text.secondary }}
                  >
                    <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
                  </Box>
                )
              } else {
                return <Typography key={key}>{value}</Typography>
              }
            default:
              return (
                <Typography key={key}>
                  {value || TABLE_CELL_NOT_APPLICABLE}
                </Typography>
              )
          }
        })
      )
      return reactNode
    } else {
      return []
    }
  }, [imageSetTableRow])

  /** テーブルに表示モデルのモデルグループのJSXの２次元配列 */
  const modelGroupRows = useMemo(() => {
    const rowData = adjustModelGroupTableRow.map(
      (modelGroup: TrainedModelGroup) => {
        return {
          trainedModelGroupId: modelGroup.trainedModelGroupId,
          trainedModelGroupName: modelGroup.trainedModelGroupName,
          trainedModelCount: modelGroup.trainedModelCount,
          latestTrainedModelVersion: modelGroup.latestTrainedModelVersion,
          latestTrainedModelName: modelGroup.latestTrainedModelName,
          updatedAt: modelGroup.updatedAt
            ? formatDateTimeSec(modelGroup.updatedAt.toDate())
            : '',
          createdAt: modelGroup.createdAt
            ? formatDateTimeSec(modelGroup.createdAt.toDate())
            : '',
          createdBy: modelGroup.createdBy,
        }
      }
    )

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (key === 'trainedModelGroupId') {
          if (value && typeof value === 'string') {
            return (
              <Tooltip key={key} title={value} placement='bottom'>
                <Typography data-testid={`model-group-${value}`}>
                  {value.substring(0, 8)}
                </Typography>
              </Tooltip>
            )
          } else {
            return (
              <Typography key={key} data-testid={`model-group-${value}`}>
                {value}
              </Typography>
            )
          }
        } else if (key === 'createdBy' && isString(value)) {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography>{value.substring(0, 8)}</Typography>
            </Tooltip>
          )
        } else if (key !== 'trainedModelGroupId' && value && value !== '') {
          return (
            <Typography key={key} data-testid={`model-group-${value}`}>
              {value}
            </Typography>
          )
        } else if (key === 'trainedModelCount') {
          return <Typography key={key}>{value}</Typography>
        } else {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
      })
    )
  }, [adjustModelGroupTableRow])

  /** テーブルに表示モデルのJSXの２次元配列 */
  const baseModelRows = useMemo(() => {
    const rowData = adjustBaseModelTableRow.map((baseModel) => {
      return {
        trainedModelId: baseModel.trainedModelId,
        trainedModelVersion: baseModel.trainedModelGroupVersion.displayName,
        trainedModelName: baseModel.trainedModelName,
        transactionStatus: baseModel.transactionStatus,
      }
    })

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '') {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        } else if (key === 'trainedModelId') {
          return (
            <Tooltip key={key} title={value} placement='bottom'>
              <Typography data-testid={`model-${value}`}>
                {value.substring(0, 8)}
              </Typography>
            </Tooltip>
          )
        } else {
          return (
            <Typography key={key}>
              {value || TABLE_CELL_NOT_APPLICABLE}
            </Typography>
          )
        }
      })
    )
  }, [adjustBaseModelTableRow])

  /** 画像セットの初期値選択 */
  useEffect(() => {
    if (
      !isUndefined(imageSetTableRow) &&
      isUndefined(props.domainData.selectedGroupedImage)
    ) {
      const imageSet = imageSetTableRow[0]
      props.setSelectedImageSet(imageSet)
    }
  }, [imageSetTableRow])

  /** 選択中のテーブルの行 */
  const [selectRowIndex, setSelectRowIndex] = useState<number | undefined>(
    undefined
  )

  /** モデルグループ詳細変換 */
  const modelGroupToDetail = (
    modelGroup?: TrainedModelGroup,
    userGroupKind?: 'UserGroup' | 'SharedUserGroup'
  ) => {
    if (isUndefined(modelGroup)) return undefined
    return {
      ['データ種別']:
        userGroupKind === 'UserGroup' ? 'カスタマーデータ' : '共有データ',
      ['モデルグループID']: modelGroup.trainedModelGroupId,
      ['登録モデル数']: `${modelGroup.trainedModelCount}`,
      ['最新バージョン']: modelGroup.latestTrainedModelVersion,
      ['最新モデル名']: modelGroup.latestTrainedModelName,
      ['更新日時']: modelGroup.updatedAt
        ? formatDateTimeSec(modelGroup.updatedAt.toDate())
        : '',
      ['作成日時']: modelGroup.createdAt
        ? formatDateTimeSec(modelGroup.createdAt.toDate())
        : '',
      ['作成ユーザーID']: modelGroup.createdBy,
    }
  }

  /** モデル詳細変換 */
  const baseModelToDetail = (
    baseModel?: TrainedModel,
    userGroupKind?: 'UserGroup' | 'SharedUserGroup'
  ) => {
    if (isUndefined(baseModel)) return undefined
    return {
      ['データ種別']:
        userGroupKind === 'UserGroup' ? 'カスタマーデータ' : '共有データ',
      ['モデルID']: baseModel.trainedModelId,
      ['バージョン']: baseModel.trainedModelGroupVersion.displayName,
      ['モデル名']: baseModel.trainedModelName,
      ['ステータス']: baseModel.transactionStatus,
    }
  }
  /** 画像セット詳細変換 */
  const imageSetToDetail = (imageSet?: GroupedImage) => {
    if (isUndefined(imageSet)) return undefined
    return {
      ['画像セットID']: imageSet.groupedDataId,
      ['画像セット名']: imageSet.groupedDataName,
      ['備考']: imageSet.remarks,
      ['生成日時']: imageSet.generatedAt
        ? formatDateTimeSec(imageSet.generatedAt.toDate())
        : '',
      ['生成ユーザーID']: imageSet.createdBy,
    }
  }

  /** tableDialogの選択したrowのデータを出力用に変換する */
  const convertToDetail = () => {
    if (isUndefined(selectRowIndex)) return

    switch (props.appState.inferenceState) {
      // TODO
      // case 0:
      //   if (
      //     isUndefined(inferenceAlgorithmTableRow) ||
      //     isUndefined(props.domainData.selectedInferenceAlgorithm.algorithmId)
      //   ) {
      //     return undefined
      //   }
      //   return inferenceModelToDetail(
      //     inferenceAlgorithmTableRow[selectRowIndex]
      //   )
      case 'GroupedDataState':
        if (isUndefined(imageSetTableRow)) {
          return undefined
        }
        return imageSetToDetail(imageSetTableRow[selectRowIndex])
      default:
        return undefined
    }
  }

  /** テーブルの列選択時のダイアログ */
  const tableDialog = useMemo(() => {
    if (isUndefined(selectRowIndex)) return
    /**  表示するものをここで受け取る */
    const detailItems = convertToDetail()
    if (isUndefined(detailItems)) {
      setSelectRowIndex(undefined)
      return
    }
    return (
      <TableDialog
        open={!isUndefined(selectRowIndex)}
        handleClose={() => setSelectRowIndex(undefined)}
        items={detailItems}
      />
    )
  }, [selectRowIndex])

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    switch (props.appState.inferenceState) {
      case 'TrainedModelListState':
        if (baseModelSubStep) {
          {
            if (!props.domainData.selectedTrainedModelGroup) return
            const maxPageNumber =
              Math.ceil(
                props.domainData.selectedTrainedModelGroup.trainedModels
                  .length / displayNumber
              ) - 1
            if (
              props.domainData.trainedModelDisplayCondition.pageNumber <=
              maxPageNumber
            ) {
              /** 表示数を変更 */
              props.setTrainedModelDisplayCondition({
                ...props.domainData.trainedModelDisplayCondition,
                displayNumber: displayNumber ?? 5,
              })
            } else {
              /** 表示数を変更 */
              props.setTrainedModelDisplayCondition({
                ...props.domainData.trainedModelDisplayCondition,
                pageNumber: maxPageNumber,
                displayNumber: displayNumber ?? 5,
              })
            }
          }
          return
        } else {
          {
            const modelGroupList =
              props.domainData.trainedModelGroupDisplayCondition
                .selectedUserGroupKind === 'SharedUserGroup'
                ? props.domainData.trainedModelGroups.sharedUserGroup
                : props.domainData.trainedModelGroups.userGroup
            const maxPageNumber =
              Math.ceil(modelGroupList.length / displayNumber) - 1
            if (
              props.domainData.trainedModelGroupDisplayCondition.pageNumber <=
              maxPageNumber
            ) {
              /** 表示数を変更 */
              props.setTrainedModelGroupDisplayCondition({
                ...props.domainData.trainedModelGroupDisplayCondition,
                displayNumber: displayNumber ?? 5,
              })
            } else {
              /** 表示数を変更 */
              props.setTrainedModelGroupDisplayCondition({
                ...props.domainData.trainedModelGroupDisplayCondition,
                pageNumber: maxPageNumber,
                displayNumber: displayNumber ?? 5,
              })
            }
          }
          return
        }
      case 'GroupedDataState':
        {
          const maxPageNumber =
            Math.ceil(
              props.domainData.groupedImageList.length / displayNumber
            ) - 1
          if (
            props.domainData.groupedImageDisplayCondition.pageNumber <=
            maxPageNumber
          ) {
            /** 表示数を変更 */
            props.setGroupedImageDisplayCondition({
              ...props.domainData.groupedImageDisplayCondition,
              displayNumber: displayNumber ?? DEFAULT_DISPLAY_NUMBER,
            })
          } else {
            /** 表示数を変更 */
            props.setGroupedImageDisplayCondition({
              ...props.domainData.groupedImageDisplayCondition,
              pageNumber: maxPageNumber,
              displayNumber: displayNumber ?? DEFAULT_DISPLAY_NUMBER,
            })
          }
        }
        return
      default:
        return
    }
  }

  /** モデルのテーブルの選択状態 */
  const selectedBaseModelIndex = useMemo(() => {
    if (isUndefined(props.domainData.selectedTrainedModel)) return -1
    return adjustBaseModelTableRow.findIndex(
      (baseModel) =>
        baseModel.trainedModelId ===
        props.domainData.selectedTrainedModel?.trainedModelId
    )
  }, [
    adjustBaseModelTableRow,
    props.domainData.selectedTrainedModel,
    props.domainData.selectedTrainedModelGroup,
  ])

  /** モデルのモデルグループテーブルの選択状態 */
  const selectedModelGroupIndex = useMemo(() => {
    if (isUndefined(props.domainData.trainedModelGroups)) return -1
    return adjustModelGroupTableRow.findIndex(
      (modelGroup: TrainedModelGroup) =>
        modelGroup.trainedModelGroupId ===
        props.domainData.selectedTrainedModelGroup?.trainedModelGroupId
    )
  }, [adjustModelGroupTableRow, props.domainData.selectedTrainedModelGroup])

  /** 画像セットテーブルの選択状態 */
  const selectedImageSetIndex = useMemo(() => {
    if (
      isUndefined(props.domainData.selectedGroupedImage) ||
      isUndefined(imageSetTableRow)
    )
      return -1
    return imageSetTableRow.findIndex(
      (imageSet) =>
        imageSet.groupedDataId ===
        props.domainData.selectedGroupedImage?.groupedDataId
    )
  }, [
    imageSetTableRow,
    props.domainData.groupedImageList,
    props.domainData.selectedGroupedImage,
  ])

  /** 画像セットのラジオボタンクリック */
  const selectImageSetTableRadio = (row: number) => {
    if (!isUndefined(imageSetTableRow)) {
      const imageSet = imageSetTableRow[row]
      props.setSelectedImageSet(imageSet)
    }
  }

  /** モデルのラジオボタンの選択された時に切り替える処理 */
  const selectBaseModelTableRadio = (row: number) => {
    const baseModel = adjustBaseModelTableRow[row]
    props.setSelectedBaseModel(baseModel)
  }

  /** モデルのモデルグループのラジオボタンの選択された時に切り替える処理 */
  const selectedModelGroupTableRadio = (row: number) => {
    const modelGroup = adjustModelGroupTableRow[row]
    props.setSelectedModelGroup(modelGroup)
  }

  return {
    convertModelKindWord,
    imageSetToDetail,
    searchedImageSetListLength,
    imageSetTableRow,
    imageSetRows,
    selectedImageSetIndex,
    selectImageSetTableRadio,
    handleChangeDisplayNumber,
    tableDialog,
    setSelectRowIndex,
    baseModelToDetail,
    modelGroupToDetail,
    modelGroupRows,
    selectedModelGroupIndex,
    selectedModelGroupTableRadio,
    selectBaseModelTableRadio,
    baseModelRows,
    selectedBaseModelIndex,
  }
}
