import React, { useRef, useEffect, useState, useMemo, ChangeEvent } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import { FileRejection } from 'react-dropzone'
import { State } from 'state/store'
import {
  DatasetAugmentationAction,
  datasetAugmentationActions,
  datasetAugmentationOperations,
  DatasetAugmentationStateKindArray,
  DatasetAugmentationStateKind,
  TextureListDisplayCondition,
  Label,
  Texture,
  PresetListDisplayCondition,
  Preset,
  RenderersInput,
  MetaData,
  ScenePartialData,
  Layout,
  OutputFormat,
} from 'state/ducks/datasetAugmentation'

import {
  CommonStepper,
  GlobalLoading,
  FileSelectableDropzone,
  showToast,
  Toast,
  CustomTrainingPageParagraph,
  SelectableTable,
  SelectableTableHeader,
  TABLE_HEADER_HEIGHT,
  RADIO_ROW_HEIGHT,
  SearchInput,
  MetadataInput,
  ErrorMessage,
  MLPipelineCompleteDialog,
  ConfirmViewerDialog,
  BreadcrumbsComponent,
  ColorChip,
} from 'views/components'
import { isUndefined } from 'utils/typeguard'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {
  USER_GROUP_KIND_LIST,
  getChipColor,
  getUserGroupKind,
  getUserGroupKindList,
} from 'views/containers/utils'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { formatDateTimeSec } from 'views/components/utils/date'
import { useTheme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Checkbox from '@mui/material/Checkbox'
import List from '@mui/material/List'
import Link from '@mui/material/Link'
import Autocomplete from '@mui/material/Autocomplete'
import Stack from '@mui/material/Stack'
import { domainDataOperations } from 'state/app/domainData/operations'
import { isUUIDv4 } from 'utils/typeguard'

/** How to simplify the description of react-redux. */
const mapStateToProps = (state: State) => ({
  ...state.pages.currentDatasetAugmentationState,
  ...state.app.domainData,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<State, void, DatasetAugmentationAction>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** 画面の必要情報の取得 */
  getParams: (datasetAugmentationStateKind: DatasetAugmentationStateKind) => {
    switch (datasetAugmentationStateKind) {
      case '3dDataState':
        dispatch(datasetAugmentationOperations.getTextureList())
        dispatch(datasetAugmentationOperations.getLayouts())
        break
      case 'RenderSettingState':
        dispatch(datasetAugmentationOperations.getPresetList())
        break
      default:
        break
    }
  },
  /** テクスチャを選択 */
  setSelectedTexture: (texture: Texture | undefined, fileIndex: number) =>
    dispatch(
      datasetAugmentationOperations.setSelectedTexture(texture, fileIndex)
    ),
  /** テクスチャを削除 */
  deleteSelectedTexture: (fileIndex: number) =>
    dispatch(datasetAugmentationOperations.deleteSelectedTexture(fileIndex)),
  /** テクスチャ一覧をクリア */
  clearTextureList: () =>
    dispatch(datasetAugmentationActions.clearTextureList()),
  /** CADデータを保持 */
  setUploadCadData: (files: File[]) => {
    dispatch(datasetAugmentationOperations.setUploadCadData(files))
  },
  /** 選択したtargetIdをセット */
  setSelectedTrgId: (fileName: string, targetId: string) => {
    dispatch(datasetAugmentationOperations.setSelectedTrgId(fileName, targetId))
  },
  /** 配置データを保持 */
  setPlacementData: (files: File[]) => {
    dispatch(datasetAugmentationOperations.setPlacementData(files[0]))
  },
  /** テクスチャを保持 */
  setUploadTextureData: (fileIndex: number, file?: File) => {
    dispatch(
      datasetAugmentationOperations.setUploadTextureData(fileIndex, file)
    )
  },
  /** 3Dデータのラベル入力 */
  setLabel: (label: Label, fileIndex: number) => {
    dispatch(datasetAugmentationOperations.setLabel(label, fileIndex))
  },
  /** テクスチャの表示条件の変更 */
  setTextureListDisplayCondition: (
    textureListCondition: TextureListDisplayCondition
  ) =>
    dispatch(
      datasetAugmentationActions.setTextureListDisplayCondition(
        textureListCondition
      )
    ),
  /** プリセットの表示条件の変更 */
  setPresetListDisplayCondition: (
    presetListCondition: PresetListDisplayCondition
  ) =>
    dispatch(
      datasetAugmentationActions.setPresetListDisplayCondition(
        presetListCondition
      )
    ),
  /** プリセットを選択 */
  setSelectedPreset: (preset?: Preset) =>
    dispatch(datasetAugmentationOperations.setSelectedPreset(preset)),
  /** バックグラウンドを選択 */
  setSelectedBackgrounds: (selectedBackgrounds: ScenePartialData[]) =>
    dispatch(
      datasetAugmentationOperations.setSelectedBackgrounds(selectedBackgrounds)
    ),
  /** 光源を選択 */
  setSelectedLightGroups: (selectedLightGroups: ScenePartialData[]) =>
    dispatch(
      datasetAugmentationOperations.setSelectedLightGroups(selectedLightGroups)
    ),
  /** カメラを選択 */
  setSelectedCameras: (selectedCameras: ScenePartialData[]) =>
    dispatch(datasetAugmentationOperations.setSelectedCameras(selectedCameras)),
  /** レイアウトを選択 */
  setSelectedLayout: (layout: Layout) =>
    dispatch(datasetAugmentationOperations.setSelectedLayout(layout)),
  /** プリセット一覧をクリア */
  clearPresetList: () => dispatch(datasetAugmentationActions.clearPresetList()),
  /** レンダラーの入力 */
  setRenderersInput: (renderersInput: RenderersInput) => {
    dispatch(datasetAugmentationOperations.setRenderersInput(renderersInput))
  },
  /** seedの入力 */
  setLayoutSeed: (layoutSeed: number) => {
    dispatch(datasetAugmentationActions.setLayoutSeed(layoutSeed))
  },
  /** オーグメンテーション用のデータセットテンプレートを抽出 */
  extractDomainDatasetTemplateListForAugmentation: () => {
    return dispatch(
      domainDataOperations.extractDomainDatasetTemplateList([
        ['All'],
        ['Train', 'Valid'],
      ])
    )
  },
  /** TrainValidのデータセットテンプレートを抽出 */
  extractTrainValidDomainDatasetTemplate: () => {
    return dispatch(
      domainDataOperations.extractDomainDatasetTemplateList([
        ['Train', 'Valid'],
      ])
    )
  },
  /** アウトプット形式の入力 */
  setOutputFormat: (outputFormat: OutputFormat) => {
    dispatch(datasetAugmentationOperations.setOutputFormat(outputFormat))
  },
  /** 入力したメタデータをセットする(オーグメンテーション) */
  setAugmentationMetaData: (data?: MetaData) =>
    dispatch(datasetAugmentationOperations.setAugmentationMetaData(data)),
  /** 入力したメタデータをセットする(データセット) */
  setGenerateDatasetMetaData: (data?: MetaData) =>
    dispatch(datasetAugmentationOperations.setGenerateDatasetMetaData(data)),
  /** 次へボタン押下時処理 */
  nextStep: (currentStep: number) => {
    if (currentStep >= DatasetAugmentationStateKindArray.length - 1) {
      /** オーグメンテーション開始 */
      dispatch(datasetAugmentationOperations.executeDatasetAugmentation())
    } else {
      dispatch(datasetAugmentationOperations.nextStep(currentStep))
    }
  },
  /** 戻るボタン押下時処理 */
  prevStep: (currentStep: number) => {
    dispatch(datasetAugmentationOperations.prevStep(currentStep))
  },
  clearDatasetAugmentationState: () =>
    dispatch(datasetAugmentationActions.clearCurrentDatasetAugmentationState()),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () =>
    dispatch(datasetAugmentationActions.setToastInfo(undefined)),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const HEADER_HEIGHT = 64
const FOOTER_HEIGHT = 100
const HEADER_MARGIN_BOTTOM = 24
const STEPPER_HEIGHT = 186
const BREADCRUMBS_HEIGHT = 40

const useStyles = makeStyles()((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
  },
  head: {
    height: STEPPER_HEIGHT,
  },
  content: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${HEADER_MARGIN_BOTTOM}px - ${STEPPER_HEIGHT}px - ${FOOTER_HEIGHT}px - ${BREADCRUMBS_HEIGHT}px)`,
    overflowY: 'auto',
  },
  footerButtons: {
    height: FOOTER_HEIGHT,
  },
  textField: {
    width: '100%',
    color: '#000 !important',
  },
  pageTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  stepper: {
    margin: theme.spacing(3),
    marginBottom: '0px',
  },
  stepContainer: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  resultCountSelectBox: {
    width: theme.custom.table.resultCountSelect.width,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  confirmButton: {
    marginRight: theme.spacing(7),
  },
  stepTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectBox: {
    width: '100%',
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchField: {
    width: '100%',
  },
  labelText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  contentBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cancelButton: {
    border: `1px solid ${theme.palette.text.primary}`,
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  settingButton: {
    backgroundColor: '#D9E5FF',
  },
}))

/** ステップの名称 */
const STEP_NAMES = [
  '3Dデータ',
  'レンダリング設定',
  '配置設定',
  'メタデータ',
  '確認',
]

/** テーブルのセルのデータ未存在時の表示 */
const TABLE_CELL_NOT_APPLICABLE = 'N/A'

/** テクスチャのテーブルの選択状態 */
const TEXTURE_HEADERS: SelectableTableHeader[] = [
  {
    id: 'name',
    title: '名前',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'overview',
    title: '概要',
    width: 200,
    sortable: false,
    position: 'center',
  },
]

/** プリセットのテーブルの選択状態 */
const PRESET_HEADERS: SelectableTableHeader[] = [
  {
    id: 'name',
    title: 'シーンプリセット名',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'overview',
    title: '概要',
    width: 150,
    sortable: false,
    position: 'center',
  },
  {
    id: 'id',
    title: 'シーンプリセットID',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'background',
    title: '背景数',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'light',
    title: '光源数',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'camera',
    title: 'カメラ設定数',
    width: 200,
    sortable: false,
    position: 'center',
  },
  {
    id: 'createdAt',
    title: '生成日時',
    width: 200,
    sortable: false,
    position: 'left',
  },
]

const MetaDataInfoConfirmView: React.FC<Props> = (props: Props) => {
  return (
    <Box component={Paper} p={'24px 32px 32px'} mt={1} width='100%'>
      <Typography>データセット生成情報（3D CAD データ）</Typography>
      <Box mt={1}>
        <MetadataInput
          nameProps={{
            label: '表示名',
            value: props.domainData.augmentationMetaData?.name,
            readOnly: true,
            variant: 'standard',
          }}
          remarksProps={{
            label: '備考',
            value: props.domainData.augmentationMetaData?.remarks,
            readOnly: true,
            variant: 'standard',
            rowNum: 0,
          }}
          textFieldSpace={0}
          helperText=''
          data-testid={'augmentationInputPreview'}
        />
      </Box>
      <Box mt={2}>
        <Typography>データセット情報</Typography>
        <Box mt={1}>
          <MetadataInput
            nameProps={{
              label: '表示名',
              value: props.domainData.generateDatasetMetaData?.name,
              readOnly: true,
              variant: 'standard',
            }}
            remarksProps={{
              label: '備考',
              value: props.domainData.generateDatasetMetaData?.remarks,
              readOnly: true,
              variant: 'standard',
              rowNum: 0,
            }}
            textFieldSpace={0}
            helperText=''
            data-testid={'generateDatasetInputPreview'}
          />
        </Box>
      </Box>
    </Box>
  )
}

const Aug3dDataInfoConfirmView: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  const globalTheme = useTheme()
  return (
    <Box component={Paper} p={'24px 32px 32px'} mt={1} width='100%'>
      <Typography>{`3Dデータ (${props.domainData.uploaded3dData.length})`}</Typography>
      {props.domainData.uploaded3dData.length !== 0 &&
        props.domainData.uploaded3dData.map((fileAndData, index) => {
          return (
            <Box ml={2} mt={2} mb={2} key={index}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant='body1' className={classes.labelText}>
                    {fileAndData.file.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ borderTop: 'dotted 1px' }}>
                  <List style={{ width: '100%' }}>
                    <Typography>ラベル</Typography>
                    <Box ml={2}>
                      <Box
                        display='flex'
                        width='100%'
                        borderBottom={'solid 1px'}
                      >
                        <Box width='30%'>ラベルID</Box>
                        <Box>{fileAndData.label.id}</Box>
                      </Box>
                      <Box mt={2}>
                        <Box
                          display='flex'
                          width='100%'
                          borderBottom={'solid 1px'}
                        >
                          <Box width='30%'>ラベル名</Box>
                          <Box>{fileAndData.label.name}</Box>
                        </Box>
                      </Box>
                      <Box mt={2}>
                        <Box
                          display='flex'
                          width='100%'
                          borderBottom={'solid 1px'}
                        >
                          <Box width='30%'>カテゴリ</Box>
                          <Box>{fileAndData.label.category}</Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box mt={2}>
                      <Typography>テクスチャ</Typography>
                    </Box>
                    {fileAndData.userUploadedTexture ? (
                      <Box mt={2}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography
                              variant='body1'
                              className={classes.labelText}
                            >
                              {fileAndData.userUploadedTexture.file.name}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails style={{ borderTop: 'dotted 1px' }}>
                            <Box ml={2}>
                              <Box
                                display='flex'
                                width='100%'
                                borderBottom={'solid 1px'}
                              >
                                <Box width='30%'>データ種別</Box>
                                <Box
                                  sx={{
                                    color: globalTheme.palette.text.secondary,
                                  }}
                                >
                                  {TABLE_CELL_NOT_APPLICABLE}
                                </Box>
                              </Box>
                              <Box mt={2}>
                                <Box
                                  display='flex'
                                  width='100%'
                                  borderBottom={'solid 1px'}
                                >
                                  <Box width='30%'>名前</Box>
                                  <Box
                                    sx={{
                                      color: globalTheme.palette.text.secondary,
                                    }}
                                  >
                                    {TABLE_CELL_NOT_APPLICABLE}
                                  </Box>
                                </Box>
                              </Box>
                              <Box mt={2}>
                                <Box
                                  display='flex'
                                  width='100%'
                                  borderBottom={'solid 1px'}
                                >
                                  <Box width='30%'>概要</Box>
                                  <Box
                                    sx={{
                                      color: globalTheme.palette.text.secondary,
                                    }}
                                  >
                                    {TABLE_CELL_NOT_APPLICABLE}
                                  </Box>
                                </Box>
                              </Box>
                              <Box mt={2}>
                                <Box
                                  display='flex'
                                  width='100%'
                                  borderBottom={'solid 1px'}
                                >
                                  <Box width='30%'>ファイル名</Box>
                                  <Box>
                                    {fileAndData.userUploadedTexture.file.name}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    ) : fileAndData.texture ? (
                      <Box mt={2}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography
                              variant='body1'
                              className={classes.labelText}
                            >
                              {fileAndData.texture.name}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails style={{ borderTop: 'dotted 1px' }}>
                            <Box ml={2}>
                              <Box
                                display='flex'
                                width='100%'
                                borderBottom={'solid 1px'}
                              >
                                <Box width='30%'>データ種別</Box>
                                <Box>
                                  {
                                    USER_GROUP_KIND_LIST.find(
                                      (userGroupKind) =>
                                        userGroupKind.kind ===
                                        fileAndData.texture?.userGroupKind
                                    )?.name
                                  }
                                </Box>
                              </Box>
                              <Box mt={2}>
                                <Box
                                  display='flex'
                                  width='100%'
                                  borderBottom={'solid 1px'}
                                >
                                  <Box width='30%'>名前</Box>
                                  <Box>{fileAndData.texture.name}</Box>
                                </Box>
                              </Box>
                              <Box mt={2}>
                                <Box
                                  display='flex'
                                  width='100%'
                                  borderBottom={'solid 1px'}
                                >
                                  <Box width='30%'>概要</Box>
                                  <Box>{fileAndData.texture.overview}</Box>
                                </Box>
                              </Box>
                              <Box mt={2}>
                                <Box
                                  display='flex'
                                  width='100%'
                                  borderBottom={'solid 1px'}
                                >
                                  <Box width='30%'>ファイル名</Box>
                                  <Box
                                    sx={{
                                      color: globalTheme.palette.text.secondary,
                                    }}
                                  >
                                    {TABLE_CELL_NOT_APPLICABLE}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
          )
        })}
    </Box>
  )
}

const RenderSettingInfoConfirmView: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  return (
    <Box component={Paper} p={'24px 32px 32px'} mt={1} width='100%'>
      <TextField
        label='生成枚数'
        style={{ width: '100%', marginBottom: '16px' }}
        value={props.domainData.renderersInput.augmentationNumber}
        variant='standard'
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        label='シーン数'
        style={{ width: '100%', marginBottom: '16px' }}
        value={
          props.domainData.selectedBackgrounds.length *
          props.domainData.selectedCameras.length *
          props.domainData.selectedLightGroups.length
        }
        variant='standard'
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        label='画像総数'
        style={{ width: '100%', marginBottom: '16px' }}
        value={
          props.domainData.selectedBackgrounds.length *
          props.domainData.selectedCameras.length *
          props.domainData.selectedLightGroups.length *
          props.domainData.renderersInput.augmentationNumber
        }
        variant='standard'
        InputProps={{
          readOnly: true,
        }}
      />
      <CustomTrainingPageParagraph level={'part'} title={'シーン設定'}>
        <Box m={2}>
          <Stack spacing={2}>
            <Box>
              <Typography>背景</Typography>
              <Autocomplete
                readOnly
                disabled
                multiple
                value={props.domainData.selectedBackgrounds}
                defaultValue={props.domainData.selectedBackgrounds}
                options={props.domainData.backgrounds}
                getOptionLabel={(option) => option.name}
                onChange={(_event, value) => {
                  props.setSelectedBackgrounds(value)
                }}
                renderInput={(params) => <TextField {...params} />}
                renderTags={(tagValue) => {
                  return tagValue.map((option, index) => (
                    <ColorChip
                      key={index}
                      label={option.name}
                      size='medium'
                      isStretch
                      variant='filled'
                      customColor={getChipColor(
                        option.userGroupId,
                        props.authedUser.auth.customClaims
                      )}
                      sx={{
                        marginRight: (theme) => theme.spacing(1),
                      }}
                    />
                  ))
                }}
              />
            </Box>
            <Box>
              <Typography>光源</Typography>
              <Autocomplete
                readOnly
                disabled
                multiple
                value={props.domainData.selectedLightGroups}
                defaultValue={props.domainData.selectedLightGroups}
                options={props.domainData.lightGroups}
                getOptionLabel={(option) => option.name}
                onChange={(_event, value) => {
                  props.setSelectedLightGroups(value)
                }}
                renderInput={(params) => <TextField {...params} />}
                renderTags={(tagValue) => {
                  return tagValue.map((option, index) => (
                    <ColorChip
                      key={index}
                      label={option.name}
                      size='medium'
                      isStretch
                      variant='filled'
                      customColor={getChipColor(
                        option.userGroupId,
                        props.authedUser.auth.customClaims
                      )}
                      sx={{
                        marginRight: (theme) => theme.spacing(1),
                      }}
                    />
                  ))
                }}
              />
            </Box>
            <Box>
              <Typography>カメラ配置</Typography>
              <Autocomplete
                readOnly
                disabled
                multiple
                value={props.domainData.selectedCameras}
                defaultValue={props.domainData.selectedCameras}
                options={props.domainData.cameras}
                getOptionLabel={(option) => option.name}
                onChange={(_event, value) => {
                  props.setSelectedCameras(value)
                }}
                renderInput={(params) => <TextField {...params} />}
                renderTags={(tagValue) => {
                  return tagValue.map((option, index) => (
                    <ColorChip
                      key={index}
                      label={option.name}
                      size='medium'
                      isStretch
                      variant='filled'
                      customColor={getChipColor(
                        option.userGroupId,
                        props.authedUser.auth.customClaims
                      )}
                      sx={{
                        marginRight: (theme) => theme.spacing(1),
                      }}
                    />
                  ))
                }}
              />
            </Box>
          </Stack>
        </Box>
      </CustomTrainingPageParagraph>
      <Box mt={2} mb={2}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant='body1' className={classes.labelText}>
              アドバンスド設定
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box mt={2}>
              <FormHelperText>Seed</FormHelperText>
              <TextField
                className={classes.textField}
                inputProps={{
                  readOnly: true,
                }}
                value={props.domainData.layoutSeed}
              />
            </Box>
            <Box mt={2} ml={2}>
              <Typography>レンダラー</Typography>
              <Box mt={1} ml={1}>
                <Typography>画像サイズ</Typography>
              </Box>
              <Box ml={2}>
                <FormHelperText>横</FormHelperText>
                <TextField
                  className={classes.textField}
                  inputProps={{
                    readOnly: true,
                  }}
                  value={props.domainData.renderersInput.size.width}
                />
                <Box mt={2}>
                  <FormHelperText>縦</FormHelperText>
                  <TextField
                    className={classes.textField}
                    value={props.domainData.renderersInput.size.height}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box mt={2} ml={2}>
              <Typography>Depth</Typography>
              <Box ml={2}>
                <FormHelperText>Min</FormHelperText>
                <TextField
                  className={classes.textField}
                  value={props.domainData.renderersInput.depth.min}
                  inputProps={{
                    readOnly: true,
                  }}
                />
                <Box mt={2}>
                  <FormHelperText>Max</FormHelperText>
                  <TextField
                    className={classes.textField}
                    value={props.domainData.renderersInput.depth.max}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box mt={2} ml={2}>
              <Typography>アウトプット形式</Typography>
              <Box ml={2}>
                <FormHelperText>アノテーション種別</FormHelperText>
                <Box>
                  <FormControl variant='outlined' className={classes.selectBox}>
                    <Select
                      readOnly
                      disabled
                      value={props.domainData.outputFormat?.datasetTemplateId}
                    >
                      {props.datasetTemplates.map((datasetTemplate) => (
                        <MenuItem
                          data-testid={datasetTemplate.datasetTemplateId}
                          value={datasetTemplate.datasetTemplateId}
                          key={datasetTemplate.datasetTemplateId}
                        >
                          {datasetTemplate.metadata.name.ja}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {!isUndefined(
                  props.domainData.outputFormat?.trainValidRatio
                ) && (
                  <Box mt={2}>
                    <FormHelperText>Train : Valid 比率（%）</FormHelperText>
                    <TextField
                      className={classes.textField}
                      value={props.domainData.outputFormat?.trainValidRatio}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  )
}

const PlacementInfoConfirmView: React.FC<Props> = (props: Props) => {
  const { classes } = useStyles()
  return (
    <Box component={Paper} p={'24px 32px 32px'} mt={1} width='100%'>
      <Typography>レイアウト</Typography>
      <Box ml={2}>
        <FormControl variant='outlined' className={classes.selectBox}>
          <Select
            value={props.domainData.selectedLayout?.id}
            defaultValue={props.domainData.selectedLayout?.id}
            readOnly
            disabled
          >
            {props.domainData.layouts.map((layout) => (
              <MenuItem
                data-testid={layout.id}
                value={layout.id}
                key={layout.id}
              >
                {layout.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box mt={2}>
        <Typography>配置データ(JSON) </Typography>
      </Box>
      {props.domainData.uploadedPlacementData && (
        <Box ml={2} mb={2}>
          <Accordion expanded>
            <AccordionSummary
              style={{ pointerEvents: 'none' }}
              expandIcon={<></>}
            >
              <Typography variant='body1' className={classes.labelText}>
                {props.domainData.uploadedPlacementData.file.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ borderTop: 'dotted 1px' }}>
              <List style={{ width: '100%' }}>
                {props.domainData.uploadedPlacementData.targetIds.map(
                  (targetId, index) => {
                    return (
                      <Box mt={2} key={index}>
                        <Typography>{`tgt_id: ${targetId}`}</Typography>
                        <Box ml={2}>
                          <FormControl
                            variant='outlined'
                            className={classes.selectBox}
                          >
                            <Select
                              value={
                                props.domainData.uploaded3dData.find(
                                  (data) => data.targetId === targetId
                                )?.file.name
                              }
                              defaultValue=''
                              onChange={(e: SelectChangeEvent<string>) =>
                                props.setSelectedTrgId(
                                  e.target.value as string,
                                  targetId
                                )
                              }
                              readOnly
                              disabled
                            >
                              {props.domainData.uploaded3dData.map(
                                (upload3dData, index) => (
                                  <MenuItem
                                    key={index}
                                    data-testid={upload3dData.file.name}
                                    value={upload3dData.file.name}
                                  >
                                    {upload3dData.file.name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    )
                  }
                )}
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </Box>
  )
}

const DatasetAugmentation: React.FC<Props> = (props: Props) => {
  const history = useHistory()
  const { classes } = useStyles()
  /** コンテンツエリア（ステッパー、戻る/次へ表示エリア以外） */
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    return () => {
      props.clearDatasetAugmentationState()
    }
  }, [])

  const [currentFileIndex, setCurrentFileIndex] = useState(0)

  /** オーグメンテーションの開始失敗時メッセージ */
  const AugmentationErrorMessage = (props: Props): JSX.Element => {
    const errorMessages: string[] = []
    if (
      props.appState.datasetAugmentationSubState.executeSubState ===
      'ExecuteError'
    ) {
      errorMessages.push('生成に失敗しました。')
      return <ErrorMessage title='' targets={errorMessages} />
    } else return <></>
  }

  const {
    selectedUserGroupKind,
    handleChangeDisplayNumber,
    pageChange,
    textureRows,
    selectTextureTableRadio,
    presetRows,
    selectPresetTableRadio,
    searchTableContent,
    handleChangeSearchValue,
    tableSearchValue,
    searchedPresetListLength,
  } = tableActions(props, currentFileIndex)

  useEffect(() => {
    if (props.appState.toastInfo) {
      showErrorToast(
        props.appState.toastInfo.title,
        props.appState.toastInfo.targets
      )
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  const showErrorToast = (fileName: string, messages: string[]) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div data-testid='uploadErrorToastFileName'>{`${fileName}`}</div>
        <div>
          {messages.map((message) => (
            <li key={message}>{message}</li>
          ))}
        </div>
      </div>
    )

  const fileUploadErrors = (fileRejections: FileRejection[]) =>
    fileRejections.forEach((errorFile) => {
      const errorFileName = errorFile.file.name
      const fileErrors = errorFile.errors
      const errorMessage: string[] = []
      fileErrors.forEach((errorFileMessage) => {
        switch (errorFileMessage.code) {
          case 'file-too-large': {
            errorMessage.push('ファイルサイズが不正です')
            break
          }
          case 'file-invalid-type': {
            errorMessage.push('選択されたファイルの形式が不正です')
            break
          }
          default:
            break
        }
      })
      showErrorToast(errorFileName, errorMessage)
    })

  const [openTextureSelectDialog, setOpenTextureSelectDialog] = useState(false)
  const [selectDialogSelectedTexture, setSelectDialogSelectedTexture] =
    useState<number>(0)

  const [openPresetDialog, setOpenPresetDialog] = useState(false)
  const [selectDialogSelectedPreset, setSelectDialogSelectedPreset] =
    useState<number>(0)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  /** カレントのステップ */
  const currentStep = useMemo(
    () =>
      DatasetAugmentationStateKindArray.indexOf(
        props.appState.datasetAugmentationState
      ),
    [props.appState.datasetAugmentationState]
  )

  /** 次へのボタン制御 */
  const enableNextButton = useMemo(() => {
    switch (props.appState.datasetAugmentationState) {
      case '3dDataState':
        return (
          props.appState.datasetAugmentationSubState['3dDataSubState'] ===
          'InputRequired'
        )
      case 'RenderSettingState':
        return (
          props.appState.datasetAugmentationSubState.renderSettingSubState ===
          'InputRequired'
        )
      case 'PlacementState':
        return (
          props.appState.datasetAugmentationSubState.placementSubState ===
          'InputRequired'
        )
      case 'MetaDataState':
        return (
          props.appState.datasetAugmentationSubState.metaDataSubState ===
          'InputRequired'
        )
      case 'ExecuteState':
        return true
      default:
        return false
    }
  }, [
    props.appState.datasetAugmentationState,
    props.appState.datasetAugmentationSubState,
  ])

  /** ステップ更新時のスクロール操作 */
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
    }
  }, [currentStep])

  /** 各種一覧取得 */
  useEffect(() => {
    props.getParams(props.appState.datasetAugmentationState)
  }, [props.appState.datasetAugmentationState])

  /** 次ページの表示 */
  const nextPageAction = () => {
    props.nextStep(currentStep)
  }

  /** 前ページ表示 */
  const prevPageAction = () => {
    props.prevStep(currentStep)
  }

  const userGroupKindList = getUserGroupKindList(
    props.authedUser.auth.customClaims.sharedList
  )

  /** Stepによってコンテンツを切り替える */
  const getStepContent = (props: Props): JSX.Element => {
    const [minInputValue, setMinInputValue] = useState<string>(
      props.domainData.renderersInput.depth.min.toLocaleString()
    )
    const [maxInputValue, setMaxInputValue] = useState<string>(
      props.domainData.renderersInput.depth.max.toLocaleString(undefined, {
        maximumFractionDigits: 4,
      })
    )

    const handleDepthChange =
      (key: string, setInputValue: (value: string) => void) =>
      (event: ChangeEvent) => {
        event.preventDefault()
        const element = event.target as HTMLInputElement
        if (/^([\d]*(\.[\d]*)?)$/.test(element.value)) {
          let value = 0
          try {
            value =
              element.value === ''
                ? 0
                : parseFloat(
                    element.value.endsWith('.')
                      ? `${element.value}0`
                      : element.value
                  )
          } catch (e: unknown) {
            value = 0
          }
          if (Number.isNaN(value)) {
            value = 0
            setInputValue('')
          } else {
            setInputValue(
              element.value.startsWith('.')
                ? `0${element.value}`
                : element.value
            )
          }
          props.setRenderersInput({
            ...props.domainData.renderersInput,
            depth: {
              ...props.domainData.renderersInput.depth,
              [key]: value,
            },
          })
        }
      }

    const datasetTemplates = useMemo(() => {
      return props.extractDomainDatasetTemplateListForAugmentation()
    }, [props.extractDomainDatasetTemplateListForAugmentation])

    const trainValidDatasetTemplateId = useMemo(() => {
      const datasetTemplates = props.extractTrainValidDomainDatasetTemplate()
      return datasetTemplates.length > 0
        ? datasetTemplates[0].datasetTemplateId
        : undefined
    }, [props.extractTrainValidDomainDatasetTemplate])

    const handleAnnotationKindChange = (datasetTemplateId: string) => {
      props.setOutputFormat({
        datasetTemplateId: datasetTemplateId,
        trainValidRatio:
          datasetTemplateId === trainValidDatasetTemplateId
            ? props.domainData.outputFormat?.trainValidRatio
            : undefined,
      })
    }

    useEffect(() => {
      if (
        isUndefined(props.domainData.outputFormat) &&
        !isUndefined(trainValidDatasetTemplateId)
      ) {
        props.setOutputFormat({
          datasetTemplateId: trainValidDatasetTemplateId,
          trainValidRatio: 20,
        })
      }
    }, [props.domainData.outputFormat, trainValidDatasetTemplateId])

    const handleTrainValidRatioChange = (trainValidRatioStr: string) => {
      if (
        isUndefined(props.domainData.outputFormat) ||
        isUndefined(props.domainData.outputFormat.datasetTemplateId)
      )
        return

      const trainValidRatioNumber = Number(trainValidRatioStr)
      props.setOutputFormat({
        datasetTemplateId: props.domainData.outputFormat.datasetTemplateId,
        trainValidRatio:
          trainValidRatioStr && !isNaN(trainValidRatioNumber)
            ? trainValidRatioNumber
            : undefined,
      })
    }

    switch (props.appState.datasetAugmentationState) {
      case '3dDataState':
        return (
          <div className={classes.stepContainer}>
            <div className={classes.stepTitle}>
              <Typography>3D CADデータ(STL) </Typography>
            </div>
            <FileSelectableDropzone
              acceptFileOperation={(files: File[]) =>
                props.setUploadCadData(files)
              }
              rejectedFileOperation={(fileRejections: FileRejection[]) =>
                fileUploadErrors(fileRejections)
              }
              fileType='.stl'
              data-testid='3dDataFileDropZone'
            />
            {props.domainData.uploaded3dData.length !== 0 &&
              props.domainData.uploaded3dData.map((fileAndData, index) => {
                return (
                  <Box mt={2} mb={2} key={index}>
                    <Accordion data-testid='open-accordion'>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography
                          variant='body1'
                          className={classes.labelText}
                        >
                          {fileAndData.file.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ borderTop: 'dotted 1px' }}>
                        <List style={{ width: '100%' }}>
                          <Box>
                            <FormHelperText>ラベルID</FormHelperText>
                            <TextField
                              className={classes.textField}
                              value={fileAndData.label.id}
                              onChange={(event) =>
                                props.setLabel(
                                  {
                                    ...fileAndData.label,
                                    id: event.target.value,
                                  },
                                  index
                                )
                              }
                              error={!isUUIDv4(fileAndData.label.id)}
                              helperText={
                                !isUUIDv4(fileAndData.label.id)
                                  ? 'uuidv4で入力してください'
                                  : undefined
                              }
                              inputProps={{ 'data-testid': 'input-label-id' }}
                              required
                            />
                          </Box>
                          <Box mt={2}>
                            <FormHelperText>ラベル名</FormHelperText>
                            <TextField
                              className={classes.textField}
                              value={fileAndData.label.name}
                              onChange={(event) =>
                                props.setLabel(
                                  {
                                    ...fileAndData.label,
                                    name: event.target.value,
                                  },
                                  index
                                )
                              }
                              error={fileAndData.label.name === ''}
                              helperText={
                                fileAndData.label.name === ''
                                  ? '入力してください'
                                  : undefined
                              }
                              inputProps={{ 'data-testid': 'input-label-name' }}
                              required
                            />
                          </Box>
                          <Box mt={2}>
                            <FormHelperText>カテゴリ</FormHelperText>
                            <TextField
                              className={classes.textField}
                              value={fileAndData.label.category}
                              onChange={(event) =>
                                props.setLabel(
                                  {
                                    ...fileAndData.label,
                                    category: event.target.value,
                                  },
                                  index
                                )
                              }
                              inputProps={{ 'data-testid': 'input-category' }}
                            />
                          </Box>
                          <Box mt={2}>
                            <div className={classes.flexAndBetween}>
                              <FormHelperText>
                                テクスチャ( BLEND )
                              </FormHelperText>
                              <Link
                                data-testid='select-texture'
                                onClick={() => {
                                  setCurrentFileIndex(index)
                                  const selectedTextureId =
                                    props.domainData.uploaded3dData[
                                      currentFileIndex
                                    ]?.texture?.id
                                  if (selectedTextureId === undefined) {
                                    setSelectDialogSelectedTexture(0)
                                  } else {
                                    const textureIndex =
                                      props.domainData.textureList.findIndex(
                                        (texture) => {
                                          return texture.id == selectedTextureId
                                        }
                                      )
                                    setSelectDialogSelectedTexture(textureIndex)
                                  }
                                  setOpenTextureSelectDialog(true)
                                }}
                              >
                                <Typography>
                                  既存のテクスチャから選択する
                                </Typography>
                              </Link>
                            </div>
                          </Box>
                          <Box
                            display='flex'
                            width='100%'
                            minHeight='37px'
                            borderBottom={'solid 1px'}
                          >
                            {fileAndData.userUploadedTexture ? (
                              <ColorChip
                                label={
                                  fileAndData.userUploadedTexture.file.name
                                }
                                size='medium'
                                variant='filled'
                                isStretch
                                customColor={'rgba(139, 195, 74, 0.4)'}
                                sx={{
                                  marginBottom: (theme) => theme.spacing(0.5),
                                }}
                                onDelete={() => {
                                  fileAndData.userUploadedTexture &&
                                    props.setUploadTextureData(index)
                                }}
                              />
                            ) : fileAndData.texture ? (
                              <ColorChip
                                label={fileAndData.texture?.name}
                                size='medium'
                                variant='filled'
                                isStretch
                                customColor={getChipColor(
                                  fileAndData.texture.userGroupId,
                                  props.authedUser.auth.customClaims
                                )}
                                sx={{
                                  marginBottom: (theme) => theme.spacing(0.5),
                                }}
                                onDelete={() => {
                                  selectTextureTableRadio(-1)
                                  props.deleteSelectedTexture(index)
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </Box>
                          <Box mt={2}>
                            <FileSelectableDropzone
                              acceptFileOperation={(files: File[]) => {
                                props.setUploadTextureData(index, files[0])
                              }}
                              rejectedFileOperation={(
                                fileRejections: FileRejection[]
                              ) => fileUploadErrors(fileRejections)}
                              fileType='.blend'
                              data-testid='textureFileDropZone'
                            />
                          </Box>
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                )
              })}
            <Dialog
              maxWidth={'sm'}
              open={openTextureSelectDialog}
              fullWidth={true}
              onClose={() => setOpenTextureSelectDialog(false)}
            >
              <Box m={2}>
                <DialogTitle>テクスチャプリセット</DialogTitle>
                <DialogContent>
                  <Typography>CADデータ</Typography>
                  <Box ml={2}>
                    <Box display='flex' width='100%' borderBottom={'solid 1px'}>
                      <Box width='30%'>ファイル名</Box>
                      <Box>
                        {props.domainData.uploaded3dData[currentFileIndex]
                          ? props.domainData.uploaded3dData[currentFileIndex]
                              .file.name
                          : ''}
                      </Box>
                    </Box>
                  </Box>
                  <Box mt={2} mb={2}>
                    <Typography>テクスチャ</Typography>
                  </Box>
                  <CustomTrainingPageParagraph>
                    <FormControl
                      variant='outlined'
                      className={classes.selectBox}
                    >
                      <InputLabel>データ種別</InputLabel>
                      <Select
                        value={
                          props.domainData.textureListDisplayCondition
                            .selectedUserGroupKind
                        }
                        onChange={(
                          e: SelectChangeEvent<'UserGroup' | 'SharedUserGroup'>
                        ) =>
                          selectedUserGroupKind(
                            e.target.value as 'UserGroup' | 'SharedUserGroup'
                          )
                        }
                        label='Select User Group Kind'
                      >
                        {userGroupKindList.map((kind) => (
                          <MenuItem
                            data-testid={kind.kind}
                            value={kind.kind}
                            key={kind.kind}
                          >
                            {kind.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </CustomTrainingPageParagraph>
                  <CustomTrainingPageParagraph level={'chapter'}>
                    <SelectableTable
                      displayNumber={
                        props.domainData.textureListDisplayCondition
                          .displayNumber
                      }
                      headers={TEXTURE_HEADERS}
                      rows={textureRows}
                      totalCount={props.domainData.textureList.length}
                      tableHeight={TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT}
                      onChangeDisplayNumber={(displayNumber: number) =>
                        handleChangeDisplayNumber(displayNumber)
                      }
                      selectedRowNumber={selectDialogSelectedTexture}
                      fixedColumnNumber={0}
                      page={
                        props.domainData.textureListDisplayCondition.pageNumber
                      }
                      onClickRadio={(row: number) =>
                        setSelectDialogSelectedTexture(row)
                      }
                      onClickPageChange={(pageNumber: number) =>
                        pageChange(pageNumber)
                      }
                    />
                  </CustomTrainingPageParagraph>
                </DialogContent>
                <div className={classes.contentBtn}>
                  <Button
                    className={classes.cancelButton}
                    onClick={() => {
                      setOpenTextureSelectDialog(false)
                    }}
                    color={'inherit'}
                  >
                    戻る
                  </Button>
                  <Button
                    className={classes.settingButton}
                    onClick={() => {
                      selectTextureTableRadio(selectDialogSelectedTexture)
                      setOpenTextureSelectDialog(false)
                    }}
                    data-testid='set-select-texture'
                  >
                    <Typography>設定</Typography>
                  </Button>
                </div>
              </Box>
            </Dialog>
          </div>
        )
      case 'RenderSettingState':
        return (
          <div className={classes.stepContainer}>
            <Box mb={2} borderBottom={'dotted 1px'}>
              <CustomTrainingPageParagraph level={'part'} title={'生成枚数'}>
                <TextField
                  className={classes.textField}
                  value={props.domainData.renderersInput.augmentationNumber}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    'data-testid': 'input-augmentation-number',
                  }}
                  onChange={(event) => {
                    let augmentationNumber = 0
                    try {
                      augmentationNumber =
                        event.target.value === ''
                          ? 0
                          : parseInt(event.target.value)
                    } catch (e: unknown) {
                      augmentationNumber = 0
                    }
                    if (Number.isNaN(augmentationNumber)) {
                      augmentationNumber = 0
                    }
                    props.setRenderersInput({
                      ...props.domainData.renderersInput,
                      augmentationNumber,
                    })
                  }}
                />
                <FormHelperText>
                  １シーンごとの枚数を指定します。
                </FormHelperText>
              </CustomTrainingPageParagraph>
            </Box>
            <Box mt={2}>
              <div className={classes.flexAndBetween}>
                <Typography>
                  シーン設定(
                  {` ${
                    props.domainData.selectedBackgrounds.length *
                    props.domainData.selectedCameras.length *
                    props.domainData.selectedLightGroups.length
                  }シーン `}
                  )
                </Typography>
                <Link
                  onClick={() => {
                    setOpenPresetDialog(true)
                  }}
                  data-testid='select-preset'
                >
                  <Typography>プリセットを選択する</Typography>
                </Link>
              </div>
            </Box>
            <Box mt={2} mb={2}>
              <Stack spacing={2}>
                <Box>
                  <Typography>背景</Typography>
                  <Autocomplete
                    data-testid='background-select'
                    multiple
                    disableCloseOnSelect
                    value={props.domainData.selectedBackgrounds}
                    defaultValue={props.domainData.selectedBackgrounds}
                    filterSelectedOptions
                    options={props.domainData.backgrounds}
                    groupBy={(option) =>
                      getUserGroupKind(props.authedUser.auth.customClaims).find(
                        (kind) => kind.id === option.userGroupId
                      )?.name ?? ''
                    }
                    getOptionLabel={(option) => (option ? option.name : '')}
                    onChange={(_event, value) => {
                      props.setSelectedBackgrounds(value)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    renderTags={(tagValue) => {
                      return tagValue.map((option, index) => (
                        <ColorChip
                          key={index}
                          label={option.name}
                          size='medium'
                          variant='filled'
                          isStretch
                          customColor={getChipColor(
                            option.userGroupId,
                            props.authedUser.auth.customClaims
                          )}
                          sx={{
                            marginRight: (theme) => theme.spacing(1),
                          }}
                          onDelete={() =>
                            props.setSelectedBackgrounds(
                              props.domainData.selectedBackgrounds.filter(
                                (selectedBackground) =>
                                  selectedBackground !== option
                              )
                            )
                          }
                        />
                      ))
                    }}
                    renderGroup={(params) => (
                      <li
                        key={params.key}
                        data-testId={`background-${
                          params.group === '共有データ' ? 'shared' : 'customer'
                        }`}
                      >
                        <Box
                          pl={1}
                          color={{
                            backgroundColor: getChipColor(
                              params.group,
                              props.authedUser.auth.customClaims
                            ),
                          }}
                        >
                          {params.group}
                        </Box>
                        <Box>{params.children}</Box>
                      </li>
                    )}
                  />
                </Box>
                <Box>
                  <Typography>光源</Typography>
                  <Autocomplete
                    data-testid='light-group-select'
                    multiple
                    disableCloseOnSelect
                    filterSelectedOptions
                    value={props.domainData.selectedLightGroups}
                    defaultValue={props.domainData.selectedLightGroups}
                    options={props.domainData.lightGroups}
                    getOptionLabel={(option) => (option ? option.name : '')}
                    groupBy={(option) =>
                      getUserGroupKind(props.authedUser.auth.customClaims).find(
                        (kind) => kind.id === option.userGroupId
                      )?.name ?? ''
                    }
                    onChange={(_event, value) => {
                      props.setSelectedLightGroups(value)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    renderTags={(tagValue) => {
                      return tagValue.map((option, index) => (
                        <ColorChip
                          key={index}
                          label={option.name}
                          size='medium'
                          variant='filled'
                          isStretch
                          customColor={getChipColor(
                            option.userGroupId,
                            props.authedUser.auth.customClaims
                          )}
                          sx={{
                            marginRight: (theme) => theme.spacing(1),
                          }}
                          onDelete={() =>
                            props.setSelectedLightGroups(
                              props.domainData.selectedLightGroups.filter(
                                (selectedLightGroup) =>
                                  selectedLightGroup !== option
                              )
                            )
                          }
                        />
                      ))
                    }}
                    renderGroup={(params) => (
                      <li
                        key={params.key}
                        data-testId={`light-group-${
                          params.group === '共有データ' ? 'shared' : 'customer'
                        }`}
                      >
                        <Box
                          pl={1}
                          color={{
                            backgroundColor: getChipColor(
                              params.group,
                              props.authedUser.auth.customClaims
                            ),
                          }}
                        >
                          {params.group}
                        </Box>
                        <Box>{params.children}</Box>
                      </li>
                    )}
                  />
                </Box>
                <Box>
                  <Typography>カメラ配置</Typography>
                  <Autocomplete
                    data-testid='camera-select'
                    multiple
                    disableCloseOnSelect
                    filterSelectedOptions
                    value={props.domainData.selectedCameras}
                    defaultValue={props.domainData.selectedCameras}
                    options={props.domainData.cameras}
                    getOptionLabel={(option) => (option ? option.name : '')}
                    groupBy={(option) =>
                      getUserGroupKind(props.authedUser.auth.customClaims).find(
                        (kind) => kind.id === option.userGroupId
                      )?.name ?? ''
                    }
                    onChange={(_event, value) => {
                      props.setSelectedCameras(value)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    renderTags={(tagValue) => {
                      return tagValue.map((option, index) => (
                        <ColorChip
                          key={index}
                          label={option.name}
                          size='medium'
                          variant='filled'
                          isStretch
                          customColor={getChipColor(
                            option.userGroupId,
                            props.authedUser.auth.customClaims
                          )}
                          sx={{
                            marginRight: (theme) => theme.spacing(1),
                          }}
                          onDelete={() =>
                            props.setSelectedCameras(
                              props.domainData.selectedCameras.filter(
                                (selectedCamera) => selectedCamera !== option
                              )
                            )
                          }
                        />
                      ))
                    }}
                    renderGroup={(params) => (
                      <li
                        key={params.key}
                        data-testId={`camera-${
                          params.group === '共有データ' ? 'shared' : 'customer'
                        }`}
                      >
                        <Box
                          pl={1}
                          color={{
                            backgroundColor: getChipColor(
                              params.group,
                              props.authedUser.auth.customClaims
                            ),
                          }}
                        >
                          {params.group}
                        </Box>
                        <Box>{params.children}</Box>
                      </li>
                    )}
                  />
                </Box>
              </Stack>
            </Box>
            <Box mt={2} mb={2}>
              <Typography data-testid='scene-count'>
                シーン設定(
                {` 画像総数 : ${
                  props.domainData.selectedBackgrounds.length *
                  props.domainData.selectedCameras.length *
                  props.domainData.selectedLightGroups.length
                } * ${props.domainData.renderersInput.augmentationNumber} = ${
                  props.domainData.selectedBackgrounds.length *
                  props.domainData.selectedCameras.length *
                  props.domainData.selectedLightGroups.length *
                  props.domainData.renderersInput.augmentationNumber
                } 枚 `}
                )
              </Typography>
            </Box>
            <Box mt={2} mb={2}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant='body1' className={classes.labelText}>
                    アドバンスド設定
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box ml={2}>
                    <FormHelperText>Seed</FormHelperText>
                    <TextField
                      className={classes.textField}
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      }}
                      value={props.domainData.layoutSeed}
                      onChange={(event) => {
                        let seed = 0
                        try {
                          seed =
                            event.target.value === ''
                              ? 0
                              : parseInt(event.target.value)
                        } catch (e: unknown) {
                          seed = 0
                        }
                        if (Number.isNaN(seed)) {
                          seed = 0
                        }
                        props.setLayoutSeed(seed)
                      }}
                    />
                  </Box>
                  <Box mt={2} ml={2}>
                    <Typography>レンダラー</Typography>
                    <Box mt={1} ml={1}>
                      <Typography>画像サイズ</Typography>
                    </Box>
                    <Box ml={2}>
                      <Box>
                        <FormHelperText>横</FormHelperText>
                        <TextField
                          className={classes.textField}
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                          }}
                          value={props.domainData.renderersInput.size.width}
                          onChange={(event) => {
                            let width = 0
                            try {
                              width =
                                event.target.value === ''
                                  ? 0
                                  : parseInt(event.target.value)
                            } catch (e: unknown) {
                              width = 0
                            }
                            if (Number.isNaN(width)) {
                              width = 0
                            }
                            props.setRenderersInput({
                              ...props.domainData.renderersInput,
                              size: {
                                ...props.domainData.renderersInput.size,
                                width,
                              },
                            })
                          }}
                        />
                      </Box>
                      <Box mt={2}>
                        <FormHelperText>縦</FormHelperText>
                        <TextField
                          className={classes.textField}
                          value={props.domainData.renderersInput.size.height}
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                          }}
                          onChange={(event) => {
                            let height = 0
                            try {
                              height =
                                event.target.value === ''
                                  ? 0
                                  : parseInt(event.target.value)
                            } catch (e: unknown) {
                              height = 0
                            }
                            if (Number.isNaN(height)) {
                              height = 0
                            }
                            props.setRenderersInput({
                              ...props.domainData.renderersInput,
                              size: {
                                ...props.domainData.renderersInput.size,
                                height,
                              },
                            })
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box mt={2} ml={2}>
                    <Typography>Depth</Typography>
                    <Box ml={2}>
                      <FormHelperText>Min</FormHelperText>
                      <Box>
                        <TextField
                          className={classes.textField}
                          value={minInputValue}
                          inputProps={{
                            inputMode: 'numeric',
                          }}
                          onChange={handleDepthChange('min', setMinInputValue)}
                          error={maxInputValue <= minInputValue}
                          helperText={
                            maxInputValue <= minInputValue
                              ? 'Maxより小さい値を設定してください'
                              : undefined
                          }
                        />
                      </Box>
                      <Box mt={2}>
                        <FormHelperText>Max</FormHelperText>
                        <TextField
                          className={classes.textField}
                          value={maxInputValue}
                          inputProps={{
                            inputMode: 'numeric',
                          }}
                          onChange={handleDepthChange('max', setMaxInputValue)}
                          error={maxInputValue <= minInputValue}
                          helperText={
                            maxInputValue <= minInputValue
                              ? 'Minより大きい値を設定してください'
                              : undefined
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box mt={2} ml={2}>
                    <Typography>アウトプット形式</Typography>
                    <Box ml={2}>
                      <FormHelperText>アノテーション種別</FormHelperText>
                      <Box>
                        <FormControl
                          variant='outlined'
                          className={classes.selectBox}
                        >
                          <Select
                            value={
                              props.domainData.outputFormat?.datasetTemplateId
                            }
                            onChange={(event) =>
                              handleAnnotationKindChange(event.target.value)
                            }
                          >
                            {datasetTemplates.map((datasetTemplate) => (
                              <MenuItem
                                data-testid={datasetTemplate.datasetTemplateId}
                                value={datasetTemplate.datasetTemplateId}
                                key={datasetTemplate.datasetTemplateId}
                              >
                                {datasetTemplate.metadata.name.ja}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                      {!isUndefined(trainValidDatasetTemplateId) &&
                        trainValidDatasetTemplateId ===
                          props.domainData.outputFormat?.datasetTemplateId && (
                          <Box mt={2}>
                            <FormHelperText>
                              Train : Valid 比率（%）
                            </FormHelperText>
                            <TextField
                              className={classes.textField}
                              value={
                                props.domainData.outputFormat?.trainValidRatio
                              }
                              inputProps={{
                                inputMode: 'numeric',
                              }}
                              onChange={(event) =>
                                handleTrainValidRatioChange(event.target.value)
                              }
                              error={
                                isUndefined(
                                  props.domainData.outputFormat?.trainValidRatio
                                ) ||
                                (props.domainData.outputFormat
                                  ?.trainValidRatio != null &&
                                  (props.domainData.outputFormat
                                    ?.trainValidRatio < 0 ||
                                    props.domainData.outputFormat
                                      ?.trainValidRatio > 100))
                              }
                              helperText={
                                isUndefined(
                                  props.domainData.outputFormat?.trainValidRatio
                                ) ||
                                (props.domainData.outputFormat
                                  ?.trainValidRatio != null &&
                                  (props.domainData.outputFormat
                                    ?.trainValidRatio < 0 ||
                                    props.domainData.outputFormat
                                      ?.trainValidRatio > 100))
                                  ? '0 ~ 100 の値を設定してください'
                                  : undefined
                              }
                            />
                          </Box>
                        )}
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Dialog
              maxWidth={'md'}
              open={openPresetDialog}
              fullWidth={true}
              onClose={() => setOpenPresetDialog(false)}
            >
              <Box m={2}>
                <DialogContent>
                  <Box ml={2}>
                    <Typography>シーンプリセット</Typography>
                    <Box ml={2} mt={2}>
                      <CustomTrainingPageParagraph>
                        <FormControl
                          variant='outlined'
                          className={classes.selectBox}
                        >
                          <InputLabel>データ種別</InputLabel>
                          <Select
                            data-testid='select-preset-data-kind'
                            value={
                              props.domainData.presetListDisplayCondition
                                .selectedUserGroupKind
                            }
                            onChange={(
                              e: SelectChangeEvent<
                                'UserGroup' | 'SharedUserGroup'
                              >
                            ) =>
                              selectedUserGroupKind(
                                e.target.value as
                                  | 'UserGroup'
                                  | 'SharedUserGroup'
                              )
                            }
                            label='Select User Group Kind'
                          >
                            {userGroupKindList.map((kind) => (
                              <MenuItem
                                data-testid={kind.kind}
                                value={kind.kind}
                                key={kind.kind}
                              >
                                {kind.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </CustomTrainingPageParagraph>
                      <CustomTrainingPageParagraph level={'chapter'}>
                        <div className={classes.searchForm}>
                          <div className={classes.searchField}>
                            <SearchInput
                              placeholder='検索（プリセットID）'
                              value={tableSearchValue}
                              onChangeValue={(event) =>
                                handleChangeSearchValue(event.target.value)
                              }
                              onClickSearch={() => searchTableContent()}
                              onPressEnter={() => searchTableContent()}
                            />
                          </div>
                        </div>
                        <SelectableTable
                          displayNumber={
                            props.domainData.presetListDisplayCondition
                              .displayNumber
                          }
                          headers={PRESET_HEADERS}
                          rows={presetRows}
                          totalCount={
                            searchedPresetListLength ??
                            props.domainData.presetList.length
                          }
                          tableHeight={
                            TABLE_HEADER_HEIGHT + 10 * RADIO_ROW_HEIGHT
                          }
                          onChangeDisplayNumber={(displayNumber: number) =>
                            handleChangeDisplayNumber(displayNumber)
                          }
                          selectedRowNumber={selectDialogSelectedPreset}
                          fixedColumnNumber={0}
                          page={
                            props.domainData.presetListDisplayCondition
                              .pageNumber
                          }
                          onClickRadio={(row: number) =>
                            setSelectDialogSelectedPreset(row)
                          }
                          onClickPageChange={(pageNumber: number) =>
                            pageChange(pageNumber)
                          }
                        />
                      </CustomTrainingPageParagraph>
                    </Box>
                  </Box>
                </DialogContent>
                <div className={classes.contentBtn}>
                  <Button
                    className={classes.cancelButton}
                    onClick={() => {
                      setOpenPresetDialog(false)
                      props.setSelectedPreset(undefined)
                    }}
                    color={'inherit'}
                  >
                    戻る
                  </Button>
                  <Button
                    className={classes.settingButton}
                    onClick={() => {
                      selectPresetTableRadio(selectDialogSelectedPreset)
                      setOpenPresetDialog(false)
                    }}
                    data-testid='set-select-preset'
                  >
                    <Typography>設定</Typography>
                  </Button>
                </div>
              </Box>
            </Dialog>
          </div>
        )
      case 'PlacementState':
        return (
          <div className={classes.stepContainer}>
            <Typography>レイアウト</Typography>
            <Box ml={2}>
              <FormControl variant='outlined' className={classes.selectBox}>
                <Select
                  value={props.domainData.selectedLayout?.id}
                  onChange={(_event, value) => {
                    const layout = props.domainData.layouts.find(
                      (layout) => layout.id === value
                    )

                    if (isUndefined(layout)) return

                    props.setSelectedLayout(layout)
                  }}
                  defaultValue={props.domainData.selectedLayout?.id}
                >
                  {props.domainData.layouts.map((layout) => (
                    <MenuItem
                      data-testid={layout.id}
                      value={layout.id}
                      key={layout.id}
                    >
                      {layout.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box mt={2}>
              <Typography>配置データ(JSON) </Typography>
            </Box>
            <Box ml={2} mb={2}>
              <FileSelectableDropzone
                acceptFileOperation={(files: File[]) =>
                  props.setPlacementData(files)
                }
                rejectedFileOperation={(fileRejections: FileRejection[]) =>
                  fileUploadErrors(fileRejections)
                }
                fileType='.json'
                data-testid='placementFileDropZone'
              />
            </Box>
            {props.domainData.uploadedPlacementData && (
              <Box ml={2} mb={2}>
                <Accordion expanded>
                  <AccordionSummary
                    style={{ pointerEvents: 'none' }}
                    expandIcon={<></>}
                  >
                    <Typography variant='body1' className={classes.labelText}>
                      {props.domainData.uploadedPlacementData.file.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ borderTop: 'dotted 1px' }}>
                    <List style={{ width: '100%' }}>
                      {props.domainData.uploadedPlacementData.targetIds.map(
                        (targetId, index) => {
                          return (
                            <Box mt={2} key={index}>
                              <Typography>{`tgt_id: ${targetId}`}</Typography>
                              <Box ml={2}>
                                <FormControl
                                  variant='outlined'
                                  className={classes.selectBox}
                                >
                                  <Select
                                    data-testid='select-tgt-id'
                                    value={
                                      props.domainData.uploaded3dData.find(
                                        (data) => data.targetId === targetId
                                      )?.file.name ?? ''
                                    }
                                    defaultValue=''
                                    onChange={(e: SelectChangeEvent<string>) =>
                                      props.setSelectedTrgId(
                                        e.target.value as string,
                                        targetId
                                      )
                                    }
                                  >
                                    {props.domainData.uploaded3dData.map(
                                      (upload3dData, index) => (
                                        <MenuItem
                                          key={index}
                                          data-testid={upload3dData.file.name}
                                          value={upload3dData.file.name}
                                        >
                                          {upload3dData.file.name}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              </Box>
                            </Box>
                          )
                        }
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
          </div>
        )
      case 'MetaDataState':
        return (
          <div className={classes.stepContainer}>
            <CustomTrainingPageParagraph level={'part'}>
              <CustomTrainingPageParagraph
                level={'part'}
                title={'データセット生成情報（3D CAD データ）'}
              >
                <MetadataInput
                  nameProps={{
                    label: '表示名',
                    value: props.domainData.augmentationMetaData?.name,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      props.setAugmentationMetaData({
                        ...props.domainData.augmentationMetaData,
                        name: e.target.value,
                      }),
                  }}
                  remarksProps={{
                    label: '備考',
                    value: props.domainData.augmentationMetaData?.remarks,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      props.setAugmentationMetaData({
                        ...props.domainData.augmentationMetaData,
                        remarks: e.target.value,
                      }),
                    rowNum: 0,
                  }}
                  helperText=''
                  data-testid={'augmentation-input'}
                />
              </CustomTrainingPageParagraph>
              <CustomTrainingPageParagraph
                level={'part'}
                title={'データセット情報'}
              >
                <MetadataInput
                  nameProps={{
                    label: '表示名',
                    value: props.domainData.generateDatasetMetaData?.name,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      props.setGenerateDatasetMetaData({
                        ...props.domainData.generateDatasetMetaData,
                        name: e.target.value,
                      }),
                  }}
                  remarksProps={{
                    label: '備考',
                    value: props.domainData.generateDatasetMetaData?.remarks,
                    variant: 'outlined',
                    readOnly: false,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      props.setGenerateDatasetMetaData({
                        ...props.domainData.generateDatasetMetaData,
                        remarks: e.target.value,
                      }),
                    rowNum: 0,
                  }}
                  helperText=''
                  data-testid={'generate-dataset-input'}
                />
              </CustomTrainingPageParagraph>
            </CustomTrainingPageParagraph>
          </div>
        )
      case 'ExecuteState':
        return (
          <div className={classes.stepContainer}>
            <AugmentationErrorMessage {...props} />
            <MetaDataInfoConfirmView {...props} />
            <Aug3dDataInfoConfirmView {...props} />
            <RenderSettingInfoConfirmView {...props} />
            <Box mb={2}>
              <PlacementInfoConfirmView {...props} />
            </Box>
          </div>
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <div className={classes.container}>
        <Box pl={7}>
          <BreadcrumbsComponent
            breadcrumbsPath={[
              {
                name: 'オーグメンテーション一覧',
                path: 'dataset-augmentations',
              },
              {
                name: 'データセット生成 (CADデータ)',
                path: 'entry',
              },
            ]}
          />
        </Box>
        <Toast containerOptions={{ limit: 20 }}>
          <div className={classes.head}>
            <div className={classes.flexAndBetween}>
              <h2
                className={classes.pageTitle}
                data-testid='dataset-augmentation-title'
              >
                データセット生成 (CADデータ)
              </h2>
              <div className={classes.confirmButton}>
                <Tooltip title='設定情報' placement='bottom'>
                  <IconButton
                    onClick={() => {
                      setOpenConfirmDialog(true)
                    }}
                  >
                    <ReceiptLongIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div className={classes.stepper}>
              <CommonStepper
                stepLabels={STEP_NAMES}
                activeStepIndex={currentStep}
              />
            </div>
          </div>
          <div className={classes.content} ref={contentRef}>
            {getStepContent(props)}
          </div>
          <div className={classes.footerButtons}>
            <div className={classes.footer}>
              {props.appState.datasetAugmentationState !== '3dDataState' ? (
                <Button
                  variant='contained'
                  color='primary'
                  disabled={false}
                  onClick={() => prevPageAction()}
                  className={classes.leftButton}
                >
                  {'戻る'}
                </Button>
              ) : (
                <div></div>
              )}
              <Button
                data-testid='next-step'
                variant='contained'
                color='primary'
                disabled={!enableNextButton}
                onClick={() => nextPageAction()}
                className={classes.rightButton}
              >
                {props.appState.datasetAugmentationState === 'ExecuteState'
                  ? '開始'
                  : '次へ'}
              </Button>
            </div>
          </div>

          <MLPipelineCompleteDialog
            open={
              !isUndefined(props.domainData.executionInfo?.mlPipelineId) &&
              !isUndefined(props.domainData.executionInfo?.stepId)
            }
            value={props.domainData.executionInfo?.mlPipelineId ?? ''}
            secondValueItem={{
              label: 'ステップ ID',
              value: props.domainData.executionInfo?.stepId ?? '',
            }}
            handleClose={() => history.push('/dataset-augmentations')}
            label={'MLパイプラインID'}
            dialogText={'正常に生成しました。'}
            data-testid={'dataset-id'}
          />

          <ConfirmViewerDialog
            maxWidth='xl'
            open={openConfirmDialog}
            message={
              <Box width='700px'>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.domainData.generateDatasetMetaData !== undefined &&
                      props.domainData.generateDatasetMetaData?.name !== ''
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                  <MetaDataInfoConfirmView {...props} />
                </Box>
                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.domainData.uploaded3dData.length !== 0
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                  <Aug3dDataInfoConfirmView {...props} />
                </Box>

                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.domainData.selectedPreset !== undefined
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />
                  <RenderSettingInfoConfirmView {...props} />
                </Box>

                <Box display='flex' paddingTop={2}>
                  <Checkbox
                    sx={
                      props.appState.datasetAugmentationSubState
                        .placementSubState === 'InputRequired'
                        ? {
                            color: 'blue',
                            '&.Mui-checked': {
                              color: 'blue',
                            },
                          }
                        : undefined
                    }
                    checkedIcon={<CheckCircleIcon />}
                    disabled
                    checked
                  />

                  <PlacementInfoConfirmView {...props} />
                </Box>
              </Box>
            }
            handleClose={() => setOpenConfirmDialog(false)}
          />
          <GlobalLoading open={props.appState.inProgress} />
        </Toast>
      </div>
    </>
  )
}

const tableActions = (props: Props, currentFileIndex: number) => {
  const globalTheme = useTheme()
  /** 検索ワードを一時保持 */
  const [tableSearchValue, setTableSearchValue] = useState<string>('')

  /** 検索結果結果の配列の長さを保持(Preset) */
  const [searchedPresetListLength, setSearchedPresetListLength] = useState<
    number | undefined
  >(undefined)

  /** 検索ワードの変更 */
  const handleChangeSearchValue = (value: string) => {
    setTableSearchValue(value)
  }
  /** テーブルに表示するテクスチャのJSXの２次元配列 */
  const textureRows = useMemo(() => {
    const rowData = props.domainData.textureList.map((texture) => {
      return {
        name: texture.name,
        overview: texture.overview,
      }
    })

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        return (
          <Typography key={key} data-testid={`texture-${value}`}>
            {value || TABLE_CELL_NOT_APPLICABLE}
          </Typography>
        )
      })
    )
  }, [props.domainData.textureList])

  /** テクスチャのラジオボタン押下時処理 */
  const selectTextureTableRadio = (row: number) => {
    const texture = row > -1 ? props.domainData.textureList[row] : undefined
    props.setSelectedTexture(texture, currentFileIndex)
  }

  /** 表示対象のプリセットデータ */
  const adjustPresetListTableRow = useMemo(() => {
    if (!isUndefined(props.domainData.presetListDisplayCondition)) {
      let newPresetListArray = props.domainData.presetList
      /** 検索ワードから検索 */
      if (props.domainData.presetListDisplayCondition.searchValue) {
        newPresetListArray = newPresetListArray.filter(
          (item) =>
            item.id.startsWith(
              props.domainData.presetListDisplayCondition.searchValue
            ) ||
            item.id === props.domainData.presetListDisplayCondition.searchValue
        )
        /** 検索後の配列の長さをセット */
        setSearchedPresetListLength(newPresetListArray.length)
        props.setSelectedPreset(newPresetListArray[0])
      } else {
        /** 検索後の配列の長さをセット */
        setSearchedPresetListLength(undefined)
      }
      /** 表示するベースモデルの整形 */
      return newPresetListArray.slice(
        props.domainData.presetListDisplayCondition.displayNumber *
          props.domainData.presetListDisplayCondition.pageNumber,
        (props.domainData.presetListDisplayCondition.pageNumber + 1) *
          props.domainData.presetListDisplayCondition.displayNumber
      )
    }
    return props.domainData.presetList
  }, [props.domainData.presetListDisplayCondition, props.domainData.presetList])

  /** テーブルに表示するプリセットのJSXの２次元配列 */
  const presetRows = useMemo(() => {
    const rowData = adjustPresetListTableRow.map((preset) => {
      return {
        name: preset.name,
        overview: preset.overview,
        id: preset.id.substring(0, 8),
        background: preset.backgrounds.length,
        light: preset.lightGroups.length,
        camera: preset.cameras.length,
        createdAt: formatDateTimeSec(preset.createdAt.toDate()),
      }
    })

    return rowData.map((data) =>
      Object.entries(data).map(([key, value]) => {
        if (value === '' || isUndefined(value)) {
          return (
            <Box key={key} sx={{ color: globalTheme.palette.text.secondary }}>
              <Typography>{TABLE_CELL_NOT_APPLICABLE}</Typography>
            </Box>
          )
        }
        return (
          <Typography key={key} data-testid={`preset-${value}`}>
            {value}
          </Typography>
        )
      })
    )
  }, [adjustPresetListTableRow, props.domainData.presetList])

  /** プリセットのラジオボタン押下時処理 */
  const selectPresetTableRadio = (row: number) => {
    const preset = row > -1 ? adjustPresetListTableRow[row] : undefined
    props.setSelectedPreset(preset)
  }

  /** 表示件数の変更 */
  const handleChangeDisplayNumber = (displayNumber: number) => {
    switch (props.appState.datasetAugmentationState) {
      case '3dDataState':
        {
          const maxPageNumber =
            Math.ceil(props.domainData.textureList.length / displayNumber) - 1
          if (
            props.domainData.textureListDisplayCondition.pageNumber <=
            maxPageNumber
          ) {
            /** 表示数を変更 */
            props.setTextureListDisplayCondition({
              ...props.domainData.textureListDisplayCondition,
              displayNumber: displayNumber,
            })
          } else {
            /** 表示数を変更 */
            props.setTextureListDisplayCondition({
              ...props.domainData.textureListDisplayCondition,
              pageNumber: maxPageNumber,
              displayNumber: displayNumber,
            })
          }
        }
        return
      case 'RenderSettingState':
        {
          const maxPageNumber =
            Math.ceil(props.domainData.presetList.length / displayNumber) - 1
          if (
            props.domainData.presetListDisplayCondition.pageNumber <=
            maxPageNumber
          ) {
            /** 表示数を変更 */
            props.setPresetListDisplayCondition({
              ...props.domainData.presetListDisplayCondition,
              displayNumber: displayNumber,
            })
          } else {
            /** 表示数を変更 */
            props.setPresetListDisplayCondition({
              ...props.domainData.presetListDisplayCondition,
              pageNumber: maxPageNumber,
              displayNumber: displayNumber,
            })
          }
        }
        return
    }
  }

  /** 検索処理 */
  const searchTableContent = () => {
    switch (props.appState.datasetAugmentationState) {
      case 'RenderSettingState':
        props.setPresetListDisplayCondition({
          ...props.domainData.presetListDisplayCondition,
          searchValue: tableSearchValue,
          pageNumber: 0,
        })
        break
      default:
        break
    }
  }

  /** テーブルのページ切り替え */
  const pageChange = (pageNumber: number) => {
    switch (props.appState.datasetAugmentationState) {
      case '3dDataState':
        props.setTextureListDisplayCondition({
          ...props.domainData.textureListDisplayCondition,
          pageNumber: pageNumber,
        })
        break
      case 'RenderSettingState':
        props.setPresetListDisplayCondition({
          ...props.domainData.presetListDisplayCondition,
          pageNumber: pageNumber,
        })
        break
      default:
        break
    }
  }

  /** ユーザーグループ種別の選択 */
  const selectedUserGroupKind = (
    selectedUserGroupKind: 'UserGroup' | 'SharedUserGroup'
  ) => {
    switch (props.appState.datasetAugmentationState) {
      case '3dDataState':
        props.clearTextureList()
        props.setTextureListDisplayCondition({
          ...props.domainData.textureListDisplayCondition,
          pageNumber: 0,
          displayNumber: 10,
          selectedUserGroupKind: selectedUserGroupKind,
        })
        props.getParams('3dDataState')
        break
      case 'RenderSettingState':
        props.clearPresetList()
        props.setPresetListDisplayCondition({
          ...props.domainData.presetListDisplayCondition,
          pageNumber: 0,
          displayNumber: 10,
          selectedUserGroupKind: selectedUserGroupKind,
        })
        props.getParams('RenderSettingState')
        break
      default:
        break
    }
  }

  return {
    selectedUserGroupKind,
    pageChange,
    handleChangeDisplayNumber,
    textureRows,
    selectTextureTableRadio,
    presetRows,
    selectPresetTableRadio,
    searchTableContent,
    handleChangeSearchValue,
    tableSearchValue,
    searchedPresetListLength,
  }
}

export const DatasetAugmentationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DatasetAugmentation))
