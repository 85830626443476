import React, { useEffect, useState, useMemo } from 'react'
import Paper from '@mui/material/Paper'
import { connect } from 'react-redux'
import clsx from 'clsx'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { ThunkDispatch } from 'redux-thunk'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { State } from 'state/store'
import { robotDataAnalysisExtractedImageDetailOperations } from 'state/ducks/robotDataAnalysisExtractedImageDetail/operations'
import {
  RobotDataAnalysisExtractedImageDetailAction,
  robotDataAnalysisExtractedImageDetailActions,
} from 'state/ducks/robotDataAnalysisExtractedImageDetail/actions'
import {
  BreadcrumbsComponent,
  GlobalLoading,
  OnsiteImageViewer,
  Toast,
  showToast,
  OnsiteInferenceResult,
  OnsiteInferenceResultWithImage,
  OnsiteImageViewerSettingDialog,
  OnsiteInferenceResultDialog,
  CheckButton,
} from 'views/components'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { TabItems } from 'views/components/organisms/tabLayout/types'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import { createColor } from 'utils/colors'
import { isUndefined } from 'utils/typeguard'
import { formatDateTimeSec } from 'views/components/utils/date'
import { InferenceResultDisplayCondition } from 'state/ducks/robotDataAnalysisExtractedImageDetail'
import { InferenceResultV1 } from 'state/utils'
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material'
import {
  getCheckedRobotData,
  setCheckedRobotData,
} from 'views/containers/utils/localStorage'
import { RobotDataAnalysisIcon } from 'views/components/atoms/icon/robotDataAnalysisIcon'
import { createRobotDataAnalysisExtractedImageQueryParams } from 'views/containers/utils/queryParams'

/** How to simplify the description of react-redux. */
const mapStateToProps = (state: State) => ({
  ...state.pages.robotDataAnalysisExtractedImageDetailState,
  ...state.app.domainData,
  executionDataList:
    state.pages.robotDataAnalysisExtractedImagesState.domainData
      .executionDataList,
  queryConditions:
    state.pages.robotDataAnalysisEntryState.domainData.queryConditions,
})

type StateProps = ReturnType<typeof mapStateToProps>
type Dispatch = ThunkDispatch<
  State,
  void,
  RobotDataAnalysisExtractedImageDetailAction
>

const mapDispatchToProps = (dispatch: Dispatch) => ({
  /** クエリデータ取得 */
  getQueryDataList: (trainingDataId: string) =>
    dispatch(
      robotDataAnalysisExtractedImageDetailOperations.getQueryDataList(
        trainingDataId
      )
    ),
  /** 実行データ取得 */
  getExecutionLog: (trainingDataId: string) =>
    dispatch(
      robotDataAnalysisExtractedImageDetailOperations.getExecutionLog(
        trainingDataId
      )
    ),
  /** コンディションの設定 */
  setInferenceResultDisplayCondition: (
    inferenceResultDisplayCondition: InferenceResultDisplayCondition
  ) =>
    dispatch(
      robotDataAnalysisExtractedImageDetailActions.setInferenceResultDisplayCondition(
        inferenceResultDisplayCondition
      )
    ),
  /** 画像のダウンロード */
  downloadTrainingImage: (trainingDataUrl: string) =>
    dispatch(
      robotDataAnalysisExtractedImageDetailOperations.downloadTrainingImage(
        trainingDataUrl
      )
    ),
  /** 確認アクション */
  confirm: (trainingDataId: string) =>
    dispatch(
      robotDataAnalysisExtractedImageDetailOperations.confirm(trainingDataId)
    ),
  /** トーストに出す情報をクリア */
  deleteToastInfo: () =>
    dispatch(
      robotDataAnalysisExtractedImageDetailActions.setToastInfo(undefined)
    ),
  /** Stateのクリア */
  clearRobotDataAnalysisExtractedImageDetailState: () =>
    dispatch(
      robotDataAnalysisExtractedImageDetailActions.clearRobotDataAnalysisExtractedImageDetailState()
    ),
})
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

const FOOTER_HEIGHT = 100

const useStyles = makeStyles()((theme) => ({
  pageIcon: {
    pointerEvents: 'none',
    paddingLeft: 0,
  },
  contentScroll: {
    width: '100%',
    height: 'calc(100% - 300px)',
    overflow: 'hidden',
    overflowY: 'auto',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(7),
    marginTop: theme.spacing(2),
  },
  stepContainer: {
    overflow: 'hidden',
    padding: `${theme.spacing(2)} ${theme.spacing(7)} ${theme.spacing(1)}`,
  },
  flexAndBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  nowTab: {
    backgroundColor: theme.palette.grey[200],
  },
  innerContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& > .MuiPaper-root': {
      backgroundColor: 'inherit',
    },
  },
  footerButtons: {
    height: FOOTER_HEIGHT,
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
  },
}))

const RobotDataAnalysisExtractedImageDetail: React.FC<Props> = (
  props: Props
) => {
  const { classes } = useStyles()
  const globalTheme = useTheme()
  const history = useHistory()
  const [nowTab, setNowTab] = useState(0)
  const [openSettingDialog, setOpenSettingDialog] = useState(false)
  const [viewScore, setViewScore] = useState(0.5)
  const [openRecognitionDialog, setOpenRecognitionDialog] = useState(false)
  const [recognitionDialogData, setRecognitionDialogData] = useState({
    trainedModelName: '',
    trainedModelVersion: '',
    trainedModelId: '',
    trainedModelGroupId: '',
    userGroupId: '',
    url: '',
    executionDataId: '',
    canvasInfo: {
      id: '',
      color: '',
      score: 0,
      label: '',
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      mask: {
        counts: '',
        size: [0],
      },
    },
    box: [0],
  })
  const [checkedRobotDataState, setCheckedRobotDataState] = useState<{
    [x: string]: { [x: string]: number[][] }
  }>({})

  useEffect(() => {
    // 表示用詳細取得
    const trainingDataId = (props.match.params as { [key: string]: string })[
      'id'
    ]

    props.getQueryDataList(trainingDataId)

    props.getExecutionLog(trainingDataId)

    setCheckedRobotDataState(
      getCheckedRobotData(
        props.authedUser.auth.customClaims.accountGroupId,
        props.authedUser.auth.customClaims.accountId,
        props.authedUser.auth.customClaims.userGroupId
      )
    )
    return () => {
      props.clearRobotDataAnalysisExtractedImageDetailState()
    }
  }, [])

  useEffect(() => {
    if (props.appState.toastInfo) {
      showErrorToast(props.appState.toastInfo.title)
      props.deleteToastInfo()
    }
  }, [props.appState.toastInfo])

  const showErrorToast = (title: string) =>
    showToast(
      'error',
      <div>
        <div>{'メッセージ種別: error'}</div>
        <div>{title}</div>
      </div>
    )

  const updateCheckedRobotData = (
    box: number[],
    trainingDataId: string,
    executionDataId: string
  ) => {
    let value = {}

    const checkedInLocalStorage = getCheckedRobotData(
      props.authedUser.auth.customClaims.accountGroupId,
      props.authedUser.auth.customClaims.accountId,
      props.authedUser.auth.customClaims.userGroupId
    )

    if (checkedInLocalStorage) {
      const tokenTrainingData = checkedInLocalStorage?.[trainingDataId] ?? {}

      const checkedBox =
        checkedInLocalStorage?.[trainingDataId]?.[executionDataId] ?? []

      const checkedBoxIndex = checkedBox.findIndex(
        (checked: number[]) => JSON.stringify(checked) === JSON.stringify(box)
      )

      if (checkedBoxIndex !== -1) {
        checkedBox.splice(checkedBoxIndex, 1)
        value = {
          ...checkedInLocalStorage,
          [trainingDataId]: {
            ...tokenTrainingData,
            [executionDataId]: checkedBox,
          },
        }
      } else {
        value = {
          ...checkedInLocalStorage,
          [trainingDataId]: {
            ...tokenTrainingData,
            [executionDataId]: [...checkedBox, box],
          },
        }
      }
    } else {
      value = {
        [trainingDataId]: {
          [executionDataId]: [box],
        },
      }
    }

    try {
      setCheckedRobotData(
        props.authedUser.auth.customClaims.accountGroupId,
        props.authedUser.auth.customClaims.accountId,
        props.authedUser.auth.customClaims.userGroupId,
        value
      )

      setCheckedRobotDataState(value)
    } catch (e) {
      if (e instanceof DOMException && e.name === 'QuotaExceededError') {
        showErrorToast('MARK できる画像上限数に達しました')
      } else {
        showErrorToast('MARK に失敗しました')
      }
    }
  }

  const labelCount = useMemo(() => {
    let count = 0
    props.domainData.inferenceResults.forEach((inferenceResult) => {
      if (inferenceResult.output) {
        count = count + inferenceResult.output.results.length
      }
    })
    return count
  }, [props.domainData.inferenceResults])

  const inferenceResultsV1 = useMemo(() => {
    const inferenceResults: InferenceResultV1[] = []
    props.domainData.inferenceResults.forEach((inferenceResult) => {
      const trainedModelId =
        props.domainData.executionData?.recognizedData.find(
          (recognizedData) =>
            recognizedData.executionDataId ===
            inferenceResult.robotExecutionDataId
        )?.model.trainedModelId ?? ''

      if (
        props.domainData.inferenceResultDisplayCondition.selectedIds.findIndex(
          (id) => id === trainedModelId
        ) !== -1
      ) {
        inferenceResult.output?.results.forEach((result) => {
          inferenceResults.push(result)
        })
      }
    })
    return inferenceResults
  }, [
    props.domainData.inferenceResults,
    props.domainData.inferenceResultDisplayCondition,
  ])

  const canvasInfos = useMemo(() => {
    if (isUndefined(props.domainData.inferenceResults)) return []
    const uniqueIds = Array.from(
      new Set(inferenceResultsV1.map((result) => result.label.id))
    )
    let idColorMapping: { [x: string]: string } = {}
    uniqueIds.forEach((id) => {
      idColorMapping = {
        ...idColorMapping,
        [`${id}`]: createColor(id).hex(),
      }
    })

    return inferenceResultsV1.map((result) => {
      return {
        id: result.label.id,
        color: idColorMapping[result.label.id],
        score: result.score,
        label: result.label.id.substring(0, 8),
        x: result.box[0],
        y: result.box[1],
        width: result.box[2] - result.box[0],
        height: result.box[3] - result.box[1],
        mask: result.mask,
      }
    })
  }, [inferenceResultsV1])

  const getTargetCanvasInfos = (executionDataId: string) => {
    if (isUndefined(props.domainData.inferenceResults)) return []

    const target = props.domainData.inferenceResults.find(
      (result) => result.robotExecutionDataId === executionDataId
    )
    const uniqueIds = Array.from(
      new Set(target?.output?.results.map((result) => result.label.id))
    )
    let idColorMapping: { [x: string]: string } = {}
    uniqueIds.forEach((id) => {
      idColorMapping = {
        ...idColorMapping,
        [`${id}`]: createColor(id).hex(),
      }
    })

    return target && target.output
      ? target.output.results.map((result) => {
          return {
            id: result.label.id,
            color: idColorMapping[result.label.id],
            score: result.score,
            label: result.label.id.substring(0, 8),
            x: result.box[0],
            y: result.box[1],
            width: result.box[2] - result.box[0],
            height: result.box[3] - result.box[1],
            mask: result.mask,
          }
        })
      : []
  }

  const getSelectedIdArray = (selectedIds: string[], selectId: string) => {
    const index = selectedIds.findIndex((id) => id === selectId)

    if (index === -1) {
      selectedIds.push(selectId)
    } else {
      selectedIds.splice(index, 1)
    }

    return selectedIds
  }

  const getChecked = (
    checkedState: { [x: string]: { [x: string]: number[][] } } | null,
    trainingDataId: string,
    executionDataId: string,
    box: number[]
  ): boolean => {
    if (checkedState) {
      const checkedBox =
        checkedState?.[trainingDataId]?.[executionDataId ?? ''] ?? []

      return checkedBox.findIndex(
        (info) => JSON.stringify(info) === JSON.stringify(box)
      ) < 0
        ? false
        : true
    }
    return false
  }

  const tabItems: TabItems[] = [
    {
      label: 'Overview',
      displayInfo: (
        <>
          <Box mb={2}>
            <OnsiteImageViewer
              url={props.domainData.executionData?.url ?? ''}
              canvasInfos={canvasInfos.filter(
                (canvasInfo) => viewScore <= canvasInfo.score * 100
              )}
              canvasDisplayCondition={{
                mask: props.domainData.inferenceResultDisplayCondition.mask,
                bbox: props.domainData.inferenceResultDisplayCondition.bbox,
                label: props.domainData.inferenceResultDisplayCondition.label,
                score: props.domainData.inferenceResultDisplayCondition.label,
                selectedLabelIds:
                  props.domainData.inferenceResultDisplayCondition.selectedIds,
              }}
              displayCount={labelCount}
              onClickSettings={() => setOpenSettingDialog(true)}
            />
          </Box>
          <Divider />
          <Box mt={2}>
            {props.domainData.executionData?.recognizedData.map(
              (result, index) => {
                return (
                  <Box mb={2} key={index}>
                    <OnsiteInferenceResult
                      checked={
                        props.domainData.inferenceResultDisplayCondition.selectedIds.findIndex(
                          (id) => id === result.model.trainedModelId
                        ) !== -1
                          ? true
                          : false
                      }
                      trainedModelName={result.model.trainedModelName}
                      trainedModelVersion={result.model.trainedModelVersion}
                      trainedModelId={result.model.trainedModelId}
                      canvasInfos={getTargetCanvasInfos(result.executionDataId)}
                      onClickLink={() =>
                        history.replace(
                          `/model-groups/${
                            result.model.trainedModelGroupId
                          }/models/${result.model.trainedModelId}${
                            result.model.userGroupId ===
                            props.authedUser.auth.customClaims.sharedList[0]
                              ? '?shared-user-group=true'
                              : ''
                          }`
                        )
                      }
                      onClickCheckbox={() =>
                        props.setInferenceResultDisplayCondition({
                          ...props.domainData.inferenceResultDisplayCondition,
                          selectedIds: getSelectedIdArray(
                            props.domainData.inferenceResultDisplayCondition
                              .selectedIds,
                            result.model.trainedModelId
                          ),
                        })
                      }
                    />
                  </Box>
                )
              }
            )}
          </Box>
          <OnsiteImageViewerSettingDialog
            open={openSettingDialog}
            defaultCheckList={{
              mask: props.domainData.inferenceResultDisplayCondition.mask,
              bbox: props.domainData.inferenceResultDisplayCondition.bbox,
            }}
            handleClose={() => setOpenSettingDialog(false)}
            handleApply={(data: {
              mask: boolean
              bbox: boolean
              score: number
            }) => {
              props.setInferenceResultDisplayCondition({
                ...props.domainData.inferenceResultDisplayCondition,
                mask: data.mask,
                bbox: data.bbox,
              })
              setViewScore(data.score)
            }}
          />
        </>
      ),
    },
    {
      label: 'Object Recognition',
      displayInfo: (
        <>
          <Box mt={2}>
            {props.domainData.inferenceResults.map((inferenceResult) => {
              return inferenceResult.output?.results.map((result, index) => {
                const canvasInfos = getTargetCanvasInfos(
                  inferenceResult.robotExecutionDataId
                )

                const executionData =
                  props.domainData.executionData?.recognizedData.find(
                    (data) =>
                      data.executionDataId ===
                      inferenceResult.robotExecutionDataId
                  )

                return (
                  <Box mb={2} key={index}>
                    <OnsiteInferenceResultWithImage
                      key={index}
                      url={props.domainData.executionData?.url ?? ''}
                      trainedModelName={
                        executionData?.model.trainedModelName ?? ''
                      }
                      trainedModelVersion={
                        executionData?.model.trainedModelVersion ?? ''
                      }
                      trainedModelId={executionData?.model.trainedModelId ?? ''}
                      canvasInfo={canvasInfos[index]}
                      onClickLink={(trainedModelId: string) =>
                        history.replace(
                          `/model-groups/${
                            executionData?.model.trainedModelGroupId
                          }/models/${trainedModelId}${
                            executionData?.model.userGroupId ===
                            props.authedUser.auth.customClaims.sharedList[0]
                              ? '?shared-user-group=true'
                              : ''
                          }`
                        )
                      }
                      checked={getChecked(
                        checkedRobotDataState,
                        props.domainData.executionData?.trainingDataId ?? '',
                        executionData?.executionDataId ?? '',
                        result.box
                      )}
                      onClickCard={() => {
                        setOpenRecognitionDialog(true)
                        setRecognitionDialogData({
                          trainedModelName:
                            executionData?.model.trainedModelName ?? '',
                          trainedModelVersion:
                            executionData?.model.trainedModelVersion ?? '',
                          trainedModelId:
                            executionData?.model.trainedModelId ?? '',
                          trainedModelGroupId:
                            executionData?.model.trainedModelGroupId ?? '',
                          userGroupId: executionData?.model.userGroupId ?? '',
                          executionDataId: executionData?.executionDataId ?? '',
                          url: props.domainData.executionData?.url ?? '',
                          canvasInfo: canvasInfos[index],
                          box: result.box,
                        })
                      }}
                      onClickCheckbox={() => {
                        updateCheckedRobotData(
                          result.box,
                          props.domainData.executionData?.trainingDataId ?? '',
                          executionData?.executionDataId ?? ''
                        )
                      }}
                    />
                  </Box>
                )
              })
            })}
          </Box>

          <OnsiteInferenceResultDialog
            open={openRecognitionDialog}
            trainedModelName={recognitionDialogData.trainedModelName}
            trainedModelVersion={recognitionDialogData.trainedModelVersion}
            trainedModelId={recognitionDialogData.trainedModelId}
            canvasInfo={recognitionDialogData.canvasInfo}
            url={recognitionDialogData.url}
            checked={getChecked(
              checkedRobotDataState,
              props.domainData.executionData?.trainingDataId ?? '',
              recognitionDialogData.executionDataId,
              recognitionDialogData.box
            )}
            handleClickCheck={() =>
              updateCheckedRobotData(
                recognitionDialogData.box,
                props.domainData.executionData?.trainingDataId ?? '',
                recognitionDialogData.executionDataId
              )
            }
            handleClickLink={() =>
              history.replace(
                `/model-groups/${
                  recognitionDialogData.trainedModelGroupId
                }/models/${recognitionDialogData.trainedModelId}${
                  recognitionDialogData.userGroupId ===
                  props.authedUser.auth.customClaims.sharedList[0]
                    ? '?shared-user-group=true'
                    : ''
                }`
              )
            }
            handleClose={() => setOpenRecognitionDialog(false)}
          />
        </>
      ),
    },
  ]

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'success':
        return <CheckCircleOutline fontSize='small' color='success' />
      case 'fail':
        return <ErrorOutline fontSize='small' color='warning' />
      case 'error':
        return <ErrorOutline fontSize='small' color='error' />
    }
  }

  const currentTrainingDataIndex = useMemo(() => {
    return props.executionDataList.findIndex(
      (executionData) =>
        executionData.trainingData.id ===
        props.domainData.executionData?.trainingDataId
    )
  }, [props.executionDataList, props.domainData.executionData])

  const searchParams = createRobotDataAnalysisExtractedImageQueryParams(
    props.queryConditions
  )

  return (
    <>
      {!isUndefined(props.domainData.executionData) ? (
        <Box className={classes.stepContainer}>
          <Toast containerOptions={{ limit: 20 }}>
            <BreadcrumbsComponent
              breadcrumbsPath={[
                {
                  name: 'On-site Data',
                  path: 'robot-data-analysis/entry',
                },
                {
                  name: 'Selected List',
                  path: `/robot-data-analysis/extracted-images${searchParams}`,
                },
                {
                  name:
                    props.domainData.executionData.trainingDataFileName !== ''
                      ? `${props.domainData.executionData.trainingDataFileName}`
                      : `${props.domainData.executionData.trainingDataId}`,
                  path: `${props.domainData.executionData.trainingDataId}`,
                },
              ]}
            />
            <Box display='flex' justifyContent='space-between' width='100%'>
              <Box display='flex' alignItems='center' justifyContent='center'>
                <RobotDataAnalysisIcon
                  className={classes.pageIcon}
                  data-testid='RobotDataAnalysisExtractedImagesTitleIcon'
                />
                <Typography component='div'>
                  <h2 data-testid='robot-data-analysis-extracted-image-detail-title'>
                    {props.domainData.executionData.trainingDataFileName}
                  </h2>
                </Typography>
                <Box display='flex' alignItems='center'>
                  (
                  {getStatusIcon(
                    props.domainData.executionData.executionStatus
                  )}
                  {props.domainData.executionData.trainingDataId})
                </Box>
              </Box>
              <Box display='flex' alignItems='center' justifyContent='center'>
                <IconButton
                  style={{ marginRight: '16px' }}
                  onClick={() =>
                    props.downloadTrainingImage(
                      props.domainData.executionData?.url ?? ''
                    )
                  }
                  data-testid='download-image'
                >
                  <FileDownloadOutlinedIcon />
                </IconButton>
                <CheckButton
                  width='144px'
                  checkedLabel={'CONFIRMED'}
                  uncheckedLabel={'CONFIRM'}
                  checked={props.domainData.executionData.confirmed}
                  onClick={() => {
                    props.confirm(
                      props.domainData.executionData?.trainingDataId ?? ''
                    )
                  }}
                  data-testid='confirm'
                />
              </Box>
            </Box>
            <Divider />
            <Box
              mt={2}
              sx={{ color: globalTheme.palette.text.secondary }}
              display='flex'
            >
              {`executed at: ${formatDateTimeSec(
                props.domainData.executionData?.executedAt.toDate()
              )}`}
              (<PrecisionManufacturingIcon />
              {props.domainData.executionData?.robotId})
            </Box>
            <Box mt={2}>
              <Box
                style={{
                  backgroundColor: '#fafafa',
                }}
              >
                <Tabs
                  indicatorColor='primary'
                  value={nowTab}
                  style={{
                    paddingBottom: '16px',
                    marginBottom: '1px',
                  }}
                  onChange={(_, value) => setNowTab(value)}
                >
                  {tabItems.map((item, index) => (
                    <Tab
                      style={{
                        width: `${100 / tabItems.length}%`,
                        maxWidth: '1200px',
                      }}
                      key={index}
                      className={clsx(nowTab === index && classes.nowTab)}
                      label={item.label}
                      data-testid={`change-tab-${index}`}
                    />
                  ))}
                </Tabs>
              </Box>
            </Box>
            <Box className={classes.contentScroll}>
              <Box className={classes.innerContainer}>
                <Paper elevation={0}>
                  <Box>{tabItems[nowTab].displayInfo}</Box>
                </Paper>
              </Box>
            </Box>
            <div className={classes.footerButtons}>
              <div className={classes.footer}>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={
                    props.executionDataList.length === 0 ||
                    currentTrainingDataIndex === 0
                  }
                  onClick={() =>
                    history.push({
                      pathname: `/robot-data-analysis/extracted-images/${
                        props.executionDataList[currentTrainingDataIndex - 1]
                          .trainingData.id
                      }`,
                    })
                  }
                  className={classes.leftButton}
                  data-testid='prev-button'
                >
                  PREV
                </Button>
                {props.executionDataList.length === 0 ? (
                  <>1/1</>
                ) : (
                  <>
                    {`${currentTrainingDataIndex + 1}/${
                      props.executionDataList.length
                    }`}
                  </>
                )}
                <Button
                  data-testid='next-button'
                  variant='contained'
                  color='primary'
                  disabled={
                    props.executionDataList.length === 0 ||
                    currentTrainingDataIndex + 1 ===
                      props.executionDataList.length
                  }
                  onClick={() =>
                    history.push({
                      pathname: `/robot-data-analysis/extracted-images/${
                        props.executionDataList[currentTrainingDataIndex + 1]
                          .trainingData.id
                      }`,
                    })
                  }
                  className={classes.rightButton}
                >
                  NEXT
                </Button>
              </div>
            </div>
          </Toast>
        </Box>
      ) : (
        <></>
      )}
      <GlobalLoading open={props.appState.inProgress} />
    </>
  )
}

export const RobotDataAnalysisExtractedImageDetailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RobotDataAnalysisExtractedImageDetail))
